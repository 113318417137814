.frame {
    .items.africa-2-frame {
        .road.africa-2 {
            z-index: 10;
            bottom: 12px;
            right: -158px;
        }

        .road.africa-2-1 {
            z-index: 10;
            bottom: 41px;
            right: 253px;
        }

        .country {
            &.hover {
                z-index: 1;

                &.active {
                    z-index: 6;
                }
            }

            &.africa-2 {
                background-image: url("assets/images/zones/africa-2/africa-2.png"), url("assets/images/zones/africa-2/bg.jpg");
                width: 100%;
                height: 100%;
                background-position: bottom right !important;
            }

            &.egypt {
                background-image: url("assets/images/zones/africa-2/egypt.png");
                background-position: 0 0;
                width: 343px;
                height: 253px;
                right: 254px;
                top: -56px;
                z-index: 7;

                &.hover {
                    background-position: -2px -334.2px;
                }
            }

            &.sudan {
                background-image: url("assets/images/zones/africa-2/sudan.png");
                background-position: 0 0;
                width: 343px;
                height: 255px;
                left: 384px;
                top: 112px;
                z-index: 8;

                &.hover {
                    background-position: 1px -340px;
                }
            }

            &.somalia {
                background-image: url("assets/images/zones/africa-2/somalia.png");
                width: 343px;
                height: 284px;
                right: 9px;
                top: 234px;
                background-position: 0 0;
                z-index: 6;

                &.hover {
                    background-position: 4px -318.5px;
                }
            }

            &.kenya {
                background-image: url("assets/images/zones/africa-2/kenya.png");
                width: 343px;
                height: 345px;
                bottom: -61px;
                right: 135px;
                z-index: 9;
                background-position: 0px 0px;

                &.hover {
                    background-position: 0px -345px;
                }
            }
        }

        .poi {
            &.egypt {
                top: 93px;
                right: 433px;
                bottom: unset;
            }

            &.sudan {
                top: 258px;
                right: 425px;
                bottom: unset;
            }

            &.somalia {
                bottom: 138px;
                right: 175px;
                top: unset;
            }

            &.kenya {
                right: 252px;
                top: 480px;
            }

            &.africa-1-3 {
                right: 789px;
                top: 347px;
            }

            &.africa-1-4 {
                right: 763px;
                top: 118px;
            }

            &.quiz-1 {
                top: 497px;
                width: 64px;
                height: 64px;
                background-size: cover;
                right: 81px;
                background-image: url("assets/images/zones/poi-quiz.png");
            }

            &.lock {
                background-image: url("assets/images/zones/zone-poi-lock.png");
            }
        }
    }
}
