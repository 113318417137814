.gallery {
    .leave-button {
        width: 121px;
        height: 134px;
        background: url("assets/images/awards/awards-back.png");
        background-position: bottom;
        border: none;
        outline: none;
        position: absolute;
        z-index: 2;
        left: 0;
        top: 0;

        &:hover {
            background-position: top;
        }
    }

    .slider-back, .slider-next {
        width: 75px;
        height: 67px;
        background: url("assets/images/gallery/gallery-arrows.png");
        background-position: bottom left;
        border: none;
        outline: none;
        position: absolute;
        z-index: 2;
        left: 10px;
        top: 280px;

        &:hover {
            background-position: top left;
        }
    }

    .slider-next {
        background-position: bottom right;
        left: auto;
        right: 10px;

        &:hover {
            background-position: top right;
        }
    }

    .blocks-list {
        width: 736px;
        position: relative;
        top: 150px;
        left: 122px;
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        flex-direction: row-reverse;
        align-items: stretch;
    }

    .gallery-item {
        width: 160px;
        height: 128px;
        border: solid 4px transparent;
        position: relative;
        box-shadow: 0 0 10px rgba($color: #000000, $alpha: .6);
        border-radius: 16px;
        margin: 0 12px 16px 12px;
        cursor: pointer;
        transition: all linear 300ms;

        &:hover {
            border: solid 4px #4482ec;
        }

        img {
            width: 152px;
            height: 120px;
            border-radius: 10px;
        }

        .description-box {
            width: 100%;
            padding: 5px 30px 5px 10px;
            position: absolute;
            bottom: 0;
            left: 0;
            font-family: 'Quicksand', sans-serif;
            background-color: #f1aa47;
            font-weight: bold;
            text-align: left;
            font-size: 0.8rem;
            line-height: 0.8rem;
            color: rgba($color: #000000, $alpha: .8);
            border-radius: 0 0 10px 10px;
        }

        .letter {
            width: 44px;
            height: 44px;
            position: absolute;
            z-index: 999;
            right: -10px;
            bottom: -10px;
            text-align:center!important;
            background-image: url("assets/images/gallery/gallery-circle-for-letter.png");
            background-repeat: no-repeat;
            background-position: center center;
            font-family: 'QuranErafont';
            font-size: 2rem;
            line-height: 44px;
            color: rgba($color: #000000, $alpha: .8);
            font-weight: bold;
        }

        .letter-arabic-medium {
            position: absolute !important;
            top: -1px !important;
            left: 0 !important;
            right: 0 !important;
        }

        .letter-arabic-high {
            position: absolute !important;
            top: -6px !important;
            left: 0 !important;
            right: 0 !important;
        }
    }

    .tab-bar {
        width: 60px;
        display: flex;
        justify-content: space-between;
        position: absolute;
        bottom: 12px;
        left: 460px;

        .tab {
            width: 11px;
            height: 11px;
            border-radius: 50%;
            background-color: #acacac;

            &.active {
                background-color: #e3d513;
            }
        }
    }

    
}


.popup-box {
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: 9999;
    top: 0;
    left: 0;
    background-color: rgba($color: #ffffff, $alpha: .6);

    .in-box {
        width: auto;
        background: #ffffff;
        border: solid 8px rgba(180, 124, 18, 0.4);
        margin: 8% auto;
        position: relative;
        padding: 8px;
        border-radius: 10px;
        display: table;

        .leave-button {
            width: 63px;
            height: 65px;
            background: url("assets/images/activity/leave/leave.png");
            background-position: bottom;
            border: none;
            outline: none;
            position: absolute;
            z-index: 2;
            right: -50px;
            top: -50px;
            left: auto;

            &:hover {
                background-position: top;
            }
        }

        .gallery_popup_img {
            width: 100%;
            max-width: 100%;
        }
    }

    .download-button {
        width: 246px;
        height: 83px;
        display: block;
        background: url("assets/images/awards/download.png");
        background-position: bottom;
        border: none;
        outline: none;
        margin: 15px auto;

        &:hover {
            background-position: top;
        }
    }
}
