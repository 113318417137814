body:before {
    display: none;
    content: url("assets/images/zones/south-america-2/paraguay-hover.png") url("/assets/images/zones/south-america-2/brazil-hover.png") url("/assets/images/zones/south-america-2/peru-hover.png") url("/assets/images/zones/south-america-2/bolivia-hover.png");
}

.frame {
    .items.south-america-2-frame {
        .road {
            height: 600px !important;
        }

        .road.south-america-2 {
            z-index: 10;
            top: -78px;
            right: 109px;
        }

        .country {
            &.hover {
                z-index: 1;

                &.active {
                    z-index: 6;
                }
            }

            &.south-america-2 {
                background-image: url("/assets/images/zones/south-america-2/south-america-2.png"), url("/assets/images/zones/south-america-2/bg.jpg") !important;
                width: 100%;
                height: 101%;
                background-position: bottom right !important;
            }

            &.paraguay {
                background-image: url("/assets/images/zones/south-america-2/paraguay.png");
                width: 267px;
                height: 199px;
                top: 303px;
                right: 274px;
                z-index: 8;

                &.hover {
                    background-image: url("/assets/images/zones/south-america-2/paraguay-hover.png");
                    width: 276px;
                    height: 215px;
                    top: 297px;
                    right: 273.5px;
                    z-index: 8;
                }
            }

            &.bolivia {
                background-image: url("/assets/images/zones/south-america-2/bolivia.png");
                width: 275px;
                height: 268px;
                left: 277px;
                top: 148px;
                z-index: 7;

                &.hover {
                    background-image: url("/assets/images/zones/south-america-2/bolivia-hover.png");
                    width: 275px;
                    height: 282px;
                    left: 268px;
                    top: 143px;
                }
            }

            &.peru {
                background-image: url("/assets/images/zones/south-america-2/peru.png");
                width: 275px;
                height: 373px;
                left: 42px;
                top: -50px;
                z-index: 7;

                &.hover {
                    background-image: url("/assets/images/zones/south-america-2/peru-hover.png");
                    width: 295px;
                    height: 391px;
                    left: 33px;
                    top: -50px;
                }
            }

            &.brazil {
                background-image: url("assets/images/zones/south-america-2/brazil.png");
                width: 839px;
                height: 730px;
                bottom: -33px;
                left: 155px;
                z-index: 6;

                &.hover {
                    background-image: url("assets/images/zones/south-america-2/brazil-hover.png");
                    width: 839px;
                    height: 877px;
                    bottom: -49px;
                    left: 146px;
                }
            }
        }

        .poi {
            &.paraguay {
                top: 362px;
                right: 464px;
                bottom: unset;
            }

            &.bolivia {
                top: 271px;
                right: 485px;
                bottom: unset;
                z-index: 10 !important;
            }

            &.peru {
                top: 63px;
                left: 105px;
                bottom: unset;
            }

            &.brazil {
                top: 183px;
                right: 242px;
            }

            &.quiz-1 {
                top: 9px;
                width: 64px;
                height: 64px;
                background-size: cover;
                right: 320px;
                background-image: url("/assets/images/zones/poi-quiz.png");
            }

            &.lock {
                background-image: url("/assets/images/zones/zone-poi-lock.png");
            }
        }
    }
}
