.frame {
    .items.africa-1-frame {
        .road.africa-1 {
            z-index: 10;
            bottom: -60px;
            right: -100px;
        }

        .country {
            &.hover {
                z-index: 1;

                &.active {
                    z-index: 6;
                }
            }

            &.africa-1 {
                background-image: url("assets/images/zones/africa-1/africa-1.png"), url("assets/images/zones/africa-1/frame-bg.png");
                width: 100%;
                height: 100%;
                background-position: bottom right !important;
            }

            &.morocco {
                background-image: url("assets/images/zones/africa-1/morocco.png");
                background-position: 0 0;
                width: 343px;
                height: 253px;
                right: 333px;
                top: -9px;
                z-index: 7;

                &.hover {
                    background-position: 0px -358px;
                }
            }

            &.mauritania {
                background-image: url("assets/images/zones/africa-1/mauritania.png");
                background-position: 0 -51px;
                width: 343px;
                height: 228px;
                left: 241px;
                top: 167px;
                z-index: 8;

                &.hover {
                    background-position: 0px -369.5px;
                }
            }

            &.nigeria {
                background-image: url("assets/images/zones/africa-1/nigeria.png");
                width: 343px;
                height: 284px;
                right: 82px;
                top: 321px;
                background-position: 0 -14px;
                z-index: 6;

                &.hover {
                    background-position: 0 -351px;
                    right: 76px;
                }
            }

            &.algeria {
                background-image: url("assets/images/zones/africa-1/algeria.png");
                width: 343px;
                height: 345px;
                bottom: 266px;
                right: 179px;
                z-index: 9;
                background-position: 0px 5px;

                &.hover {
                    background-position: 0px -326px;
                }
            }
        }

        .poi {
            &.morocco {
                top: 103px;
                right: 468px;
                bottom: unset;
            }

            &.mauritania {
                top: 268px;
                right: 509px;
                bottom: unset;
            }

            &.nigeria {
                bottom: 88px;
                right: 179px;
                top: unset;
            }

            &.algeria {
                right: 241px;
                top: 150px;
            }

            &.quiz-1 {
                top: 135px;
                width: 64px;
                height: 64px;
                background-size: cover;
                right: 32px;
                background-image: url("assets/images/zones/poi-quiz.png");
            }

            &.lock {
                background-image: url("assets/images/zones/zone-poi-lock.png");
            }
        }
    }
}
