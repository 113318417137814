.books{
    .leave-button {
        width: 121px;
        height: 134px;
        background: url("assets/images/awards/awards-back.png");
        background-position: bottom;
        border: none;
        outline: none;
        position: absolute;
        z-index: 2;
        left: 0;
        top: 0;
        &:hover {
            background-position: top;
        }
    }
    .slider-back, .slider-next
    {
        width: 100px;
        height: 106px;
        background: url("assets/images/games/games-arrows.png");
        background-position: bottom left;
        border: none;
        outline: none;
        position: absolute;
        z-index: 2;
        left: 10px;
        top: 280px;
        &:hover {
            background-position: top left;
        }
    }
    .slider-next
    {
        background-position: bottom right;
        left: auto;
        right: 10px;
        &:hover {
            background-position: top right;
        }
    }
    .blocks-list
    {
        width: 640px;
        position: relative;
        top: 120px;
        left: 170px;
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        align-items: stretch;
    }
    .item
    {
        width: 117px;
        height: 171px;
        position: relative;
        background-image: url("assets/images/books/books.png");
        background-position: left bottom;
        margin: 0 0 70px 0;
        outline: none;
        cursor: pointer;
        padding: 4px;
        &:nth-child(10n+3)
        {
            background-position: -117px bottom;
            &:hover
            {
                background-position: -117px top;
            }
        }
        &:nth-child(10n+2)
        {
            background-position: -234px bottom;
            &:hover
            {
                background-position: -234px top;
            }
        }
        &:nth-child(10n+4)
        {
            background-position: -351px bottom;
            &:hover
            {
                background-position: -351px top;
            }
        }
        &:nth-child(10n+5)
        {
            background-position: -468px bottom;
            &:hover
            {
                background-position: -468px top;
            }
        }
        &:nth-child(10n+6)
        {
            background-position: -468px bottom;
            &:hover
            {
                background-position: -468px top;
            }
        }
        &:nth-child(10n+7)
        {
            background-position: -351px bottom;
            &:hover
            {
                background-position: -351px top;
            }
        }
        &:nth-child(10n+8)
        {
            background-position: -234px bottom;
            &:hover
            {
                background-position: -234px top;
            }
        }
        &:nth-child(10n+9)
        {
            background-position: -117px bottom;
            &:hover
            {
                background-position: -117px top;
            }
        }


        &:hover
        {
            background-position: left top;


        }

        .number
        {
            text-align: center;
            color: rgba($color: #3c2517, $alpha: .8);
            font-family: "Kids Rock DEMO";
            font-size: 1.4rem;
            position: absolute;
            top: 15px;
            left: 20px;
        }
        .description-box
        {
            width: 92px;
            position: absolute;
            top: 104px;
            left: 10px;
            font-family: 'Quicksand', sans-serif;
            font-weight: bold;
            text-align: left;
            font-size: 0.8rem;
            line-height: 0.9rem;
            color: rgba($color: #ffffff, $alpha: .8);
            padding: 14px 4px;
        }
        &.lock
        {
            cursor: default;
            .number
            {
                color: rgba($color: #ffffff, $alpha: .8);
            }
            .description-box{
                &::after
                {
                    background-position: top;
                }
            }
            &::after
            {
                content: '';
                width: 92px;
                height: 95px;
                position: absolute;
                top: 18px;
                left: 10px;
                z-index: 4;
                background-color: rgba($color: #000000, $alpha: .6);
            }
            &::before
            {
                content: '';
                width: 28px;
                height: 33px;
                position: absolute;
                top: 50px;
                right: 46px;
                z-index: 6;
                background-image: url("assets/images/songs/songs-lock-icon.png");
                background-repeat: no-repeat;
                background-position: center center;
            }
        }
    }

}
