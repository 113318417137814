body:before {
    display: none;
    content: url("assets/images/zones/north-america-2/jamaica-hover.png") url("/assets/images/zones/north-america-2/haiti-hover.png") url("/assets/images/zones/north-america-2/dominican-hover.png") url("/assets/images/zones/north-america-2/theBahamas-hover.png");
}

.frame {
    .items.north-america-2-frame {
        .road {
            height: 600px !important;
        }

        .road.north-america-2 {
            z-index: 17;
            top: 55px;
            right: 239px;
        }

        .country {
            &.hover {
                z-index: 1;

                &.active {
                    z-index: 6;
                }
            }

            &.north-america-2 {
                background-image: url("/assets/images/zones/north-america-2/north-america-2.png"), url("/assets/images/zones/north-america-2/bg.jpg") !important;
                width: 100%;
                height: 100%;
                background-position: bottom right !important;
            }

            &.jamaica {
                background-image: url("/assets/images/zones/north-america-2/jamaica.png");
                width: 777px;
                height: 173px;
                bottom: 75px;
                right: 114px;
                top: 365px;
                z-index: 7;

                &.hover {
                    background-image: url("/assets/images/zones/north-america-2/jamaica-hover.png");
                    width: 777px;
                    height: 173px;
                    bottom: 75px;
                    right: 114px;
                    top: 399px;
                    z-index: 7;
                }
            }

            &.haiti {
                background-image: url("/assets/images/zones/north-america-2/haiti.png");
                width: 388px;
                height: 696px;
                left: 352px;
                top: -187px;
                z-index: 8;

                &.hover {
                    background-image: url("/assets/images/zones/north-america-2/haiti-hover.png");
                    width: 388px;
                    height: 696px;
                    left: 352px;
                    top: 351.5px;
                    
                }
            }

            &.dominican {
                background-image: url("/assets/images/zones/north-america-2/dominican.png");
                width: 231px;
                height: 723px;
                right: 251px;
                top: -188px;
                z-index: 6;

                &.hover {
                    background-image: url("/assets/images/zones/north-america-2/dominican-hover.png");
                    width: 231px;
                    height: 723px;
                    right: 251px;
                    top: 322.5px;
                }
            }

            &.theBahamas {
                background-image: url("assets/images/zones/north-america-2/theBahamas.png");
                width: 445px;
                height: 388px;
                top: -39px;
                right: 426px;
                z-index: 9;

                &.hover {
                    background-image: url("assets/images/zones/north-america-2/theBahamas-hover.png");
                    width: 445px;
                    height: 388px;
                    top: -11px;
                    right: 426px;
                    
                }
            }

        }

        .poi {
            &.jamaica {
                top: 438px;
                right: 794px;
            }

            &.haiti {
                top: 427px;
                right: 462px;
                bottom: unset;
            }

            &.dominican {
                top: 418px;
                right: 362px;
                bottom: unset;
            }

            &.theBahamas {
                right: 636px;
                top: 60px;
            }

            &.quiz-1 {
                top: 119px;
                width: 64px;
                height: 64px;
                background-size: cover;
                right: 841px;
                background-image: url("/assets/images/zones/poi-quiz.png");
            }

            &.lock {
                background-image: url("/assets/images/zones/zone-poi-lock.png");
            }
        }
    }
}
