body:before {
    display: none;
    content: url("assets/images/zones/south-america-1/uruguay-hover.png") url("/assets/images/zones/south-america-1/argentina-hover.png") url("/assets/images/zones/south-america-1/falkland-hover.png") url("/assets/images/zones/south-america-1/chile-hover.png");
}

.frame {
    .items.south-america-1-frame {
        .road {
            height: 600px !important;
        }

        .road.south-america-1 {
            z-index: 10;
            top: -38px;
            right: -124px;
        }

        .country {
            &.hover {
                z-index: 1;

                &.active {
                    z-index: 6;
                }
            }

            &.south-america-1 {
                background-image: url("/assets/images/zones/south-america-1/south-america-1.png"), url("/assets/images/zones/south-america-1/bg.jpg") !important;
                width: 100%;
                height: 100%;
                background-position: bottom right !important;
            }

            &.falkland-islands {
                background-image: url("/assets/images/zones/south-america-1/falkland.png");
                width: 220px;
                height: 90px;
                bottom: 77px;
                right: 141px;
                z-index: 7;

                &.hover {
                    background-image: url("/assets/images/zones/south-america-1/falkland-hover.png");
                    width: 220px;
                    height: 90px;
                    bottom: 63px;
                    right: 149.5px;
                    z-index: 7;
                }
            }

            &.chile {
                background-image: url("/assets/images/zones/south-america-1/chile.png");
                width: 320px;
                height: 780px;
                left: 242px;
                top: -212px;
                z-index: 8;

                &.hover {
                    background-image: url("/assets/images/zones/south-america-1/chile-hover.png");
                    width: 320px;
                    height: 780px;
                    left: 233px;
                    top: -199px;
                }
            }

            &.argentina {
                background-image: url("/assets/images/zones/south-america-1/argentina.png");
                width: 350px;
                height: 740px;
                right: 323px;
                top: -170px;
                z-index: 6;

                &.hover {
                    background-image: url("/assets/images/zones/south-america-1/argentina-hover.png");
                    width: 350px;
                    height: 740px;
                    right: 331px;
                    top: -164px;
                }
            }

            &.uruguay {
                background-image: url("assets/images/zones/south-america-1/uruguay.png");
                width: 200px;
                height: 140px;
                top: 19px;
                right: 256px;
                z-index: 9;

                &.hover {
                    background-image: url("assets/images/zones/south-america-1/uruguay-hover.png");
                    width: 200px;
                    height: 140px;
                    top: 29px;
                    right: 266px;
                }
            }
        }

        .poi {
            &.falkland-islands {
                bottom: 65px;
                right: 343px;
                top: unset;
            }

            &.chile {
                top: 355px;
                right: 572px;
                bottom: unset;
            }

            &.argentina {
                top: 200px;
                right: 463px;
                bottom: unset;
            }

            &.uruguay {
                right: 373px;
                top: 40px;
            }

            &.quiz-1 {
                top: 14px;
                width: 64px;
                height: 64px;
                background-size: cover;
                right: 482px;
                background-image: url("/assets/images/zones/poi-quiz.png");
            }

            &.lock {
                background-image: url("/assets/images/zones/zone-poi-lock.png");
            }
        }
    }
}
