@font-face {
    font-family: 'QuranErafont';
    src: url('assets/fonts/quranera-font/QuranErafont.eot');
    src: url('assets/fonts/quranera-font/QuranErafont.eot?#iefix') format('embedded-opentype'), url('assets/fonts/quranera-font/QuranErafont.woff') format('woff'), url('assets/fonts/quranera-font/QuranErafont.ttf') format('truetype'), url('assets/fonts/quranera-font/QuranErafont.svg#QuranErafont') format('svg');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'HafsQuranEra';
    src: local('HafsQuranEra'), local('HafsQuranEra-Regular'), url('assets/fonts/Hafs/HafsQuranEra.eot');
    src: url('assets/fonts/Hafs/HafsQuranEra.eot');
    src: url('assets/fonts/Hafs/HafsQuranEra.eot?#iefix') format('embedded-opentype'), url('assets/fonts/Hafs/HafsQuranEra.woff') format('woff'), url('assets/fonts/Hafs/HafsQuranEra.ttf') format('truetype'), url('assets/fonts/Hafs/HafsQuranEra.svg#HafsQuranEra') format('svg');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'QuranEraV3';
    src: url('assets/fonts/quraneraV3/QuranEraV3.eot');
    src: url('assets/fonts/quraneraV3/QuranEraV3.eot?#iefix') format('embedded-opentype'), url('assets/fonts/quraneraV3/QuranEraV3.woff') format('woff'), url('assets/fonts/quraneraV3/QuranEraV3.ttf') format('truetype'), url('assets/fonts/quraneraV3/QuranEraV3.svg#QuranEraFontv3') format('svg');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'QuranEraV2';
    src: url('assets/fonts/quraneraV2/Quran Era Font v2.eot');
    src: url('assets/fonts/quraneraV2/Quran Era Font v2.eot?#iefix') format('embedded-opentype'), url('assets/fonts/quraneraV2/Quran Era Font v2.woff') format('woff'), url('assets/fonts/quraneraV2/Quran Era Font v2.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
}
