/* For use in src/lib/core/theming/_palette.scss */

$md-primary: (50: #e8e8ec, 100: #c7c6ce, 200: #a1a1ae, 300: #7b7b8e, 400: #5f5e75, 500: #43425d, 600: #3d3c55, 700: #34334b, 800: #2c2b41, 900: #1e1d30, A100: #7777ff, A200: #4444ff, A400: #1111ff, A700: #0000f6, contrast: (50: #000000, 100: #000000, 200: #000000, 300: #ffffff, 400: #ffffff, 500: #ffffff, 600: #ffffff, 700: #ffffff, 800: #ffffff, 900: #ffffff, A100: #000000, A200: #ffffff, A400: #ffffff, A700: #ffffff, ));
$md-accent: (50 : #fef6e4,
    100 : #fce8ba,
    200 : #fad98d,
    300 : #f8c95f,
    400 : #f7be3c,
    500 : #f5b21a,
    600 : #f4ab17,
    700 : #f2a213,
    800 : #f0990f,
    900 : #ee8a08,
    A100 : #ffffff,
    A200 : #fff2e3,
    A400 : #ffd9b0,
    A700 : #ffcd96,
    contrast: (50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #ffffff,
        400 : #ffffff,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    ));