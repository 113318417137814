header {
    height: auto;
    padding: 12px 0 0 0;

    .header-row {
        display: flex;
        align-items: flex-start;
    }

    .logo-box {
        margin-right: 20px;
        margin-top: 50px;
        flex: 0;
        text-align: center;
        //margin: 0 60px 0 100px;
        outline: none;

        img {
            width: auto;
            max-width: 240px;
        }
    }

    .col-auto {
        align-self: center;
    }

    .menu {
        width: 602px;
        height: 100px;
        background-image: url("assets/images/header/bg.png");
        background-repeat: no-repeat;
        background-position: 0 51px;
        margin-top: 12px;

        ul {
            list-style: none;
            padding: 0;
            margin: 0;
            display: flex;
            justify-content: center;

            li {
                height: 100px;
                position: relative;
            }

            a {
                width: 114px;
                height: 93px;
                display: block;
                cursor: pointer;
                background-image: url("assets/images/header/lessons.png");
                background-repeat: no-repeat;
                background-position: bottom;
                &:hover {
                    background-position: top;
                }


                &.Stories
                {
                    background-image: url("assets/images/header/stories.png");
                }


                &.videos
                {
                    background-image: url("assets/images/header/videos.png");
                }
                &.books
                {
                    background-image: url("assets/images/header/mbooks.png");
                }
                &.songs
                {
                    background-image: url("assets/images/header/songs.png");
                }
                &.games
                {
                    background-image: url("assets/images/header/games.png");
                }
            }
        }
    }


    .profile-box {
        width: 289px;
        height: 147px;
        background-image: url("assets/images/header/profile-bg.png");
        background-repeat: no-repeat;
        background-position: bottom;
        margin: 10px 0 0 20px;
        position: relative;
        outline: none;
        .avatar {
            width: 142px;
            height: 142px;
            background-image: url("assets/images/header/profile-avatar-bg.png");
            background-repeat: no-repeat;
            background-position: bottom;
            position: relative;
            left: -5px;
            top: 4px;
            outline: none;
            &:hover {
                background-position: top;
            }

            img {
                width: 110px;
                height: 140px;
                border-radius: 0 0 50% 50%;
                position: absolute;
                top: -16px;
                left: 14px;
                cursor: pointer;
            }
        }

        .logout {
            width: 72px;
            height: 22px;
            position: absolute;
            background: url("assets/images/header/logout.png");
            background-repeat: no-repeat;
            background-position: bottom;
            right: 40px;
            bottom: 16px;
            border: none;
            outline: none;

            &:hover {
                background-position: top;
            }
        }

        .assignment-bell {
            color: #00cd60;
            cursor: pointer;
            position: absolute;
            top: 30px;
            right: 20px;
        }

        .name-box,
        .points {
            position: absolute;
            left: 134px;
            top: 30px;
            font-family: "Kids Rock DEMO";
            font-size: 1.2rem;
            vertical-align: middle;
            background: linear-gradient(to bottom, #faf5cf, #fbf4c2, #faf5cf);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            -webkit-text-stroke: 1px #612e0d;
            -webkit-filter: drop-shadow(1px 1px #612e0d);
            filter: drop-shadow(1px 1px #612e0d);
            cursor: pointer;
        }

        .points {
            left: 174px;
            top: 70px;
        }
    }
}

.header-profile-box {
    position: absolute;
    z-index: 10;
    float: right;
    right: 10px;
    padding: 0;
}

