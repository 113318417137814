/* You can add global styles to this file, and also import other style files */
@import url('https://fonts.googleapis.com/css?family=Quicksand&display=swap');

@import 'assets/scss/kidsRock';
@import 'assets/scss/ulitka';
// @import 'assets/scss/adobeNaskh';
@import 'assets/scss/arabic-typesetting';
@import 'assets/scss/nunito';
@import 'assets/scss/quranera-font';
@import 'assets/scss/vars';
@import '~bootstrap/scss/bootstrap-reboot';
@import '~bootstrap/scss/bootstrap-grid';
@import 'assets/scss/main';
@import 'assets/scss/header';
@import 'assets/scss/awards';
@import 'assets/scss/progress';
@import 'assets/scss/games';
@import 'assets/scss/gallery';
@import 'assets/scss/letters';
@import 'assets/scss/drag-and-drop';
@import 'assets/scss/songs';
@import 'assets/scss/letter-song';
@import 'assets/scss/books';
@import 'assets/scss/page';
@import 'assets/scss/cabinet';
@import 'assets/scss/inner-login';
@import 'assets/scss/admin/styles';
