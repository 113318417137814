@charset "UTF-8";
/* You can add global styles to this file, and also import other style files */
@import url("https://fonts.googleapis.com/css?family=Quicksand&display=swap");
@font-face {
  font-family: "Kids Rock DEMO";
  src: url("assets/fonts/KidsRockDEMO-Regular.svg#KidsRockDEMO-Regular") format("svg"), url("assets/fonts/KidsRockDEMO-Regular.ttf") format("truetype"), url("assets/fonts/KidsRockDEMO-Regular.woff") format("woff");
  src: url("assets/fonts/KidsRockDEMO-Regular1.eot");
  src: url("assets/fonts/KidsRockDEMO-Regular1.eot?#iefix") format("embedded-opentype"), url("assets/fonts/KidsRockDEMO-Regular1.woff2") format("woff2");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Beanstalker DEMO";
  src: url("assets/fonts/beanstalker/BeanstalkerDEMO-Regular.eot");
  src: url("assets/fonts/beanstalker/BeanstalkerDEMO-Regular.eot?#iefix") format("embedded-opentype"), url("assets/fonts/beanstalker/BeanstalkerDEMO-Regular.woff2") format("woff2"), url("assets/fonts/beanstalker/BeanstalkerDEMO-Regular.woff") format("woff"), url("assets/fonts/beanstalker/BeanstalkerDEMO-Regular.ttf") format("truetype"), url("assets/fonts/beanstalker/BeanstalkerDEMO-Regular.svg#BeanstalkerDEMO-Regular") format("svg");
  font-weight: normal;
  font-style: normal;
}
.ulitka {
  font-family: "Kids Rock DEMO" !important;
}

.font-loader {
  position: absolute;
  left: -1000px;
  visibility: hidden;
}

@font-face {
  font-family: "Arabic Typesetting";
  src: url("assets/fonts/arabic-typesetting/Arabic Typesetting.eot");
  src: url("assets/fonts/arabic-typesetting/Arabic Typesetting.eot?#iefix") format("embedded-opentype"), url("assets/fonts/arabic-typesetting/Arabic Typesetting.woff2") format("woff2"), url("assets/fonts/arabic-typesetting/Arabic Typesetting.woff") format("woff"), url("assets/fonts/arabic-typesetting/Arabic Typesetting.ttf") format("truetype"), url("assets/fonts/arabic-typesetting/Arabic Typesetting.svg#Arabic Typesetting") format("svg");
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: "Nunito";
  font-style: normal;
  font-weight: normal;
  src: url("assets/fonts/Nunito/Nunito-Regular.woff") format("woff"), url("assets/fonts/Nunito/Nunito-Regular.ttf") format("truetype");
}
@font-face {
  font-family: "QuranErafont";
  src: url("assets/fonts/quranera-font/QuranErafont.eot");
  src: url("assets/fonts/quranera-font/QuranErafont.eot?#iefix") format("embedded-opentype"), url("assets/fonts/quranera-font/QuranErafont.woff") format("woff"), url("assets/fonts/quranera-font/QuranErafont.ttf") format("truetype"), url("assets/fonts/quranera-font/QuranErafont.svg#QuranErafont") format("svg");
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: "HafsQuranEra";
  src: local("HafsQuranEra"), local("HafsQuranEra-Regular"), url("assets/fonts/Hafs/HafsQuranEra.eot");
  src: url("assets/fonts/Hafs/HafsQuranEra.eot");
  src: url("assets/fonts/Hafs/HafsQuranEra.eot?#iefix") format("embedded-opentype"), url("assets/fonts/Hafs/HafsQuranEra.woff") format("woff"), url("assets/fonts/Hafs/HafsQuranEra.ttf") format("truetype"), url("assets/fonts/Hafs/HafsQuranEra.svg#HafsQuranEra") format("svg");
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: "QuranEraV3";
  src: url("assets/fonts/quraneraV3/QuranEraV3.eot");
  src: url("assets/fonts/quraneraV3/QuranEraV3.eot?#iefix") format("embedded-opentype"), url("assets/fonts/quraneraV3/QuranEraV3.woff") format("woff"), url("assets/fonts/quraneraV3/QuranEraV3.ttf") format("truetype"), url("assets/fonts/quraneraV3/QuranEraV3.svg#QuranEraFontv3") format("svg");
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: "QuranEraV2";
  src: url("assets/fonts/quraneraV2/Quran Era Font v2.eot");
  src: url("assets/fonts/quraneraV2/Quran Era Font v2.eot?#iefix") format("embedded-opentype"), url("assets/fonts/quraneraV2/Quran Era Font v2.woff") format("woff"), url("assets/fonts/quraneraV2/Quran Era Font v2.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}
/*!
 * Bootstrap Reboot v4.6.1 (https://getbootstrap.com/)
 * Copyright 2011-2021 The Bootstrap Authors
 * Copyright 2011-2021 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 * Forked from Normalize.css, licensed MIT (https://github.com/necolas/normalize.css/blob/master/LICENSE.md)
 */
*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: left;
  background-color: #fff;
}

[tabindex="-1"]:focus:not(:focus-visible) {
  outline: 0 !important;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  text-decoration-skip-ink: none;
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: 0.5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

b,
strong {
  font-weight: bolder;
}

small {
  font-size: 80%;
}

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

a {
  color: #2183dd;
  text-decoration: none;
  background-color: transparent;
}
a:hover {
  color: #175c9a;
  text-decoration: underline;
}

a:not([href]):not([class]) {
  color: inherit;
  text-decoration: none;
}
a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none;
}

pre,
code,
kbd,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 1em;
}

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  -ms-overflow-style: scrollbar;
}

figure {
  margin: 0 0 1rem;
}

img {
  vertical-align: middle;
  border-style: none;
}

svg {
  overflow: hidden;
  vertical-align: middle;
}

table {
  border-collapse: collapse;
}

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #6c757d;
  text-align: left;
  caption-side: bottom;
}

th {
  text-align: inherit;
  text-align: -webkit-match-parent;
}

label {
  display: inline-block;
  margin-bottom: 0.5rem;
}

button {
  border-radius: 0;
}

button:focus:not(:focus-visible) {
  outline: 0;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
input {
  overflow: visible;
}

button,
select {
  text-transform: none;
}

[role=button] {
  cursor: pointer;
}

select {
  word-wrap: normal;
}

button,
[type=button],
[type=reset],
[type=submit] {
  -webkit-appearance: button;
}

button:not(:disabled),
[type=button]:not(:disabled),
[type=reset]:not(:disabled),
[type=submit]:not(:disabled) {
  cursor: pointer;
}

button::-moz-focus-inner,
[type=button]::-moz-focus-inner,
[type=reset]::-moz-focus-inner,
[type=submit]::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

input[type=radio],
input[type=checkbox] {
  box-sizing: border-box;
  padding: 0;
}

textarea {
  overflow: auto;
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal;
}

progress {
  vertical-align: baseline;
}

[type=number]::-webkit-inner-spin-button,
[type=number]::-webkit-outer-spin-button {
  height: auto;
}

[type=search] {
  outline-offset: -2px;
  -webkit-appearance: none;
}

[type=search]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

summary {
  display: list-item;
  cursor: pointer;
}

template {
  display: none;
}

[hidden] {
  display: none !important;
}

/*!
 * Bootstrap Grid v4.6.1 (https://getbootstrap.com/)
 * Copyright 2011-2021 The Bootstrap Authors
 * Copyright 2011-2021 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 */
html {
  box-sizing: border-box;
  -ms-overflow-style: scrollbar;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

.container,
.container-fluid,
.container-xl,
.container-lg,
.container-md,
.container-sm {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .container-sm, .container {
    max-width: 540px;
  }
}
@media (min-width: 768px) {
  .container-md, .container-sm, .container {
    max-width: 720px;
  }
}
@media (min-width: 992px) {
  .container-lg, .container-md, .container-sm, .container {
    max-width: 960px;
  }
}
@media (min-width: 1200px) {
  .container-xl, .container-lg, .container-md, .container-sm, .container {
    max-width: 1140px;
  }
}
.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}

.no-gutters {
  margin-right: 0;
  margin-left: 0;
}
.no-gutters > .col,
.no-gutters > [class*=col-] {
  padding-right: 0;
  padding-left: 0;
}

.col-xl,
.col-xl-auto, .col-xl-12, .col-xl-11, .col-xl-10, .col-xl-9, .col-xl-8, .col-xl-7, .col-xl-6, .col-xl-5, .col-xl-4, .col-xl-3, .col-xl-2, .col-xl-1, .col-lg,
.col-lg-auto, .col-lg-12, .col-lg-11, .col-lg-10, .col-lg-9, .col-lg-8, .col-lg-7, .col-lg-6, .col-lg-5, .col-lg-4, .col-lg-3, .col-lg-2, .col-lg-1, .col-md,
.col-md-auto, .col-md-12, .col-md-11, .col-md-10, .col-md-9, .col-md-8, .col-md-7, .col-md-6, .col-md-5, .col-md-4, .col-md-3, .col-md-2, .col-md-1, .col-sm,
.col-sm-auto, .col-sm-12, .col-sm-11, .col-sm-10, .col-sm-9, .col-sm-8, .col-sm-7, .col-sm-6, .col-sm-5, .col-sm-4, .col-sm-3, .col-sm-2, .col-sm-1, .col,
.col-auto, .col-12, .col-11, .col-10, .col-9, .col-8, .col-7, .col-6, .col-5, .col-4, .col-3, .col-2, .col-1 {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
}

.row-cols-1 > * {
  flex: 0 0 100%;
  max-width: 100%;
}

.row-cols-2 > * {
  flex: 0 0 50%;
  max-width: 50%;
}

.row-cols-3 > * {
  flex: 0 0 33.3333333333%;
  max-width: 33.3333333333%;
}

.row-cols-4 > * {
  flex: 0 0 25%;
  max-width: 25%;
}

.row-cols-5 > * {
  flex: 0 0 20%;
  max-width: 20%;
}

.row-cols-6 > * {
  flex: 0 0 16.6666666667%;
  max-width: 16.6666666667%;
}

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: 100%;
}

.col-1 {
  flex: 0 0 8.33333333%;
  max-width: 8.33333333%;
}

.col-2 {
  flex: 0 0 16.66666667%;
  max-width: 16.66666667%;
}

.col-3 {
  flex: 0 0 25%;
  max-width: 25%;
}

.col-4 {
  flex: 0 0 33.33333333%;
  max-width: 33.33333333%;
}

.col-5 {
  flex: 0 0 41.66666667%;
  max-width: 41.66666667%;
}

.col-6 {
  flex: 0 0 50%;
  max-width: 50%;
}

.col-7 {
  flex: 0 0 58.33333333%;
  max-width: 58.33333333%;
}

.col-8 {
  flex: 0 0 66.66666667%;
  max-width: 66.66666667%;
}

.col-9 {
  flex: 0 0 75%;
  max-width: 75%;
}

.col-10 {
  flex: 0 0 83.33333333%;
  max-width: 83.33333333%;
}

.col-11 {
  flex: 0 0 91.66666667%;
  max-width: 91.66666667%;
}

.col-12 {
  flex: 0 0 100%;
  max-width: 100%;
}

.order-first {
  order: -1;
}

.order-last {
  order: 13;
}

.order-0 {
  order: 0;
}

.order-1 {
  order: 1;
}

.order-2 {
  order: 2;
}

.order-3 {
  order: 3;
}

.order-4 {
  order: 4;
}

.order-5 {
  order: 5;
}

.order-6 {
  order: 6;
}

.order-7 {
  order: 7;
}

.order-8 {
  order: 8;
}

.order-9 {
  order: 9;
}

.order-10 {
  order: 10;
}

.order-11 {
  order: 11;
}

.order-12 {
  order: 12;
}

.offset-1 {
  margin-left: 8.33333333%;
}

.offset-2 {
  margin-left: 16.66666667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.33333333%;
}

.offset-5 {
  margin-left: 41.66666667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.33333333%;
}

.offset-8 {
  margin-left: 66.66666667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.33333333%;
}

.offset-11 {
  margin-left: 91.66666667%;
}

@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }

  .row-cols-sm-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .row-cols-sm-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .row-cols-sm-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .row-cols-sm-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .row-cols-sm-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }

  .row-cols-sm-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }

  .col-sm-1 {
    flex: 0 0 8.33333333%;
    max-width: 8.33333333%;
  }

  .col-sm-2 {
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%;
  }

  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-sm-4 {
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }

  .col-sm-5 {
    flex: 0 0 41.66666667%;
    max-width: 41.66666667%;
  }

  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-sm-7 {
    flex: 0 0 58.33333333%;
    max-width: 58.33333333%;
  }

  .col-sm-8 {
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%;
  }

  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-sm-10 {
    flex: 0 0 83.33333333%;
    max-width: 83.33333333%;
  }

  .col-sm-11 {
    flex: 0 0 91.66666667%;
    max-width: 91.66666667%;
  }

  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .order-sm-first {
    order: -1;
  }

  .order-sm-last {
    order: 13;
  }

  .order-sm-0 {
    order: 0;
  }

  .order-sm-1 {
    order: 1;
  }

  .order-sm-2 {
    order: 2;
  }

  .order-sm-3 {
    order: 3;
  }

  .order-sm-4 {
    order: 4;
  }

  .order-sm-5 {
    order: 5;
  }

  .order-sm-6 {
    order: 6;
  }

  .order-sm-7 {
    order: 7;
  }

  .order-sm-8 {
    order: 8;
  }

  .order-sm-9 {
    order: 9;
  }

  .order-sm-10 {
    order: 10;
  }

  .order-sm-11 {
    order: 11;
  }

  .order-sm-12 {
    order: 12;
  }

  .offset-sm-0 {
    margin-left: 0;
  }

  .offset-sm-1 {
    margin-left: 8.33333333%;
  }

  .offset-sm-2 {
    margin-left: 16.66666667%;
  }

  .offset-sm-3 {
    margin-left: 25%;
  }

  .offset-sm-4 {
    margin-left: 33.33333333%;
  }

  .offset-sm-5 {
    margin-left: 41.66666667%;
  }

  .offset-sm-6 {
    margin-left: 50%;
  }

  .offset-sm-7 {
    margin-left: 58.33333333%;
  }

  .offset-sm-8 {
    margin-left: 66.66666667%;
  }

  .offset-sm-9 {
    margin-left: 75%;
  }

  .offset-sm-10 {
    margin-left: 83.33333333%;
  }

  .offset-sm-11 {
    margin-left: 91.66666667%;
  }
}
@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }

  .row-cols-md-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .row-cols-md-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .row-cols-md-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .row-cols-md-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .row-cols-md-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }

  .row-cols-md-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }

  .col-md-1 {
    flex: 0 0 8.33333333%;
    max-width: 8.33333333%;
  }

  .col-md-2 {
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%;
  }

  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-md-4 {
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }

  .col-md-5 {
    flex: 0 0 41.66666667%;
    max-width: 41.66666667%;
  }

  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-md-7 {
    flex: 0 0 58.33333333%;
    max-width: 58.33333333%;
  }

  .col-md-8 {
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%;
  }

  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-md-10 {
    flex: 0 0 83.33333333%;
    max-width: 83.33333333%;
  }

  .col-md-11 {
    flex: 0 0 91.66666667%;
    max-width: 91.66666667%;
  }

  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .order-md-first {
    order: -1;
  }

  .order-md-last {
    order: 13;
  }

  .order-md-0 {
    order: 0;
  }

  .order-md-1 {
    order: 1;
  }

  .order-md-2 {
    order: 2;
  }

  .order-md-3 {
    order: 3;
  }

  .order-md-4 {
    order: 4;
  }

  .order-md-5 {
    order: 5;
  }

  .order-md-6 {
    order: 6;
  }

  .order-md-7 {
    order: 7;
  }

  .order-md-8 {
    order: 8;
  }

  .order-md-9 {
    order: 9;
  }

  .order-md-10 {
    order: 10;
  }

  .order-md-11 {
    order: 11;
  }

  .order-md-12 {
    order: 12;
  }

  .offset-md-0 {
    margin-left: 0;
  }

  .offset-md-1 {
    margin-left: 8.33333333%;
  }

  .offset-md-2 {
    margin-left: 16.66666667%;
  }

  .offset-md-3 {
    margin-left: 25%;
  }

  .offset-md-4 {
    margin-left: 33.33333333%;
  }

  .offset-md-5 {
    margin-left: 41.66666667%;
  }

  .offset-md-6 {
    margin-left: 50%;
  }

  .offset-md-7 {
    margin-left: 58.33333333%;
  }

  .offset-md-8 {
    margin-left: 66.66666667%;
  }

  .offset-md-9 {
    margin-left: 75%;
  }

  .offset-md-10 {
    margin-left: 83.33333333%;
  }

  .offset-md-11 {
    margin-left: 91.66666667%;
  }
}
@media (min-width: 992px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }

  .row-cols-lg-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .row-cols-lg-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .row-cols-lg-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .row-cols-lg-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .row-cols-lg-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }

  .row-cols-lg-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }

  .col-lg-1 {
    flex: 0 0 8.33333333%;
    max-width: 8.33333333%;
  }

  .col-lg-2 {
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%;
  }

  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-lg-4 {
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }

  .col-lg-5 {
    flex: 0 0 41.66666667%;
    max-width: 41.66666667%;
  }

  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-lg-7 {
    flex: 0 0 58.33333333%;
    max-width: 58.33333333%;
  }

  .col-lg-8 {
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%;
  }

  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-lg-10 {
    flex: 0 0 83.33333333%;
    max-width: 83.33333333%;
  }

  .col-lg-11 {
    flex: 0 0 91.66666667%;
    max-width: 91.66666667%;
  }

  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .order-lg-first {
    order: -1;
  }

  .order-lg-last {
    order: 13;
  }

  .order-lg-0 {
    order: 0;
  }

  .order-lg-1 {
    order: 1;
  }

  .order-lg-2 {
    order: 2;
  }

  .order-lg-3 {
    order: 3;
  }

  .order-lg-4 {
    order: 4;
  }

  .order-lg-5 {
    order: 5;
  }

  .order-lg-6 {
    order: 6;
  }

  .order-lg-7 {
    order: 7;
  }

  .order-lg-8 {
    order: 8;
  }

  .order-lg-9 {
    order: 9;
  }

  .order-lg-10 {
    order: 10;
  }

  .order-lg-11 {
    order: 11;
  }

  .order-lg-12 {
    order: 12;
  }

  .offset-lg-0 {
    margin-left: 0;
  }

  .offset-lg-1 {
    margin-left: 8.33333333%;
  }

  .offset-lg-2 {
    margin-left: 16.66666667%;
  }

  .offset-lg-3 {
    margin-left: 25%;
  }

  .offset-lg-4 {
    margin-left: 33.33333333%;
  }

  .offset-lg-5 {
    margin-left: 41.66666667%;
  }

  .offset-lg-6 {
    margin-left: 50%;
  }

  .offset-lg-7 {
    margin-left: 58.33333333%;
  }

  .offset-lg-8 {
    margin-left: 66.66666667%;
  }

  .offset-lg-9 {
    margin-left: 75%;
  }

  .offset-lg-10 {
    margin-left: 83.33333333%;
  }

  .offset-lg-11 {
    margin-left: 91.66666667%;
  }
}
@media (min-width: 1200px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }

  .row-cols-xl-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .row-cols-xl-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .row-cols-xl-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .row-cols-xl-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .row-cols-xl-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }

  .row-cols-xl-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }

  .col-xl-1 {
    flex: 0 0 8.33333333%;
    max-width: 8.33333333%;
  }

  .col-xl-2 {
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%;
  }

  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-xl-4 {
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }

  .col-xl-5 {
    flex: 0 0 41.66666667%;
    max-width: 41.66666667%;
  }

  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-xl-7 {
    flex: 0 0 58.33333333%;
    max-width: 58.33333333%;
  }

  .col-xl-8 {
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%;
  }

  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-xl-10 {
    flex: 0 0 83.33333333%;
    max-width: 83.33333333%;
  }

  .col-xl-11 {
    flex: 0 0 91.66666667%;
    max-width: 91.66666667%;
  }

  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .order-xl-first {
    order: -1;
  }

  .order-xl-last {
    order: 13;
  }

  .order-xl-0 {
    order: 0;
  }

  .order-xl-1 {
    order: 1;
  }

  .order-xl-2 {
    order: 2;
  }

  .order-xl-3 {
    order: 3;
  }

  .order-xl-4 {
    order: 4;
  }

  .order-xl-5 {
    order: 5;
  }

  .order-xl-6 {
    order: 6;
  }

  .order-xl-7 {
    order: 7;
  }

  .order-xl-8 {
    order: 8;
  }

  .order-xl-9 {
    order: 9;
  }

  .order-xl-10 {
    order: 10;
  }

  .order-xl-11 {
    order: 11;
  }

  .order-xl-12 {
    order: 12;
  }

  .offset-xl-0 {
    margin-left: 0;
  }

  .offset-xl-1 {
    margin-left: 8.33333333%;
  }

  .offset-xl-2 {
    margin-left: 16.66666667%;
  }

  .offset-xl-3 {
    margin-left: 25%;
  }

  .offset-xl-4 {
    margin-left: 33.33333333%;
  }

  .offset-xl-5 {
    margin-left: 41.66666667%;
  }

  .offset-xl-6 {
    margin-left: 50%;
  }

  .offset-xl-7 {
    margin-left: 58.33333333%;
  }

  .offset-xl-8 {
    margin-left: 66.66666667%;
  }

  .offset-xl-9 {
    margin-left: 75%;
  }

  .offset-xl-10 {
    margin-left: 83.33333333%;
  }

  .offset-xl-11 {
    margin-left: 91.66666667%;
  }
}
.d-none {
  display: none !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important;
  }

  .d-sm-inline {
    display: inline !important;
  }

  .d-sm-inline-block {
    display: inline-block !important;
  }

  .d-sm-block {
    display: block !important;
  }

  .d-sm-table {
    display: table !important;
  }

  .d-sm-table-row {
    display: table-row !important;
  }

  .d-sm-table-cell {
    display: table-cell !important;
  }

  .d-sm-flex {
    display: flex !important;
  }

  .d-sm-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 768px) {
  .d-md-none {
    display: none !important;
  }

  .d-md-inline {
    display: inline !important;
  }

  .d-md-inline-block {
    display: inline-block !important;
  }

  .d-md-block {
    display: block !important;
  }

  .d-md-table {
    display: table !important;
  }

  .d-md-table-row {
    display: table-row !important;
  }

  .d-md-table-cell {
    display: table-cell !important;
  }

  .d-md-flex {
    display: flex !important;
  }

  .d-md-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 992px) {
  .d-lg-none {
    display: none !important;
  }

  .d-lg-inline {
    display: inline !important;
  }

  .d-lg-inline-block {
    display: inline-block !important;
  }

  .d-lg-block {
    display: block !important;
  }

  .d-lg-table {
    display: table !important;
  }

  .d-lg-table-row {
    display: table-row !important;
  }

  .d-lg-table-cell {
    display: table-cell !important;
  }

  .d-lg-flex {
    display: flex !important;
  }

  .d-lg-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important;
  }

  .d-xl-inline {
    display: inline !important;
  }

  .d-xl-inline-block {
    display: inline-block !important;
  }

  .d-xl-block {
    display: block !important;
  }

  .d-xl-table {
    display: table !important;
  }

  .d-xl-table-row {
    display: table-row !important;
  }

  .d-xl-table-cell {
    display: table-cell !important;
  }

  .d-xl-flex {
    display: flex !important;
  }

  .d-xl-inline-flex {
    display: inline-flex !important;
  }
}
@media print {
  .d-print-none {
    display: none !important;
  }

  .d-print-inline {
    display: inline !important;
  }

  .d-print-inline-block {
    display: inline-block !important;
  }

  .d-print-block {
    display: block !important;
  }

  .d-print-table {
    display: table !important;
  }

  .d-print-table-row {
    display: table-row !important;
  }

  .d-print-table-cell {
    display: table-cell !important;
  }

  .d-print-flex {
    display: flex !important;
  }

  .d-print-inline-flex {
    display: inline-flex !important;
  }
}
.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.flex-fill {
  flex: 1 1 auto !important;
}

.flex-grow-0 {
  flex-grow: 0 !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  flex-shrink: 1 !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

@media (min-width: 576px) {
  .flex-sm-row {
    flex-direction: row !important;
  }

  .flex-sm-column {
    flex-direction: column !important;
  }

  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }

  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .flex-sm-fill {
    flex: 1 1 auto !important;
  }

  .flex-sm-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-sm-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }

  .justify-content-sm-start {
    justify-content: flex-start !important;
  }

  .justify-content-sm-end {
    justify-content: flex-end !important;
  }

  .justify-content-sm-center {
    justify-content: center !important;
  }

  .justify-content-sm-between {
    justify-content: space-between !important;
  }

  .justify-content-sm-around {
    justify-content: space-around !important;
  }

  .align-items-sm-start {
    align-items: flex-start !important;
  }

  .align-items-sm-end {
    align-items: flex-end !important;
  }

  .align-items-sm-center {
    align-items: center !important;
  }

  .align-items-sm-baseline {
    align-items: baseline !important;
  }

  .align-items-sm-stretch {
    align-items: stretch !important;
  }

  .align-content-sm-start {
    align-content: flex-start !important;
  }

  .align-content-sm-end {
    align-content: flex-end !important;
  }

  .align-content-sm-center {
    align-content: center !important;
  }

  .align-content-sm-between {
    align-content: space-between !important;
  }

  .align-content-sm-around {
    align-content: space-around !important;
  }

  .align-content-sm-stretch {
    align-content: stretch !important;
  }

  .align-self-sm-auto {
    align-self: auto !important;
  }

  .align-self-sm-start {
    align-self: flex-start !important;
  }

  .align-self-sm-end {
    align-self: flex-end !important;
  }

  .align-self-sm-center {
    align-self: center !important;
  }

  .align-self-sm-baseline {
    align-self: baseline !important;
  }

  .align-self-sm-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important;
  }

  .flex-md-column {
    flex-direction: column !important;
  }

  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-md-wrap {
    flex-wrap: wrap !important;
  }

  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .flex-md-fill {
    flex: 1 1 auto !important;
  }

  .flex-md-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-md-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }

  .justify-content-md-start {
    justify-content: flex-start !important;
  }

  .justify-content-md-end {
    justify-content: flex-end !important;
  }

  .justify-content-md-center {
    justify-content: center !important;
  }

  .justify-content-md-between {
    justify-content: space-between !important;
  }

  .justify-content-md-around {
    justify-content: space-around !important;
  }

  .align-items-md-start {
    align-items: flex-start !important;
  }

  .align-items-md-end {
    align-items: flex-end !important;
  }

  .align-items-md-center {
    align-items: center !important;
  }

  .align-items-md-baseline {
    align-items: baseline !important;
  }

  .align-items-md-stretch {
    align-items: stretch !important;
  }

  .align-content-md-start {
    align-content: flex-start !important;
  }

  .align-content-md-end {
    align-content: flex-end !important;
  }

  .align-content-md-center {
    align-content: center !important;
  }

  .align-content-md-between {
    align-content: space-between !important;
  }

  .align-content-md-around {
    align-content: space-around !important;
  }

  .align-content-md-stretch {
    align-content: stretch !important;
  }

  .align-self-md-auto {
    align-self: auto !important;
  }

  .align-self-md-start {
    align-self: flex-start !important;
  }

  .align-self-md-end {
    align-self: flex-end !important;
  }

  .align-self-md-center {
    align-self: center !important;
  }

  .align-self-md-baseline {
    align-self: baseline !important;
  }

  .align-self-md-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 992px) {
  .flex-lg-row {
    flex-direction: row !important;
  }

  .flex-lg-column {
    flex-direction: column !important;
  }

  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }

  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .flex-lg-fill {
    flex: 1 1 auto !important;
  }

  .flex-lg-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-lg-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }

  .justify-content-lg-start {
    justify-content: flex-start !important;
  }

  .justify-content-lg-end {
    justify-content: flex-end !important;
  }

  .justify-content-lg-center {
    justify-content: center !important;
  }

  .justify-content-lg-between {
    justify-content: space-between !important;
  }

  .justify-content-lg-around {
    justify-content: space-around !important;
  }

  .align-items-lg-start {
    align-items: flex-start !important;
  }

  .align-items-lg-end {
    align-items: flex-end !important;
  }

  .align-items-lg-center {
    align-items: center !important;
  }

  .align-items-lg-baseline {
    align-items: baseline !important;
  }

  .align-items-lg-stretch {
    align-items: stretch !important;
  }

  .align-content-lg-start {
    align-content: flex-start !important;
  }

  .align-content-lg-end {
    align-content: flex-end !important;
  }

  .align-content-lg-center {
    align-content: center !important;
  }

  .align-content-lg-between {
    align-content: space-between !important;
  }

  .align-content-lg-around {
    align-content: space-around !important;
  }

  .align-content-lg-stretch {
    align-content: stretch !important;
  }

  .align-self-lg-auto {
    align-self: auto !important;
  }

  .align-self-lg-start {
    align-self: flex-start !important;
  }

  .align-self-lg-end {
    align-self: flex-end !important;
  }

  .align-self-lg-center {
    align-self: center !important;
  }

  .align-self-lg-baseline {
    align-self: baseline !important;
  }

  .align-self-lg-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 1200px) {
  .flex-xl-row {
    flex-direction: row !important;
  }

  .flex-xl-column {
    flex-direction: column !important;
  }

  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }

  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .flex-xl-fill {
    flex: 1 1 auto !important;
  }

  .flex-xl-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-xl-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }

  .justify-content-xl-start {
    justify-content: flex-start !important;
  }

  .justify-content-xl-end {
    justify-content: flex-end !important;
  }

  .justify-content-xl-center {
    justify-content: center !important;
  }

  .justify-content-xl-between {
    justify-content: space-between !important;
  }

  .justify-content-xl-around {
    justify-content: space-around !important;
  }

  .align-items-xl-start {
    align-items: flex-start !important;
  }

  .align-items-xl-end {
    align-items: flex-end !important;
  }

  .align-items-xl-center {
    align-items: center !important;
  }

  .align-items-xl-baseline {
    align-items: baseline !important;
  }

  .align-items-xl-stretch {
    align-items: stretch !important;
  }

  .align-content-xl-start {
    align-content: flex-start !important;
  }

  .align-content-xl-end {
    align-content: flex-end !important;
  }

  .align-content-xl-center {
    align-content: center !important;
  }

  .align-content-xl-between {
    align-content: space-between !important;
  }

  .align-content-xl-around {
    align-content: space-around !important;
  }

  .align-content-xl-stretch {
    align-content: stretch !important;
  }

  .align-self-xl-auto {
    align-self: auto !important;
  }

  .align-self-xl-start {
    align-self: flex-start !important;
  }

  .align-self-xl-end {
    align-self: flex-end !important;
  }

  .align-self-xl-center {
    align-self: center !important;
  }

  .align-self-xl-baseline {
    align-self: baseline !important;
  }

  .align-self-xl-stretch {
    align-self: stretch !important;
  }
}
.m-0 {
  margin: 0 !important;
}

.mt-0,
.my-0 {
  margin-top: 0 !important;
}

.mr-0,
.mx-0 {
  margin-right: 0 !important;
}

.mb-0,
.my-0 {
  margin-bottom: 0 !important;
}

.ml-0,
.mx-0 {
  margin-left: 0 !important;
}

.m-1 {
  margin: 0.25rem !important;
}

.mt-1,
.my-1 {
  margin-top: 0.25rem !important;
}

.mr-1,
.mx-1 {
  margin-right: 0.25rem !important;
}

.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important;
}

.ml-1,
.mx-1 {
  margin-left: 0.25rem !important;
}

.m-2 {
  margin: 0.5rem !important;
}

.mt-2,
.my-2 {
  margin-top: 0.5rem !important;
}

.mr-2,
.mx-2 {
  margin-right: 0.5rem !important;
}

.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important;
}

.ml-2,
.mx-2 {
  margin-left: 0.5rem !important;
}

.m-3 {
  margin: 1rem !important;
}

.mt-3,
.my-3 {
  margin-top: 1rem !important;
}

.mr-3,
.mx-3 {
  margin-right: 1rem !important;
}

.mb-3,
.my-3 {
  margin-bottom: 1rem !important;
}

.ml-3,
.mx-3 {
  margin-left: 1rem !important;
}

.m-4 {
  margin: 1.5rem !important;
}

.mt-4,
.my-4 {
  margin-top: 1.5rem !important;
}

.mr-4,
.mx-4 {
  margin-right: 1.5rem !important;
}

.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important;
}

.ml-4,
.mx-4 {
  margin-left: 1.5rem !important;
}

.m-5 {
  margin: 3rem !important;
}

.mt-5,
.my-5 {
  margin-top: 3rem !important;
}

.mr-5,
.mx-5 {
  margin-right: 3rem !important;
}

.mb-5,
.my-5 {
  margin-bottom: 3rem !important;
}

.ml-5,
.mx-5 {
  margin-left: 3rem !important;
}

.p-0 {
  padding: 0 !important;
}

.pt-0,
.py-0 {
  padding-top: 0 !important;
}

.pr-0,
.px-0 {
  padding-right: 0 !important;
}

.pb-0,
.py-0 {
  padding-bottom: 0 !important;
}

.pl-0,
.px-0 {
  padding-left: 0 !important;
}

.p-1 {
  padding: 0.25rem !important;
}

.pt-1,
.py-1 {
  padding-top: 0.25rem !important;
}

.pr-1,
.px-1 {
  padding-right: 0.25rem !important;
}

.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important;
}

.pl-1,
.px-1 {
  padding-left: 0.25rem !important;
}

.p-2 {
  padding: 0.5rem !important;
}

.pt-2,
.py-2 {
  padding-top: 0.5rem !important;
}

.pr-2,
.px-2 {
  padding-right: 0.5rem !important;
}

.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important;
}

.pl-2,
.px-2 {
  padding-left: 0.5rem !important;
}

.p-3 {
  padding: 1rem !important;
}

.pt-3,
.py-3 {
  padding-top: 1rem !important;
}

.pr-3,
.px-3 {
  padding-right: 1rem !important;
}

.pb-3,
.py-3 {
  padding-bottom: 1rem !important;
}

.pl-3,
.px-3 {
  padding-left: 1rem !important;
}

.p-4 {
  padding: 1.5rem !important;
}

.pt-4,
.py-4 {
  padding-top: 1.5rem !important;
}

.pr-4,
.px-4 {
  padding-right: 1.5rem !important;
}

.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important;
}

.pl-4,
.px-4 {
  padding-left: 1.5rem !important;
}

.p-5 {
  padding: 3rem !important;
}

.pt-5,
.py-5 {
  padding-top: 3rem !important;
}

.pr-5,
.px-5 {
  padding-right: 3rem !important;
}

.pb-5,
.py-5 {
  padding-bottom: 3rem !important;
}

.pl-5,
.px-5 {
  padding-left: 3rem !important;
}

.m-n1 {
  margin: -0.25rem !important;
}

.mt-n1,
.my-n1 {
  margin-top: -0.25rem !important;
}

.mr-n1,
.mx-n1 {
  margin-right: -0.25rem !important;
}

.mb-n1,
.my-n1 {
  margin-bottom: -0.25rem !important;
}

.ml-n1,
.mx-n1 {
  margin-left: -0.25rem !important;
}

.m-n2 {
  margin: -0.5rem !important;
}

.mt-n2,
.my-n2 {
  margin-top: -0.5rem !important;
}

.mr-n2,
.mx-n2 {
  margin-right: -0.5rem !important;
}

.mb-n2,
.my-n2 {
  margin-bottom: -0.5rem !important;
}

.ml-n2,
.mx-n2 {
  margin-left: -0.5rem !important;
}

.m-n3 {
  margin: -1rem !important;
}

.mt-n3,
.my-n3 {
  margin-top: -1rem !important;
}

.mr-n3,
.mx-n3 {
  margin-right: -1rem !important;
}

.mb-n3,
.my-n3 {
  margin-bottom: -1rem !important;
}

.ml-n3,
.mx-n3 {
  margin-left: -1rem !important;
}

.m-n4 {
  margin: -1.5rem !important;
}

.mt-n4,
.my-n4 {
  margin-top: -1.5rem !important;
}

.mr-n4,
.mx-n4 {
  margin-right: -1.5rem !important;
}

.mb-n4,
.my-n4 {
  margin-bottom: -1.5rem !important;
}

.ml-n4,
.mx-n4 {
  margin-left: -1.5rem !important;
}

.m-n5 {
  margin: -3rem !important;
}

.mt-n5,
.my-n5 {
  margin-top: -3rem !important;
}

.mr-n5,
.mx-n5 {
  margin-right: -3rem !important;
}

.mb-n5,
.my-n5 {
  margin-bottom: -3rem !important;
}

.ml-n5,
.mx-n5 {
  margin-left: -3rem !important;
}

.m-auto {
  margin: auto !important;
}

.mt-auto,
.my-auto {
  margin-top: auto !important;
}

.mr-auto,
.mx-auto {
  margin-right: auto !important;
}

.mb-auto,
.my-auto {
  margin-bottom: auto !important;
}

.ml-auto,
.mx-auto {
  margin-left: auto !important;
}

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important;
  }

  .mt-sm-0,
.my-sm-0 {
    margin-top: 0 !important;
  }

  .mr-sm-0,
.mx-sm-0 {
    margin-right: 0 !important;
  }

  .mb-sm-0,
.my-sm-0 {
    margin-bottom: 0 !important;
  }

  .ml-sm-0,
.mx-sm-0 {
    margin-left: 0 !important;
  }

  .m-sm-1 {
    margin: 0.25rem !important;
  }

  .mt-sm-1,
.my-sm-1 {
    margin-top: 0.25rem !important;
  }

  .mr-sm-1,
.mx-sm-1 {
    margin-right: 0.25rem !important;
  }

  .mb-sm-1,
.my-sm-1 {
    margin-bottom: 0.25rem !important;
  }

  .ml-sm-1,
.mx-sm-1 {
    margin-left: 0.25rem !important;
  }

  .m-sm-2 {
    margin: 0.5rem !important;
  }

  .mt-sm-2,
.my-sm-2 {
    margin-top: 0.5rem !important;
  }

  .mr-sm-2,
.mx-sm-2 {
    margin-right: 0.5rem !important;
  }

  .mb-sm-2,
.my-sm-2 {
    margin-bottom: 0.5rem !important;
  }

  .ml-sm-2,
.mx-sm-2 {
    margin-left: 0.5rem !important;
  }

  .m-sm-3 {
    margin: 1rem !important;
  }

  .mt-sm-3,
.my-sm-3 {
    margin-top: 1rem !important;
  }

  .mr-sm-3,
.mx-sm-3 {
    margin-right: 1rem !important;
  }

  .mb-sm-3,
.my-sm-3 {
    margin-bottom: 1rem !important;
  }

  .ml-sm-3,
.mx-sm-3 {
    margin-left: 1rem !important;
  }

  .m-sm-4 {
    margin: 1.5rem !important;
  }

  .mt-sm-4,
.my-sm-4 {
    margin-top: 1.5rem !important;
  }

  .mr-sm-4,
.mx-sm-4 {
    margin-right: 1.5rem !important;
  }

  .mb-sm-4,
.my-sm-4 {
    margin-bottom: 1.5rem !important;
  }

  .ml-sm-4,
.mx-sm-4 {
    margin-left: 1.5rem !important;
  }

  .m-sm-5 {
    margin: 3rem !important;
  }

  .mt-sm-5,
.my-sm-5 {
    margin-top: 3rem !important;
  }

  .mr-sm-5,
.mx-sm-5 {
    margin-right: 3rem !important;
  }

  .mb-sm-5,
.my-sm-5 {
    margin-bottom: 3rem !important;
  }

  .ml-sm-5,
.mx-sm-5 {
    margin-left: 3rem !important;
  }

  .p-sm-0 {
    padding: 0 !important;
  }

  .pt-sm-0,
.py-sm-0 {
    padding-top: 0 !important;
  }

  .pr-sm-0,
.px-sm-0 {
    padding-right: 0 !important;
  }

  .pb-sm-0,
.py-sm-0 {
    padding-bottom: 0 !important;
  }

  .pl-sm-0,
.px-sm-0 {
    padding-left: 0 !important;
  }

  .p-sm-1 {
    padding: 0.25rem !important;
  }

  .pt-sm-1,
.py-sm-1 {
    padding-top: 0.25rem !important;
  }

  .pr-sm-1,
.px-sm-1 {
    padding-right: 0.25rem !important;
  }

  .pb-sm-1,
.py-sm-1 {
    padding-bottom: 0.25rem !important;
  }

  .pl-sm-1,
.px-sm-1 {
    padding-left: 0.25rem !important;
  }

  .p-sm-2 {
    padding: 0.5rem !important;
  }

  .pt-sm-2,
.py-sm-2 {
    padding-top: 0.5rem !important;
  }

  .pr-sm-2,
.px-sm-2 {
    padding-right: 0.5rem !important;
  }

  .pb-sm-2,
.py-sm-2 {
    padding-bottom: 0.5rem !important;
  }

  .pl-sm-2,
.px-sm-2 {
    padding-left: 0.5rem !important;
  }

  .p-sm-3 {
    padding: 1rem !important;
  }

  .pt-sm-3,
.py-sm-3 {
    padding-top: 1rem !important;
  }

  .pr-sm-3,
.px-sm-3 {
    padding-right: 1rem !important;
  }

  .pb-sm-3,
.py-sm-3 {
    padding-bottom: 1rem !important;
  }

  .pl-sm-3,
.px-sm-3 {
    padding-left: 1rem !important;
  }

  .p-sm-4 {
    padding: 1.5rem !important;
  }

  .pt-sm-4,
.py-sm-4 {
    padding-top: 1.5rem !important;
  }

  .pr-sm-4,
.px-sm-4 {
    padding-right: 1.5rem !important;
  }

  .pb-sm-4,
.py-sm-4 {
    padding-bottom: 1.5rem !important;
  }

  .pl-sm-4,
.px-sm-4 {
    padding-left: 1.5rem !important;
  }

  .p-sm-5 {
    padding: 3rem !important;
  }

  .pt-sm-5,
.py-sm-5 {
    padding-top: 3rem !important;
  }

  .pr-sm-5,
.px-sm-5 {
    padding-right: 3rem !important;
  }

  .pb-sm-5,
.py-sm-5 {
    padding-bottom: 3rem !important;
  }

  .pl-sm-5,
.px-sm-5 {
    padding-left: 3rem !important;
  }

  .m-sm-n1 {
    margin: -0.25rem !important;
  }

  .mt-sm-n1,
.my-sm-n1 {
    margin-top: -0.25rem !important;
  }

  .mr-sm-n1,
.mx-sm-n1 {
    margin-right: -0.25rem !important;
  }

  .mb-sm-n1,
.my-sm-n1 {
    margin-bottom: -0.25rem !important;
  }

  .ml-sm-n1,
.mx-sm-n1 {
    margin-left: -0.25rem !important;
  }

  .m-sm-n2 {
    margin: -0.5rem !important;
  }

  .mt-sm-n2,
.my-sm-n2 {
    margin-top: -0.5rem !important;
  }

  .mr-sm-n2,
.mx-sm-n2 {
    margin-right: -0.5rem !important;
  }

  .mb-sm-n2,
.my-sm-n2 {
    margin-bottom: -0.5rem !important;
  }

  .ml-sm-n2,
.mx-sm-n2 {
    margin-left: -0.5rem !important;
  }

  .m-sm-n3 {
    margin: -1rem !important;
  }

  .mt-sm-n3,
.my-sm-n3 {
    margin-top: -1rem !important;
  }

  .mr-sm-n3,
.mx-sm-n3 {
    margin-right: -1rem !important;
  }

  .mb-sm-n3,
.my-sm-n3 {
    margin-bottom: -1rem !important;
  }

  .ml-sm-n3,
.mx-sm-n3 {
    margin-left: -1rem !important;
  }

  .m-sm-n4 {
    margin: -1.5rem !important;
  }

  .mt-sm-n4,
.my-sm-n4 {
    margin-top: -1.5rem !important;
  }

  .mr-sm-n4,
.mx-sm-n4 {
    margin-right: -1.5rem !important;
  }

  .mb-sm-n4,
.my-sm-n4 {
    margin-bottom: -1.5rem !important;
  }

  .ml-sm-n4,
.mx-sm-n4 {
    margin-left: -1.5rem !important;
  }

  .m-sm-n5 {
    margin: -3rem !important;
  }

  .mt-sm-n5,
.my-sm-n5 {
    margin-top: -3rem !important;
  }

  .mr-sm-n5,
.mx-sm-n5 {
    margin-right: -3rem !important;
  }

  .mb-sm-n5,
.my-sm-n5 {
    margin-bottom: -3rem !important;
  }

  .ml-sm-n5,
.mx-sm-n5 {
    margin-left: -3rem !important;
  }

  .m-sm-auto {
    margin: auto !important;
  }

  .mt-sm-auto,
.my-sm-auto {
    margin-top: auto !important;
  }

  .mr-sm-auto,
.mx-sm-auto {
    margin-right: auto !important;
  }

  .mb-sm-auto,
.my-sm-auto {
    margin-bottom: auto !important;
  }

  .ml-sm-auto,
.mx-sm-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important;
  }

  .mt-md-0,
.my-md-0 {
    margin-top: 0 !important;
  }

  .mr-md-0,
.mx-md-0 {
    margin-right: 0 !important;
  }

  .mb-md-0,
.my-md-0 {
    margin-bottom: 0 !important;
  }

  .ml-md-0,
.mx-md-0 {
    margin-left: 0 !important;
  }

  .m-md-1 {
    margin: 0.25rem !important;
  }

  .mt-md-1,
.my-md-1 {
    margin-top: 0.25rem !important;
  }

  .mr-md-1,
.mx-md-1 {
    margin-right: 0.25rem !important;
  }

  .mb-md-1,
.my-md-1 {
    margin-bottom: 0.25rem !important;
  }

  .ml-md-1,
.mx-md-1 {
    margin-left: 0.25rem !important;
  }

  .m-md-2 {
    margin: 0.5rem !important;
  }

  .mt-md-2,
.my-md-2 {
    margin-top: 0.5rem !important;
  }

  .mr-md-2,
.mx-md-2 {
    margin-right: 0.5rem !important;
  }

  .mb-md-2,
.my-md-2 {
    margin-bottom: 0.5rem !important;
  }

  .ml-md-2,
.mx-md-2 {
    margin-left: 0.5rem !important;
  }

  .m-md-3 {
    margin: 1rem !important;
  }

  .mt-md-3,
.my-md-3 {
    margin-top: 1rem !important;
  }

  .mr-md-3,
.mx-md-3 {
    margin-right: 1rem !important;
  }

  .mb-md-3,
.my-md-3 {
    margin-bottom: 1rem !important;
  }

  .ml-md-3,
.mx-md-3 {
    margin-left: 1rem !important;
  }

  .m-md-4 {
    margin: 1.5rem !important;
  }

  .mt-md-4,
.my-md-4 {
    margin-top: 1.5rem !important;
  }

  .mr-md-4,
.mx-md-4 {
    margin-right: 1.5rem !important;
  }

  .mb-md-4,
.my-md-4 {
    margin-bottom: 1.5rem !important;
  }

  .ml-md-4,
.mx-md-4 {
    margin-left: 1.5rem !important;
  }

  .m-md-5 {
    margin: 3rem !important;
  }

  .mt-md-5,
.my-md-5 {
    margin-top: 3rem !important;
  }

  .mr-md-5,
.mx-md-5 {
    margin-right: 3rem !important;
  }

  .mb-md-5,
.my-md-5 {
    margin-bottom: 3rem !important;
  }

  .ml-md-5,
.mx-md-5 {
    margin-left: 3rem !important;
  }

  .p-md-0 {
    padding: 0 !important;
  }

  .pt-md-0,
.py-md-0 {
    padding-top: 0 !important;
  }

  .pr-md-0,
.px-md-0 {
    padding-right: 0 !important;
  }

  .pb-md-0,
.py-md-0 {
    padding-bottom: 0 !important;
  }

  .pl-md-0,
.px-md-0 {
    padding-left: 0 !important;
  }

  .p-md-1 {
    padding: 0.25rem !important;
  }

  .pt-md-1,
.py-md-1 {
    padding-top: 0.25rem !important;
  }

  .pr-md-1,
.px-md-1 {
    padding-right: 0.25rem !important;
  }

  .pb-md-1,
.py-md-1 {
    padding-bottom: 0.25rem !important;
  }

  .pl-md-1,
.px-md-1 {
    padding-left: 0.25rem !important;
  }

  .p-md-2 {
    padding: 0.5rem !important;
  }

  .pt-md-2,
.py-md-2 {
    padding-top: 0.5rem !important;
  }

  .pr-md-2,
.px-md-2 {
    padding-right: 0.5rem !important;
  }

  .pb-md-2,
.py-md-2 {
    padding-bottom: 0.5rem !important;
  }

  .pl-md-2,
.px-md-2 {
    padding-left: 0.5rem !important;
  }

  .p-md-3 {
    padding: 1rem !important;
  }

  .pt-md-3,
.py-md-3 {
    padding-top: 1rem !important;
  }

  .pr-md-3,
.px-md-3 {
    padding-right: 1rem !important;
  }

  .pb-md-3,
.py-md-3 {
    padding-bottom: 1rem !important;
  }

  .pl-md-3,
.px-md-3 {
    padding-left: 1rem !important;
  }

  .p-md-4 {
    padding: 1.5rem !important;
  }

  .pt-md-4,
.py-md-4 {
    padding-top: 1.5rem !important;
  }

  .pr-md-4,
.px-md-4 {
    padding-right: 1.5rem !important;
  }

  .pb-md-4,
.py-md-4 {
    padding-bottom: 1.5rem !important;
  }

  .pl-md-4,
.px-md-4 {
    padding-left: 1.5rem !important;
  }

  .p-md-5 {
    padding: 3rem !important;
  }

  .pt-md-5,
.py-md-5 {
    padding-top: 3rem !important;
  }

  .pr-md-5,
.px-md-5 {
    padding-right: 3rem !important;
  }

  .pb-md-5,
.py-md-5 {
    padding-bottom: 3rem !important;
  }

  .pl-md-5,
.px-md-5 {
    padding-left: 3rem !important;
  }

  .m-md-n1 {
    margin: -0.25rem !important;
  }

  .mt-md-n1,
.my-md-n1 {
    margin-top: -0.25rem !important;
  }

  .mr-md-n1,
.mx-md-n1 {
    margin-right: -0.25rem !important;
  }

  .mb-md-n1,
.my-md-n1 {
    margin-bottom: -0.25rem !important;
  }

  .ml-md-n1,
.mx-md-n1 {
    margin-left: -0.25rem !important;
  }

  .m-md-n2 {
    margin: -0.5rem !important;
  }

  .mt-md-n2,
.my-md-n2 {
    margin-top: -0.5rem !important;
  }

  .mr-md-n2,
.mx-md-n2 {
    margin-right: -0.5rem !important;
  }

  .mb-md-n2,
.my-md-n2 {
    margin-bottom: -0.5rem !important;
  }

  .ml-md-n2,
.mx-md-n2 {
    margin-left: -0.5rem !important;
  }

  .m-md-n3 {
    margin: -1rem !important;
  }

  .mt-md-n3,
.my-md-n3 {
    margin-top: -1rem !important;
  }

  .mr-md-n3,
.mx-md-n3 {
    margin-right: -1rem !important;
  }

  .mb-md-n3,
.my-md-n3 {
    margin-bottom: -1rem !important;
  }

  .ml-md-n3,
.mx-md-n3 {
    margin-left: -1rem !important;
  }

  .m-md-n4 {
    margin: -1.5rem !important;
  }

  .mt-md-n4,
.my-md-n4 {
    margin-top: -1.5rem !important;
  }

  .mr-md-n4,
.mx-md-n4 {
    margin-right: -1.5rem !important;
  }

  .mb-md-n4,
.my-md-n4 {
    margin-bottom: -1.5rem !important;
  }

  .ml-md-n4,
.mx-md-n4 {
    margin-left: -1.5rem !important;
  }

  .m-md-n5 {
    margin: -3rem !important;
  }

  .mt-md-n5,
.my-md-n5 {
    margin-top: -3rem !important;
  }

  .mr-md-n5,
.mx-md-n5 {
    margin-right: -3rem !important;
  }

  .mb-md-n5,
.my-md-n5 {
    margin-bottom: -3rem !important;
  }

  .ml-md-n5,
.mx-md-n5 {
    margin-left: -3rem !important;
  }

  .m-md-auto {
    margin: auto !important;
  }

  .mt-md-auto,
.my-md-auto {
    margin-top: auto !important;
  }

  .mr-md-auto,
.mx-md-auto {
    margin-right: auto !important;
  }

  .mb-md-auto,
.my-md-auto {
    margin-bottom: auto !important;
  }

  .ml-md-auto,
.mx-md-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important;
  }

  .mt-lg-0,
.my-lg-0 {
    margin-top: 0 !important;
  }

  .mr-lg-0,
.mx-lg-0 {
    margin-right: 0 !important;
  }

  .mb-lg-0,
.my-lg-0 {
    margin-bottom: 0 !important;
  }

  .ml-lg-0,
.mx-lg-0 {
    margin-left: 0 !important;
  }

  .m-lg-1 {
    margin: 0.25rem !important;
  }

  .mt-lg-1,
.my-lg-1 {
    margin-top: 0.25rem !important;
  }

  .mr-lg-1,
.mx-lg-1 {
    margin-right: 0.25rem !important;
  }

  .mb-lg-1,
.my-lg-1 {
    margin-bottom: 0.25rem !important;
  }

  .ml-lg-1,
.mx-lg-1 {
    margin-left: 0.25rem !important;
  }

  .m-lg-2 {
    margin: 0.5rem !important;
  }

  .mt-lg-2,
.my-lg-2 {
    margin-top: 0.5rem !important;
  }

  .mr-lg-2,
.mx-lg-2 {
    margin-right: 0.5rem !important;
  }

  .mb-lg-2,
.my-lg-2 {
    margin-bottom: 0.5rem !important;
  }

  .ml-lg-2,
.mx-lg-2 {
    margin-left: 0.5rem !important;
  }

  .m-lg-3 {
    margin: 1rem !important;
  }

  .mt-lg-3,
.my-lg-3 {
    margin-top: 1rem !important;
  }

  .mr-lg-3,
.mx-lg-3 {
    margin-right: 1rem !important;
  }

  .mb-lg-3,
.my-lg-3 {
    margin-bottom: 1rem !important;
  }

  .ml-lg-3,
.mx-lg-3 {
    margin-left: 1rem !important;
  }

  .m-lg-4 {
    margin: 1.5rem !important;
  }

  .mt-lg-4,
.my-lg-4 {
    margin-top: 1.5rem !important;
  }

  .mr-lg-4,
.mx-lg-4 {
    margin-right: 1.5rem !important;
  }

  .mb-lg-4,
.my-lg-4 {
    margin-bottom: 1.5rem !important;
  }

  .ml-lg-4,
.mx-lg-4 {
    margin-left: 1.5rem !important;
  }

  .m-lg-5 {
    margin: 3rem !important;
  }

  .mt-lg-5,
.my-lg-5 {
    margin-top: 3rem !important;
  }

  .mr-lg-5,
.mx-lg-5 {
    margin-right: 3rem !important;
  }

  .mb-lg-5,
.my-lg-5 {
    margin-bottom: 3rem !important;
  }

  .ml-lg-5,
.mx-lg-5 {
    margin-left: 3rem !important;
  }

  .p-lg-0 {
    padding: 0 !important;
  }

  .pt-lg-0,
.py-lg-0 {
    padding-top: 0 !important;
  }

  .pr-lg-0,
.px-lg-0 {
    padding-right: 0 !important;
  }

  .pb-lg-0,
.py-lg-0 {
    padding-bottom: 0 !important;
  }

  .pl-lg-0,
.px-lg-0 {
    padding-left: 0 !important;
  }

  .p-lg-1 {
    padding: 0.25rem !important;
  }

  .pt-lg-1,
.py-lg-1 {
    padding-top: 0.25rem !important;
  }

  .pr-lg-1,
.px-lg-1 {
    padding-right: 0.25rem !important;
  }

  .pb-lg-1,
.py-lg-1 {
    padding-bottom: 0.25rem !important;
  }

  .pl-lg-1,
.px-lg-1 {
    padding-left: 0.25rem !important;
  }

  .p-lg-2 {
    padding: 0.5rem !important;
  }

  .pt-lg-2,
.py-lg-2 {
    padding-top: 0.5rem !important;
  }

  .pr-lg-2,
.px-lg-2 {
    padding-right: 0.5rem !important;
  }

  .pb-lg-2,
.py-lg-2 {
    padding-bottom: 0.5rem !important;
  }

  .pl-lg-2,
.px-lg-2 {
    padding-left: 0.5rem !important;
  }

  .p-lg-3 {
    padding: 1rem !important;
  }

  .pt-lg-3,
.py-lg-3 {
    padding-top: 1rem !important;
  }

  .pr-lg-3,
.px-lg-3 {
    padding-right: 1rem !important;
  }

  .pb-lg-3,
.py-lg-3 {
    padding-bottom: 1rem !important;
  }

  .pl-lg-3,
.px-lg-3 {
    padding-left: 1rem !important;
  }

  .p-lg-4 {
    padding: 1.5rem !important;
  }

  .pt-lg-4,
.py-lg-4 {
    padding-top: 1.5rem !important;
  }

  .pr-lg-4,
.px-lg-4 {
    padding-right: 1.5rem !important;
  }

  .pb-lg-4,
.py-lg-4 {
    padding-bottom: 1.5rem !important;
  }

  .pl-lg-4,
.px-lg-4 {
    padding-left: 1.5rem !important;
  }

  .p-lg-5 {
    padding: 3rem !important;
  }

  .pt-lg-5,
.py-lg-5 {
    padding-top: 3rem !important;
  }

  .pr-lg-5,
.px-lg-5 {
    padding-right: 3rem !important;
  }

  .pb-lg-5,
.py-lg-5 {
    padding-bottom: 3rem !important;
  }

  .pl-lg-5,
.px-lg-5 {
    padding-left: 3rem !important;
  }

  .m-lg-n1 {
    margin: -0.25rem !important;
  }

  .mt-lg-n1,
.my-lg-n1 {
    margin-top: -0.25rem !important;
  }

  .mr-lg-n1,
.mx-lg-n1 {
    margin-right: -0.25rem !important;
  }

  .mb-lg-n1,
.my-lg-n1 {
    margin-bottom: -0.25rem !important;
  }

  .ml-lg-n1,
.mx-lg-n1 {
    margin-left: -0.25rem !important;
  }

  .m-lg-n2 {
    margin: -0.5rem !important;
  }

  .mt-lg-n2,
.my-lg-n2 {
    margin-top: -0.5rem !important;
  }

  .mr-lg-n2,
.mx-lg-n2 {
    margin-right: -0.5rem !important;
  }

  .mb-lg-n2,
.my-lg-n2 {
    margin-bottom: -0.5rem !important;
  }

  .ml-lg-n2,
.mx-lg-n2 {
    margin-left: -0.5rem !important;
  }

  .m-lg-n3 {
    margin: -1rem !important;
  }

  .mt-lg-n3,
.my-lg-n3 {
    margin-top: -1rem !important;
  }

  .mr-lg-n3,
.mx-lg-n3 {
    margin-right: -1rem !important;
  }

  .mb-lg-n3,
.my-lg-n3 {
    margin-bottom: -1rem !important;
  }

  .ml-lg-n3,
.mx-lg-n3 {
    margin-left: -1rem !important;
  }

  .m-lg-n4 {
    margin: -1.5rem !important;
  }

  .mt-lg-n4,
.my-lg-n4 {
    margin-top: -1.5rem !important;
  }

  .mr-lg-n4,
.mx-lg-n4 {
    margin-right: -1.5rem !important;
  }

  .mb-lg-n4,
.my-lg-n4 {
    margin-bottom: -1.5rem !important;
  }

  .ml-lg-n4,
.mx-lg-n4 {
    margin-left: -1.5rem !important;
  }

  .m-lg-n5 {
    margin: -3rem !important;
  }

  .mt-lg-n5,
.my-lg-n5 {
    margin-top: -3rem !important;
  }

  .mr-lg-n5,
.mx-lg-n5 {
    margin-right: -3rem !important;
  }

  .mb-lg-n5,
.my-lg-n5 {
    margin-bottom: -3rem !important;
  }

  .ml-lg-n5,
.mx-lg-n5 {
    margin-left: -3rem !important;
  }

  .m-lg-auto {
    margin: auto !important;
  }

  .mt-lg-auto,
.my-lg-auto {
    margin-top: auto !important;
  }

  .mr-lg-auto,
.mx-lg-auto {
    margin-right: auto !important;
  }

  .mb-lg-auto,
.my-lg-auto {
    margin-bottom: auto !important;
  }

  .ml-lg-auto,
.mx-lg-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important;
  }

  .mt-xl-0,
.my-xl-0 {
    margin-top: 0 !important;
  }

  .mr-xl-0,
.mx-xl-0 {
    margin-right: 0 !important;
  }

  .mb-xl-0,
.my-xl-0 {
    margin-bottom: 0 !important;
  }

  .ml-xl-0,
.mx-xl-0 {
    margin-left: 0 !important;
  }

  .m-xl-1 {
    margin: 0.25rem !important;
  }

  .mt-xl-1,
.my-xl-1 {
    margin-top: 0.25rem !important;
  }

  .mr-xl-1,
.mx-xl-1 {
    margin-right: 0.25rem !important;
  }

  .mb-xl-1,
.my-xl-1 {
    margin-bottom: 0.25rem !important;
  }

  .ml-xl-1,
.mx-xl-1 {
    margin-left: 0.25rem !important;
  }

  .m-xl-2 {
    margin: 0.5rem !important;
  }

  .mt-xl-2,
.my-xl-2 {
    margin-top: 0.5rem !important;
  }

  .mr-xl-2,
.mx-xl-2 {
    margin-right: 0.5rem !important;
  }

  .mb-xl-2,
.my-xl-2 {
    margin-bottom: 0.5rem !important;
  }

  .ml-xl-2,
.mx-xl-2 {
    margin-left: 0.5rem !important;
  }

  .m-xl-3 {
    margin: 1rem !important;
  }

  .mt-xl-3,
.my-xl-3 {
    margin-top: 1rem !important;
  }

  .mr-xl-3,
.mx-xl-3 {
    margin-right: 1rem !important;
  }

  .mb-xl-3,
.my-xl-3 {
    margin-bottom: 1rem !important;
  }

  .ml-xl-3,
.mx-xl-3 {
    margin-left: 1rem !important;
  }

  .m-xl-4 {
    margin: 1.5rem !important;
  }

  .mt-xl-4,
.my-xl-4 {
    margin-top: 1.5rem !important;
  }

  .mr-xl-4,
.mx-xl-4 {
    margin-right: 1.5rem !important;
  }

  .mb-xl-4,
.my-xl-4 {
    margin-bottom: 1.5rem !important;
  }

  .ml-xl-4,
.mx-xl-4 {
    margin-left: 1.5rem !important;
  }

  .m-xl-5 {
    margin: 3rem !important;
  }

  .mt-xl-5,
.my-xl-5 {
    margin-top: 3rem !important;
  }

  .mr-xl-5,
.mx-xl-5 {
    margin-right: 3rem !important;
  }

  .mb-xl-5,
.my-xl-5 {
    margin-bottom: 3rem !important;
  }

  .ml-xl-5,
.mx-xl-5 {
    margin-left: 3rem !important;
  }

  .p-xl-0 {
    padding: 0 !important;
  }

  .pt-xl-0,
.py-xl-0 {
    padding-top: 0 !important;
  }

  .pr-xl-0,
.px-xl-0 {
    padding-right: 0 !important;
  }

  .pb-xl-0,
.py-xl-0 {
    padding-bottom: 0 !important;
  }

  .pl-xl-0,
.px-xl-0 {
    padding-left: 0 !important;
  }

  .p-xl-1 {
    padding: 0.25rem !important;
  }

  .pt-xl-1,
.py-xl-1 {
    padding-top: 0.25rem !important;
  }

  .pr-xl-1,
.px-xl-1 {
    padding-right: 0.25rem !important;
  }

  .pb-xl-1,
.py-xl-1 {
    padding-bottom: 0.25rem !important;
  }

  .pl-xl-1,
.px-xl-1 {
    padding-left: 0.25rem !important;
  }

  .p-xl-2 {
    padding: 0.5rem !important;
  }

  .pt-xl-2,
.py-xl-2 {
    padding-top: 0.5rem !important;
  }

  .pr-xl-2,
.px-xl-2 {
    padding-right: 0.5rem !important;
  }

  .pb-xl-2,
.py-xl-2 {
    padding-bottom: 0.5rem !important;
  }

  .pl-xl-2,
.px-xl-2 {
    padding-left: 0.5rem !important;
  }

  .p-xl-3 {
    padding: 1rem !important;
  }

  .pt-xl-3,
.py-xl-3 {
    padding-top: 1rem !important;
  }

  .pr-xl-3,
.px-xl-3 {
    padding-right: 1rem !important;
  }

  .pb-xl-3,
.py-xl-3 {
    padding-bottom: 1rem !important;
  }

  .pl-xl-3,
.px-xl-3 {
    padding-left: 1rem !important;
  }

  .p-xl-4 {
    padding: 1.5rem !important;
  }

  .pt-xl-4,
.py-xl-4 {
    padding-top: 1.5rem !important;
  }

  .pr-xl-4,
.px-xl-4 {
    padding-right: 1.5rem !important;
  }

  .pb-xl-4,
.py-xl-4 {
    padding-bottom: 1.5rem !important;
  }

  .pl-xl-4,
.px-xl-4 {
    padding-left: 1.5rem !important;
  }

  .p-xl-5 {
    padding: 3rem !important;
  }

  .pt-xl-5,
.py-xl-5 {
    padding-top: 3rem !important;
  }

  .pr-xl-5,
.px-xl-5 {
    padding-right: 3rem !important;
  }

  .pb-xl-5,
.py-xl-5 {
    padding-bottom: 3rem !important;
  }

  .pl-xl-5,
.px-xl-5 {
    padding-left: 3rem !important;
  }

  .m-xl-n1 {
    margin: -0.25rem !important;
  }

  .mt-xl-n1,
.my-xl-n1 {
    margin-top: -0.25rem !important;
  }

  .mr-xl-n1,
.mx-xl-n1 {
    margin-right: -0.25rem !important;
  }

  .mb-xl-n1,
.my-xl-n1 {
    margin-bottom: -0.25rem !important;
  }

  .ml-xl-n1,
.mx-xl-n1 {
    margin-left: -0.25rem !important;
  }

  .m-xl-n2 {
    margin: -0.5rem !important;
  }

  .mt-xl-n2,
.my-xl-n2 {
    margin-top: -0.5rem !important;
  }

  .mr-xl-n2,
.mx-xl-n2 {
    margin-right: -0.5rem !important;
  }

  .mb-xl-n2,
.my-xl-n2 {
    margin-bottom: -0.5rem !important;
  }

  .ml-xl-n2,
.mx-xl-n2 {
    margin-left: -0.5rem !important;
  }

  .m-xl-n3 {
    margin: -1rem !important;
  }

  .mt-xl-n3,
.my-xl-n3 {
    margin-top: -1rem !important;
  }

  .mr-xl-n3,
.mx-xl-n3 {
    margin-right: -1rem !important;
  }

  .mb-xl-n3,
.my-xl-n3 {
    margin-bottom: -1rem !important;
  }

  .ml-xl-n3,
.mx-xl-n3 {
    margin-left: -1rem !important;
  }

  .m-xl-n4 {
    margin: -1.5rem !important;
  }

  .mt-xl-n4,
.my-xl-n4 {
    margin-top: -1.5rem !important;
  }

  .mr-xl-n4,
.mx-xl-n4 {
    margin-right: -1.5rem !important;
  }

  .mb-xl-n4,
.my-xl-n4 {
    margin-bottom: -1.5rem !important;
  }

  .ml-xl-n4,
.mx-xl-n4 {
    margin-left: -1.5rem !important;
  }

  .m-xl-n5 {
    margin: -3rem !important;
  }

  .mt-xl-n5,
.my-xl-n5 {
    margin-top: -3rem !important;
  }

  .mr-xl-n5,
.mx-xl-n5 {
    margin-right: -3rem !important;
  }

  .mb-xl-n5,
.my-xl-n5 {
    margin-bottom: -3rem !important;
  }

  .ml-xl-n5,
.mx-xl-n5 {
    margin-left: -3rem !important;
  }

  .m-xl-auto {
    margin: auto !important;
  }

  .mt-xl-auto,
.my-xl-auto {
    margin-top: auto !important;
  }

  .mr-xl-auto,
.mx-xl-auto {
    margin-right: auto !important;
  }

  .mb-xl-auto,
.my-xl-auto {
    margin-bottom: auto !important;
  }

  .ml-xl-auto,
.mx-xl-auto {
    margin-left: auto !important;
  }
}
.frame .items.zone-6-frame .country.zone-6 {
  background-image: url("assets/images/zones/6/zone6.png"), url("assets/images/zones/5/frame-bg-2.png");
  width: 100%;
  height: 100%;
}
.frame .items.zone-6-frame .country.zone-6-germany {
  background-image: url("assets/images/zones/6/germany-zone6.png");
  width: 179px;
  height: 192px;
  bottom: 23px;
}
.frame .items.zone-6-frame .country.zone-6-bosnia {
  background-image: url("/assets/images/zones/6/bosnia.png");
  width: 28px;
  height: 50px;
  bottom: 0;
  left: 252px;
}
.frame .items.zone-6-frame .country.poland {
  background-image: url("assets/images/zones/6/poland.png");
  background-position: 1px -24px;
  width: 264px;
  height: 275px;
  bottom: 21px;
  left: 87px;
}
.frame .items.zone-6-frame .country.poland.hover {
  background-position: 0px -393px;
}
.frame .items.zone-6-frame .country.russia {
  background-image: url("assets/images/zones/6/russia.png");
  width: 824px;
  height: 716px;
  right: -91px;
  bottom: -84px;
  background-position: 0 -148px;
}
.frame .items.zone-6-frame .country.russia.hover {
  background-position: -1px -931px;
}
.frame .items.zone-6-frame .country.finland {
  background-image: url("assets/images/zones/6/finland.png");
  width: 276px;
  height: 247px;
  left: 203px;
  top: -17px;
  background-position: 0 -60px;
}
.frame .items.zone-6-frame .country.finland.hover {
  background-position: 1px -405px;
}
.frame .items.zone-6-frame .country.sweden {
  background-image: url("assets/images/zones/6/sweden.png");
  width: 235px;
  height: 343px;
  left: 57px;
  background-position: 0 -55px;
  top: 0;
}
.frame .items.zone-6-frame .country.sweden.hover {
  background-position: 0px -459px;
  height: 354px;
  top: 2px;
}
.frame .items.zone-6-frame .poi.poland {
  left: 269px;
  bottom: 132px;
}
.frame .items.zone-6-frame .poi.russia {
  right: 235px;
  top: 50%;
}
.frame .items.zone-6-frame .poi.finland {
  left: 267px;
  top: 161px;
}
.frame .items.zone-6-frame .poi.sweden {
  left: 88px;
  top: 237px;
}
.frame .items.zone-6-frame .poi.quiz-1 {
  left: 360px;
  bottom: 61px;
  width: 50px;
  height: 53px;
  background-size: cover;
  top: auto;
  z-index: 10;
  background: url("assets/images/zones/quiz-activity-new.png") bottom;
}
.frame .items.zone-6-frame .poi.quiz-2 {
  left: 112px;
  bottom: 200px;
  width: 50px;
  height: 53px;
  background-size: cover;
}
.frame .road.zone-6-1 {
  z-index: 10;
  bottom: 133px;
  left: 0;
  width: 144px;
  height: 80px;
  right: auto;
}
.frame .road.zone-6 {
  bottom: -62px;
  left: 113px;
  z-index: 10;
}

.frame .items.zone-7-frame .road {
  width: 400px;
  height: 489px;
  z-index: 8;
  bottom: 65px;
  right: 23px;
}
.frame .items.zone-7-frame .country.hover {
  z-index: 1;
}
.frame .items.zone-7-frame .country.hover.active {
  z-index: 6;
}
.frame .items.zone-7-frame .country.zone-7 {
  background-image: url("assets/images/zones/7/zone7.png"), url("assets/images/zones/7/frame-bg.png");
  width: 100%;
  height: 100%;
  background-position: bottom right !important;
}
.frame .items.zone-7-frame .country.germany {
  background-image: url("assets/images/zones/7/germany.png");
  background-position: 0 0;
  width: 343px;
  height: 253px;
  right: -51px;
  top: 69px;
  z-index: 7;
}
.frame .items.zone-7-frame .country.germany.hover {
  background-position: 1px -284px;
  right: -50px;
}
.frame .items.zone-7-frame .country.uk {
  background-image: url("assets/images/zones/7/uk.png");
  background-position: 0 -51px;
  width: 343px;
  height: 228px;
  left: 363px;
  top: 32px;
  z-index: 6;
}
.frame .items.zone-7-frame .country.uk.hover {
  background-position: 0px -350px;
  height: 231px;
}
.frame .items.zone-7-frame .country.france {
  background-image: url("assets/images/zones/7/france.png");
  width: 343px;
  height: 284px;
  right: 103px;
  top: 130px;
  background-position: 0 -14px;
  z-index: 6;
}
.frame .items.zone-7-frame .country.france.hover {
  background-position: 0 -324px;
  height: 314px;
  right: 104px;
}
.frame .items.zone-7-frame .country.spain {
  background-image: url("assets/images/zones/7/spain.png");
  width: 343px;
  height: 233px;
  bottom: 82px;
  right: 211px;
  z-index: 6;
  background-position: 0 -35px;
}
.frame .items.zone-7-frame .country.spain.hover {
  background-position: -1px -305px;
  height: 270px;
  bottom: 75px;
}
.frame .items.zone-7-frame .poi.germany {
  top: 157px;
  right: 27px;
  bottom: unset;
}
.frame .items.zone-7-frame .poi.uk {
  top: 174px;
  right: 393px;
  bottom: unset;
}
.frame .items.zone-7-frame .poi.france {
  bottom: 255px;
  right: 250px;
  top: unset;
}
.frame .items.zone-7-frame .poi.spain {
  right: 347px;
  bottom: 80px;
}
.frame .items.zone-7-frame .poi.quiz-1 {
  top: 81px;
  width: 50px;
  height: 50px;
  background-size: cover;
  right: 4px;
  background: url("assets/images/zones/quiz-activity-new.png") bottom;
}
.frame .items.zone-7-frame .poi.quiz-2 {
  width: 50px;
  height: 50px;
  background-size: cover;
  right: 192px;
  bottom: 44px;
}

.frame .items.africa-1-frame .road.africa-1 {
  z-index: 10;
  bottom: -60px;
  right: -100px;
}
.frame .items.africa-1-frame .country.hover {
  z-index: 1;
}
.frame .items.africa-1-frame .country.hover.active {
  z-index: 6;
}
.frame .items.africa-1-frame .country.africa-1 {
  background-image: url("assets/images/zones/africa-1/africa-1.png"), url("assets/images/zones/africa-1/frame-bg.png");
  width: 100%;
  height: 100%;
  background-position: bottom right !important;
}
.frame .items.africa-1-frame .country.morocco {
  background-image: url("assets/images/zones/africa-1/morocco.png");
  background-position: 0 0;
  width: 343px;
  height: 253px;
  right: 333px;
  top: -9px;
  z-index: 7;
}
.frame .items.africa-1-frame .country.morocco.hover {
  background-position: 0px -358px;
}
.frame .items.africa-1-frame .country.mauritania {
  background-image: url("assets/images/zones/africa-1/mauritania.png");
  background-position: 0 -51px;
  width: 343px;
  height: 228px;
  left: 241px;
  top: 167px;
  z-index: 8;
}
.frame .items.africa-1-frame .country.mauritania.hover {
  background-position: 0px -369.5px;
}
.frame .items.africa-1-frame .country.nigeria {
  background-image: url("assets/images/zones/africa-1/nigeria.png");
  width: 343px;
  height: 284px;
  right: 82px;
  top: 321px;
  background-position: 0 -14px;
  z-index: 6;
}
.frame .items.africa-1-frame .country.nigeria.hover {
  background-position: 0 -351px;
  right: 76px;
}
.frame .items.africa-1-frame .country.algeria {
  background-image: url("assets/images/zones/africa-1/algeria.png");
  width: 343px;
  height: 345px;
  bottom: 266px;
  right: 179px;
  z-index: 9;
  background-position: 0px 5px;
}
.frame .items.africa-1-frame .country.algeria.hover {
  background-position: 0px -326px;
}
.frame .items.africa-1-frame .poi.morocco {
  top: 103px;
  right: 468px;
  bottom: unset;
}
.frame .items.africa-1-frame .poi.mauritania {
  top: 268px;
  right: 509px;
  bottom: unset;
}
.frame .items.africa-1-frame .poi.nigeria {
  bottom: 88px;
  right: 179px;
  top: unset;
}
.frame .items.africa-1-frame .poi.algeria {
  right: 241px;
  top: 150px;
}
.frame .items.africa-1-frame .poi.quiz-1 {
  top: 135px;
  width: 64px;
  height: 64px;
  background-size: cover;
  right: 32px;
  background-image: url("assets/images/zones/poi-quiz.png");
}
.frame .items.africa-1-frame .poi.lock {
  background-image: url("assets/images/zones/zone-poi-lock.png");
}

.frame .items.africa-2-frame .road.africa-2 {
  z-index: 10;
  bottom: 12px;
  right: -158px;
}
.frame .items.africa-2-frame .road.africa-2-1 {
  z-index: 10;
  bottom: 41px;
  right: 253px;
}
.frame .items.africa-2-frame .country.hover {
  z-index: 1;
}
.frame .items.africa-2-frame .country.hover.active {
  z-index: 6;
}
.frame .items.africa-2-frame .country.africa-2 {
  background-image: url("assets/images/zones/africa-2/africa-2.png"), url("assets/images/zones/africa-2/bg.jpg");
  width: 100%;
  height: 100%;
  background-position: bottom right !important;
}
.frame .items.africa-2-frame .country.egypt {
  background-image: url("assets/images/zones/africa-2/egypt.png");
  background-position: 0 0;
  width: 343px;
  height: 253px;
  right: 254px;
  top: -56px;
  z-index: 7;
}
.frame .items.africa-2-frame .country.egypt.hover {
  background-position: -2px -334.2px;
}
.frame .items.africa-2-frame .country.sudan {
  background-image: url("assets/images/zones/africa-2/sudan.png");
  background-position: 0 0;
  width: 343px;
  height: 255px;
  left: 384px;
  top: 112px;
  z-index: 8;
}
.frame .items.africa-2-frame .country.sudan.hover {
  background-position: 1px -340px;
}
.frame .items.africa-2-frame .country.somalia {
  background-image: url("assets/images/zones/africa-2/somalia.png");
  width: 343px;
  height: 284px;
  right: 9px;
  top: 234px;
  background-position: 0 0;
  z-index: 6;
}
.frame .items.africa-2-frame .country.somalia.hover {
  background-position: 4px -318.5px;
}
.frame .items.africa-2-frame .country.kenya {
  background-image: url("assets/images/zones/africa-2/kenya.png");
  width: 343px;
  height: 345px;
  bottom: -61px;
  right: 135px;
  z-index: 9;
  background-position: 0px 0px;
}
.frame .items.africa-2-frame .country.kenya.hover {
  background-position: 0px -345px;
}
.frame .items.africa-2-frame .poi.egypt {
  top: 93px;
  right: 433px;
  bottom: unset;
}
.frame .items.africa-2-frame .poi.sudan {
  top: 258px;
  right: 425px;
  bottom: unset;
}
.frame .items.africa-2-frame .poi.somalia {
  bottom: 138px;
  right: 175px;
  top: unset;
}
.frame .items.africa-2-frame .poi.kenya {
  right: 252px;
  top: 480px;
}
.frame .items.africa-2-frame .poi.africa-1-3 {
  right: 789px;
  top: 347px;
}
.frame .items.africa-2-frame .poi.africa-1-4 {
  right: 763px;
  top: 118px;
}
.frame .items.africa-2-frame .poi.quiz-1 {
  top: 497px;
  width: 64px;
  height: 64px;
  background-size: cover;
  right: 81px;
  background-image: url("assets/images/zones/poi-quiz.png");
}
.frame .items.africa-2-frame .poi.lock {
  background-image: url("assets/images/zones/zone-poi-lock.png");
}

.frame .items.africa-3-frame .road.africa-3 {
  z-index: 10;
  bottom: 76px;
  right: 124px;
  height: 527px !important;
}
.frame .items.africa-3-frame .country.hover {
  z-index: 1;
}
.frame .items.africa-3-frame .country.hover.active {
  z-index: 6;
}
.frame .items.africa-3-frame .country.africa-3 {
  background-image: url("assets/images/zones/africa-3/africa-3.png"), url("assets/images/zones/africa-3/bg.jpg");
  width: 100%;
  height: 100%;
  background-position: bottom right !important;
}
.frame .items.africa-3-frame .country.madagascar {
  background-image: url("assets/images/zones/africa-3/madagascar.png");
  background-position: 0 0;
  width: 343px;
  height: 320px;
  right: -17px;
  top: 4px;
  z-index: 7;
}
.frame .items.africa-3-frame .country.madagascar.hover {
  background-position: 0px -340px;
}
.frame .items.africa-3-frame .country.southAfrica {
  background-image: url("assets/images/zones/africa-3/southAfrica.png");
  background-position: 0 0;
  width: 380px;
  height: 350px;
  left: 196px;
  top: 199px;
  z-index: 8;
}
.frame .items.africa-3-frame .country.southAfrica.hover {
  background-position: -1px -353.5px;
}
.frame .items.africa-3-frame .country.zimbabwe {
  background-image: url("assets/images/zones/africa-3/zimbabwe.png");
  width: 343px;
  height: 284px;
  right: 311px;
  top: 20px;
  background-position: 0 0;
  z-index: 7;
}
.frame .items.africa-3-frame .country.zimbabwe.hover {
  background-position: -1px -343px;
}
.frame .items.africa-3-frame .country.namibia {
  background-image: url("assets/images/zones/africa-3/namibia.png");
  width: 343px;
  height: 325px;
  bottom: 189px;
  right: 530px;
  z-index: 6;
  background-position: 0px 0px;
}
.frame .items.africa-3-frame .country.namibia.hover {
  background-position: 1.4px -298px;
}
.frame .items.africa-3-frame .poi.madagascar {
  top: 88px;
  right: 103px;
  bottom: unset;
}
.frame .items.africa-3-frame .poi.southAfrica {
  top: 416px;
  right: 583px;
  bottom: unset;
}
.frame .items.africa-3-frame .poi.zimbabwe {
  bottom: 356px;
  right: 439px;
  top: unset;
}
.frame .items.africa-3-frame .poi.namibia {
  right: 691px;
  top: 293px;
}
.frame .items.africa-3-frame .poi.quiz-1 {
  top: 487px;
  width: 64px;
  height: 64px;
  background-size: cover;
  right: 857px;
  background-image: url("assets/images/zones/poi-quiz.png");
}
.frame .items.africa-3-frame .poi.lock {
  background-image: url("assets/images/zones/zone-poi-lock.png");
}

body:before {
  display: none;
  content: url("assets/images/zones/south-america-1/uruguay-hover.png") url("/assets/images/zones/south-america-1/argentina-hover.png") url("/assets/images/zones/south-america-1/falkland-hover.png") url("/assets/images/zones/south-america-1/chile-hover.png");
}

.frame .items.south-america-1-frame .road {
  height: 600px !important;
}
.frame .items.south-america-1-frame .road.south-america-1 {
  z-index: 10;
  top: -38px;
  right: -124px;
}
.frame .items.south-america-1-frame .country.hover {
  z-index: 1;
}
.frame .items.south-america-1-frame .country.hover.active {
  z-index: 6;
}
.frame .items.south-america-1-frame .country.south-america-1 {
  background-image: url("/assets/images/zones/south-america-1/south-america-1.png"), url("/assets/images/zones/south-america-1/bg.jpg") !important;
  width: 100%;
  height: 100%;
  background-position: bottom right !important;
}
.frame .items.south-america-1-frame .country.falkland-islands {
  background-image: url("/assets/images/zones/south-america-1/falkland.png");
  width: 220px;
  height: 90px;
  bottom: 77px;
  right: 141px;
  z-index: 7;
}
.frame .items.south-america-1-frame .country.falkland-islands.hover {
  background-image: url("/assets/images/zones/south-america-1/falkland-hover.png");
  width: 220px;
  height: 90px;
  bottom: 63px;
  right: 149.5px;
  z-index: 7;
}
.frame .items.south-america-1-frame .country.chile {
  background-image: url("/assets/images/zones/south-america-1/chile.png");
  width: 320px;
  height: 780px;
  left: 242px;
  top: -212px;
  z-index: 8;
}
.frame .items.south-america-1-frame .country.chile.hover {
  background-image: url("/assets/images/zones/south-america-1/chile-hover.png");
  width: 320px;
  height: 780px;
  left: 233px;
  top: -199px;
}
.frame .items.south-america-1-frame .country.argentina {
  background-image: url("/assets/images/zones/south-america-1/argentina.png");
  width: 350px;
  height: 740px;
  right: 323px;
  top: -170px;
  z-index: 6;
}
.frame .items.south-america-1-frame .country.argentina.hover {
  background-image: url("/assets/images/zones/south-america-1/argentina-hover.png");
  width: 350px;
  height: 740px;
  right: 331px;
  top: -164px;
}
.frame .items.south-america-1-frame .country.uruguay {
  background-image: url("assets/images/zones/south-america-1/uruguay.png");
  width: 200px;
  height: 140px;
  top: 19px;
  right: 256px;
  z-index: 9;
}
.frame .items.south-america-1-frame .country.uruguay.hover {
  background-image: url("assets/images/zones/south-america-1/uruguay-hover.png");
  width: 200px;
  height: 140px;
  top: 29px;
  right: 266px;
}
.frame .items.south-america-1-frame .poi.falkland-islands {
  bottom: 65px;
  right: 343px;
  top: unset;
}
.frame .items.south-america-1-frame .poi.chile {
  top: 355px;
  right: 572px;
  bottom: unset;
}
.frame .items.south-america-1-frame .poi.argentina {
  top: 200px;
  right: 463px;
  bottom: unset;
}
.frame .items.south-america-1-frame .poi.uruguay {
  right: 373px;
  top: 40px;
}
.frame .items.south-america-1-frame .poi.quiz-1 {
  top: 14px;
  width: 64px;
  height: 64px;
  background-size: cover;
  right: 482px;
  background-image: url("/assets/images/zones/poi-quiz.png");
}
.frame .items.south-america-1-frame .poi.lock {
  background-image: url("/assets/images/zones/zone-poi-lock.png");
}

body:before {
  display: none;
  content: url("assets/images/zones/south-america-2/paraguay-hover.png") url("/assets/images/zones/south-america-2/brazil-hover.png") url("/assets/images/zones/south-america-2/peru-hover.png") url("/assets/images/zones/south-america-2/bolivia-hover.png");
}

.frame .items.south-america-2-frame .road {
  height: 600px !important;
}
.frame .items.south-america-2-frame .road.south-america-2 {
  z-index: 10;
  top: -78px;
  right: 109px;
}
.frame .items.south-america-2-frame .country.hover {
  z-index: 1;
}
.frame .items.south-america-2-frame .country.hover.active {
  z-index: 6;
}
.frame .items.south-america-2-frame .country.south-america-2 {
  background-image: url("/assets/images/zones/south-america-2/south-america-2.png"), url("/assets/images/zones/south-america-2/bg.jpg") !important;
  width: 100%;
  height: 101%;
  background-position: bottom right !important;
}
.frame .items.south-america-2-frame .country.paraguay {
  background-image: url("/assets/images/zones/south-america-2/paraguay.png");
  width: 267px;
  height: 199px;
  top: 303px;
  right: 274px;
  z-index: 8;
}
.frame .items.south-america-2-frame .country.paraguay.hover {
  background-image: url("/assets/images/zones/south-america-2/paraguay-hover.png");
  width: 276px;
  height: 215px;
  top: 297px;
  right: 273.5px;
  z-index: 8;
}
.frame .items.south-america-2-frame .country.bolivia {
  background-image: url("/assets/images/zones/south-america-2/bolivia.png");
  width: 275px;
  height: 268px;
  left: 277px;
  top: 148px;
  z-index: 7;
}
.frame .items.south-america-2-frame .country.bolivia.hover {
  background-image: url("/assets/images/zones/south-america-2/bolivia-hover.png");
  width: 275px;
  height: 282px;
  left: 268px;
  top: 143px;
}
.frame .items.south-america-2-frame .country.peru {
  background-image: url("/assets/images/zones/south-america-2/peru.png");
  width: 275px;
  height: 373px;
  left: 42px;
  top: -50px;
  z-index: 7;
}
.frame .items.south-america-2-frame .country.peru.hover {
  background-image: url("/assets/images/zones/south-america-2/peru-hover.png");
  width: 295px;
  height: 391px;
  left: 33px;
  top: -50px;
}
.frame .items.south-america-2-frame .country.brazil {
  background-image: url("assets/images/zones/south-america-2/brazil.png");
  width: 839px;
  height: 730px;
  bottom: -33px;
  left: 155px;
  z-index: 6;
}
.frame .items.south-america-2-frame .country.brazil.hover {
  background-image: url("assets/images/zones/south-america-2/brazil-hover.png");
  width: 839px;
  height: 877px;
  bottom: -49px;
  left: 146px;
}
.frame .items.south-america-2-frame .poi.paraguay {
  top: 362px;
  right: 464px;
  bottom: unset;
}
.frame .items.south-america-2-frame .poi.bolivia {
  top: 271px;
  right: 485px;
  bottom: unset;
  z-index: 10 !important;
}
.frame .items.south-america-2-frame .poi.peru {
  top: 63px;
  left: 105px;
  bottom: unset;
}
.frame .items.south-america-2-frame .poi.brazil {
  top: 183px;
  right: 242px;
}
.frame .items.south-america-2-frame .poi.quiz-1 {
  top: 9px;
  width: 64px;
  height: 64px;
  background-size: cover;
  right: 320px;
  background-image: url("/assets/images/zones/poi-quiz.png");
}
.frame .items.south-america-2-frame .poi.lock {
  background-image: url("/assets/images/zones/zone-poi-lock.png");
}

body:before {
  display: none;
  content: url("assets/images/zones/south-america-3/ecuador-hover.png") url("/assets/images/zones/south-america-3/colombia-hover.png") url("/assets/images/zones/south-america-3/guyana-hover.png") url("/assets/images/zones/south-america-3/venezuela-hover.png");
}

.frame .items.south-america-3-frame .road {
  height: 600px !important;
}
.frame .items.south-america-3-frame .road.south-america-3 {
  z-index: 10;
  top: 86px;
  right: 228px;
}
.frame .items.south-america-3-frame .country.hover {
  z-index: 1;
}
.frame .items.south-america-3-frame .country.hover.active {
  z-index: 6;
}
.frame .items.south-america-3-frame .country.south-america-3 {
  background-image: url("/assets/images/zones/south-america-3/south-america-3.png"), url("/assets/images/zones/south-america-3/bg.jpg") !important;
  width: 101%;
  height: 101%;
  background-position: bottom right !important;
}
.frame .items.south-america-3-frame .country.guyana {
  background-image: url("/assets/images/zones/south-america-3/guyana.png");
  width: 168px;
  height: 186px;
  top: 119px;
  right: 299px;
  z-index: 8;
}
.frame .items.south-america-3-frame .country.guyana.hover {
  background-image: url("/assets/images/zones/south-america-3/guyana-hover.png");
  width: 180px;
  height: 203px;
  top: 115px;
  right: 295px;
  z-index: 8;
}
.frame .items.south-america-3-frame .country.venezuela {
  background-image: url("/assets/images/zones/south-america-3/venezuela.png");
  width: 333px;
  height: 310px;
  left: 244px;
  top: 14px;
  z-index: 6;
}
.frame .items.south-america-3-frame .country.venezuela.hover {
  background-image: url("/assets/images/zones/south-america-3/venezuela-hover.png");
  width: 343px;
  height: 327px;
  left: 234.5px;
  top: 17px;
}
.frame .items.south-america-3-frame .country.colombia {
  background-image: url("/assets/images/zones/south-america-3/colombia.png");
  width: 319px;
  height: 452px;
  left: 78px;
  top: -13px;
  z-index: 7;
}
.frame .items.south-america-3-frame .country.colombia.hover {
  background-image: url("/assets/images/zones/south-america-3/colombia-hover.png");
  width: 336px;
  height: 465px;
  left: 69.5px;
  top: -17px;
}
.frame .items.south-america-3-frame .country.ecuador {
  background-image: url("assets/images/zones/south-america-3/ecuador.png");
  width: 196px;
  height: 197px;
  bottom: 142px;
  left: 12px;
  z-index: 9;
}
.frame .items.south-america-3-frame .country.ecuador.hover {
  background-image: url("assets/images/zones/south-america-3/ecuador-hover.png");
  width: 200px;
  height: 223px;
  bottom: 127px;
  left: 12px;
}
.frame .items.south-america-3-frame .poi.guyana {
  top: 233px;
  right: 360px;
  bottom: unset;
}
.frame .items.south-america-3-frame .poi.venezuela {
  top: 77px;
  right: 495px;
  bottom: unset;
}
.frame .items.south-america-3-frame .poi.colombia {
  top: 144px;
  left: 193px;
  bottom: unset;
}
.frame .items.south-america-3-frame .poi.ecuador {
  left: 48px;
  bottom: 200px;
}
.frame .items.south-america-3-frame .poi.quiz-1 {
  top: 196px;
  width: 64px;
  height: 64px;
  background-size: cover;
  left: 11px;
  background-image: url("/assets/images/zones/poi-quiz.png");
}
.frame .items.south-america-3-frame .poi.lock {
  background-image: url("/assets/images/zones/zone-poi-lock.png");
}

body:before {
  display: none;
  content: url("assets/images/zones/north-america-1/hover-panama.png") url("/assets/images/zones/north-america-1/hover costarika.png") url("/assets/images/zones/north-america-1/huver-hunduras.png") url("/assets/images/zones/north-america-1/huver-guatemale.png");
}

.frame .items.north-america-1-frame .road {
  height: 600px !important;
}
.frame .items.north-america-1-frame .road.north-america-1 {
  width: 790px !important;
  z-index: 17;
  top: 10px;
  right: -10px;
}
.frame .items.north-america-1-frame .country.hover {
  z-index: 1;
}
.frame .items.north-america-1-frame .country.hover.active {
  z-index: 6;
}
.frame .items.north-america-1-frame .country.north-america-1 {
  background-image: url("/assets/images/zones/north-america-1/north-america-1.png"), url("/assets/images/zones/north-america-1/bg.jpg") !important;
  width: 100%;
  height: 100%;
  background-position: bottom right !important;
}
.frame .items.north-america-1-frame .country.panama {
  background-image: url("/assets/images/zones/north-america-1/panama.png");
  width: 324px;
  height: 200px;
  bottom: 75px;
  right: 114px;
  top: 380px;
  z-index: 7;
}
.frame .items.north-america-1-frame .country.panama.hover {
  background-image: url("/assets/images/zones/north-america-1/hover-panama.png");
  width: 324px;
  height: 200px;
  bottom: 75px;
  right: 114px;
  top: 410.5px;
  z-index: 7;
}
.frame .items.north-america-1-frame .country.costarika {
  background-image: url("/assets/images/zones/north-america-1/costarika.png");
  width: 320px;
  height: 692px;
  left: 386px;
  top: -175px;
  z-index: 8;
}
.frame .items.north-america-1-frame .country.costarika.hover {
  background-image: url("/assets/images/zones/north-america-1/hover costarika.png");
  width: 320px;
  height: 692px;
  left: 386px;
  top: 311px;
}
.frame .items.north-america-1-frame .country.hunduras {
  background-image: url("/assets/images/zones/north-america-1/hunduras.png");
  width: 350px;
  height: 443px;
  right: 408px;
  top: -160px;
  z-index: 6;
}
.frame .items.north-america-1-frame .country.hunduras.hover {
  background-image: url("/assets/images/zones/north-america-1/huver-hunduras.png");
  width: 350px;
  height: 443px;
  right: 410px;
  top: 59px;
}
.frame .items.north-america-1-frame .country.guatimala {
  background-image: url("assets/images/zones/north-america-1/guatemale.png");
  width: 227px;
  height: 218px;
  top: 16px;
  right: 681px;
  z-index: 9;
}
.frame .items.north-america-1-frame .country.guatimala.hover {
  background-image: url("assets/images/zones/north-america-1/huver-guatemale.png");
  width: 227px;
  height: 218px;
  top: 20px;
  right: 683px;
}
.frame .items.north-america-1-frame .poi.panama {
  bottom: 53px;
  right: 279px;
  top: unset;
}
.frame .items.north-america-1-frame .poi.costarika {
  top: 383px;
  right: 470px;
  bottom: unset;
}
.frame .items.north-america-1-frame .poi.hunduras {
  top: 140px;
  right: 582px;
  bottom: unset;
}
.frame .items.north-america-1-frame .poi.guatimala {
  right: 736px;
  top: 108px;
}
.frame .items.north-america-1-frame .poi.quiz-1 {
  top: 33px;
  width: 64px;
  height: 64px;
  background-size: cover;
  right: 336px;
  background-image: url("/assets/images/zones/poi-quiz.png");
}
.frame .items.north-america-1-frame .poi.lock {
  background-image: url("/assets/images/zones/zone-poi-lock.png");
}

body:before {
  display: none;
  content: url("assets/images/zones/north-america-2/jamaica-hover.png") url("/assets/images/zones/north-america-2/haiti-hover.png") url("/assets/images/zones/north-america-2/dominican-hover.png") url("/assets/images/zones/north-america-2/theBahamas-hover.png");
}

.frame .items.north-america-2-frame .road {
  height: 600px !important;
}
.frame .items.north-america-2-frame .road.north-america-2 {
  z-index: 17;
  top: 55px;
  right: 239px;
}
.frame .items.north-america-2-frame .country.hover {
  z-index: 1;
}
.frame .items.north-america-2-frame .country.hover.active {
  z-index: 6;
}
.frame .items.north-america-2-frame .country.north-america-2 {
  background-image: url("/assets/images/zones/north-america-2/north-america-2.png"), url("/assets/images/zones/north-america-2/bg.jpg") !important;
  width: 100%;
  height: 100%;
  background-position: bottom right !important;
}
.frame .items.north-america-2-frame .country.jamaica {
  background-image: url("/assets/images/zones/north-america-2/jamaica.png");
  width: 777px;
  height: 173px;
  bottom: 75px;
  right: 114px;
  top: 365px;
  z-index: 7;
}
.frame .items.north-america-2-frame .country.jamaica.hover {
  background-image: url("/assets/images/zones/north-america-2/jamaica-hover.png");
  width: 777px;
  height: 173px;
  bottom: 75px;
  right: 114px;
  top: 399px;
  z-index: 7;
}
.frame .items.north-america-2-frame .country.haiti {
  background-image: url("/assets/images/zones/north-america-2/haiti.png");
  width: 388px;
  height: 696px;
  left: 352px;
  top: -187px;
  z-index: 8;
}
.frame .items.north-america-2-frame .country.haiti.hover {
  background-image: url("/assets/images/zones/north-america-2/haiti-hover.png");
  width: 388px;
  height: 696px;
  left: 352px;
  top: 351.5px;
}
.frame .items.north-america-2-frame .country.dominican {
  background-image: url("/assets/images/zones/north-america-2/dominican.png");
  width: 231px;
  height: 723px;
  right: 251px;
  top: -188px;
  z-index: 6;
}
.frame .items.north-america-2-frame .country.dominican.hover {
  background-image: url("/assets/images/zones/north-america-2/dominican-hover.png");
  width: 231px;
  height: 723px;
  right: 251px;
  top: 322.5px;
}
.frame .items.north-america-2-frame .country.theBahamas {
  background-image: url("assets/images/zones/north-america-2/theBahamas.png");
  width: 445px;
  height: 388px;
  top: -39px;
  right: 426px;
  z-index: 9;
}
.frame .items.north-america-2-frame .country.theBahamas.hover {
  background-image: url("assets/images/zones/north-america-2/theBahamas-hover.png");
  width: 445px;
  height: 388px;
  top: -11px;
  right: 426px;
}
.frame .items.north-america-2-frame .poi.jamaica {
  top: 438px;
  right: 794px;
}
.frame .items.north-america-2-frame .poi.haiti {
  top: 427px;
  right: 462px;
  bottom: unset;
}
.frame .items.north-america-2-frame .poi.dominican {
  top: 418px;
  right: 362px;
  bottom: unset;
}
.frame .items.north-america-2-frame .poi.theBahamas {
  right: 636px;
  top: 60px;
}
.frame .items.north-america-2-frame .poi.quiz-1 {
  top: 119px;
  width: 64px;
  height: 64px;
  background-size: cover;
  right: 841px;
  background-image: url("/assets/images/zones/poi-quiz.png");
}
.frame .items.north-america-2-frame .poi.lock {
  background-image: url("/assets/images/zones/zone-poi-lock.png");
}

body:before {
  display: none;
  content: url("assets/images/zones/north-america-3/cuba-hover.png") url("/assets/images/zones/north-america-3/mexico-hover.png") url("/assets/images/zones/north-america-3/usa-hover-original.png") url("/assets/images/zones/north-america-3/canada-hover.png");
}

.frame .items.north-america-3-frame .road {
  height: 600px !important;
}
.frame .items.north-america-3-frame .road.north-america-3 {
  z-index: 12;
  top: 14px;
  right: 98px;
}
.frame .items.north-america-3-frame .country.hover {
  z-index: 1;
}
.frame .items.north-america-3-frame .country.hover.active {
  z-index: 6;
}
.frame .items.north-america-3-frame .country.north-america-3 {
  background-image: url("/assets/images/zones/north-america-3/north-america-3.png"), url("/assets/images/zones/north-america-3/bg.jpg") !important;
  width: 100%;
  height: 100%;
  background-position: bottom right !important;
}
.frame .items.north-america-3-frame .country.cuba {
  background-image: url("/assets/images/zones/north-america-3/cuba.png");
  width: 324px;
  height: 200px;
  bottom: 75px;
  right: -72px;
  top: 298px;
  z-index: 7;
}
.frame .items.north-america-3-frame .country.cuba.hover {
  background-image: url("/assets/images/zones/north-america-3/cuba-hover.png");
  width: 324px;
  height: 200px;
  bottom: 75px;
  right: -72px;
  top: 374.5px;
  z-index: 7;
}
.frame .items.north-america-3-frame .country.mexico {
  background-image: url("/assets/images/zones/north-america-3/mexico.png");
  width: 329px;
  height: 692px;
  left: 368px;
  top: -131px;
  z-index: 8;
}
.frame .items.north-america-3-frame .country.mexico.hover {
  background-image: url("/assets/images/zones/north-america-3/mexico-hover.png");
  width: 329px;
  height: 692px;
  left: 368px;
  top: 326px;
}
.frame .items.north-america-3-frame .country.usa-original {
  background-image: url("/assets/images/zones/north-america-3/usa-original.png");
  width: 510px;
  height: 723px;
  right: 173px;
  top: -310px;
  z-index: 10;
}
.frame .items.north-america-3-frame .country.usa-original.hover {
  background-image: url("/assets/images/zones/north-america-3/usa-hover-original.png");
  width: 510px;
  height: 723px;
  right: 173px;
  top: 127px;
  z-index: 10;
}
.frame .items.north-america-3-frame .country.canada {
  background-image: url("assets/images/zones/north-america-3/canada.png");
  width: 649px;
  height: 263px;
  top: -32px;
  right: 77px;
  z-index: 9;
}
.frame .items.north-america-3-frame .country.canada.hover {
  background-image: url("assets/images/zones/north-america-3/canada-hover.png");
  width: 649px;
  height: 263px;
  top: -35px;
  right: 77px;
}
.frame .items.north-america-3-frame .poi.cuba {
  bottom: 127px;
  right: 146px;
  top: unset;
}
.frame .items.north-america-3-frame .poi.mexico {
  top: 443px;
  right: 422px;
  bottom: unset;
}
.frame .items.north-america-3-frame .poi.usa-original {
  top: 271px;
  right: 315px;
  bottom: unset;
}
.frame .items.north-america-3-frame .poi.canada {
  right: 428px;
  top: 70px;
}
.frame .items.north-america-3-frame .poi.quiz-1 {
  top: 106px;
  width: 64px;
  height: 64px;
  background-size: cover;
  right: 805px;
  background-image: url("/assets/images/zones/poi-quiz.png");
}
.frame .items.north-america-3-frame .poi.lock {
  background-image: url("/assets/images/zones/zone-poi-lock.png");
}

body {
  margin: 0;
  padding: 0;
  color: #010101;
  font-size: 14px;
  font-family: "Beanstalker DEMO";
}

img {
  -webkit-user-select: none;
  -webkit-touch-callout: inherit;
  touch-action: none;
  -ms-touch-action: none;
  pointer-events: none;
}

a,
a:hover,
a:active {
  text-decoration: none;
  outline: none;
  color: #010101;
}

.s-b {
  justify-content: space-between;
}

.a-c-c {
  align-content: center;
}

.clearfix {
  clear: both;
}

.row {
  margin-right: -5px;
  margin-left: -5px;
}

.col-xl,
.col-xl-auto,
.col-xl-12,
.col-xl-11,
.col-xl-10,
.col-xl-9,
.col-xl-8,
.col-xl-7,
.col-xl-6,
.col-xl-5,
.col-xl-4,
.col-xl-3,
.col-xl-2,
.col-xl-1,
.col-lg,
.col-lg-auto,
.col-lg-12,
.col-lg-11,
.col-lg-10,
.col-lg-9,
.col-lg-8,
.col-lg-7,
.col-lg-6,
.col-lg-5,
.col-lg-4,
.col-lg-3,
.col-lg-2,
.col-lg-1,
.col-md,
.col-md-auto,
.col-md-12,
.col-md-11,
.col-md-10,
.col-md-9,
.col-md-8,
.col-md-7,
.col-md-6,
.col-md-5,
.col-md-4,
.col-md-3,
.col-md-2,
.col-md-1,
.col-sm,
.col-sm-auto,
.col-sm-12,
.col-sm-11,
.col-sm-10,
.col-sm-9,
.col-sm-8,
.col-sm-7,
.col-sm-6,
.col-sm-5,
.col-sm-4,
.col-sm-3,
.col-sm-2,
.col-sm-1,
.col,
.col-auto,
.col-12,
.col-11,
.col-10,
.col-9,
.col-8,
.col-7,
.col-6,
.col-5,
.col-4,
.col-3,
.col-2,
.col-1 {
  padding-right: 5px;
  padding-left: 5px;
}

.hidden {
  display: none !important;
}

.full-width {
  width: 100%;
}

.text-danger {
  color: #ff0000;
}

.mat-radio-outer-circle {
  border-color: #2bb24c !important;
}

.mat-radio-inner-circle {
  background-color: #2bb24c !important;
}

.mat-form-field-appearance-outline .mat-form-field-outline-thick {
  color: #2bb24c !important;
}

.mat-flat-button,
.mat-raised-button,
.mat-fab,
.mat-mini-fab {
  color: #ffffff;
  background-color: #dddddd;
}

.game-box {
  background-color: #00afde;
  background-image: url("assets/images/bg/bg-1-1-2.jpg");
  background-size: cover;
  min-height: 100vh;
  overflow: auto;
}

.container {
  display: table;
  margin: 0 auto;
}

.justyfy-content-center {
  justify-content: center;
}

.frame {
  width: 980px;
  height: 600px;
  display: block;
  margin: 45px 110px 100px;
  position: relative;
  background-image: url("assets/images/loader.gif");
  background-repeat: no-repeat;
  background-position: center;
  background-color: #3db8ff;
  background-size: contain;
}
.frame .zone-5-frame .poi.quiz {
  top: 18px;
  left: 49%;
}
.frame::after {
  width: 1174px;
  height: 684px;
  content: "";
  background-image: url("assets/images/new_frame.png");
  background-repeat: no-repeat;
  position: absolute;
  z-index: 3;
  top: -46px;
  left: -103px;
}
.frame .frame-title {
  position: absolute;
  top: -88px;
  left: 243px;
  z-index: 5;
}
.frame .frame-bg {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
}
.frame .frame-bg .top {
  z-index: 0;
}
.frame .frame-bg-school {
  background: url(assets/images/profile/bg-big.jpg);
  width: 300px;
  height: 66px;
  background-position: -420px 340px;
  position: absolute;
  top: 219px;
  left: 422px;
}
.frame .frame-in {
  width: 980px;
  height: 600px;
  display: block;
  overflow: hidden;
  position: relative;
  top: 0;
  left: 0;
  z-index: 4;
}
.frame .frame-in.flex {
  display: flex;
  justify-content: center;
}
.frame .continents {
  position: relative;
  height: 600px;
}
.frame .continents svg {
  position: absolute;
  background-repeat: no-repeat;
  background-image: url("assets/images/continents/all-continents.png");
  z-index: 5;
}
.frame .continents svg g {
  fill: transparent;
}
.frame .continents svg.hover g {
  fill: rgba(0, 0, 0, 0.2) !important;
  cursor: pointer;
}
.frame .continents svg.asia {
  width: 455px;
  height: 405px;
  top: 98px;
  right: 15px;
  background-position: -209px 0;
}
.frame .continents svg.europe {
  width: 178px;
  height: 139px;
  top: 101px;
  right: 387px;
  background-position: -664px 0;
}
.frame .continents svg.africa {
  width: 209px;
  height: 240px;
  top: 226px;
  right: 387px;
  background-position: 0 0;
}
.frame .continents svg.southamerica {
  width: 143px;
  height: 227px;
  top: 307px;
  left: 192px;
  background-position: -1235px 0;
}
.frame .continents svg.northamerica {
  width: 392px;
  height: 241px;
  top: 93px;
  left: 30px;
  background-position: -842px 0;
}
.frame .continents .label-img {
  width: 90px;
  height: 90px;
  background-image: url("/assets/images/continents/labels/labels.png");
  background-position-x: 0;
  cursor: pointer;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 8;
}
@keyframes jump {
  from {
    bottom: 71px;
  }
  50% {
    bottom: 91px;
  }
  to {
    bottom: 71px;
  }
}
.frame .continents .label-img.active:after {
  content: "";
  background-image: url("assets/images/zones/arrow.png");
  position: absolute;
  width: 41px;
  height: 61px;
  left: 23px;
  animation: jump 1.4s linear infinite;
}
.frame .continents .label-img.label-img1 {
  background-position-y: 0;
}
.frame .continents .label-img.label-img2 {
  background-position-y: -90px;
}
.frame .continents .label-img.label-img3 {
  background-position-y: -180px;
}
.frame .continents .label-img.label-img4 {
  background-position-y: -270px;
}
.frame .continents .label-img.label-img5 {
  background-position-y: -360px;
}
.frame .continents .label-img.lock {
  background-position-x: 90px;
}
.frame .continents .label {
  width: 90px;
  height: 90px;
  cursor: pointer;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 9;
}
@keyframes jump {
  from {
    bottom: 71px;
  }
  50% {
    bottom: 91px;
  }
  to {
    bottom: 71px;
  }
}
.frame .continents .label.active:after {
  content: "";
  background-image: url("assets/images/zones/arrow.png");
  position: absolute;
  width: 41px;
  height: 61px;
  left: 23px;
  animation: jump 1.4s linear infinite;
}
.frame .activity-list {
  display: flex;
  margin-top: 155px;
  flex-wrap: wrap;
  text-align: center;
  align-content: center;
  width: 920px;
}
.frame .activity-list .activity {
  margin-bottom: 5px;
  width: 131px;
  height: 180px;
  background-image: url("assets/images/activity-list/activity-bg.png");
  background-position: 0px -175px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.frame .activity-list .activity .number {
  font-family: "Kids Rock DEMO";
  color: #fdf0c9;
  font-size: 1.1rem;
  text-shadow: 2px 0px 1px #5a2a0a;
  padding-top: 12px;
}
.frame .activity-list .activity .title {
  font-family: "Kids Rock DEMO";
  color: #a15221;
  font-size: 1rem;
  text-shadow: 2px 0px 1px #fff1c5;
  padding: 12px 15px 0 15px;
  height: 50px;
  line-height: 1.1em;
  vertical-align: middle;
}
.frame .activity-list .activity .type-image {
  width: 53px;
  height: 53px;
  background-image: url("assets/images/activity-list/types/defoult.png");
  background-size: cover;
}
.frame .activity-list .activity.video .type-image {
  background-image: url("assets/images/activity-list/types/video.png");
}
.frame .activity-list .activity.welcome .type-image {
  background-image: url("assets/images/activity-list/types/welcome.png");
}
.frame .activity-list .activity.questions .type-image {
  background-image: url("assets/images/activity-list/types/questions.png");
}
.frame .activity-list .activity.dot-to-dot .type-image {
  background-image: url("assets/images/activity-list/types/dot-to-dot.png");
}
.frame .activity-list .activity.match-the-codes .type-image {
  background-image: url("assets/images/activity-list/types/match-the-codes.png");
}
.frame .activity-list .activity.letter-song .type-image {
  background-image: url("assets/images/activity-list/types/letter-songs.png");
}
.frame .activity-list .activity.reading .type-image {
  background-image: url("assets/images/activity-list/types/reading.png");
}
.frame .activity-list .activity.sound-search .type-image {
  background-image: url("assets/images/activity-list/types/sound-search.png");
}
.frame .activity-list .activity.find-the-letter .type-image {
  background-image: url("assets/images/activity-list/types/find-the-letter.png");
}
.frame .activity-list .activity.drag-drop .type-image, .frame .activity-list .activity.drag-n-drop .type-image {
  background-image: url("assets/images/activity-list/types/dragDrop.png");
}
.frame .activity-list .activity.sound-hive .type-image {
  background-image: url("assets/images/activity-list/types/sound-hive.png");
}
.frame .activity-list .activity.letter-grid .type-image {
  background-image: url("assets/images/activity-list/types/letter-grid.png");
}
.frame .activity-list .activity.stack-wood .type-image {
  background-image: url("assets/images/activity-list/types/Stack the Logs.png");
}
.frame .activity-list .activity.jumping-kangaroo .type-image {
  background-image: url("assets/images/activity-list/types/Jumping Kangaroo.png");
}
.frame .activity-list .activity.submarine-game .type-image {
  background-image: url("assets/images/activity-list/types/Submarine.png");
}
.frame .activity-list .activity.to-the-air .type-image {
  background-image: url("assets/images/activity-list/types/Up to the Sky.png");
}
.frame .activity-list .activity.truck-game .type-image {
  background-image: url("assets/images/activity-list/types/Truck Checkpoint.png");
}
.frame .activity-list .activity.letter-cloud .type-image {
  background-image: url("assets/images/activity-list/types/letter-cloud.png");
}
.frame .activity-list .activity.catch-the-crab .type-image {
  background-image: url("assets/images/activity-list/types/catch-the-crab.png");
}
.frame .activity-list .activity.cross-the-bridge .type-image {
  background-image: url("assets/images/activity-list/types/cross-the-bridge.png");
}
.frame .activity-list .activity.letter-basket .type-image {
  background-image: url("assets/images/activity-list/types/letter-basket.png");
}
.frame .activity-list .activity.ice-blocks .type-image {
  background-image: url("assets/images/activity-list/types/ice-blocks.png");
}
.frame .activity-list .activity.car-repair .type-image {
  background-image: url("assets/images/activity-list/types/car-repairs.png");
}
.frame .activity-list .activity.pop-the-bubbles .type-image {
  background-image: url("assets/images/activity-list/types/pop-the-bubbles.png");
}
.frame .activity-list .activity.shooting-hoops .type-image {
  background-image: url("assets/images/activity-list/types/shooting-hoops.png");
}
.frame .activity-list .activity.sound-pads .type-image {
  background-image: url("assets/images/activity-list/types/Sound pad icon.png");
}
.frame .activity-list .activity.sound-balls .type-image {
  background-image: url("assets/images/activity-list/types/Snowball icon.png");
}
.frame .activity-list .activity.climb-wall .type-image {
  background-image: url("assets/images/activity-list/types/climb-wall.png");
}
.frame .activity-list .activity.dough-press .type-image {
  background-image: url("assets/images/activity-list/types/dough-press.png");
}
.frame .activity-list .activity.demolish-building .type-image {
  background-image: url("assets/images/activity-list/types/demolish-building.png");
}
.frame .activity-list .activity.dough-press .type-image {
  background-image: url("assets/images/activity-list/types/dough-press.png");
}
.frame .activity-list .activity.letter-bubble .type-image {
  background-image: url("assets/images/activity-list/types/letter-bubble.png");
}
.frame .activity-list .activity.load-train .type-image {
  background-image: url("assets/images/activity-list/types/load-train.png");
}
.frame .activity-list .activity.rocket-launch .type-image {
  background-image: url("assets/images/activity-list/types/rocket-launch.png");
}
.frame .activity-list .activity.truck-race .type-image {
  background-image: url("assets/images/activity-list/types/truck-race.png");
}
.frame .activity-list .activity.iqra-time .type-image {
  background-image: url("assets/images/activity-list/types/iqra-time.png");
}
.frame .activity-list .activity.recite-verse .type-image {
  background-image: url("assets/images/activity-list/types/iqra-time.png");
}
.frame .activity-list .activity.mosque-complete-verse .type-image {
  background-image: url("assets/images/activity-list/types/At the Mosque.png");
}
.frame .activity-list .activity.aeroplane .type-image {
  background-image: url("assets/images/activity-list/types/Flying Verse.png");
}
.frame .activity-list .activity.meteors-game .type-image {
  background-image: url("assets/images/activity-list/types/Save the Planet.png");
}
.frame .activity-list .activity.farm-game .type-image {
  background-image: url("assets/images/activity-list/types/Melon Farm.png");
}
.frame .activity-list .activity.castle-game .type-image {
  background-image: url("assets/images/activity-list/types/Treasure Hunt.png");
}
.frame .activity-list .activity.find-the-way .type-image {
  background-image: url("assets/images/activity-list/types/Find the way.png");
}
.frame .activity-list .activity.slime-splat .type-image {
  background-image: url("assets/images/activity-list/types/Slime Splat.png");
}
.frame .activity-list .activity.bird-game .type-image {
  background-image: url("assets/images/activity-list/types/Lost Bird.png");
}
.frame .activity-list .activity.factory-game .type-image {
  background-image: url("assets/images/activity-list/types/Sort the Boxes.png");
}
.frame .activity-list .activity.ice-board .type-image {
  background-image: url("assets/images/activity-list/types/Ice Board.png");
}
.frame .activity-list .activity .star-box {
  font-family: "Kids Rock DEMO";
  color: #a15221;
  font-size: 1rem;
  text-shadow: 4px 0px 2px #fff1c5;
  padding: 0 0 0 32px;
  position: relative;
}
.frame .activity-list .activity .star-box::before {
  content: "";
  width: 24px;
  height: 23px;
  background-image: url("assets/images/activity-list/star.png");
  position: absolute;
  left: 0;
  display: block;
}
.frame .activity-list .activity svg {
  width: 126px;
  height: 187px;
  position: absolute;
  z-index: 10;
  top: 1px;
  left: 5px;
}
.frame .activity-list .activity svg g {
  fill: none;
}
.frame .activity-list .activity.lock {
  pointer-events: none;
  cursor: default;
}
.frame .activity-list .activity.lock svg g {
  fill: rgba(0, 0, 0, 0.5);
}
.frame .activity-list .activity.lock::after {
  content: "";
  width: 29px;
  height: 34px;
  position: absolute;
  z-index: 11;
  right: 0;
  bottom: 0;
}
.frame .activity-list .activity.lock:hover {
  cursor: unset;
  background-position: bottom;
}
.frame .activity-list .activity.lock:hover svg g {
  fill: rgba(0, 0, 0, 0.5);
}
.frame .activity-list .activity:hover {
  cursor: pointer;
  background-position: -3px 5px;
}
.frame .activity-list .activity:hover svg g {
  fill: rgba(255, 255, 255, 0.2);
}
.frame .continet-title,
.frame .zone-title {
  width: 197px;
  height: 54px;
  position: absolute;
  top: -44px;
  text-align: center;
  left: 20px;
  background-image: url("assets/images/activity-list/title-bg.png");
  font-family: "Kids Rock DEMO";
  z-index: 9;
  color: #c7670a;
  font-size: 1.2rem;
  text-shadow: 1px 0px 0px #eef58a;
  padding: 10px 0;
}
.frame .zone-title {
  left: auto;
  right: 20px;
}
.frame .back-to-map {
  width: 124px;
  height: 66px;
  position: absolute;
  cursor: pointer;
  border: none;
  outline: none;
  top: 40px;
  left: 40px;
  background: url("assets/images/activity-list/back-to-map.png");
  background-position: bottom;
  z-index: 9;
}
.frame .back-to-map:hover {
  background-position: top;
}
.frame .items {
  position: relative;
  height: 600px;
  transition: all 500ms;
}
.frame .items .poi {
  width: 66px;
  height: 68px;
  display: block;
  background-image: url("assets/images/zones/zone-poi.png");
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: 17;
  opacity: 0;
  text-align: center;
  color: #ffffff;
  font-weight: normal;
  cursor: pointer;
  padding-top: 12px;
  animation: fade 1s linear forwards;
}
.frame .items .poi.quiz {
  background-image: url("assets/images/zones/poi-quiz.png");
}
.frame .items .poi.turkey {
  bottom: 262px;
  right: 176px;
}
.frame .items .poi.greece {
  left: 37%;
  bottom: 136px;
}
.frame .items .poi.italy {
  left: 151px;
  bottom: 199px;
}
.frame .items .poi.bosnia {
  left: 37%;
  top: 115px;
}
.frame .items .poi span {
  font-family: "Kids Rock DEMO";
  font-size: 1.8rem;
  background-color: #ffffff;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  -webkit-text-stroke: 1px #000000;
  filter: drop-shadow(2px 2px #000000);
}
.frame .items .poi.dark {
  background-image: url("assets/images/zones/zone-poi-dark.png");
  cursor: default;
}
.frame .items .poi.dark span {
  background-color: rgba(255, 255, 255, 0.5);
}
@keyframes fade {
  to {
    opacity: 1;
  }
}
.frame .items .poi.active::after {
  bottom: 71px;
  background-image: url("assets/images/zones/arrow.png");
  opacity: 1;
  animation: jump 1.4s linear infinite;
}
.frame .items .poi::after {
  content: "";
  width: 41px;
  height: 61px;
  display: block;
  bottom: 5px;
  position: absolute;
  opacity: 0;
  left: 13px;
  transition: opacity 400ms linear;
  z-index: 10;
}
@keyframes jump {
  from {
    bottom: 71px;
  }
  50% {
    bottom: 91px;
  }
  to {
    bottom: 71px;
  }
}
.frame .items .poi.lock {
  background-image: url("assets/images/zones/zone-poi-lock.png");
}
.frame .items .quiz-activity {
  width: 53px;
  height: 53px;
  background: url("assets/images/zones/quiz-activity-new.png");
  background-position: top;
  border: none;
  outline: none;
  position: absolute;
  z-index: 7;
  left: 86px;
  top: 26px;
}
.frame .items .quiz-activity.zone-2-1 {
  left: 88px;
  top: 496px;
}
.frame .items .quiz-activity.zone-2-2 {
  left: 198px;
  top: 158px;
}
.frame .items .quiz-activity.zone-3-1 {
  left: 668px;
  top: 170px;
}
.frame .items .quiz-activity.zone-3-2 {
  left: 15px;
  top: 170px;
}
.frame .items .quiz-activity.zone-4-1 {
  left: 493px;
  top: 170px;
}
.frame .items .quiz-activity.zone-4-2 {
  left: 203px;
  top: 218px;
}
.frame .items .quiz-activity.active {
  background-position: center;
  cursor: pointer;
}
.frame .items .quiz-activity.active.current:after {
  content: "";
  bottom: 71px;
  left: 5px;
  background-image: url("assets/images/zones/arrow.png");
  opacity: 1;
  animation: jump 1.4s linear infinite;
  width: 41px;
  height: 61px;
  position: absolute;
}
.frame .items .quiz-activity.block {
  background-position: bottom;
  cursor: default;
}
.frame .items .plane {
  width: 85px;
  height: 53px;
  display: block;
  position: absolute;
  z-index: 8;
  background-image: url("assets/images/zones/plane.png");
  bottom: 168px;
  right: 16px;
  animation: start 3s linear;
  transition: all 1.5s linear;
}
@keyframes start {
  from {
    bottom: 260px;
    right: -160px;
    transform: rotate(-40deg);
  }
  30% {
    bottom: 260px;
    right: -160px;
  }
  to {
    bottom: 168px;
    right: 16px;
    transform: rotate(0);
  }
}
.frame .items .plane.to-1 {
  bottom: 168px;
  right: 16px;
}
.frame .items .plane.to-2 {
  bottom: 180px;
  right: 378px;
  transform: rotate(-20deg);
}
.frame .items .plane.to-3 {
  bottom: 386px;
  right: 736px;
  transform: rotate(10deg);
}
.frame .items .plane.to-4 {
  bottom: 480px;
  right: 603px;
}
.frame .items .country {
  background-repeat: no-repeat;
  background-position: bottom left;
  transition: all 200ms fade;
  position: absolute;
  z-index: 6;
}
.frame .items .country.hover {
  background-position: top left;
  cursor: pointer;
}
.frame .items .country.australia {
  width: 400px;
  height: 377px;
  bottom: 55px;
  right: 186px;
  background-image: url("assets/images/zones/1/australia.png");
}
.frame .items .country.new-zealand {
  width: 183px;
  height: 250px;
  bottom: 22px;
  right: 55px;
  background-image: url("assets/images/zones/1/new-zealand.png");
}
.frame .items .country.indonesia {
  width: 562px;
  height: 249px;
  bottom: 316px;
  left: 127px;
  background-image: url("assets/images/zones/1/indonesia.png");
}
.frame .items .country.indonesia.dark {
  bottom: -109px;
  left: 117px;
  background-image: url("assets/images/zones/2/2-indonesia.png");
}
.frame .items .country.malaysia {
  width: 260px;
  height: 137px;
  bottom: 453px;
  left: 183px;
  background-image: url("assets/images/zones/1/malaysia.png");
}
.frame .items .country.malaysia.dark {
  bottom: 20px;
  left: 170px;
  background-image: url("assets/images/zones/2/2-malaysia.png");
}
.frame .items .country.thailand {
  width: 148px;
  height: 212px;
  bottom: 532px;
  left: 148px;
  background-image: url("assets/images/zones/2/thailand.png");
}
.frame .items .country.thailand.zone2 {
  bottom: 90px;
  left: 88px;
}
.frame .items .country.philippines {
  width: 158px;
  height: 206px;
  bottom: 513px;
  left: 417px;
  background-image: url("assets/images/zones/1/philippines.png");
}
.frame .items .country.philippines.zone2 {
  bottom: 70px;
  left: 390px;
}
.frame .items .country.japan {
  width: 185px;
  height: 288px;
  bottom: 300px;
  left: 387px;
  background-image: url("assets/images/zones/2/japan.png");
}
.frame .items .country.south-korea {
  width: 170px;
  height: 126px;
  bottom: 349px;
  left: 275px;
  background-image: url("assets/images/zones/2/south-korea.png");
}
.frame .items .country.china {
  width: 550px;
  height: 384px;
  bottom: 240px;
  left: -153px;
  background-image: url("assets/images/zones/2/china-mini.png");
}
.frame .items .country.china.zone3 {
  width: 679px;
  height: 466px;
  bottom: 161px;
  left: 221px;
  background-image: url("assets/images/zones/3/china.png");
}
.frame .items .country.india {
  width: 299px;
  height: 317px;
  bottom: 22px;
  left: 240px;
  background-image: url("assets/images/zones/3/india.png");
}
.frame .items .country.pakistan {
  width: 144px;
  height: 181px;
  bottom: 211px;
  left: 142px;
  background-image: url("assets/images/zones/3/pakistan.png");
}
.frame .items .country.uzbekistan {
  width: 148px;
  height: 126px;
  bottom: 409px;
  left: 68px;
  background-image: url("assets/images/zones/3/uzbekistan.png");
}
.frame .items .country.iran {
  width: 172px;
  height: 201px;
  bottom: 230px;
  left: 12px;
  background-image: url("assets/images/zones/4/iran.png");
}
.frame .items .country.iran.zone4 {
  bottom: 234px;
  left: 475px;
}
.frame .items .country.uae {
  width: 84px;
  height: 144px;
  bottom: 178px;
  left: 102px;
  background-image: url("assets/images/zones/4/uae.png");
}
.frame .items .country.uae.zone4 {
  bottom: 181px;
  left: 563px;
}
.frame .items .country.saudi {
  width: 303px;
  height: 232px;
  bottom: 92px;
  left: -138px;
  background-image: url("assets/images/zones/4/saudi.png");
}
.frame .items .country.saudi.zone4 {
  bottom: 94px;
  left: 323px;
}
.frame .items .country.palestine {
  width: 162px;
  height: 107px;
  bottom: 264px;
  left: 276px;
  background-image: url("assets/images/zones/4/palestine.png");
}
.frame .items .country.zone-5 {
  background-image: url("assets/images/zones/5/zone5.png"), url("assets/images/zones/5/frame-bg-2.png");
  width: 100%;
  height: 100%;
}
.frame .items .country.turkey {
  width: 534px;
  height: 282px;
  right: -24px;
  bottom: 89px;
  z-index: 8;
  background-size: cover;
  background-image: url("assets/images/zones/5/turkey.png");
  background-position: 0 0;
}
.frame .items .country.turkey.hover {
  background-position: 0 -305px;
  right: -21px;
  bottom: 86px;
}
.frame .items .country.greece {
  background-image: url("assets/images/zones/5/greece.png");
  background-position: 0 0;
  width: 276px;
  height: 353px;
  bottom: 93px;
  right: 430px;
  z-index: 6;
}
.frame .items .country.greece.hover {
  background-position: 0 -360px;
  bottom: 99px;
}
.frame .items .country.italy {
  background-image: url("assets/images/zones/5/italy.png");
  width: 437px;
  height: 470px;
  left: -51px;
  bottom: 54px;
  background-position: 0 -57px;
}
.frame .items .country.italy.hover {
  background-position: 1px -551px;
  bottom: 80px;
  left: -51px;
}
.frame .items .country.bosnia {
  background-image: url("assets/images/zones/5/bosnia.png");
  background-position: 0 0;
  width: 206px;
  height: 195px;
  bottom: 329px;
  left: 230px;
}
.frame .items .country.bosnia.hover {
  background-position: 0 -200px;
  bottom: 333px;
}
.frame .road {
  width: 740px;
  height: 493px;
  bottom: 110px;
  right: 132px;
  background: none;
  position: absolute;
  z-index: 6;
}
.frame .road path {
  stroke: #ffffff;
  stroke-width: 4px;
  stroke-dasharray: 20;
  stroke-dashoffset: 200;
  animation: dash 4s linear forwards;
  fill: none !important;
}
.frame .road.zone2 {
  width: 576px;
  height: 455px;
  bottom: -25px;
  right: 412px;
}
.frame .road.zone3 {
  width: 900px;
  height: 290px;
  bottom: 155px;
  right: 78px;
}
.frame .road.zone4 {
  width: 400px;
  height: 316px;
  bottom: 150px;
  right: 353px;
}
.frame .road.zone-5 {
  z-index: 10;
  width: 810px;
  height: 500px;
}
@keyframes dash {
  from {
    stroke: transparent;
  }
  30% {
    stroke: transparent;
    stroke-dashoffset: 200;
  }
  to {
    stroke-dashoffset: 0;
    stroke: #ffffff;
  }
}

.footer {
  width: 370px;
  height: 134px;
  position: absolute;
  z-index: 5;
  bottom: -70px;
  right: 276px;
}
.footer .footer-bg {
  width: 370px;
  height: 134px;
  position: relative;
}
.footer .zone-menu {
  width: 330px;
  position: absolute;
  z-index: 2;
  top: 68px;
  left: 20px;
  list-style: none;
  display: flex;
  justify-content: space-around;
  padding: 0;
  margin: 0;
}
.footer .zone-menu li {
  width: 60px;
}
.footer .zone-menu .menu-item {
  height: 32px;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: right;
  padding-right: 36px;
  font-size: 24px;
  font-family: "Kids Rock DEMO";
  line-height: 34px;
  background: #ffffff;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  -webkit-text-stroke: 1px #91301a;
  position: relative;
}
.footer .zone-menu .menu-item::after {
  content: "";
  width: 31px;
  height: 30px;
  position: absolute;
  right: 0;
  top: 0;
  background-image: url("assets/images/zones/menu-objects.png");
  background-position: 0 -70px;
}
.footer .zone-menu .menu-item.active::before {
  content: "";
  width: 32px;
  height: 41px;
  position: absolute;
  right: 14px;
  top: 32px;
  background-image: url("assets/images/zones/menu-objects.png");
  background-position: -68px -59px;
}
.footer .zone-menu .lock {
  width: 24px;
  height: 32px;
  display: inline-block;
  background-image: url("assets/images/zones/menu-objects.png");
  background-position: 0 0;
}
.footer .zone-menu li:nth-child(3) .lock {
  background-position: -24px 0;
}
.footer .zone-menu li:nth-child(4) .lock {
  background-position: -48px 0;
}

.activity-title {
  width: 384px;
  height: 149px;
  position: absolute;
  z-index: 2;
  top: -13px;
  text-align: center;
  left: 305px;
  background-image: url("assets/images/activity-list/main-title-bg.png");
  font-family: "Kids Rock DEMO";
  color: #0e551b;
  font-size: 2.4rem;
  text-shadow: 2px 0px 1px #c1ea75;
  padding: 54px 0 0;
}
.activity-title.red {
  background-image: url("assets/images/activity-list/main-title-bg-red.png");
  color: #810224;
  text-shadow: 2px 0px 1px #fb8396;
}
.activity-title.blue {
  background-image: url("assets/images/activity-list/main-title-bg-blue.png");
  color: #0e4f54;
  text-shadow: 2px 0px 1px #d1f1ef;
}
.activity-title.brown {
  background-image: url("assets/images/activity-list/main-title-bg-brown.png");
  color: #65271e;
  text-shadow: 2px 0px 1px #f7c194;
}
.activity-title.yellow {
  background-image: url("assets/images/activity-list/main-title-bg-yellow.png");
  color: #803e03;
  text-shadow: 2px 0px 1px #ffb943;
}
.activity-title.violet {
  background-image: url("assets/images/activity-list/main-title-bg-violet.png");
  color: #472050;
  text-shadow: 2px 0px 1px #e4a5cc;
}

.introduction {
  text-align: center;
}
.introduction .title {
  position: relative !important;
  display: inline-block;
  font-family: "Kids Rock DEMO";
  margin: 70px 0;
  font-size: 2.9rem;
}
.introduction .title .main-text {
  background: linear-gradient(to bottom, #edee62, #e6bd4e, #dd7c33);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  filter: drop-shadow(3px 3px 0 #43425d);
  position: relative;
  z-index: 2;
}
.introduction .title .bg-text {
  position: absolute;
  left: 0;
  z-index: 0;
  -webkit-text-stroke: 16px #ffffff;
  filter: none;
}
.introduction .img {
  width: 100%;
  max-width: 100%;
  display: block;
  margin: 0 auto;
}
.introduction .text {
  width: 600px;
  display: table;
  margin: 30px auto;
  font-family: "Kids Rock DEMO";
  text-shadow: 2px 0px #fff9f1;
  font-size: 30px;
  color: #43425d;
  min-height: 150px;
  line-height: 50px;
}
.introduction .introduction-audio-button {
  width: 58px;
  height: 59px;
  background: url("assets/images/activity/introduction/introduction-sound.png");
  background-position: bottom;
  border: none;
  outline: none;
  position: absolute;
  z-index: 2;
  right: 226px;
  top: 77px;
  overflow: visible;
}
.introduction .introduction-audio-button:hover {
  background-position: top;
}
.introduction .introduction-audio-button.ios::after {
  content: "";
  width: 41px;
  height: 61px;
  display: block;
  background-image: url("assets/images/activity/introduction/red-arrow.png");
  animation: jump 1.4s linear infinite;
  position: absolute;
  opacity: 1;
  bottom: 50px;
  left: 13px;
  transition: opacity 400ms linear;
  z-index: 10;
}
@keyframes jump {
  from {
    bottom: 50px;
  }
  50% {
    bottom: 70px;
  }
  to {
    bottom: 50px;
  }
}
.introduction .introduction-button {
  width: 218px;
  height: 84px;
  background: url("assets/images/activity/introduction/play.png");
  background-position: bottom;
  border: none;
  outline: none;
}
.introduction .introduction-button:hover {
  background-position: top;
}
.introduction.quiz .title {
  margin: 70px 0;
  font-size: 2rem;
  letter-spacing: 4px;
}
.introduction.quiz .title .main-text {
  background: linear-gradient(to bottom, #4aeaff, #00aff1);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  filter: drop-shadow(3px 3px 0 #404f6e);
}
.introduction.quiz .title .bg-text {
  -webkit-text-stroke: 6px #404f6e;
}
.introduction.quiz .text {
  width: 690px;
  display: table;
  margin: 10px auto;
  padding-left: 288px;
  font-family: "Kids Rock DEMO";
  text-shadow: 2px 0px #fff9f1;
  font-size: 1.6rem;
  color: #332e28;
  min-height: 150px;
  text-align: left;
  line-height: 3.2rem;
}
.introduction.quiz .introduction-button {
  width: 168px;
  height: 67px;
  background: url("assets/images/activity/quiz/quiz-play-button.png");
  background-position: bottom;
  border: none;
  outline: none;
  margin-top: 15px;
}
.introduction.quiz .introduction-button:hover {
  background-position: top;
}
.introduction.quiz .introduction-audio-button {
  right: 136px;
  top: 64px;
}

.complete {
  position: relative;
  text-align: center;
}
.complete .trophy {
  width: 239px;
  height: 233px;
  background-image: url("assets/images/activity/complete/trophy.png");
  position: absolute;
  top: 240px;
  left: 164px;
  animation: zoomIn 500ms linear;
}
.complete.welcome .trophy {
  left: 374px;
}
.complete .total-score {
  font-family: "Kids Rock DEMO";
  font-size: 2.8rem;
  color: #ffe058;
  position: absolute;
  top: 270px;
  left: 686px;
  background: linear-gradient(to bottom, #f4fa38, #fdba22, #bd6c2f);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  -webkit-text-stroke: 2px #91301a;
  filter: drop-shadow(2px 2px #5a2a0a);
  animation: zoomIn 100ms linear;
}
.complete .stars {
  font-family: "Kids Rock DEMO";
  font-size: 2rem;
  color: #ffffff;
  position: absolute;
  top: 359px;
  left: 497px;
  -webkit-text-stroke: 1px #91301a;
  animation: zoomIn 100ms linear;
}
.complete .back-to-menu,
.complete .again,
.complete .next-activity {
  width: 135px;
  height: 76px;
  background: url("assets/images/activity/complete/complete-bg.png");
  position: absolute;
  top: 490px;
  left: 405px;
  background-position: 0 -676px;
  border: none;
  outline: none;
}
.complete .back-to-menu:hover,
.complete .again:hover,
.complete .next-activity:hover {
  background-position: 0 -600px;
}
.complete .again {
  width: 136px;
  height: 75px;
  background-position: -135px -675px;
  left: 556px;
}
.complete .again:hover {
  background-position: -135px -600px;
}
.complete .next-activity {
  width: 136px;
  height: 75px;
  background-position: -271px -675px;
  left: 710px;
}
.complete .next-activity:hover {
  background-position: -271px -600px;
}
@keyframes zoomIn {
  from {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3);
  }
  50% {
    opacity: 1;
  }
}
.complete.quiz .title {
  margin: 70px 0;
  font-size: 2rem;
  letter-spacing: 4px;
  position: relative !important;
  display: inline-block;
  font-family: "Kids Rock DEMO";
}
.complete.quiz .title .main-text {
  background: linear-gradient(to bottom, #4aeaff, #00aff1);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  filter: drop-shadow(3px 3px 0 #404f6e);
  position: relative;
  z-index: 2;
}
.complete.quiz .title .bg-text {
  position: absolute;
  left: 0;
  z-index: 0;
  -webkit-text-stroke: 6px #404f6e;
  filter: none;
}
.complete.quiz .center-box {
  width: 660px;
  margin: 0 auto;
  display: flex;
}
.complete.quiz .cert-box {
  flex: auto;
  width: 590px;
  display: flex;
  align-items: center;
}
.complete.quiz .cert-box .sert {
  width: 155px;
  padding: 2px;
  background-color: rgba(0, 0, 0, 0.8);
  text-align: center;
  position: relative;
  transition: all linear 200ms;
  cursor: pointer;
  margin: 27px;
  margin-left: 100px;
  margin-top: 12px;
}
.complete.quiz .cert-box .sert img {
  width: 100%;
  max-width: 100%;
}
.complete.quiz .cert-box .sert .cert-text {
  top: 88px;
  position: absolute;
  z-index: 4;
}
.complete.quiz .cert-box .sert .name {
  font-size: 0.7rem;
}
.complete.quiz .cert-box .sert .description {
  font-size: 0.5rem;
  color: #e51b00;
  padding: 4px 10px;
}
.complete.quiz .cert-box .sert .date {
  font-size: 0.3rem;
  padding: 4px 0 0 60px;
  color: #0090d9;
}
.complete.quiz .box-style {
  font-family: "Kids Rock DEMO";
  padding-top: 70px;
  color: #000000;
  font-size: 1.6rem;
  opacity: 0.7;
  text-align: left;
  margin-left: 10px;
}
.complete.quiz .text-box {
  font-family: "Kids Rock DEMO";
  flex: auto;
  padding-top: 70px;
  color: #000000;
  font-size: 1.6rem;
  text-shadow: 2px 0 #fff9f1;
  opacity: 0.7;
  text-align: left;
}
.complete.quiz .again {
  left: 460px;
}
.complete.quiz .next-activity {
  left: 610px;
}
.complete.continent {
  position: absolute;
  z-index: 1000000;
  top: 0;
  left: 120px;
}
.complete.continent.quiz .title {
  margin: 70px 73px;
  width: 100%;
  text-align: center;
}
.complete.continent.quiz .title .bg-text {
  width: 100%;
}
.complete.continent.quiz .cert-box {
  width: 663px;
}
.complete.continent.quiz .cert-box .cert-text {
  width: 100%;
  color: #a72441;
}
.complete.continent.quiz .cert-box .date {
  bottom: -39px;
  left: -38px;
}
.complete.continent.quiz .next-activity {
  left: 524px;
}

.try-again {
  position: relative;
}
.try-again .fail-star {
  width: 239px;
  height: 233px;
  background-image: url("assets/images/activity/try-again/fail-star.png");
  position: absolute;
  top: 240px;
  left: 164px;
  animation: zoomIn 500ms linear;
}
.try-again .total-score {
  font-family: "Kids Rock DEMO";
  font-size: 2.8rem;
  color: #ffe058;
  position: absolute;
  top: 270px;
  left: 686px;
  background: linear-gradient(to bottom, #f4fa38, #fdba22, #bd6c2f);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  -webkit-text-stroke: 2px #91301a;
  filter: drop-shadow(2px 2px #5a2a0a);
  animation: zoomIn 100ms linear;
}
.try-again .stars {
  font-family: "Kids Rock DEMO";
  font-size: 2rem;
  color: #ffffff;
  position: absolute;
  top: 359px;
  left: 497px;
  -webkit-text-stroke: 1px #91301a;
  animation: zoomIn 100ms linear;
}
.try-again .back-to-menu,
.try-again .again {
  width: 136px;
  height: 76px;
  background: url("assets/images/activity/complete/back-to-menu.png");
  position: absolute;
  top: 490px;
  left: 425px;
  background-position: bottom;
  border: none;
  outline: none;
}
.try-again .back-to-menu:hover,
.try-again .again:hover {
  background-position: top;
}
.try-again .again {
  width: 136px;
  height: 75px;
  background: url("assets/images/activity/complete/again.png");
  background-position: bottom;
  left: 576px;
}
@keyframes zoomIn {
  from {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3);
  }
  50% {
    opacity: 1;
  }
}
.try-again.quiz .title {
  margin: 70px 0;
  font-size: 2rem;
  letter-spacing: 4px;
  position: relative !important;
  display: inline-block;
  font-family: "Kids Rock DEMO";
}
.try-again.quiz .title .main-text {
  background: linear-gradient(to bottom, #4aeaff, #00aff1);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  filter: drop-shadow(3px 3px 0 #404f6e);
  position: relative;
  z-index: 2;
}
.try-again.quiz .title .bg-text {
  position: absolute;
  left: 0;
  z-index: 0;
  -webkit-text-stroke: 6px #404f6e;
  filter: none;
}
.try-again.quiz .center-box {
  width: 660px;
  margin: 0 auto;
  display: flex;
}
.try-again.quiz .cert-box {
  flex: auto;
  width: 590px;
}
.try-again.quiz .text-box {
  font-family: "Kids Rock DEMO";
  flex: auto;
  padding-top: 70px;
  color: #000000;
  font-size: 1.6rem;
  text-shadow: 2px 0 #fff9f1;
  opacity: 0.7;
  text-align: left;
}
.try-again.quiz .text-box p {
  margin-bottom: 25px;
}
.try-again.quiz .again {
  left: 610px;
}
.try-again.quiz .back-to-menu {
  left: 460px;
}

.leave .text2 {
  width: 660px;
  display: table;
  margin: 288px auto 39px;
  font-family: "Kids Rock DEMO";
  text-shadow: 2px 0px #fff9f1;
  font-size: 26px;
  color: #43425d;
  text-align: center;
}
.leave .text {
  width: 660px;
  display: table;
  margin: 260px auto 60px;
  font-family: "Kids Rock DEMO";
  text-shadow: 2px 0px #fff9f1;
  font-size: 26px;
  color: #43425d;
  text-align: center;
}
.leave .save-ok {
  width: 218px;
  height: 84px;
  background: url("assets/images/activity/dot-to-dot/ok-button.png");
  background-position: bottom;
  border: none;
  outline: none;
}
.leave .save-ok:hover {
  background-position: top;
}
.leave .leave-yes,
.leave .leave-no {
  width: 172px;
  height: 79px;
  background: url("assets/images/activity/leave/leave-bg.png");
  background-position: -172px -679px;
  border: none;
  outline: none;
}
.leave .leave-yes:hover,
.leave .leave-no:hover {
  background-position: -172px -600px;
}
.leave .leave-no {
  background-position: 0 -679px;
}
.leave .leave-no:hover {
  background-position: 0 -600px;
}

.leave-button {
  width: 63px;
  height: 65px;
  background: url("assets/images/activity/leave/leave.png");
  background-position: bottom;
  border: none;
  outline: none;
  position: absolute;
  z-index: 2;
  right: 10px;
  top: 10px;
}
.leave-button:hover {
  background-position: top;
}

.video-box {
  position: relative;
}

.quiz-box .leave-button {
  width: 95px;
  height: 98px;
  background: url("assets/images/activity/quiz/quiz-close-new.png");
  background-position: bottom;
  border: none;
  outline: none;
  position: absolute;
  z-index: 2;
  right: 14px;
  top: -15px;
}
.quiz-box .leave-button:hover {
  background-position: top;
}
.quiz-box .time-box {
  font-family: "Kids Rock DEMO";
  color: #543018;
  font-size: 1.4rem;
  line-height: 84px;
  position: absolute;
  top: 8px;
  right: 400px;
}
.quiz-box .title-box {
  position: relative;
  top: 0;
  left: 375px;
  width: 240px;
}
.quiz-box .title-box .title {
  font-family: "Kids Rock DEMO";
  color: #ffffff;
  font-size: 2.2rem;
  line-height: 84px;
  display: inline;
  position: relative;
}
.quiz-box .title-box .title .main-text {
  background: linear-gradient(to bottom, #ffffff, #ffffff);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  filter: drop-shadow(3px 3px 0 #43425d);
  position: relative;
  z-index: 2;
}
.quiz-box .title-box .title .bg-text {
  width: 300px;
  position: absolute;
  left: 0;
  z-index: 0;
  -webkit-text-stroke: 6px #4a1d00;
  filter: none;
  text-align: left;
}
.quiz-box .sound-button {
  width: 95px;
  height: 97px;
  background: url("assets/images/activity/quiz/quiz-audio-new.png");
  background-position: bottom;
  border: none;
  outline: none;
  position: absolute;
  z-index: 2;
  right: 76px;
  top: 126px;
}
.quiz-box .sound-button:hover {
  background-position: top;
}
.quiz-box .question-box {
  width: 646px;
  position: absolute;
  font-family: "Kids Rock DEMO";
  top: 143px;
  left: 140px;
  text-align: left;
  font-size: 1.4rem;
  line-height: 1.8rem;
}
.quiz-box .question-box .question-number {
  color: #000000;
  display: block;
  float: left;
}
.quiz-box .question-box .question-text {
  color: #000000;
  display: block;
  float: left;
  padding-left: 20px;
}
.quiz-box .answers-box {
  position: absolute;
  width: 688px;
  top: 216px;
  left: 140px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  font-family: "QuranErafont";
}
.quiz-box .answers-box .answer-button {
  width: 340px;
  height: 150px;
  background: url("assets/images/activity/quiz/quiz-buttons-new.png");
  background-position: 0 -450px;
  background-repeat: no-repeat;
  border: none;
  outline: none;
  text-align: left;
  font-size: 4rem;
  margin-bottom: 0;
  color: #000000;
  display: flex;
  justify-content: center;
  align-items: center;
}
.quiz-box .answers-box .answer-button img {
  height: 110px;
}
.quiz-box .answers-box .answer-button:hover {
  background-position: 0 -300px;
}
.quiz-box .answers-box .answer-button.correnct {
  background-position: 0 0;
}
.quiz-box .answers-box .answer-button.incorrenct {
  background-position: 0 -150px;
}
.quiz-box .progress-bar {
  width: 735px;
  position: absolute;
  top: 535px;
  left: 120px;
}
.quiz-box .progress-bar .bar {
  height: 28px;
  border-radius: 4px;
}
.quiz-box .progress-bar .bar .mat-progress-bar-background {
  display: none;
}
.quiz-box .progress-bar .bar .mat-progress-bar-fill::before {
  content: "";
  width: 98%;
  height: 14px;
  margin: 3px 1%;
  background-color: rgba(64, 188, 213, 0.2);
  position: absolute;
  z-index: 5;
  border-radius: 6px 6px 0 0;
  left: 0;
}
.quiz-box .progress-bar .bar .mat-progress-bar-fill::after {
  border-radius: 6px;
  background: linear-gradient(to top, #40bcd5, #1c97be 20%, #44c1e1);
}
.quiz-box .progress-bar .bar .mat-progress-bar-buffer {
  background: none;
}

.dot-to-dot-box .title-box {
  text-align: center;
  width: 685px;
  height: 90px;
  background-image: url("assets/images/activity/dot-to-dot/top-bar.png");
  margin: 10px 126px;
}
.dot-to-dot-box .title-box span {
  font-family: "Kids Rock DEMO";
  color: #8e3f04;
  font-size: 2rem;
  line-height: 84px;
  text-transform: uppercase;
}
.dot-to-dot-box .title-box span small {
  padding-left: 25px;
  font-family: "QuranErafont";
  font-size: 3rem;
  color: #343333;
  text-shadow: -1px -1px 0 #fff, 1px -1px 0 #fff, -1px 1px 0 #fff, 1px 1px 0 #fff;
  -webkit-text-shadow: -1px -1px 0 #fff, 1px -1px 0 #fff, -1px 1px 0 #fff, 1px 1px 0 #fff;
}
.dot-to-dot-box .leave-button {
  width: 63px;
  height: 65px;
  background: url("assets/images/activity/leave/leave.png");
  background-position: bottom;
  border: none;
  outline: none;
  position: absolute;
  z-index: 2;
  right: 45px;
  top: 28px;
}
.dot-to-dot-box .leave-button:hover {
  background-position: top;
}
.dot-to-dot-box .sound-button {
  width: 63px;
  height: 65px;
  background: url("assets/images/activity/dot-to-dot/sound.png");
  background-position: bottom;
  border: none;
  outline: none;
  position: absolute;
  z-index: 2;
  left: 30px;
  top: 28px;
}
.dot-to-dot-box .sound-button.pause, .dot-to-dot-box .sound-button:hover {
  background-position: top;
}
.dot-to-dot-box .main-box {
  display: flex;
}
.dot-to-dot-box .main-box .brush-size {
  width: 115px;
  height: 250px;
  position: absolute;
  top: 170px;
  left: 0;
}
.dot-to-dot-box .main-box .brush-size ul {
  padding: 0;
  list-style: none;
}
.dot-to-dot-box .main-box .brush-size li {
  width: 14px;
  height: 14px;
  border-radius: 50%;
  background-color: #000000;
  border: solid 3px transparent;
  position: absolute;
  cursor: pointer;
  top: 206px;
  left: 50px;
}
.dot-to-dot-box .main-box .brush-size li.m {
  width: 22px;
  height: 22px;
  top: 164px;
  left: 46px;
}
.dot-to-dot-box .main-box .brush-size li.l {
  width: 30px;
  height: 30px;
  top: 114px;
  left: 42px;
}
.dot-to-dot-box .main-box .brush-size li.active {
  border-color: #eda742;
}
.dot-to-dot-box .main-box .brush-size li.s.active {
  width: 20px;
  height: 20px;
  top: 203px;
  left: 47px;
}
.dot-to-dot-box .main-box .brush-size li.m.active {
  width: 28px;
  height: 28px;
  top: 161px;
  left: 43px;
}
.dot-to-dot-box .main-box .brush-size li.l.active {
  width: 36px;
  height: 36px;
  top: 111px;
  left: 39px;
}
.dot-to-dot-box .main-box .brush-size .brush {
  width: 115px;
  height: 104px;
  cursor: pointer;
  position: absolute;
  left: 0;
  top: 0;
  background: url("assets/images/activity/dot-to-dot/brush-active.png");
  opacity: 0;
}
.dot-to-dot-box .main-box .brush-size.active .brush, .dot-to-dot-box .main-box .brush-size:hover .brush {
  opacity: 1;
}
.dot-to-dot-box .main-box .eraser {
  width: 114px;
  height: 103px;
  position: absolute;
  left: 0;
  bottom: 60px;
  cursor: pointer;
}
.dot-to-dot-box .main-box .eraser:hover, .dot-to-dot-box .main-box .eraser.active {
  background: url("assets/images/activity/dot-to-dot/eraser.png");
}
.dot-to-dot-box .main-box .draw-box {
  width: 500px;
  height: 400px;
  position: absolute;
  top: 160px;
  left: 210px;
  text-align: left;
}
.dot-to-dot-box .main-box .draw-box .canvas-bg {
  width: 500px;
  height: 400px;
  position: absolute;
  top: 0;
  left: 0;
  user-drag: none;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-drag: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}
.dot-to-dot-box .main-box .draw-box .current-letter {
  position: absolute;
  z-index: 5;
  font-family: "Kids Rock DEMO";
  color: #8e3f04;
  font-size: 3.2rem;
  line-height: 3.2rem;
  border: solid 4px #8e3f04;
  border-radius: 50%;
  padding: 15px 30px;
  text-transform: uppercase;
}
.dot-to-dot-box .main-box .paint-example {
  width: 120px;
  height: 120px;
  position: absolute;
  top: 124px;
  right: 24px;
  border-radius: 16px;
}
.dot-to-dot-box .main-box .color-box {
  width: 132px;
  padding: 0;
  list-style: none;
  position: absolute;
  top: 258px;
  right: 18px;
}
.dot-to-dot-box .main-box .color-box li {
  width: 64px;
  height: 66px;
  cursor: pointer;
  background-repeat: no-repeat;
  float: left;
  position: absolute;
  top: 0;
  left: 0;
}
.dot-to-dot-box .main-box .color-box li:nth-child(2n) {
  right: 0;
  left: auto;
}
.dot-to-dot-box .main-box .color-box li:nth-child(3) {
  top: 52px;
}
.dot-to-dot-box .main-box .color-box li:nth-child(4) {
  top: 52px;
}
.dot-to-dot-box .main-box .color-box li:nth-child(5) {
  top: 104px;
}
.dot-to-dot-box .main-box .color-box li:nth-child(6) {
  top: 104px;
}
.dot-to-dot-box .main-box .color-box li:nth-child(7) {
  top: 156px;
}
.dot-to-dot-box .main-box .color-box li:nth-child(8) {
  top: 156px;
}
.dot-to-dot-box .main-box .color-box li:nth-child(9) {
  top: 208px;
}
.dot-to-dot-box .main-box .color-box li:nth-child(10) {
  top: 208px;
}
.dot-to-dot-box .main-box .color-box li:nth-child(11) {
  top: 260px;
}
.dot-to-dot-box .main-box .color-box li:nth-child(12) {
  top: 260px;
}
.dot-to-dot-box .main-box .color-box li.active, .dot-to-dot-box .main-box .color-box li:hover {
  background-image: url("assets/images/activity/dot-to-dot/colors_hover.png");
  background-position: 0 0;
}
.dot-to-dot-box .main-box .color-box li.active:nth-child(2), .dot-to-dot-box .main-box .color-box li:nth-child(2):hover {
  background-position: -64px 0;
}
.dot-to-dot-box .main-box .color-box li.active:nth-child(3), .dot-to-dot-box .main-box .color-box li:nth-child(3):hover {
  background-position: 0 -66px;
}
.dot-to-dot-box .main-box .color-box li.active:nth-child(4), .dot-to-dot-box .main-box .color-box li:nth-child(4):hover {
  background-position: -64px -66px;
}
.dot-to-dot-box .main-box .color-box li.active:nth-child(5), .dot-to-dot-box .main-box .color-box li:nth-child(5):hover {
  background-position: 0 -132px;
}
.dot-to-dot-box .main-box .color-box li.active:nth-child(6), .dot-to-dot-box .main-box .color-box li:nth-child(6):hover {
  background-position: -64px -132px;
}
.dot-to-dot-box .main-box .color-box li.active:nth-child(7), .dot-to-dot-box .main-box .color-box li:nth-child(7):hover {
  background-position: 0 -198px;
}
.dot-to-dot-box .main-box .color-box li.active:nth-child(8), .dot-to-dot-box .main-box .color-box li:nth-child(8):hover {
  background-position: -64px -198px;
}
.dot-to-dot-box .main-box .color-box li.active:nth-child(9), .dot-to-dot-box .main-box .color-box li:nth-child(9):hover {
  background-position: 0 -264px;
}
.dot-to-dot-box .main-box .color-box li.active:nth-child(10), .dot-to-dot-box .main-box .color-box li:nth-child(10):hover {
  background-position: -64px -264px;
}
.dot-to-dot-box .main-box .color-box li.active:nth-child(11), .dot-to-dot-box .main-box .color-box li:nth-child(11):hover {
  background-position: 0 -328px;
}
.dot-to-dot-box .main-box .color-box li.active:nth-child(12), .dot-to-dot-box .main-box .color-box li:nth-child(12):hover {
  background-position: -64px -328px;
}

.done-button2 {
  width: 92px;
  height: 64px;
  background: none;
  background-image: url("assets/images/activity/dot-to-dot/next-button.png");
  background-position: bottom;
  border: none;
  outline: none;
  position: absolute;
  z-index: 2;
  left: 610px;
  top: 489px;
}
.done-button2:hover {
  background-position: top;
}
.done-button2.last {
  background-image: url("assets/images/activity/dot-to-dot/done-button.png");
}

.profile .leave-button {
  width: 63px;
  height: 65px;
  background: url("assets/images/activity/leave/leave.png");
  background-position: bottom;
  border: none;
  outline: none;
  position: absolute;
  z-index: 2;
  right: 24px;
  top: 10px;
}
.profile .leave-button:hover {
  background-position: top;
}
.profile .parents-button {
  width: 162px;
  height: 56px;
  background: url("assets/images/profile/parents.png");
  background-position: bottom;
  border: none;
  outline: none;
  position: absolute;
  z-index: 2;
  left: 24px;
  top: 15px;
}
.profile .parents-button:hover {
  background-position: top;
}
.profile .profile-data {
  width: 800px;
  min-height: 360px;
  position: absolute;
  z-index: 2;
  left: 60px;
  top: 100px;
  display: flex;
}
.profile .profile-data .profile-school-name {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.profile .profile-data .avatar {
  width: 360px;
  height: 360px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}
.profile .profile-data .avatar img {
  width: 325px;
  height: 325px;
}
.profile .profile-data .avatar .edit {
  width: 75px;
  height: 64px;
  background: url("assets/images/profile/pen.png");
  background-position: bottom;
  border: none;
  outline: none;
  position: absolute;
  z-index: 2;
  right: 48px;
  bottom: 48px;
}
.profile .profile-data .avatar .edit:hover {
  background-position: top;
}
.profile .profile-data ul {
  list-style: none;
  padding: 50px 0 0 150px;
}
.profile .profile-data ul li {
  width: 280px;
  font-size: 1.5rem;
  line-height: 70px;
  min-height: 70px;
  font-family: "Kids Rock DEMO";
  text-align: left;
}
.profile .profile-data .user-data5 ul {
  padding-top: 88px;
}
.profile .action-buttons {
  width: 880px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  z-index: 2;
  left: 40px;
  bottom: 16px;
}
.profile .action-buttons .awards,
.profile .action-buttons .assignment,
.profile .action-buttons .progress,
.profile .action-buttons .gallery {
  width: 246px;
  height: 83px;
  background: url("assets/images/profile/my-awards.png");
  background-position: bottom;
  border: none;
  outline: none;
  margin: 0 5px 0 15px;
}
.profile .action-buttons .awards:hover,
.profile .action-buttons .assignment:hover,
.profile .action-buttons .progress:hover,
.profile .action-buttons .gallery:hover {
  background-position: top;
}
.profile .action-buttons .awards.progress,
.profile .action-buttons .assignment.progress,
.profile .action-buttons .progress.progress,
.profile .action-buttons .gallery.progress {
  background-image: url("assets/images/profile/my-progress.png");
}
.profile .action-buttons .awards.gallery,
.profile .action-buttons .assignment.gallery,
.profile .action-buttons .progress.gallery,
.profile .action-buttons .gallery.gallery {
  background-image: url("assets/images/profile/my-gallery.png");
}
.profile .action-buttons .awards.assignment,
.profile .action-buttons .assignment.assignment,
.profile .action-buttons .progress.assignment,
.profile .action-buttons .gallery.assignment {
  background-image: url("assets/images/profile/my-assignments.png");
}

.set-avatar .back-button {
  width: 133px;
  height: 48px;
  background: url("assets/images/avatar/back-1.png");
  background-position: bottom;
  border: none;
  outline: none;
  position: absolute;
  z-index: 2;
  left: 16px;
  top: 25px;
}
.set-avatar .back-button:hover {
  background-position: top;
}
.set-avatar .in-box {
  width: 850px;
  height: 570px;
  display: flex;
  align-items: center;
  margin-left: 124px;
  margin-top: 12px;
}
.set-avatar .big-avatar {
  width: 540px;
  height: 496px;
  margin-top: 94px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}
.set-avatar .big-avatar .done {
  width: 75px;
  height: 48px;
  background: url("assets/images/avatar/done.png");
  background-position: center;
  border: none;
  outline: none;
  position: absolute;
  z-index: 2;
  right: 34px;
  top: 28px;
}
.set-avatar .big-avatar .done:hover {
  background-position: top;
}
.set-avatar .big-avatar .done.set {
  background-position: bottom;
}
.set-avatar .avatar-list {
  width: 124px;
  margin-left: 34px;
}
.set-avatar .avatar-list ul {
  height: 550px;
  display: flex;
  flex-direction: column;
  align-items: center;
  list-style: none;
  padding: 0;
  margin-top: 30px;
}
.set-avatar .avatar-list ul li {
  height: 92px;
}
.set-avatar .avatar-list ul button {
  width: 98px;
  height: 95px;
  background: url("assets/images/avatar/avatars/avatars.png");
  background-position: bottom;
  border: none;
  outline: none;
}
.set-avatar .avatar-list ul button:hover {
  background-position: top;
}
.set-avatar .avatar-list ul button.b1 {
  background-position: 1176px bottom;
}
.set-avatar .avatar-list ul button.b1:hover {
  background-position: 1176px center;
}
.set-avatar .avatar-list ul button.b1.active {
  background-position: 1176px top;
}
.set-avatar .avatar-list ul button.b6 {
  background-position: 686px bottom;
}
.set-avatar .avatar-list ul button.b6:hover {
  background-position: 686px center;
}
.set-avatar .avatar-list ul button.b6.active {
  background-position: 686px top;
}
.set-avatar .avatar-list ul button.b5 {
  background-position: 784px bottom;
}
.set-avatar .avatar-list ul button.b5:hover {
  background-position: 784px center;
}
.set-avatar .avatar-list ul button.b5.active {
  background-position: 784px top;
}
.set-avatar .avatar-list ul button.b4 {
  background-position: 882px bottom;
}
.set-avatar .avatar-list ul button.b4:hover {
  background-position: 882px center;
}
.set-avatar .avatar-list ul button.b4.active {
  background-position: 882px top;
}
.set-avatar .avatar-list ul button.b3 {
  background-position: 980px bottom;
}
.set-avatar .avatar-list ul button.b3:hover {
  background-position: 980px center;
}
.set-avatar .avatar-list ul button.b3.active {
  background-position: 980px top;
}
.set-avatar .avatar-list ul button.b2 {
  background-position: 1078px bottom;
}
.set-avatar .avatar-list ul button.b2:hover {
  background-position: 1078px center;
}
.set-avatar .avatar-list ul button.b2.active {
  background-position: 1078px top;
}
.set-avatar .avatar-list ul button.g1 {
  background-position: 588px bottom;
}
.set-avatar .avatar-list ul button.g1:hover {
  background-position: 588px center;
}
.set-avatar .avatar-list ul button.g1.active {
  background-position: 588px top;
}
.set-avatar .avatar-list ul button.g2 {
  background-position: 490px bottom;
}
.set-avatar .avatar-list ul button.g2:hover {
  background-position: 490px center;
}
.set-avatar .avatar-list ul button.g2.active {
  background-position: 490px top;
}
.set-avatar .avatar-list ul button.g3 {
  background-position: 392px bottom;
}
.set-avatar .avatar-list ul button.g3:hover {
  background-position: 392px center;
}
.set-avatar .avatar-list ul button.g3.active {
  background-position: 392px top;
}
.set-avatar .avatar-list ul button.g4 {
  background-position: 294px bottom;
}
.set-avatar .avatar-list ul button.g4:hover {
  background-position: 294px center;
}
.set-avatar .avatar-list ul button.g4.active {
  background-position: 294px top;
}
.set-avatar .avatar-list ul button.g5 {
  background-position: 196px bottom;
}
.set-avatar .avatar-list ul button.g5:hover {
  background-position: 196px center;
}
.set-avatar .avatar-list ul button.g5.active {
  background-position: 196px top;
}
.set-avatar .avatar-list ul button.g6 {
  background-position: 98px bottom;
}
.set-avatar .avatar-list ul button.g6:hover {
  background-position: 98px center;
}
.set-avatar .avatar-list ul button.g6.active {
  background-position: 98px top;
}
.set-avatar .sex-box {
  width: 150px;
  height: 258px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
}
.set-avatar .sex-box button {
  width: 99px;
  height: 101px;
  background: url("assets/images/avatar/boy.png");
  background-position: bottom;
  border: none;
  outline: none;
  position: relative;
}
.set-avatar .sex-box button.female {
  background-image: url("assets/images/avatar/girl.png");
}
.set-avatar .sex-box button:hover {
  background-position: top;
}
.set-avatar .sex-box button.active::after {
  content: "";
  width: 37px;
  height: 54px;
  background: url("assets/images/avatar/arrow-1.png");
  position: absolute;
  left: -44px;
  top: 24px;
}

.alert-pop-up {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
  transition: all linear 200ms;
  font-family: "Nunito", sans-serif;
  font-size: 1.1rem;
  background-color: rgba(255, 255, 255, 0.4);
}
.alert-pop-up .alert-pop-up-in {
  width: 500px;
  padding: 20px;
  background-color: #ffffff;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.8);
  color: rgba(0, 0, 0, 0.8);
  position: relative;
}
.alert-pop-up .alert-pop-up-in-content {
  max-height: 350px;
  overflow: auto;
}
.alert-pop-up .alert-pop-up-in button {
  min-width: 150px;
  margin: 15px auto;
  display: table;
}
.alert-pop-up .alert-pop-up-in h4 {
  text-align: center;
  font-size: 1.3rem;
  margin-bottom: 30px;
}
.alert-pop-up .alert-pop-up-in p {
  margin-bottom: 30px;
}

.progress {
  position: relative;
  display: flex;
  margin-bottom: 20px;
}

.progress-track {
  position: absolute;
  top: 5px;
  width: 100%;
  height: 5px;
  background-color: #dfe3e4;
  z-index: 1;
}

.progress-step {
  position: relative;
  width: 100%;
  font-size: 12px;
  text-align: center;
  z-index: 2;
}
.progress-step:last-child:after {
  display: none;
}
.progress-step:before {
  content: "";
  display: flex;
  margin: 0 auto;
  margin-bottom: 10px;
  width: 16px;
  height: 16px;
  background: #fff;
  border: 4px solid #dfe3e4;
  border-radius: 100%;
  color: #fff;
  position: relative;
  z-index: 4;
}
.progress-step:after {
  content: "";
  position: absolute;
  top: 6px;
  left: 50%;
  width: 0%;
  transition: width 1s ease-in;
  height: 5px;
  background: #dfe3e4;
  z-index: 3;
}
.progress-step.is-active {
  color: #2183dd;
}
.progress-step.is-active:before {
  border: 4px solid #777;
  animation: pulse 2s infinite;
}
.progress-step.is-complete {
  color: #009900;
}
.progress-step.is-complete:before {
  font-size: 10px;
  background: #fff;
  border: 4px solid #009900;
}
.progress-step.is-complete:after {
  background: #2183dd;
  animation: nextStep 1s;
  animation-fill-mode: forwards;
}

@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 rgba(33, 131, 221, 0.4);
  }
  70% {
    box-shadow: 0 0 0 10px rgba(33, 131, 221, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(33, 131, 221, 0);
  }
}
@keyframes nextStep {
  0% {
    width: 0%;
  }
  100% {
    width: 100%;
  }
}
.text-denger {
  color: #ff0000;
}

.m-b-0 {
  margin-bottom: 0;
}

.mat-focused .mat-form-field-label {
  /*change color of label*/
  color: rgba(0, 0, 0, 0.9) !important;
}

.mat-form-field-empty.mat-form-field-label {
  color: #000000 !important;
}

.phaser-3 canvas {
  margin-top: 0px !important;
}

#game-holder canvas {
  margin-top: 0px !important;
}
#game-holder.quiz canvas {
  margin-top: 0px !important;
  margin-left: -16px !important;
}

.is-mobile-app .zone-title {
  display: none;
}
.is-mobile-app .continet-title {
  display: none;
}
.is-mobile-app .footer {
  bottom: 6px;
}
.is-mobile-app > .game-box > main > .container {
  max-width: 960px;
  padding: 0;
}
.is-mobile-app .frame {
  margin: 0;
}
.is-mobile-app .frame:after {
  display: none;
}
.is-mobile-app .frame-title {
  display: none;
}

.is-mobile-app .zone-title {
  display: none;
}
.is-mobile-app .continet-title {
  display: none;
}
.is-mobile-app .footer {
  bottom: 6px;
}
.is-mobile-app > .game-box > main > .container {
  max-width: 960px;
  padding: 0;
}
.is-mobile-app .frame {
  margin: 0;
}
.is-mobile-app .frame:after {
  display: none;
}
.is-mobile-app .frame-title {
  display: none;
}

.pr-5 {
  padding-right: 5px !important;
}

.ml-auto {
  margin-left: auto !important;
}

.font-QuranEra {
  font-family: "QuranErafont";
}

.font-HafsQuranEra {
  font-family: "HafsQuranEra";
}

.font-QuraneraV3 {
  font-family: "QuranEraV3";
}

.mat-select-panel {
  max-height: 356px !important;
}

.simple-snack-bar .mat-simple-snackbar {
  justify-content: center;
  font-family: "Nunito", sans-serif;
  font-size: 16px !important;
}

.mat-snack-bar-container {
  min-width: unset !important;
}

header {
  height: auto;
  padding: 12px 0 0 0;
}
header .header-row {
  display: flex;
  align-items: flex-start;
}
header .logo-box {
  margin-right: 20px;
  margin-top: 50px;
  flex: 0;
  text-align: center;
  outline: none;
}
header .logo-box img {
  width: auto;
  max-width: 240px;
}
header .col-auto {
  align-self: center;
}
header .menu {
  width: 602px;
  height: 100px;
  background-image: url("assets/images/header/bg.png");
  background-repeat: no-repeat;
  background-position: 0 51px;
  margin-top: 12px;
}
header .menu ul {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: center;
}
header .menu ul li {
  height: 100px;
  position: relative;
}
header .menu ul a {
  width: 114px;
  height: 93px;
  display: block;
  cursor: pointer;
  background-image: url("assets/images/header/lessons.png");
  background-repeat: no-repeat;
  background-position: bottom;
}
header .menu ul a:hover {
  background-position: top;
}
header .menu ul a.Stories {
  background-image: url("assets/images/header/stories.png");
}
header .menu ul a.videos {
  background-image: url("assets/images/header/videos.png");
}
header .menu ul a.books {
  background-image: url("assets/images/header/mbooks.png");
}
header .menu ul a.songs {
  background-image: url("assets/images/header/songs.png");
}
header .menu ul a.games {
  background-image: url("assets/images/header/games.png");
}
header .profile-box {
  width: 289px;
  height: 147px;
  background-image: url("assets/images/header/profile-bg.png");
  background-repeat: no-repeat;
  background-position: bottom;
  margin: 10px 0 0 20px;
  position: relative;
  outline: none;
}
header .profile-box .avatar {
  width: 142px;
  height: 142px;
  background-image: url("assets/images/header/profile-avatar-bg.png");
  background-repeat: no-repeat;
  background-position: bottom;
  position: relative;
  left: -5px;
  top: 4px;
  outline: none;
}
header .profile-box .avatar:hover {
  background-position: top;
}
header .profile-box .avatar img {
  width: 110px;
  height: 140px;
  border-radius: 0 0 50% 50%;
  position: absolute;
  top: -16px;
  left: 14px;
  cursor: pointer;
}
header .profile-box .logout {
  width: 72px;
  height: 22px;
  position: absolute;
  background: url("assets/images/header/logout.png");
  background-repeat: no-repeat;
  background-position: bottom;
  right: 40px;
  bottom: 16px;
  border: none;
  outline: none;
}
header .profile-box .logout:hover {
  background-position: top;
}
header .profile-box .assignment-bell {
  color: #00cd60;
  cursor: pointer;
  position: absolute;
  top: 30px;
  right: 20px;
}
header .profile-box .name-box,
header .profile-box .points {
  position: absolute;
  left: 134px;
  top: 30px;
  font-family: "Kids Rock DEMO";
  font-size: 1.2rem;
  vertical-align: middle;
  background: linear-gradient(to bottom, #faf5cf, #fbf4c2, #faf5cf);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  -webkit-text-stroke: 1px #612e0d;
  -webkit-filter: drop-shadow(1px 1px #612e0d);
  filter: drop-shadow(1px 1px #612e0d);
  cursor: pointer;
}
header .profile-box .points {
  left: 174px;
  top: 70px;
}

.header-profile-box {
  position: absolute;
  z-index: 10;
  float: right;
  right: 10px;
  padding: 0;
}

.awards .leave-button {
  width: 121px;
  height: 134px;
  background: url("assets/images/awards/awards-back.png");
  background-position: bottom;
  border: none;
  outline: none;
  position: absolute;
  z-index: 2;
  left: 0;
  top: 0;
}
.awards .leave-button:hover {
  background-position: top;
}
.awards .points {
  width: 524px;
  position: absolute;
  z-index: 2;
  top: 155px;
  left: 260px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.awards .points > div {
  width: 48px;
  font-family: "Kids Rock DEMO";
  font-size: 1.1rem;
  color: #ffffff;
  text-shadow: 0 0 10px rgba(0, 0, 0, 0.8);
  text-align: center;
}
.awards .slider-back, .awards .slider-next {
  width: 45px;
  height: 42px;
  background: url("assets/images/awards/awards-arrows.png");
  background-position: bottom left;
  border: none;
  outline: none;
  position: absolute;
  z-index: 2;
  left: 330px;
  top: 216px;
}
.awards .slider-back:hover, .awards .slider-next:hover {
  background-position: top left;
}
.awards .slider-next {
  background-position: bottom right;
  left: auto;
  right: 334px;
}
.awards .slider-next:hover {
  background-position: top right;
}
.awards .slider-titles {
  width: 160px;
  position: absolute;
  z-index: 2;
  top: 220px;
  left: 405px;
  font-family: "Kids Rock DEMO";
  font-size: 1.2rem;
  color: #ffffff;
  text-shadow: 0 0 10px rgba(0, 0, 0, 0.8);
  text-align: center;
}
.awards .slider-box {
  width: 800px;
  display: block;
  position: relative;
  top: 290px;
  left: 90px;
}
.awards .slider-box .item {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  transform: translateX(0);
}
.awards .sert {
  width: 110px;
  padding: 2px;
  background-color: rgba(180, 124, 18, 0.4);
  border: solid 2px rgba(255, 255, 255, 0.6);
  text-align: center;
  position: relative;
  transition: all linear 200ms;
  margin-right: 25px;
  cursor: pointer;
}
.awards .sert img {
  width: 100%;
  max-width: 100%;
}
.awards .sert .cert-text {
  width: 96% !important;
  top: 60px;
  position: absolute;
  z-index: 4;
}
.awards .sert .name {
  font-size: 0.4rem;
}
.awards .sert .description {
  font-size: 0.3rem;
  color: #E51B00;
  padding: 4px 15px;
}
.awards .sert .date {
  position: absolute;
  top: 0 !important;
  margin: 38px 0 0 63px !important;
  font-size: 0.2rem;
  color: #0090d9;
}
.awards .sert:hover {
  -webkit-transform: scale3d(1.3, 1.3, 1.3);
  transform: scale3d(1.3, 1.3, 1.3);
  background-color: #b233d1;
}
.awards .sert.final .cert-text {
  top: 52px;
}
.awards .sert.final .name {
  font-size: 0.6rem;
  line-height: 0.8rem;
  font-family: "Hey Comic";
  color: #a72441;
  margin-top: 3px !important;
}
.awards .sert.final .school {
  font-size: 0.4rem;
  line-height: 0.4rem;
  color: #E51B00;
}
.awards .sert.final .description {
  text-align: left;
  color: #000000;
  line-height: 0.3rem;
  font-size: 0.2rem;
  padding: 0 12px;
  margin-top: 0.4rem;
}
.awards .sert.final .date {
  font-family: "Hey Comic";
  font-size: 0.3rem;
  padding: 0 50px 0 0;
  color: #0152bd;
}

.download-button {
  width: 246px;
  height: 83px;
  background: url("assets/images/awards/download.png");
  background-position: bottom;
  border: none;
  outline: none;
  margin: 15px 5px 0 15px;
}
.download-button:hover {
  background-position: top;
}

.popup-box {
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 9999;
  top: 0;
  left: 0;
  background-color: rgba(255, 255, 255, 0.6);
}
.popup-box .in-box {
  width: auto;
  background: #ffffff;
  border: solid 8px rgba(180, 124, 18, 0.4);
  margin: 8% auto;
  position: relative;
  padding: 8px;
  border-radius: 10px;
  display: table;
}
.popup-box .in-box .leave-button {
  width: 63px;
  height: 65px;
  background: url("assets/images/activity/leave/leave.png");
  background-position: bottom;
  border: none;
  outline: none;
  position: absolute;
  z-index: 2;
  right: -50px;
  top: -50px;
  left: auto;
}
.popup-box .in-box .leave-button:hover {
  background-position: top;
}
.popup-box .in-box .cert {
  text-align: center;
  position: relative;
}
.popup-box .in-box .cert2 {
  text-align: center;
  position: relative;
}
.popup-box .in-box .cert img, .popup-box .in-box .cert2 img {
  width: 350px;
}
.popup-box .in-box .cert-text {
  width: 100%;
  top: 212px;
  position: absolute;
  z-index: 4;
}
.popup-box .in-box .name {
  font-size: 1.4rem;
  line-height: 1.8rem;
  margin-bottom: 10px;
}
.popup-box .in-box .description {
  font-size: 1.1rem;
  color: #E51B00;
  padding: 4px 15px;
}
.popup-box .in-box .date {
  font-size: 0.8rem;
  padding: 17px 0 0 145px;
  color: #0090d9;
}
.popup-box .in-box .cert2 .cert-text {
  top: 182px;
}
.popup-box .in-box .cert2 .name {
  font-family: "Hey Comic";
  margin-top: 22px;
  color: #a72441;
  font-size: 1.8rem;
  line-height: 0.8rem;
}
.popup-box .in-box .school {
  font-size: 1.4rem;
  line-height: 1.4rem;
  color: #E51B00;
}
.popup-box .in-box .cert2 .description {
  text-align: center !important;
  color: #000000;
  line-height: 1rem;
  font-size: 0.7rem;
  font-weight: 600 !important;
  margin-top: 29px !important;
  padding: 0 !important;
}
.popup-box .in-box .cert2 .date {
  font-family: "Hey Comic";
  padding: 87px 215px 0 0;
  color: #000000;
  text-shadow: #ffffff 0 0 1px;
}

.progress .leave-button {
  width: 121px;
  height: 134px;
  background: url("assets/images/awards/awards-back.png");
  background-position: bottom;
  border: none;
  outline: none;
  position: absolute;
  z-index: 2;
  left: 0;
  top: 0;
}
.progress .leave-button:hover {
  background-position: top;
}
.progress .top-bar {
  position: relative;
  top: 0 !important;
  margin-top: 120px !important;
}
.progress .top-bar .select-box {
  width: 190px;
  margin-right: 100px;
  position: absolute;
  left: 194px;
  top: -5px;
  /* Hide the items when the select box is closed: */
}
.progress .top-bar .select-box .main-select {
  width: 185px;
  height: 47px;
  position: absolute;
  z-index: 56;
  background-image: url("assets/images/progress/progress-slect.png");
  background-position: bottom;
  padding-right: 30px;
  margin: 4px 2px;
  cursor: pointer;
}
.progress .top-bar .select-box .main-select span {
  font-family: "Quicksand", sans-serif;
  color: #DBECFB;
  font-size: 1.1rem;
  line-height: 46px;
  background-color: #DBECFB;
  -webkit-background-clip: text;
  font-weight: bolder;
  -webkit-text-fill-color: transparent;
  filter: drop-shadow(2px 2px rgba(3, 23, 110, 0.2));
}
.progress .top-bar .select-box .main-select.active {
  background-position: top;
}
.progress .top-bar .select-box .select-items {
  width: 184px;
  max-height: 400px;
  font-family: "Quicksand", sans-serif;
  position: absolute;
  background-color: #186994;
  top: 4px;
  left: 4px;
  z-index: 50;
  padding: 5px 8px 4px 8px;
  border-radius: 0 0 15px 15px;
  box-shadow: 0 0 10px #186994;
  overflow-y: auto;
  margin-top: 46px;
}
.progress .top-bar .select-box .select-items div {
  color: #DBECFB;
  padding: 4px 0;
  border-bottom: 1px solid rgba(137, 182, 217, 0.5);
  cursor: pointer;
}
.progress .top-bar .select-box .select-items::-webkit-scrollbar {
  width: 6px;
  background-color: transparent;
}
.progress .top-bar .select-box .select-items::-webkit-scrollbar-thumb {
  background-color: #186994;
}
.progress .top-bar .select-box .select-items::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: transparent;
}
.progress .top-bar .select-box .select-hide {
  display: none;
}
.progress .top-bar .select-box .select-items div:hover {
  background-image: url("assets/images/progress/progress-slected.png");
  background-repeat: no-repeat;
  border: none;
  margin-top: 1px;
}
.progress .top-bar .points {
  width: 100px;
  position: absolute;
  left: 700px;
  top: 88px;
}
.progress .top-bar .points ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
.progress .top-bar .points ul li {
  font-family: "Kids Rock DEMO Regular", sans-serif;
  font-size: 1.5rem;
  width: auto !important;
  display: inline-flex;
  color: #EF6100;
  text-shadow: 0 0 1px rgba(0, 0, 0, 0.5);
  margin-top: 5px;
}
.progress .top-bar .points ul li img {
  padding-bottom: 2px !important;
  margin-left: 30px !important;
}
.progress .top-bar .points ul li span:nth-child(2) {
  margin-left: 12px !important;
  cursor: default !important;
}
.progress .bottom-bar {
  position: relative;
  top: 0 !important;
  margin-top: 248px !important;
}
.progress .bottom-bar .progress-box {
  width: 55px;
  position: absolute;
  top: -21px;
  left: 141px;
}
.progress .bottom-bar .progress-box ul {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
}
.progress .bottom-bar .progress-box ul > :last-child {
  margin-top: auto;
}
.progress .bottom-bar .progress {
  width: 198px;
  margin: 10px 0 6px 12px;
  float: left;
}
.progress .bottom-bar .progress .bar {
  height: 17px;
  border-radius: 10px;
}
.progress .bottom-bar .progress .bar .mat-progress-bar-background {
  display: none;
}
.progress .bottom-bar .progress .bar .mat-progress-bar-fill {
  border-radius: 20px;
}
.progress .bottom-bar .progress .bar .mat-progress-bar-fill::after {
  border-radius: 30px;
  background: linear-gradient(to top, #F08236, #DD5112 20%, #F08236 70%, #FFCA61);
}
.progress .bottom-bar .progress .bar .mat-progress-bar-buffer {
  background: none;
}
.progress .bottom-bar .minuts {
  font-family: "Quicksand", sans-serif;
  margin: 6.5px 2px 0 0;
  float: right;
  color: #161D35;
  font-size: 0.8rem;
  font-weight: bold;
}
.progress .bottom-bar .chart-box {
  width: 262px;
  height: 241px;
  position: absolute;
  top: -3px;
  left: 200px;
  display: inline-flex;
}
.progress .bottom-bar .chart-box > .chart-column {
  height: 100% !important;
  width: 50% !important;
  vertical-align: bottom;
  position: relative;
}
.progress .bottom-bar .chart-box > .chart-column > .chart-percentage-tw {
  width: 25% !important;
  position: absolute;
  background: #020024;
  background: linear-gradient(90deg, #020024 0%, #ff77d7 0%, #c4008a 100%);
  bottom: 0 !important;
  left: 32.5% !important;
  border-top-left-radius: 25px !important;
  border-top-right-radius: 25px !important;
}
.progress .bottom-bar .chart-box > .chart-column > .chart-percentage-lw {
  width: 25% !important;
  position: absolute;
  background: #020024;
  background: linear-gradient(90deg, #020024 0%, #fcff88 0%, #c9c600 100%);
  bottom: 0 !important;
  left: 32.5% !important;
  border-top-left-radius: 25px !important;
  border-top-right-radius: 25px !important;
}
.progress .bottom-bar .time-box {
  position: absolute;
  left: 718px;
  top: 168px;
  color: #ffffff;
  font-family: "Quicksand", sans-serif;
  font-size: 1.5rem;
  font-weight: 800;
}

.games .leave-button {
  width: 121px;
  height: 134px;
  background: url("assets/images/awards/awards-back.png");
  background-position: bottom;
  border: none;
  outline: none;
  position: absolute;
  z-index: 2;
  left: 0;
  top: 0;
}
.games .leave-button:hover {
  background-position: top;
}
.games .slider-back, .games .slider-next {
  width: 100px;
  height: 106px;
  background: url("assets/images/games/games-arrows.png");
  background-position: bottom left;
  border: none;
  outline: none;
  position: absolute;
  z-index: 2;
  left: 10px;
  top: 280px;
}
.games .slider-back:hover, .games .slider-next:hover {
  background-position: top left;
}
.games .slider-next {
  background-position: bottom right;
  left: auto;
  right: 10px;
}
.games .slider-next:hover {
  background-position: top right;
}
.games .blocks-list {
  width: 736px;
  position: relative;
  top: 150px;
  left: 122px;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: stretch;
}
.games .game-item {
  width: 160px;
  height: 128px;
  border: solid 4px transparent;
  position: relative;
  overflow: hidden;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.6);
  border-radius: 16px;
  margin: 0 16px 16px 0;
  cursor: pointer;
  transition: all linear 300ms;
}
.games .game-item:hover {
  border: solid 4px #9458db;
}
.games .game-item .thumbnail {
  width: 158px;
  height: 94px;
  margin: 0 auto;
  display: table;
  width: 100%;
  max-width: 100%;
  background-position: 0 0;
}
.games .game-item .thumbnail.match-the-codes {
  background: url("assets/images/games/dolphines.jpg");
}
.games .game-item .thumbnail.drag-drop {
  background: url("assets/images/games/drag-and-drop-game.jpg");
}
.games .game-item:nth-child(5n-5) .match-the-codes {
  background-position: 0 -94px;
}
.games .game-item:nth-child(5n-4) .match-the-codes {
  background-position: 0 -188px;
}
.games .game-item:nth-child(5n-3) .match-the-codes {
  background-position: 0 -282px;
}
.games .game-item:nth-child(5n-2) .match-the-codes {
  background-position: 0 -376px;
}
.games .game-item:nth-child(5n-1) .match-the-codes {
  background-position: 0 -470px;
}
.games .game-item:nth-child(4n+3) .drag-drop {
  background-position: 0 -94px;
}
.games .game-item:nth-child(4n+4) .drag-drop {
  background-position: 0 -188px;
}
.games .game-item:nth-child(4n+5) .drag-drop {
  background-position: 0 -282px;
}
.games .game-item:nth-child(4n+2) .drag-drop {
  background-position: 0 -376px;
}
.games .game-item .number {
  width: 40px;
  height: 44px;
  background: url("assets/images/games/number-bg.png");
  position: absolute;
  top: 0;
  left: 0;
  line-height: 44px;
  text-align: center;
  color: rgba(0, 0, 0, 0.8);
  font-family: "Kids Rock DEMO";
  font-size: 1.2rem;
  z-index: 6;
}
.games .game-item .description-box {
  width: 100%;
  padding: 7px 30px 7px 10px;
  position: absolute;
  bottom: 0;
  left: 0;
  font-family: "Quicksand", sans-serif;
  background-color: #ffffff;
  font-weight: bold;
  text-align: left;
  font-size: 0.8rem;
  line-height: 0.8rem;
  color: rgba(0, 0, 0, 0.8);
}
.games .game-item .description-box::after {
  content: "";
  width: 36px;
  height: 36px;
  position: absolute;
  right: 0;
  top: -19px;
  background-color: #ffffff;
  border-radius: 50%;
  background-image: url("assets/images/games/game-icon.png");
  background-repeat: no-repeat;
  background-position: center center;
}
.games .game-item.lock {
  cursor: default;
}
.games .game-item.lock:hover {
  border-color: transparent;
}
.games .game-item.lock::after {
  content: "";
  width: 168px;
  height: 136px;
  position: absolute;
  top: -4px;
  left: -4px;
  z-index: 4;
  border-radius: 10px;
  background-color: rgba(0, 0, 0, 0.6);
}
.games .game-item.lock::before {
  content: "";
  width: 36px;
  height: 36px;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 6;
  border-radius: 0 10px 0 10px;
  background-color: #ffffff;
  background-image: url("assets/images/games/games-lock.png");
  background-repeat: no-repeat;
  background-position: center center;
}

.gallery .leave-button {
  width: 121px;
  height: 134px;
  background: url("assets/images/awards/awards-back.png");
  background-position: bottom;
  border: none;
  outline: none;
  position: absolute;
  z-index: 2;
  left: 0;
  top: 0;
}
.gallery .leave-button:hover {
  background-position: top;
}
.gallery .slider-back, .gallery .slider-next {
  width: 75px;
  height: 67px;
  background: url("assets/images/gallery/gallery-arrows.png");
  background-position: bottom left;
  border: none;
  outline: none;
  position: absolute;
  z-index: 2;
  left: 10px;
  top: 280px;
}
.gallery .slider-back:hover, .gallery .slider-next:hover {
  background-position: top left;
}
.gallery .slider-next {
  background-position: bottom right;
  left: auto;
  right: 10px;
}
.gallery .slider-next:hover {
  background-position: top right;
}
.gallery .blocks-list {
  width: 736px;
  position: relative;
  top: 150px;
  left: 122px;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  flex-direction: row-reverse;
  align-items: stretch;
}
.gallery .gallery-item {
  width: 160px;
  height: 128px;
  border: solid 4px transparent;
  position: relative;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.6);
  border-radius: 16px;
  margin: 0 12px 16px 12px;
  cursor: pointer;
  transition: all linear 300ms;
}
.gallery .gallery-item:hover {
  border: solid 4px #4482ec;
}
.gallery .gallery-item img {
  width: 152px;
  height: 120px;
  border-radius: 10px;
}
.gallery .gallery-item .description-box {
  width: 100%;
  padding: 5px 30px 5px 10px;
  position: absolute;
  bottom: 0;
  left: 0;
  font-family: "Quicksand", sans-serif;
  background-color: #f1aa47;
  font-weight: bold;
  text-align: left;
  font-size: 0.8rem;
  line-height: 0.8rem;
  color: rgba(0, 0, 0, 0.8);
  border-radius: 0 0 10px 10px;
}
.gallery .gallery-item .letter {
  width: 44px;
  height: 44px;
  position: absolute;
  z-index: 999;
  right: -10px;
  bottom: -10px;
  text-align: center !important;
  background-image: url("assets/images/gallery/gallery-circle-for-letter.png");
  background-repeat: no-repeat;
  background-position: center center;
  font-family: "QuranErafont";
  font-size: 2rem;
  line-height: 44px;
  color: rgba(0, 0, 0, 0.8);
  font-weight: bold;
}
.gallery .gallery-item .letter-arabic-medium {
  position: absolute !important;
  top: -1px !important;
  left: 0 !important;
  right: 0 !important;
}
.gallery .gallery-item .letter-arabic-high {
  position: absolute !important;
  top: -6px !important;
  left: 0 !important;
  right: 0 !important;
}
.gallery .tab-bar {
  width: 60px;
  display: flex;
  justify-content: space-between;
  position: absolute;
  bottom: 12px;
  left: 460px;
}
.gallery .tab-bar .tab {
  width: 11px;
  height: 11px;
  border-radius: 50%;
  background-color: #acacac;
}
.gallery .tab-bar .tab.active {
  background-color: #e3d513;
}

.popup-box {
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 9999;
  top: 0;
  left: 0;
  background-color: rgba(255, 255, 255, 0.6);
}
.popup-box .in-box {
  width: auto;
  background: #ffffff;
  border: solid 8px rgba(180, 124, 18, 0.4);
  margin: 8% auto;
  position: relative;
  padding: 8px;
  border-radius: 10px;
  display: table;
}
.popup-box .in-box .leave-button {
  width: 63px;
  height: 65px;
  background: url("assets/images/activity/leave/leave.png");
  background-position: bottom;
  border: none;
  outline: none;
  position: absolute;
  z-index: 2;
  right: -50px;
  top: -50px;
  left: auto;
}
.popup-box .in-box .leave-button:hover {
  background-position: top;
}
.popup-box .in-box .gallery_popup_img {
  width: 100%;
  max-width: 100%;
}
.popup-box .download-button {
  width: 246px;
  height: 83px;
  display: block;
  background: url("assets/images/awards/download.png");
  background-position: bottom;
  border: none;
  outline: none;
  margin: 15px auto;
}
.popup-box .download-button:hover {
  background-position: top;
}

.letters .leave-button {
  width: 121px;
  height: 134px;
  background: url("assets/images/awards/awards-back.png");
  background-position: bottom;
  border: none;
  outline: none;
  position: absolute;
  z-index: 2;
  left: 0;
  top: 0;
}
.letters .leave-button:hover {
  background-position: top;
}
.letters .slider-back, .letters .slider-next {
  width: 100px;
  height: 106px;
  background: url("assets/images/games/games-arrows.png");
  background-position: bottom left;
  border: none;
  outline: none;
  position: absolute;
  z-index: 2;
  left: 10px;
  top: 280px;
}
.letters .slider-back:hover, .letters .slider-next:hover {
  background-position: top left;
}
.letters .slider-next {
  background-position: bottom right;
  left: auto;
  right: 10px;
}
.letters .slider-next:hover {
  background-position: top right;
}
.letters .blocks-list {
  width: 700px;
  position: relative;
  top: 180px;
  left: 140px;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: stretch;
}
.letters .item {
  width: 205px;
  height: 158px;
  border: solid 4px transparent;
  position: relative;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.6);
  border-radius: 8px;
  margin: 0 14px 46px;
  cursor: pointer;
  transition: all linear 300ms;
}
.letters .item img {
  max-width: 100%;
  border-radius: 4px 4px 0 0;
}
.letters .item:hover {
  border: solid 4px #5ed933;
}
.letters .item:hover .number {
  background-position: top;
}
.letters .item .number {
  width: 48px;
  height: 48px;
  background: url("assets/images/new-letters/letters-number-bg.png");
  background-position: bottom;
  position: absolute;
  top: 0;
  left: -24px;
  line-height: 44px;
  text-align: center;
  color: rgba(0, 0, 0, 0.8);
  font-family: "Kids Rock DEMO";
  font-size: 1.2rem;
  z-index: 6;
}
.letters .item .description-box-menu {
  width: 100%;
  min-height: 40px;
  padding: 8px 30px 8px 5px;
  position: absolute;
  bottom: 0;
  left: 0;
  font-family: sans-serif;
  background-color: #ffffff;
  font-weight: bold;
  text-align: left;
  font-size: 0.8rem;
  line-height: 0.8rem;
  color: rgba(0, 0, 0, 0.8);
}
.letters .item .description-box-menu::after {
  content: "";
  width: 30px;
  height: 30px;
  position: absolute;
  right: 0;
  top: -19px;
  background-color: #ffffff;
  border-radius: 50%;
  background-image: url("assets/images/new-letters/play-icon.png");
  background-repeat: no-repeat;
  background-position: center center;
}
.letters .item .description-box {
  width: 100%;
  min-height: 40px;
  padding: 8px 30px 8px 5px;
  position: absolute;
  bottom: 0;
  left: 0;
  font-family: "Quicksand", sans-serif;
  background-color: #ffffff;
  font-weight: bold;
  text-align: left;
  font-size: 0.8rem;
  line-height: 0.8rem;
  color: rgba(0, 0, 0, 0.8);
}
.letters .item .description-box::after {
  content: "";
  width: 30px;
  height: 30px;
  position: absolute;
  right: 0;
  top: -19px;
  background-color: #ffffff;
  border-radius: 50%;
  background-image: url("assets/images/new-letters/play-icon.png");
  background-repeat: no-repeat;
  background-position: center center;
}
.letters .item.lock {
  cursor: default;
}
.letters .item.lock:hover {
  border-color: transparent;
}
.letters .item.lock::after {
  content: "";
  width: 205px;
  height: 158px;
  position: absolute;
  top: -4px;
  left: -4px;
  z-index: 4;
  border-radius: 8px;
  background-color: rgba(0, 0, 0, 0.4);
}
.letters .item.lock::before {
  content: "";
  width: 36px;
  height: 36px;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 6;
  border-radius: 0 10px 0 10px;
  background-color: #ffffff;
  background-image: url("assets/images/games/games-lock.png");
  background-repeat: no-repeat;
  background-position: center center;
}
.letters.stories .blocks-list {
  top: 150px;
}

.books_list {
  width: 170px;
  height: 458px;
  float: left;
  margin: 88px 4px 88px 48px;
}

.drag-drop-book {
  width: 155px;
  height: 62px;
  text-align: center;
  margin: 2px auto 16px;
  background: url("assets/images/activity/dragDrop/drag-button.png");
  background-position: bottom;
  color: #ffffff;
  font-family: "QuranErafont";
  display: flex;
  justify-content: center;
  align-items: center;
}
.drag-drop-book:hover {
  background-position: top;
  cursor: pointer;
}
.drag-drop-book span {
  font-size: 2.2rem;
}
.drag-drop-book.set, .drag-drop-book.in-target {
  background: none;
}
.drag-drop-book.set span, .drag-drop-book.in-target span {
  display: none;
}
.drag-drop-book.drag {
  position: -webkit-fixed;
  position: fixed;
  z-index: 9999;
}
.drag-drop-book.set-in {
  background: url("assets/images/activity/dragDrop/drag-button.png");
  background-position: top;
}
.drag-drop-book.set-in span {
  display: block;
}

.bookcases {
  width: 705px;
  height: 458px;
  float: right;
  margin: 88px 4px;
}

.fail-box {
  width: 170px;
  display: flex;
  justify-content: space-around;
  position: absolute;
  left: 48px;
  top: 10px;
}
.fail-box .fail {
  width: 43px;
  height: 45px;
  background-image: url("assets/images/activity/dragDrop/fail-bg.png");
  background-position: bottom;
}
.fail-box .fail.active {
  background-position: top;
}

.bookcase {
  width: 160px;
  height: 200px;
  float: left;
  margin: 0 66px 56px 18px;
}
.bookcase:nth-child(3n) {
  margin-left: 31px;
  margin-right: 0;
}
.bookcase .title {
  width: auto;
  height: 100px;
  margin: 10px auto;
  text-align: center;
}
.bookcase .title img {
  height: 100px;
}
.bookcase .target {
  width: 155px;
  height: 62px;
  margin: 26px 2px;
}
.bookcase.error .in-target {
  background: rgba(199, 20, 20, 0.5);
  border-radius: 10px;
}

.stars-box {
  width: 418px;
  height: 96px;
  background: url("assets/images/activity/dragDrop/stars-tab.png");
  position: absolute;
  z-index: 1;
  display: flex;
  justify-content: space-around;
  padding: 40px 30px 0;
  top: -24px;
  left: 300px;
}
.stars-box .star {
  width: 31px;
  height: 30px;
  background: url("assets/images/activity/dragDrop/star2.png");
  background-position: top;
}
.stars-box .star.success {
  background-position: bottom;
}

.cdk-drag-placeholder {
  opacity: 0;
}

.cdk-drag-animating {
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

.songs .leave-button {
  width: 121px;
  height: 134px;
  background: url("assets/images/awards/awards-back.png");
  background-position: bottom;
  border: none;
  outline: none;
  position: absolute;
  z-index: 2;
  left: 0;
  top: 0;
}
.songs .leave-button:hover {
  background-position: top;
}
.songs .slider-back, .songs .slider-next {
  width: 100px;
  height: 106px;
  background: url("assets/images/games/games-arrows.png");
  background-position: bottom left;
  border: none;
  outline: none;
  position: absolute;
  z-index: 2;
  left: 10px;
  top: 280px;
}
.songs .slider-back:hover, .songs .slider-next:hover {
  background-position: top left;
}
.songs .slider-next {
  background-position: bottom right;
  left: auto;
  right: 10px;
}
.songs .slider-next:hover {
  background-position: top right;
}
.songs .blocks-list {
  width: 660px;
  position: relative;
  top: 130px;
  left: 160px;
  display: block;
}
.songs .item {
  width: 100%;
  height: 85px;
  border: solid 4px #b29770;
  position: relative;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.6);
  background-color: #e7d1c6;
  border-radius: 20px;
  margin: 0 0 10px 0;
  cursor: pointer;
  transition: all linear 300ms;
  padding: 4px;
}
.songs .item .border-box {
  width: 647px;
  height: 72px;
  position: absolute;
  border: dashed 1px #ac8b6d;
  border-radius: 16px;
  top: 2px;
  left: 2px;
  z-index: 1;
}
.songs .item:hover {
  border: solid 4px #5ed933;
}
.songs .item:hover .number {
  background-position: top;
}
.songs .item .number {
  width: 62px;
  height: 63px;
  background: url("assets/images/songs/songs-number-bg.png");
  line-height: 63px;
  text-align: center;
  color: rgba(60, 37, 23, 0.8);
  font-family: "Kids Rock DEMO";
  font-size: 1.4rem;
  float: left;
  margin-right: 15px;
}
.songs .item .description-box {
  width: 450px;
  float: left;
  font-family: "Quicksand", sans-serif;
  font-weight: bold;
  text-align: left;
  font-size: 1rem;
  line-height: 1.1rem;
  color: rgba(0, 0, 0, 0.8);
  padding: 11px 0;
}
.songs .item .description-box::after {
  content: "";
  width: 42px;
  height: 42px;
  position: absolute;
  right: -8px;
  bottom: -8px;
  border-radius: 50%;
  background-image: url("assets/images/songs/music-icon.png");
  background-repeat: no-repeat;
  background-position: bottom;
  z-index: 5;
}
.songs .item .letter-box {
  width: 106px;
  height: 77px;
  float: right;
  background-image: url("assets/images/songs/songs-letters.png");
  position: absolute;
  z-index: 2;
  top: 0;
  right: 0;
}
.songs .item .letter-box.n1-1 {
  background-position: 0 0;
}
.songs .item .letter-box.n1-2 {
  background-position: -106px 0;
}
.songs .item .letter-box.n1-3 {
  background-position: -212px 0;
}
.songs .item .letter-box.n1-4 {
  background-position: -318px 0;
}
.songs .item .letter-box.n1-5 {
  background-position: 0 -77px;
}
.songs .item .letter-box.n1-6 {
  background-position: -106px -77px;
}
.songs .item .letter-box.n1-7 {
  background-position: -212px -77px;
}
.songs .item .letter-box.n1-8 {
  background-position: -318px -77px;
}
.songs .item .letter-box.n1-9 {
  background-position: 0 -154px;
}
.songs .item .letter-box.n1-10 {
  background-position: -106px -154px;
}
.songs .item .letter-box.n1-11 {
  background-position: -212px -154px;
}
.songs .item .letter-box.n1-12 {
  background-position: -318px -154px;
}
.songs .item .letter-box.n1-13 {
  background-position: 0 -231px;
}
.songs .item .letter-box.n1-14 {
  background-position: -106px -231px;
}
.songs .item .letter-box.n1-15 {
  background-position: -212px -231px;
}
.songs .item .letter-box.n1-16 {
  background-position: -318px -231px;
}
.songs .item.lock {
  cursor: default;
}
.songs .item.lock .description-box::after {
  background-position: top;
}
.songs .item.lock::after {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 4;
  border-radius: 16px;
  background-color: rgba(0, 0, 0, 0.2);
}
.songs .item.lock::before {
  content: "";
  width: 28px;
  height: 33px;
  position: absolute;
  top: 20px;
  right: 33px;
  z-index: 6;
  background-image: url("assets/images/songs/songs-lock-icon.png");
  background-repeat: no-repeat;
  background-position: center center;
}

.letter-song {
  position: relative;
}
.letter-song .letter-box {
  position: absolute;
  margin: 95px auto 60px;
  left: 306px;
}
.letter-song .letter-box img {
  width: 416px;
  height: 256px;
}
.letter-song .letter-box.video {
  width: 980px;
  height: 600px;
  margin: 0;
  left: 0;
  top: 0;
}
.letter-song .letter-box video {
  width: 980px;
  height: 600px;
}
.letter-song .audio-box {
  position: absolute;
  width: 560px;
  left: 238px;
  top: 438px;
  display: flex;
  justify-content: space-between;
}
.letter-song .audio-box .actions-box button {
  width: 60px;
  height: 62px;
  border: none;
  background: url("assets/images/activity/letter-song/play-button.png") no-repeat;
  outline: none;
  background-position: bottom left;
}
.letter-song .audio-box .actions-box button.pause {
  background-position: bottom right;
}
.letter-song .audio-box .actions-box button:hover {
  background-position: top left;
}
.letter-song .audio-box .actions-box button:hover.pause {
  background-position: top right;
}
.letter-song .audio-box .progress-box {
  width: 480px;
  padding-top: 20px;
  position: relative;
}
.letter-song .audio-box .progress-box .progress-bar {
  height: 12px;
  border-radius: 10px;
  background: linear-gradient(to top, #53a3c9, #74b7d7, #53a3c9);
  border: solid 1px #41ffde;
}
.letter-song .audio-box .progress-box .progress-bar .mat-progress-bar-background {
  display: none;
}
.letter-song .audio-box .progress-box .progress-bar .mat-progress-bar-fill {
  border-radius: 10px !important;
  background: linear-gradient(to top, #a30d42, #f66f6c);
}
.letter-song .audio-box .progress-box .progress-bar .mat-progress-bar-fill::after {
  display: none;
  border-radius: 10px;
  background: linear-gradient(to top, #a30d42, #f66f6c);
}
.letter-song .audio-box .progress-box .progress-bar .mat-progress-bar-buffer {
  background: none;
}
.letter-song .audio-box .progress-box .red-dot {
  content: "";
  width: 27px;
  height: 29px;
  display: block;
  background-image: url("assets/images/activity/letter-song/red-dot.png");
  background-repeat: no-repeat;
  background-position: center right;
  position: absolute;
  left: -12px;
  top: 11px;
  z-index: 4;
  cursor: pointer !important;
}
.letter-song .audio-box .time {
  text-align: center;
  margin-top: 10px;
}
.letter-song .audio-box .time .timer {
  color: #ffffff;
  font-family: "Kids Rock DEMO";
  font-size: 1.2rem;
  font-weight: bold;
}
.letter-song.video-box .audio-box {
  width: 629px;
  height: 121px;
  padding: 25px;
  left: 176px;
  background-image: url("assets/images/activity/video/player-bg.png");
  transition: all linear 200ms;
  animation-name: hide;
  animation-duration: 2000ms;
  opacity: 0;
}
.letter-song.video-box .audio-box.active, .letter-song.video-box .audio-box:hover {
  opacity: 1;
}
@keyframes hide {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

.books .leave-button {
  width: 121px;
  height: 134px;
  background: url("assets/images/awards/awards-back.png");
  background-position: bottom;
  border: none;
  outline: none;
  position: absolute;
  z-index: 2;
  left: 0;
  top: 0;
}
.books .leave-button:hover {
  background-position: top;
}
.books .slider-back, .books .slider-next {
  width: 100px;
  height: 106px;
  background: url("assets/images/games/games-arrows.png");
  background-position: bottom left;
  border: none;
  outline: none;
  position: absolute;
  z-index: 2;
  left: 10px;
  top: 280px;
}
.books .slider-back:hover, .books .slider-next:hover {
  background-position: top left;
}
.books .slider-next {
  background-position: bottom right;
  left: auto;
  right: 10px;
}
.books .slider-next:hover {
  background-position: top right;
}
.books .blocks-list {
  width: 640px;
  position: relative;
  top: 120px;
  left: 170px;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: stretch;
}
.books .item {
  width: 117px;
  height: 171px;
  position: relative;
  background-image: url("assets/images/books/books.png");
  background-position: left bottom;
  margin: 0 0 70px 0;
  outline: none;
  cursor: pointer;
  padding: 4px;
}
.books .item:nth-child(10n+3) {
  background-position: -117px bottom;
}
.books .item:nth-child(10n+3):hover {
  background-position: -117px top;
}
.books .item:nth-child(10n+2) {
  background-position: -234px bottom;
}
.books .item:nth-child(10n+2):hover {
  background-position: -234px top;
}
.books .item:nth-child(10n+4) {
  background-position: -351px bottom;
}
.books .item:nth-child(10n+4):hover {
  background-position: -351px top;
}
.books .item:nth-child(10n+5) {
  background-position: -468px bottom;
}
.books .item:nth-child(10n+5):hover {
  background-position: -468px top;
}
.books .item:nth-child(10n+6) {
  background-position: -468px bottom;
}
.books .item:nth-child(10n+6):hover {
  background-position: -468px top;
}
.books .item:nth-child(10n+7) {
  background-position: -351px bottom;
}
.books .item:nth-child(10n+7):hover {
  background-position: -351px top;
}
.books .item:nth-child(10n+8) {
  background-position: -234px bottom;
}
.books .item:nth-child(10n+8):hover {
  background-position: -234px top;
}
.books .item:nth-child(10n+9) {
  background-position: -117px bottom;
}
.books .item:nth-child(10n+9):hover {
  background-position: -117px top;
}
.books .item:hover {
  background-position: left top;
}
.books .item .number {
  text-align: center;
  color: rgba(60, 37, 23, 0.8);
  font-family: "Kids Rock DEMO";
  font-size: 1.4rem;
  position: absolute;
  top: 15px;
  left: 20px;
}
.books .item .description-box {
  width: 92px;
  position: absolute;
  top: 104px;
  left: 10px;
  font-family: "Quicksand", sans-serif;
  font-weight: bold;
  text-align: left;
  font-size: 0.8rem;
  line-height: 0.9rem;
  color: rgba(255, 255, 255, 0.8);
  padding: 14px 4px;
}
.books .item.lock {
  cursor: default;
}
.books .item.lock .number {
  color: rgba(255, 255, 255, 0.8);
}
.books .item.lock .description-box::after {
  background-position: top;
}
.books .item.lock::after {
  content: "";
  width: 92px;
  height: 95px;
  position: absolute;
  top: 18px;
  left: 10px;
  z-index: 4;
  background-color: rgba(0, 0, 0, 0.6);
}
.books .item.lock::before {
  content: "";
  width: 28px;
  height: 33px;
  position: absolute;
  top: 50px;
  right: 46px;
  z-index: 6;
  background-image: url("assets/images/songs/songs-lock-icon.png");
  background-repeat: no-repeat;
  background-position: center center;
}

a {
  text-decoration: none;
  color: #2bb24c;
}
a:hover {
  color: #21893a;
}

.m-0 {
  margin: 0 !important;
}

.page-main {
  padding-top: 60px;
  background-color: #3db8ff;
  background-image: url("assets/images/page_bg.jpg");
  background-size: cover;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: flex-start;
}

.mat-button,
.mat-flat-button {
  line-height: 50px !important;
  margin-bottom: 20px !important;
}

.login-box {
  font-family: "Nunito", sans-serif;
  margin: 20px 0;
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0 8px 10px rgba(0, 0, 0, 0.5);
  font-size: 1rem;
}
.login-box .logo-box {
  padding: 15px;
  text-align: center;
  border-radius: 10px 10px 0 0;
}
.login-box .logo-box img {
  width: 201px;
}
.login-box .main-box {
  padding: 30px;
  padding-top: 0px !important;
  color: #929292;
  text-align: center;
}
.login-box .main-box.with-row {
  padding: 30px 60px;
}
.login-box .main-box .title {
  color: #2bb24c;
  text-align: center;
}
.login-box .main-box .title-balck {
  color: #000000;
}
.login-box .main-box .sub-title {
  font-size: 1.1em;
  font-weight: normal;
  color: rgba(0, 0, 0, 0.9);
}
.login-box .main-box .text-left {
  text-align: left;
}
.login-box .main-box .text-center {
  text-align: center;
}
.login-box .main-box a {
  text-decoration: none;
  color: #2bb24c;
}
.login-box .main-box a:hover {
  color: #21893a;
}
.login-box .logo-footer {
  padding: 15px;
  background-color: #2bb24c;
  color: #ffffff;
  text-align: center;
  border-radius: 0 0 10px 10px;
}
.login-box .logo-footer a {
  color: #ffffff;
  text-decoration: underline;
  transition: all linear 200ms;
}
.login-box .logo-footer a:hover {
  text-decoration: none;
}
.login-box .logo-footer p {
  margin: 0;
}
.login-box .sign-up-button {
  width: 100%;
  max-width: 300px;
  font-size: 1.2rem;
  font-weight: bold;
}
.login-box .myCardElement {
  margin-bottom: 40px;
}

.m-b {
  margin-bottom: 20px;
}

.amount {
  font-family: "Montserrat", sans-serif;
  font-size: 1.1rem;
}

.cabinet-main {
  font-family: "Nunito", sans-serif;
  background: radial-gradient(circle, #3db8ff, #ffffff);
  min-height: 100vh;
}
@media (max-width: 600px) {
  .cabinet-main .container {
    width: 100vw !important;
  }
  .cabinet-main .tab-header {
    flex-wrap: wrap;
  }
  .cabinet-main .tab-header li {
    margin-bottom: 20px !important;
  }
  .cabinet-main .tab-body {
    padding: 80px 20px !important;
  }
  .cabinet-main .tab-body .form-box {
    width: 100% !important;
  }
  .cabinet-main .tab-body .childs {
    justify-content: center;
  }
}
.cabinet-main .cabinet-header {
  justify-content: space-between;
  align-items: flex-end;
  margin: 30px 0 0;
}
.cabinet-main .cabinet-header .logout-button {
  margin-bottom: 0 !important;
}
.cabinet-main .back-button {
  float: left;
  position: absolute;
  left: 30px;
  top: 90px;
  min-width: 150px;
}

.mat-radio-button {
  margin: 0 20px 0 0;
}

.cabinet-box {
  margin: 20px 0;
}
.cabinet-box .profile-tab-box .tab-header {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  justify-content: center;
}
.cabinet-box .profile-tab-box .tab-header li {
  text-align: center;
  margin: 0 5px;
}
.cabinet-box .profile-tab-box .tab-header li a {
  display: block;
  min-width: 256px;
  background-color: #e5e6e9;
  border-radius: 5px 5px 0 0;
  color: #495057;
  padding: 15px 30px;
}
.cabinet-box .profile-tab-box .tab-header li a.active {
  background-color: #2bb24c;
  color: #ffffff;
  box-shadow: 0 0 1px rgba(0, 0, 0, 0.2);
}
.cabinet-box .profile-tab-box .tab-body {
  background-color: #ffffff;
  border-radius: 5px;
  box-shadow: 0 6px 6px rgba(0, 0, 0, 0.5);
  font-size: 1rem;
  padding: 100px 40px;
  position: relative;
}
.cabinet-box .profile-tab-box .tab-body::before, .cabinet-box .profile-tab-box .tab-body::after {
  content: "";
  background-color: #2bb24c;
  width: 100%;
  height: 60px;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 5px 5px 0 0;
}
.cabinet-box .profile-tab-box .tab-body::after {
  bottom: 0;
  top: auto;
  left: 0;
  border-radius: 0 0 5px 5px;
}
.cabinet-box .profile-tab-box .tab-body.billing-box {
  padding: 54px 0;
}
.cabinet-box .new-child {
  display: flex;
  min-height: 60vh;
  justify-content: center;
  align-items: center;
}
.cabinet-box .childs {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
}
.cabinet-box .avatar-box {
  text-align: center;
  margin: 20px;
}
.cabinet-box .avatar-box .avatar {
  width: 160px;
  height: 160px;
  padding: 2px;
  border-radius: 50%;
  background-color: #f5f7f9;
  color: #495057;
}
.cabinet-box .avatar-box .avatar .mat-icon {
  width: 160px;
  height: 160px;
  font-size: 100px;
  line-height: 160px;
}
.cabinet-box .avatar-box .title {
  color: #2bb24c;
  padding: 15px 0;
  font-weight: bold;
}
.cabinet-box .avatar-box.new .title {
  color: #495057;
}
.cabinet-box .form-box {
  width: 70%;
  margin: 0 auto;
  color: #495057;
}
.cabinet-box .form-box .title {
  text-align: center;
  margin-bottom: 50px;
}
.cabinet-box .form-box .sub-title {
  font-size: 1rem;
}
.cabinet-box .create-button {
  width: 100%;
}
.cabinet-box .personal-info .avatar {
  display: table;
  margin: 20px auto 0 auto;
  position: relative;
}
.cabinet-box .personal-info .user {
  width: 160px;
  height: 160px;
  border-radius: 50%;
  color: #d5d5d5;
  line-height: 160px;
  font-size: 160px;
}
.cabinet-box .personal-info .edit {
  width: 30px;
  height: 30px;
  line-height: 30px;
  font-size: 24px;
  border-radius: 50%;
  background-color: #2bb24c;
  color: #ffffff;
  text-align: center;
  position: absolute;
  top: 30px;
  right: 10px;
}
.cabinet-box .personal-info .title,
.cabinet-box .personal-info .info-value {
  margin-top: 20px;
  line-height: 1.1rem;
}
.cabinet-box .personal-info .info-value {
  color: #b3b3b3;
}
.cabinet-box .billing .billing-header {
  min-height: 125px;
  background-color: #495057;
}
.cabinet-box .billing .billing-body {
  padding: 20px;
  display: grid;
  overflow-x: auto;
}
.cabinet-box .billing .plan-buttons {
  border: solid 1px #2bb24c;
  border-radius: 4px;
  display: inline-block;
}
.cabinet-box .billing .plan-buttons button {
  background: none;
  border: none;
  box-shadow: none;
  outline: none;
  padding: 4px 10px;
  color: #a0a2a7;
}
.cabinet-box .billing .plan-buttons button:first-child {
  border-radius: 4px 0 0 4px;
}
.cabinet-box .billing .plan-buttons button:last-child {
  border-radius: 0 4px 4px 0;
}
.cabinet-box .billing .plan-buttons button.active {
  background-color: #2bb24c;
  color: #ffffff;
}
.cabinet-box .billing .status::before {
  content: "";
  margin-right: 15px;
  background-color: #ff005a;
  width: 20px;
  height: 20px;
  display: inline-block;
  border-radius: 50%;
  position: relative;
  top: 3px;
}
.cabinet-box .billing .status.active::before {
  background-color: #2bb24c;
}
.cabinet-box .billing .billing-button {
  background: none;
  border: none;
  box-shadow: none;
  outline: none;
  padding: 5px 10px;
  background-color: #dddddd;
  color: #ffffff;
  border-radius: 4px;
  min-width: 120px;
}
.cabinet-box .billing .billing-button.primary {
  background-color: #2bb24c;
}
.cabinet-box .billing-table {
  min-width: 100%;
}
.cabinet-box .billing-table th {
  padding: 10px;
}
.cabinet-box .billing-table tbody tr:nth-child(odd) {
  background-color: #f7f9fc;
}
.cabinet-box .billing-table td {
  padding: 10px;
  color: #a0a2a7;
}
.cabinet-box .billing-table td:first-child {
  text-align: center;
}

.stripe-pop-up {
  width: 100vw;
  height: 100vh;
  position: fixed;
  background-color: rgba(255, 255, 255, 0.95);
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  font-family: "Nunito", sans-serif;
  z-index: 999;
}
.stripe-pop-up .stripe-pop-up-in {
  width: 37vw;
  padding: 20px 20px 0 20px;
  border-radius: 10px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.5);
  background-color: #ffffff;
}
.stripe-pop-up .stripe-pop-up-in h4 {
  font-size: 1.4rem;
  text-align: center;
  margin-bottom: 30px;
}
.stripe-pop-up .stripe-pop-up-in.success-pup-up .mat-icon {
  width: 75px;
  height: 75px;
  line-height: 75px;
  text-align: center;
  border-radius: 50%;
  font-size: 72px;
  background-color: #2bb24c;
  color: #ffffff;
  margin: 15px auto;
}
.stripe-pop-up .stripe-pop-up-in.success-pup-up .row {
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}
.stripe-pop-up .stripe-pop-up-in.success-pup-up .done-button {
  background-color: #2bb24c;
  color: #ffffff;
  min-width: 120px;
}
.stripe-pop-up .stripe-pop-up-in.success-pup-up p {
  text-align: center;
}
.stripe-pop-up .stripe-pop-up-inMobi {
  width: 78vw;
  padding: 20px 20px 0 20px;
  border-radius: 10px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.5);
  background-color: #ffffff;
}
.stripe-pop-up .stripe-pop-up-inMobi h4 {
  font-size: 1.4rem;
  text-align: center;
  margin-bottom: 30px;
}
.stripe-pop-up .stripe-pop-up-inMobi.success-pup-up .mat-icon {
  width: 75px;
  height: 75px;
  line-height: 75px;
  text-align: center;
  border-radius: 50%;
  font-size: 72px;
  background-color: #2bb24c;
  color: #ffffff;
  margin: 15px auto;
}
.stripe-pop-up .stripe-pop-up-inMobi.success-pup-up .row {
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}
.stripe-pop-up .stripe-pop-up-inMobi.success-pup-up .done-button {
  background-color: #2bb24c;
  color: #ffffff;
  min-width: 120px;
}
.stripe-pop-up .stripe-pop-up-inMobi.success-pup-up p {
  text-align: center;
}
.stripe-pop-up .stripe-pop-up-inMobi2 {
  padding: 20px 20px 0 20px;
  border-radius: 10px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.5);
  background-color: #ffffff;
}
.stripe-pop-up .stripe-pop-up-inMobi2 h4 {
  font-size: 1.4rem;
  text-align: center;
  margin-bottom: 30px;
}
.stripe-pop-up .stripe-pop-up-inMobi2.success-pup-up .mat-icon {
  width: 75px;
  height: 75px;
  line-height: 75px;
  text-align: center;
  border-radius: 50%;
  font-size: 72px;
  background-color: #2bb24c;
  color: #ffffff;
  margin: 15px auto;
}
.stripe-pop-up .stripe-pop-up-inMobi2.success-pup-up .row {
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}
.stripe-pop-up .stripe-pop-up-inMobi2.success-pup-up .done-button {
  background-color: #2bb24c;
  color: #ffffff;
  min-width: 120px;
}
.stripe-pop-up .stripe-pop-up-inMobi2.success-pup-up p {
  text-align: center;
}
@media (max-width: 600px) {
  .stripe-pop-up .stripe-pop-up-in {
    width: 90vw;
  }
}

.done-box .mat-icon {
  width: 100px;
  height: 100px;
  line-height: 100px;
  text-align: center;
  border-radius: 50%;
  font-size: 80px;
  background-color: #2bb24c;
  color: #ffffff;
  margin: 15px auto;
}
.done-box .row {
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}
.done-box .done-button {
  background-color: #2bb24c;
  color: #ffffff;
  min-width: 120px;
}
.done-box p {
  text-align: center;
}

.myCardElement {
  height: 50px;
  padding: 15px 12px;
  width: 100%;
  color: #32325d;
  background-color: white;
  border: 1px solid rgba(0, 0, 0, 0.12);
  border-radius: 4px;
  -webkit-transition: box-shadow 150ms ease;
  transition: box-shadow 150ms ease;
  margin-bottom: 15px;
}

.myCardElement--focus {
  box-shadow: 0 1px 3px 0 #cfd7df;
}

.myCardElement--invalid {
  border-color: #fa755a;
}

.myCardElement--webkit-autofill {
  background-color: #fefde5 !important;
}

.loader-box {
  width: 100vw;
  height: 100vh;
  position: fixed;
  background-color: rgba(255, 255, 255, 0.4);
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  z-index: 9999;
}
.loader-box img {
  max-width: 100%;
  max-height: 100%;
}
.loader-box .circle {
  position: absolute;
  width: 0.5em;
  height: 0.5em;
  border-radius: 50%;
  background: black;
  opacity: 0;
  animation: move-to-center 3s ease-in-out infinite;
}
.loader-box .circle:nth-child(1) {
  transform: rotate(12deg) translateX(4em);
  animation-delay: 0.05s;
}
.loader-box .circle:nth-child(2) {
  transform: rotate(24deg) translateX(4em);
  animation-delay: 0.1s;
}
.loader-box .circle:nth-child(3) {
  transform: rotate(36deg) translateX(4em);
  animation-delay: 0.15s;
}
.loader-box .circle:nth-child(4) {
  transform: rotate(48deg) translateX(4em);
  animation-delay: 0.2s;
}
.loader-box .circle:nth-child(5) {
  transform: rotate(60deg) translateX(4em);
  animation-delay: 0.25s;
}
.loader-box .circle:nth-child(6) {
  transform: rotate(72deg) translateX(4em);
  animation-delay: 0.3s;
}
.loader-box .circle:nth-child(7) {
  transform: rotate(84deg) translateX(4em);
  animation-delay: 0.35s;
}
.loader-box .circle:nth-child(8) {
  transform: rotate(96deg) translateX(4em);
  animation-delay: 0.4s;
}
.loader-box .circle:nth-child(9) {
  transform: rotate(108deg) translateX(4em);
  animation-delay: 0.45s;
}
.loader-box .circle:nth-child(10) {
  transform: rotate(120deg) translateX(4em);
  animation-delay: 0.5s;
}
.loader-box .circle:nth-child(11) {
  transform: rotate(132deg) translateX(4em);
  animation-delay: 0.55s;
}
.loader-box .circle:nth-child(12) {
  transform: rotate(144deg) translateX(4em);
  animation-delay: 0.6s;
}
.loader-box .circle:nth-child(13) {
  transform: rotate(156deg) translateX(4em);
  animation-delay: 0.65s;
}
.loader-box .circle:nth-child(14) {
  transform: rotate(168deg) translateX(4em);
  animation-delay: 0.7s;
}
.loader-box .circle:nth-child(15) {
  transform: rotate(180deg) translateX(4em);
  animation-delay: 0.75s;
}
.loader-box .circle:nth-child(16) {
  transform: rotate(192deg) translateX(4em);
  animation-delay: 0.8s;
}
.loader-box .circle:nth-child(17) {
  transform: rotate(204deg) translateX(4em);
  animation-delay: 0.85s;
}
.loader-box .circle:nth-child(18) {
  transform: rotate(216deg) translateX(4em);
  animation-delay: 0.9s;
}
.loader-box .circle:nth-child(19) {
  transform: rotate(228deg) translateX(4em);
  animation-delay: 0.95s;
}
.loader-box .circle:nth-child(20) {
  transform: rotate(240deg) translateX(4em);
  animation-delay: 1s;
}
.loader-box .circle:nth-child(21) {
  transform: rotate(252deg) translateX(4em);
  animation-delay: 1.05s;
}
.loader-box .circle:nth-child(22) {
  transform: rotate(264deg) translateX(4em);
  animation-delay: 1.1s;
}
.loader-box .circle:nth-child(23) {
  transform: rotate(276deg) translateX(4em);
  animation-delay: 1.15s;
}
.loader-box .circle:nth-child(24) {
  transform: rotate(288deg) translateX(4em);
  animation-delay: 1.2s;
}
.loader-box .circle:nth-child(25) {
  transform: rotate(300deg) translateX(4em);
  animation-delay: 1.25s;
}
.loader-box .circle:nth-child(26) {
  transform: rotate(312deg) translateX(4em);
  animation-delay: 1.3s;
}
.loader-box .circle:nth-child(27) {
  transform: rotate(324deg) translateX(4em);
  animation-delay: 1.35s;
}
.loader-box .circle:nth-child(28) {
  transform: rotate(336deg) translateX(4em);
  animation-delay: 1.4s;
}
.loader-box .circle:nth-child(29) {
  transform: rotate(348deg) translateX(4em);
  animation-delay: 1.45s;
}
.loader-box .circle:nth-child(30) {
  transform: rotate(360deg) translateX(4em);
  animation-delay: 1.5s;
}
.loader-box .circle:nth-child(31) {
  transform: rotate(372deg) translateX(4em);
  animation-delay: 1.55s;
}
.loader-box .circle:nth-child(32) {
  transform: rotate(384deg) translateX(4em);
  animation-delay: 1.6s;
}
.loader-box .circle:nth-child(33) {
  transform: rotate(396deg) translateX(4em);
  animation-delay: 1.65s;
}
.loader-box .circle:nth-child(34) {
  transform: rotate(408deg) translateX(4em);
  animation-delay: 1.7s;
}
.loader-box .circle:nth-child(35) {
  transform: rotate(420deg) translateX(4em);
  animation-delay: 1.75s;
}
.loader-box .circle:nth-child(36) {
  transform: rotate(432deg) translateX(4em);
  animation-delay: 1.8s;
}
.loader-box .circle:nth-child(37) {
  transform: rotate(444deg) translateX(4em);
  animation-delay: 1.85s;
}
.loader-box .circle:nth-child(38) {
  transform: rotate(456deg) translateX(4em);
  animation-delay: 1.9s;
}
.loader-box .circle:nth-child(39) {
  transform: rotate(468deg) translateX(4em);
  animation-delay: 1.95s;
}
.loader-box .circle:nth-child(40) {
  transform: rotate(480deg) translateX(4em);
  animation-delay: 2s;
}
.loader-box .circle:nth-child(41) {
  transform: rotate(492deg) translateX(4em);
  animation-delay: 2.05s;
}
.loader-box .circle:nth-child(42) {
  transform: rotate(504deg) translateX(4em);
  animation-delay: 2.1s;
}
.loader-box .circle:nth-child(43) {
  transform: rotate(516deg) translateX(4em);
  animation-delay: 2.15s;
}
.loader-box .circle:nth-child(44) {
  transform: rotate(528deg) translateX(4em);
  animation-delay: 2.2s;
}
.loader-box .circle:nth-child(45) {
  transform: rotate(540deg) translateX(4em);
  animation-delay: 2.25s;
}
.loader-box .circle:nth-child(46) {
  transform: rotate(552deg) translateX(4em);
  animation-delay: 2.3s;
}
.loader-box .circle:nth-child(47) {
  transform: rotate(564deg) translateX(4em);
  animation-delay: 2.35s;
}
.loader-box .circle:nth-child(48) {
  transform: rotate(576deg) translateX(4em);
  animation-delay: 2.4s;
}
.loader-box .circle:nth-child(49) {
  transform: rotate(588deg) translateX(4em);
  animation-delay: 2.45s;
}
.loader-box .circle:nth-child(50) {
  transform: rotate(600deg) translateX(4em);
  animation-delay: 2.5s;
}
.loader-box .circle:nth-child(51) {
  transform: rotate(612deg) translateX(4em);
  animation-delay: 2.55s;
}
.loader-box .circle:nth-child(52) {
  transform: rotate(624deg) translateX(4em);
  animation-delay: 2.6s;
}
.loader-box .circle:nth-child(53) {
  transform: rotate(636deg) translateX(4em);
  animation-delay: 2.65s;
}
.loader-box .circle:nth-child(54) {
  transform: rotate(648deg) translateX(4em);
  animation-delay: 2.7s;
}
.loader-box .circle:nth-child(55) {
  transform: rotate(660deg) translateX(4em);
  animation-delay: 2.75s;
}
.loader-box .circle:nth-child(56) {
  transform: rotate(672deg) translateX(4em);
  animation-delay: 2.8s;
}
.loader-box .circle:nth-child(57) {
  transform: rotate(684deg) translateX(4em);
  animation-delay: 2.85s;
}
.loader-box .circle:nth-child(58) {
  transform: rotate(696deg) translateX(4em);
  animation-delay: 2.9s;
}
.loader-box .circle:nth-child(59) {
  transform: rotate(708deg) translateX(4em);
  animation-delay: 2.95s;
}
.loader-box .circle:nth-child(60) {
  transform: rotate(720deg) translateX(4em);
  animation-delay: 3s;
}
@keyframes move-to-center {
  to {
    opacity: 1;
    transform: translate(0, 0);
  }
}

.loginBox {
  font-family: "Nunito", sans-serif;
}
.loginBox .loginBox-text {
  text-align: center;
  font-size: 1.2rem;
  color: rgba(0, 0, 0, 0.8);
}
.loginBox .loginBox-error-text {
  color: #ff0000;
}
.loginBox .loginBox-form {
  margin: 20px auto 0;
}
.loginBox .form-group {
  min-height: 60px;
  margin-bottom: 10px;
}
.loginBox .form-group input {
  background: transparent;
  color: rgba(0, 0, 0, 0.8);
  display: block;
  width: 100%;
  padding: 2px 20px;
  outline: none;
  border: solid 2px #bebebe;
}
.loginBox .loginBox-button {
  width: 218px;
  height: 84px;
  background: url("assets/images/inner-log-in/log-in-button.png");
  background-position: bottom;
  border: none;
  outline: none;
}
.loginBox .loginBox-button:hover {
  background-position: top;
}

@font-face {
  font-family: "PointSoft";
  src: url("assets/fonts/PointSoft/PointSoftDEMO-Light.eot");
  src: url("assets/fonts/PointSoft/PointSoftDEMO-Light.eot?#iefix") format("embedded-opentype"), url("assets/fonts/PointSoft/PointSoftDEMO-Light.woff2") format("woff2"), url("assets/fonts/PointSoft/PointSoftDEMO-Light.woff") format("woff"), url("assets/fonts/PointSoft/Ndiscover - Point Soft DEMO Light.otf") format("truetype"), url("assets/fonts/PointSoft/PointSoftDEMO-SemiBold.svg#PointSoft") format("svg");
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: "QuranErafont";
  src: url("assets/fonts/quranera-font/QuranErafont.eot");
  src: url("assets/fonts/quranera-font/QuranErafont.eot?#iefix") format("embedded-opentype"), url("assets/fonts/quranera-font/QuranErafont.woff") format("woff"), url("assets/fonts/quranera-font/QuranErafont.ttf") format("truetype"), url("assets/fonts/quranera-font/QuranErafont.svg#QuranErafont") format("svg");
  font-weight: 500;
  font-style: normal;
}
body,
.mat-card {
  font-family: PointSoft;
}

.field-wrap {
  margin-bottom: 10px;
}
.field-wrap.field-wrap-form {
  padding: 3px 20px;
}
.field-wrap.field-wrap-form button {
  width: 100%;
}
.field-wrap.field-wrap-form .mat-form-field {
  width: 100%;
}
.field-wrap.field-wrap-form .custom-label {
  font-weight: bold;
  font-size: 16px;
}
.field-wrap.field-wrap-inline {
  margin-right: 30px;
  display: inline-block;
}
.field-wrap .mat-form-field .mat-form-field-wrapper .mat-form-field-flex {
  border-radius: 10px;
  padding-bottom: 0.3em;
  padding-top: 0.3em;
}
.field-wrap .mat-form-field .mat-form-field-wrapper .mat-form-field-flex .mat-form-field-infix {
  width: 300px;
}
.field-wrap .mat-form-field .mat-form-field-wrapper .mat-form-field-underline {
  display: none;
}
.field-wrap .custom-label {
  display: block;
  margin-bottom: 5px;
  color: #425c5a;
  font-size: 19px;
}

.mat-flat-button {
  margin-right: 15px !important;
  border-radius: 10px !important;
  font-size: 18px;
}
.mat-flat-button.mat-primary {
  background-color: #01bfa5;
}
.mat-flat-button.mat-primary[disabled] {
  background-color: #01bfa5;
}
.mat-flat-button.mat-warn {
  background-color: #ef6148;
}
.mat-flat-button.mat-warn[disabled] {
  background-color: #ef6148;
}

@media (max-width: 700px) {
  .dashboard-grid .main-pic-ulitka {
    display: none;
  }
}
@media (max-width: 550px) {
  .dashboard-grid .person-game-avatar {
    display: none !important;
  }
}
.dashboard-grid .mat-card {
  margin: 10px;
  border-radius: 10px;
  height: 95%;
}
.dashboard-grid .mat-grid-tile .mat-figure {
  align-items: flex-start;
}
.dashboard-grid .colored-card {
  cursor: default;
  min-height: 200px;
  overflow: hidden;
}
.dashboard-grid .colored-card p {
  margin: 12px 0 30px 0;
}
.dashboard-grid .colored-card a {
  font-weight: bold;
  position: absolute;
  bottom: 15px;
  left: 17px;
  right: 17px;
}
.dashboard-grid .colored-card a:after {
  content: "›";
  font-weight: bold;
  font-size: 30px;
  position: absolute;
  top: 50%;
  line-height: 10px;
  margin-top: -7px;
  margin-left: 10px;
}
.dashboard-grid .colored-card a .mat-icon {
  width: 10px;
  height: 10px;
  font-size: 20px;
  vertical-align: text-top;
}
.dashboard-grid .colored-card .mat-icon {
  width: 60px;
  height: 60px;
}
.dashboard-grid .colored-card .icon-wrap {
  text-align: center;
}
.dashboard-grid .colored-card.red .mat-icon {
  fill: #ef6148;
}
.dashboard-grid .colored-card.red a {
  color: #ef6148;
}
.dashboard-grid .colored-card.orange .mat-icon {
  fill: #fea53b;
}
.dashboard-grid .colored-card.orange a {
  color: #fea53b;
}
.dashboard-grid .colored-card.green .mat-icon {
  fill: #01bfa5;
}
.dashboard-grid .colored-card.green a {
  color: #01bfa5;
}
.dashboard-grid .colored-card.blue .mat-icon {
  fill: #61a5de;
}
.dashboard-grid .colored-card.blue a {
  color: #61a5de;
}

.mat-card-content {
  font-size: 17px;
}

.tab-buttons .mat-card-content {
  text-align: center;
  font-size: 21px;
}
.tab-buttons .colored-card {
  cursor: pointer;
}
.tab-buttons .colored-card .mat-card-content {
  font-weight: normal;
}
.tab-buttons .colored-card.green {
  background-color: #a0e3dc;
}
.tab-buttons .colored-card.green .mat-card-content {
  font-weight: bold;
}

.custom-table-tile {
  height: 550px;
}

.custom-table-wrap {
  width: 100%;
  padding: 10px;
}
.custom-table-wrap .custom-table {
  width: 100%;
}

.card-table {
  width: 100%;
  box-shadow: none;
}

.page-title {
  color: #425c5a;
  margin-bottom: 20px;
  font-size: 22px;
}
.page-title .mat-icon {
  font-size: 32px;
  vertical-align: text-top;
  margin-right: 10px;
  cursor: pointer;
}

.blue-button {
  background-color: #61a5de;
}
.blue-button:focus {
  box-shadow: none !important;
}

.click-row {
  cursor: pointer;
}
.click-row:hover {
  background-color: rgba(0, 0, 0, 0.04);
}

.mat-flat-button {
  min-width: 120px !important;
  margin-right: 10px !important;
  font-size: 21px;
  margin-left: 0px !important;
}
.mat-flat-button.mat-error {
  display: inline-block;
}
.mat-flat-button:focus {
  outline: none;
  box-shadow: 0 0 0 2px #101010;
}
.mat-flat-button[disabled] {
  color: #fff !important;
}
.mat-flat-button.orange {
  background-color: #fea53b;
}
.mat-flat-button.orange[disabled] {
  background-color: #fea53b;
}
.mat-flat-button.red {
  background-color: #ef6148;
}
.mat-flat-button.red[disabled] {
  background-color: #ef6148;
}
.mat-flat-button.grey {
  background-color: #425c5a;
}
.mat-flat-button.grey[disabled] {
  background-color: #425c5a;
}
.mat-flat-button.simple {
  min-width: auto !important;
}

.sidenav {
  padding-top: 11px;
}

.lesson-wrap-reso {
  border-radius: 10px;
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.5);
  text-align: center;
  padding-bottom: 10px;
  margin: 5px;
  height: 98%;
}
.lesson-wrap-reso .lesson-image img {
  border-radius: 10px;
  max-width: 100%;
}
.lesson-wrap-reso .lesson-image img.quiz {
  max-height: 303px;
}
.lesson-wrap-reso .lesson-title {
  color: #425c5a;
  margin-top: 15px;
  margin-bottom: 15px;
  font-size: 16px;
}
.lesson-wrap-reso .lesson-button {
  margin: 18px 0 15px 0 !important;
  line-height: 40px !important;
}
.lesson-wrap-reso .lesson-description {
  color: #425c5a;
  font-size: 14px;
}
.lesson-wrap-reso .lesson-letters {
  color: #425c5a;
  font-size: 35px;
  font-family: QuranErafont;
}
.lesson-wrap-reso .lesson-letters .fCalibri {
  font-size: 14px !important;
  font-family: Calibri !important;
}
.lesson-wrap {
  border-radius: 10px;
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.5);
  text-align: center;
  padding-bottom: 100px;
  margin: 5px;
  height: 107%;
}
.lesson-wrap .lesson-image img {
  border-radius: 10px;
  max-width: 100%;
}
.lesson-wrap .lesson-image img.quiz {
  max-height: 303px;
}
.lesson-wrap .lesson-title {
  color: #425c5a;
  margin-top: 15px;
  margin-bottom: 15px;
  font-size: 16px;
}
.lesson-wrap .lesson-button {
  margin: 18px 0 15px 0 !important;
  line-height: 40px !important;
}
.lesson-wrap .lesson-button.card-btn {
  margin: 0px 0 25px 0 !important;
  line-height: 40px !important;
}
.lesson-wrap .lesson-description {
  color: #425c5a;
  font-size: 14px;
}
.lesson-wrap .lesson-letters {
  color: #425c5a;
  font-size: 35px;
  font-family: QuranErafont;
}
.lesson-wrap .lesson-letters .fCalibri {
  font-size: 14px !important;
  font-family: Calibri !important;
}
.mat-paginator-container {
  justify-content: flex-start !important;
}

.pull-right {
  float: right;
  white-space: nowrap;
}

.custom-select.white-background .mat-select {
  background-color: #fff;
}
.custom-select .mat-select {
  display: inline-block;
  padding: 10px 20px;
  background-color: #efefef;
  border-radius: 10px;
  font-size: 18px;
  width: auto;
  margin-left: 10px;
}
.custom-select .mat-select .mat-select-value {
  max-width: none;
  text-align: center !important;
}

.colored-tab-item {
  padding-left: 13px;
  padding-right: 13px;
}
.colored-tab-item .green {
  background-color: #a0e3dc;
}
.colored-tab-item .green .mat-card-content {
  color: #425c5a;
  font-weight: bold;
}
.colored-tab-item .mat-card-content {
  font-size: 20px;
  text-align: center;
  color: #718583;
}
.colored-tab-item:hover .top-line {
  background-color: #01bfa5;
}

.mat-drawer-container {
  background-color: #f6f7f9;
}

.lesson-vowels {
  color: #425c5a;
  height: 80px;
  position: relative;
}
.lesson-vowels .lesson-sncd-line span {
  color: #425c5a;
  height: 80px;
  position: relative;
  font-size: 17px !important;
  font-family: PointSoft !important;
}
.lesson-vowels .lesson-sncd-line .kasra01 {
  font-family: Arabic Typesetting !important;
  font-size: 65px !important;
  position: relative !important;
}
.lesson-vowels .kasra {
  position: relative;
  font-size: 40px !important;
}
.lesson-vowels .kasra .vowel-line {
  font-size: 57px;
  position: absolute;
  top: -4px;
  left: 50%;
  margin-left: -7px;
}
.lesson-vowels .kasra .fCalibri {
  font-size: 14px !important;
  font-family: Calibri !important;
}
.lesson-vowels .kasra .tanween {
  top: 63px !important;
}
.lesson-vowels .kasra .vowel-line-Waw {
  font-size: 57px;
  position: absolute;
  top: -4px;
  left: 53%;
  margin-left: -7px;
}
.lesson-vowels .kasra .vowel-line-start {
  font-size: 36px;
  position: absolute;
  top: 25px;
  left: 46%;
  margin-left: -7px;
}
.lesson-vowels .kasra .vowel-line-end {
  font-size: 57px;
  position: absolute;
  top: -5px;
  left: 50%;
  margin-left: -7px;
}
.lesson-vowels .kasra .vowel-line-endA {
  font-size: 57px;
  position: absolute;
  top: 29px;
  left: 54.2%;
  margin-left: -7px;
}
.lesson-vowels .kasra .vowel-line-endAK {
  font-size: 57px;
  position: absolute;
  top: 29px;
  left: 50%;
  margin-left: -7px;
}
.lesson-vowels .kasra .vowel-line-endS {
  font-size: 57px;
  position: absolute;
  top: -7px;
  left: 78.2%;
  margin-left: -7px;
}
.lesson-vowels .kasra .vowel-line-endSS {
  font-size: 57px;
  position: absolute;
  top: 22px;
  left: 81.2%;
  margin-left: -7px;
}
.lesson-vowels .tanween {
  top: 40px !important;
}
.lesson-vowels .fCalibri {
  font-size: 14px !important;
  font-family: Calibri !important;
}
.lesson-vowels .fArabic {
  font-family: "Arabic Typesetting" !important;
}
.lesson-vowels .fatan {
  top: 29px !important;
}
.lesson-vowels .dhamma-top {
  margin-top: 10px !important;
}
.lesson-vowels .dhamma {
  font-size: 24px !important;
}
.lesson-vowels span {
  font-size: 30px;
}
.lesson-vowels .vowel-circle {
  font-family: QuranErafont;
  font-size: 30px;
}
.lesson-vowels .vowel-line {
  font-size: 57px;
  position: absolute;
  top: 7px;
  left: 50%;
  margin-left: -7px;
}
.lesson-vowels .vowel-line2 {
  font-size: 57px;
  position: absolute;
  top: 33px;
  left: 50%;
  margin-left: -7px;
}

.disabledRow {
  opacity: 0.5;
}

.email-field .mat-form-field-flex {
  background: none !important;
  padding-left: 0 !important;
  padding-right: 1.5em !important;
}
.email-field .mat-form-field-flex input {
  color: inherit;
}

.navbar-sidenav-container .mat-nav-list .mat-list-item {
  height: 80px !important;
}
.navbar-sidenav-container .mat-list-text {
  padding-top: 10px !important;
  font-size: 22px;
}

.mat-header-cell {
  font-size: 17px;
  font-weight: bold;
}

.mat-cell,
.mat-footer-cell {
  font-size: 16px;
}

#studentsMainContent .mat-paginator-page-size {
  display: flex !important;
}

.mat-paginator-page-size {
  display: none !important;
}

.mat-paginator {
  font-size: 14px;
}

.student-disabled {
  color: #f00;
  font-weight: bold;
  float: right;
}

.pagination-wrap {
  margin-top: 50px;
  text-align: center;
}
.pagination-wrap .pagination {
  display: inline-block;
  padding-left: 0;
}
.pagination-wrap .pagination .page-item {
  display: inline-block;
  list-style: none;
  margin: 0 10px;
}
.pagination-wrap .pagination .page-item span {
  color: rgba(0, 0, 0, 0.54);
  cursor: pointer;
}
.pagination-wrap .pagination .page-item span svg {
  width: 20px;
  margin-top: -6px;
}
.pagination-wrap .pagination .page-item span.active {
  font-weight: bold;
}

.resource-wrap {
  border-radius: 10px;
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.5);
  text-align: center;
  padding-bottom: 5px;
  margin: 5px;
  height: 97%;
  cursor: pointer;
}
.resource-wrap .resource-image img {
  border-radius: 10px;
  max-width: 100%;
}
.resource-wrap .resource-image img.quiz {
  max-height: 303px;
}
.resource-wrap .resource-title {
  color: #425c5a;
  margin-top: 5px;
  font-size: 17px;
}

.sub-title {
  padding: 17px;
  border-radius: 10px;
  background-color: #fedfba;
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 10px;
}

.custom-full-width {
  width: 100%;
}
.custom-full-width .mat-form-field-flex {
  background-color: whitesmoke;
  padding-bottom: 10px;
  border-radius: 10px;
  padding-left: 10px;
  padding-right: 10px;
}

.custom-field-wrap {
  margin-bottom: 10px;
  margin-top: 15px;
  vertical-align: top;
}
.custom-field-wrap.field-wrap-select .mat-form-field {
  width: 100%;
}
.custom-field-wrap.field-wrap-inline {
  display: inline-block;
}
.custom-field-wrap.field-wrap-inline.field-wrap-select {
  width: 100%;
}
.custom-field-wrap.field-wrap-inline.field-wrap-select .mat-form-field {
  width: 100%;
}
.custom-field-wrap.field-wrap-inline.field-wrap-select .mat-form-field-flex {
  padding-bottom: 3px !important;
}
.custom-field-wrap .mat-form-field .mat-form-field-wrapper .mat-form-field-flex {
  border-radius: 10px;
  padding-bottom: 0.3em;
  padding-top: 0.3em;
}
.custom-field-wrap .mat-form-field .mat-form-field-wrapper .mat-form-field-flex .mat-form-field-infix {
  width: 300px;
}
.custom-field-wrap .mat-form-field .mat-form-field-wrapper .mat-form-field-underline {
  display: none;
}
.custom-field-wrap .custom-label {
  display: block;
  margin-bottom: 5px;
  color: #425c5a;
  font-size: 19px;
}

.inner-tabs .blue-button {
  min-width: 255px !important;
  border-radius: 25px !important;
  background: none;
  color: #61a5de;
  border: #61a5de 1px solid;
}
.inner-tabs .blue-button-active {
  background: #61a5de;
  color: #fff;
}

.custom-modal-wrap .mat-dialog-container {
  border-radius: 15px;
  padding: 0;
}

.custom-modal {
  width: 450px;
}
.custom-modal-header {
  background: #a6e8df;
  padding: 18px;
  border-radius: 15px;
  font-weight: bold;
  font-size: 20px;
}
.custom-modal-header img {
  width: 93px;
  position: absolute;
  margin-top: -40px;
  margin-left: 310px;
}
.custom-modal-content {
  padding: 18px;
  color: #425c5a;
  font-size: 16px;
}
.custom-modal-content strong {
  color: #01bfa5;
}
.custom-modal-btn {
  text-align: center;
}
.custom-modal-btn button {
  width: 100px;
}
.custom-modal-close {
  width: 29px;
  position: absolute;
  z-index: 18;
  margin-top: -15px;
  background: #fff;
  margin-left: 435px;
  border-radius: 50%;
}

.cutsom-table-action {
  border-radius: 10px;
  background-color: #eee;
  display: inline-block;
  padding: 3px;
}
.cutsom-table-action-btn {
  display: inline-block;
  padding: 4px 7px;
  cursor: pointer;
}
.cutsom-table-action-btn svg {
  color: #566d6b;
}
.cutsom-table-action-btn-red svg {
  color: #ef7864;
}
.cutsom-table-action-btn:first-child {
  border-right: #000 1px solid;
}

.assignment-status {
  color: #ef7864;
}
.assignment-status.assignment-active {
  color: #01bfa5;
}

.create-an-activity .date-picker-field .mat-form-field-infix {
  border-top: 0;
  line-height: 6px;
}

.manage-assignment-form {
  border-radius: 10px;
  background-color: #f6f7f9;
  padding: 15px 15px 0 15px;
}
.manage-assignment-form .mat-form-field-infix {
  border-top: 0;
  line-height: 6px;
}
.manage-assignment-form-element {
  display: inline-block;
}
.manage-assignment-form-action {
  display: inline-block;
}
.manage-assignment-form-title {
  font-weight: bold;
  margin-bottom: 10px;
}

.white-background .search {
  background-color: #fff !important;
}

.report-header {
  margin-bottom: 20px;
}

.custom-widget {
  border-radius: 10px;
  box-shadow: 1px 1px 6px #c3c1c1;
  margin-bottom: 10px;
}
.custom-widget.red-lessons {
  box-shadow: none;
}
.custom-widget.red-lessons .custom-widget-item {
  border-right: 0;
  background-color: #fde7e4;
  border-radius: 0px 0px 10px 10px;
  margin: 0px 5px 0 5px;
}
.custom-widget.red-lessons .custom-widget-item div {
  color: #ef6148;
  margin: 10px 0 10px 0;
}
.custom-widget.gold-trophy .custom-widget-header-trophy {
  background-color: #fea53b;
}
.custom-widget.green-quiz .quiz-description {
  background-color: #fff2e2;
  margin: 10px;
  padding: 15px;
}
.custom-widget.green-quiz .quiz-description h1 {
  color: #536966;
  font-size: 15px;
}
.custom-widget.green-quiz .quiz-description span {
  color: #61a5de;
  display: block;
  font-size: 22px;
  font-weight: bold;
  margin-top: 10px;
}
.custom-widget.green-quiz .quiz-description div {
  color: #5a6f6b;
  font-size: 12px;
}
.custom-widget-title {
  color: #01bfa5;
  text-align: center;
  font-weight: bold;
}
.custom-widget-sub-item-line {
  text-align: center;
}
.custom-widget-sub-item-line div {
  font-size: 25px;
  font-weight: bold;
  color: #fea53b;
}
.custom-widget-sub-item-line span {
  font-size: 13px;
  color: #425c5a;
}
.custom-widget-item {
  text-align: center;
  min-height: 110px;
  margin: 10px;
  border-right: 1px solid #cdcdcd;
  padding-left: 0;
  padding-right: 13px;
}
.custom-widget-item:last-child {
  border-right: 0;
}
.custom-widget-item div {
  font-size: 35px;
  font-weight: bold;
  color: #fea53b;
}
.custom-widget-item span {
  font-size: 13px;
  color: #425c5a;
}
.custom-widget-header {
  padding: 15px 20px;
  border-radius: 10px 10px 0 0;
}
.custom-widget-header-red-lesson-icon {
  background-image: url("assets/images/admin/red-lesson-icon.png");
  width: 25px;
  height: 25px;
  display: inline-block;
  background-size: 100%;
  background-repeat: no-repeat;
  margin-bottom: -7px;
  margin-right: 10px;
}
.custom-widget-header-green-quiz-icon {
  background-image: url("assets/images/admin/green-quiz-icon.png");
  width: 25px;
  height: 25px;
  display: inline-block;
  background-size: 100%;
  background-repeat: no-repeat;
  margin-bottom: -7px;
  margin-right: 10px;
}
.custom-widget-header-blue {
  color: #fff;
  background-color: #61a5de;
}
.custom-widget-header-red {
  color: #fff;
  background-color: #ef6148;
}
.custom-widget-header-green {
  color: #fff;
  background-color: #01bfa5;
}

.mat-datepicker-content .mat-calendar {
  height: auto !important;
}

.filter-form .custom-field-wrap .mat-input-element {
  margin-bottom: 8px;
}
.filter-form .custom-field-wrap .mat-select {
  margin-top: 8px;
}
.filter-form .custom-field-wrap .mat-form-field-label-wrapper .mat-form-field-label {
  margin-top: 0;
}

.custom-select .mat-select .mat-select-trigger {
  padding: 6px 55px;
}