.books_list {
    width: 170px;
    height: 458px;
    float: left;
    margin: 88px 4px 88px 48px;
}

.drag-drop-book {
    width: 155px;
    height: 62px;
    text-align: center;
    margin: 2px auto 16px;
    background: url("assets/images/activity/dragDrop/drag-button.png");
    background-position: bottom;
    color: #ffffff;
    font-family: 'QuranErafont';
    display: flex;
    justify-content: center;
    align-items: center;

    &:hover {
        background-position: top;
        cursor: pointer;
    }

    span {
        font-size: 2.2rem;
    }

    &.set,
    &.in-target {
        background: none;

        span {
            display: none;
        }
    }

    &.drag {
        position: -webkit-fixed;
        position: fixed;
        z-index: 9999;
    }

    &.set-in {
        background: url("assets/images/activity/dragDrop/drag-button.png");
        background-position: top;

        span {
            display: block;
        }
    }
}

.bookcases {
    width: 705px;
    height: 458px;
    float: right;
    margin: 88px 4px;
}

.fail-box {
    width: 170px;
    display: flex;
    justify-content: space-around;
    position: absolute;
    left: 48px;
    top: 10px;

    .fail {
        width: 43px;
        height: 45px;
        background-image: url("assets/images/activity/dragDrop/fail-bg.png");
        background-position: bottom;

        &.active {
            background-position: top;
        }
    }
}

.bookcase {
    width: 160px;
    height: 200px;
    float: left;
    margin: 0 66px 56px 18px;

    &:nth-child(3n) {
        margin-left: 31px;
        margin-right: 0;
    }

    .title {
        width: auto;
        height: 100px;
        margin: 10px auto;
        text-align: center;

        img {
            height: 100px;
        }
    }

    .target {
        width: 155px;
        height: 62px;
        margin: 26px 2px;
    }

    &.error {
        .in-target {
            background: rgba(#c71414, .5);
            border-radius: 10px;
        }
    }
}

.stars-box {
    width: 418px;
    height: 96px;
    background: url("assets/images/activity/dragDrop/stars-tab.png");
    position: absolute;
    z-index: 1;
    display: flex;
    justify-content: space-around;
    padding: 40px 30px 0;
    top: -24px;
    left: 300px;

    .star {
        width: 31px;
        height: 30px;
        background: url("assets/images/activity/dragDrop/star2.png");
        background-position: top;

        &.success {
            background-position: bottom;
        }
    }
}


.cdk-drag-placeholder {
    opacity: 0;
}

.cdk-drag-animating {
    transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}