.awards {
    .leave-button {
        width: 121px;
        height: 134px;
        background: url("assets/images/awards/awards-back.png");
        background-position: bottom;
        border: none;
        outline: none;
        position: absolute;
        z-index: 2;
        left: 0;
        top: 0;

        &:hover {
            background-position: top;
        }
    }

    .points {
        width: 524px;
        position: absolute;
        z-index: 2;
        top: 155px;
        left: 260px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        & > div {
            width: 48px;
            font-family: "Kids Rock DEMO";
            font-size: 1.1rem;
            color: #ffffff;
            text-shadow: 0 0 10px rgba($color: #000000, $alpha: .8);
            text-align: center;
        }
    }

    .slider-back, .slider-next {
        width: 45px;
        height: 42px;
        background: url("assets/images/awards/awards-arrows.png");
        background-position: bottom left;
        border: none;
        outline: none;
        position: absolute;
        z-index: 2;
        left: 330px;
        top: 216px;

        &:hover {
            background-position: top left;
        }
    }

    .slider-next {
        background-position: bottom right;
        left: auto;
        right: 334px;

        &:hover {
            background-position: top right;
        }
    }

    .slider-titles {
        width: 160px;
        position: absolute;
        z-index: 2;
        top: 220px;
        left: 405px;
        font-family: "Kids Rock DEMO";
        font-size: 1.2rem;
        color: #ffffff;
        text-shadow: 0 0 10px rgba($color: #000000, $alpha: .8);
        text-align: center;
    }

    .slider-box {
        width: 800px;
        display: block;
        position: relative;
        top: 290px;
        left: 90px;

        .item {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            transform: translateX(0);
        }
    }

    .sert {
        width: 110px;
        padding: 2px;
        background-color: rgba($color: #b47c12, $alpha: .4);
        border: solid 2px rgba($color: #ffffff, $alpha: .6);
        text-align: center;
        position: relative;
        transition: all linear 200ms;
        margin-right: 25px;
        cursor: pointer;

        img {
            width: 100%;
            max-width: 100%;
        }

        .cert-text {
            width: 96% !important;
            top: 60px;
            position: absolute;
            z-index: 4;
        }

        .name {
            font-size: 0.4rem;
        }

        .description {
            font-size: 0.3rem;
            color: #E51B00;
            padding: 4px 15px;
        }

        .date {
            position: absolute;
            top: 0 !important;
            margin: 38px 0 0 63px !important;
            font-size: 0.2rem;
            color: #0090d9;
        }

        &:hover {
            -webkit-transform: scale3d(1.3, 1.3, 1.3);
            transform: scale3d(1.3, 1.3, 1.3);
            background-color: rgba($color: #B233D1, $alpha: 1);
        }

        &.final {
            .cert-text {
                top: 52px;
            }

            .name {
                font-size: 0.6rem;
                line-height: 0.8rem;
                font-family: 'Hey Comic';
                color: #a72441;
                margin-top: 3px !important;
            }

            .school {
                font-size: 0.4rem;
                line-height: 0.4rem;
                color: #E51B00;
            }

            .description {
                text-align: left;
                color: #000000;
                line-height: 0.3rem;
                font-size: 0.2rem;
                padding: 0 12px;
                margin-top: 0.4rem;
            }

            .date {
                font-family:'Hey Comic';
                font-size: 0.3rem;
                padding: 0 50px 0 0;
                color: #0152bd;
            }
        }
    }

    

    
}

.download-button {
    width: 246px;
    height: 83px;
    background: url("assets/images/awards/download.png");
    background-position: bottom;
    border: none;
    outline: none;
    margin: 15px 5px 0 15px;

    &:hover {
        background-position: top;
    }
}

.popup-box {
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: 9999;
    top: 0;
    left: 0;
    background-color: rgba($color: #ffffff, $alpha: .6);

    .in-box {
        width: auto;
        background: #ffffff;
        border: solid 8px rgba(180, 124, 18, 0.4);
        margin: 8% auto ;
        position: relative;
        padding: 8px;
        border-radius: 10px;
        display: table;

        .leave-button {
            width: 63px;
            height: 65px;
            background: url("assets/images/activity/leave/leave.png");
            background-position: bottom;
            border: none;
            outline: none;
            position: absolute;
            z-index: 2;
            right: -50px;
            top: -50px;
            left: auto;

            &:hover {
                background-position: top;
            }
        }

        .cert {
            text-align: center;
            position: relative;
        }

        .cert2 {
            text-align: center;
            position: relative;
        }

        .cert img, .cert2 img {
            width: 350px;
        }

        .cert-text {
            width: 100%;
            top: 212px;
            position: absolute;
            z-index: 4;
        }

        .name {
            font-size: 1.4rem;
            line-height: 1.8rem;
            margin-bottom: 10px;
        }

        .description {
            font-size: 1.1rem;
            color: #E51B00;
            padding: 4px 15px;
        }

        .date {
            font-size: 0.8rem;
            padding: 17px 0 0 145px;
            color: #0090d9;
        }

        .cert2 .cert-text {
            top: 182px;
        }

        .cert2 .name {
            font-family: 'Hey Comic';
            margin-top: 22px;
            color: #a72441;
            font-size: 1.8rem;
            line-height: 0.8rem;
        }

        .school {
            font-size: 1.4rem;
            line-height: 1.4rem;
            color: #E51B00;
        }

        .cert2 .description {
            text-align: center !important;
            color: #000000;
            line-height: 1rem;
            font-size: 0.7rem;
            font-weight: 600 !important;
            margin-top: 29px !important;
            padding: 0 !important;
        }

        .cert2 .date {
            font-family: 'Hey Comic';
            padding: 87px 215px 0 0;
            color: #000000;
            text-shadow: #ffffff 0 0 1px;
        }
    }
}
