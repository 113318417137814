.songs{
    .leave-button {
        width: 121px;
        height: 134px;
        background: url("assets/images/awards/awards-back.png");
        background-position: bottom;
        border: none;
        outline: none;
        position: absolute;
        z-index: 2;
        left: 0;
        top: 0;
        &:hover {
            background-position: top;
        }
    }
    .slider-back, .slider-next
    {
        width: 100px;
        height: 106px;
        background: url("assets/images/games/games-arrows.png");
        background-position: bottom left;
        border: none;
        outline: none;
        position: absolute;
        z-index: 2;
        left: 10px;
        top: 280px;
        &:hover {
            background-position: top left;
        }
    }
    .slider-next
    {
        background-position: bottom right;
        left: auto;
        right: 10px;
        &:hover {
            background-position: top right;
        }
    }
    .blocks-list
    {
        width: 660px;
        position: relative;
        top: 130px;
        left: 160px;
        display: block;
    }
    .item
    {
        width: 100%;
        height: 85px;
        border: solid 4px #b29770;
        position: relative;
        box-shadow: 0 0 10px rgba($color: #000000, $alpha: .6);
        background-color: #e7d1c6;
        border-radius: 20px;
        margin: 0 0 10px 0;
        cursor: pointer;
        transition: all linear 300ms;
        padding: 4px;

        .border-box
        {
            width: 647px;
            height: 72px;
            position: absolute;
            border: dashed 1px #ac8b6d;
            border-radius: 16px;
            top: 2px;
            left: 2px;
            z-index: 1;
        }


        &:hover
        {
            border: solid 4px #5ed933;
            .number
            {
                background-position: top;
            }
            
        }
        
        .number
        {
            width: 62px;
            height: 63px;
            background: url("assets/images/songs/songs-number-bg.png");            
            line-height: 63px;
            text-align: center;
            color: rgba($color: #3c2517, $alpha: .8);
            font-family: "Kids Rock DEMO";
            font-size: 1.4rem;
            float: left;
            margin-right: 15px;
        }
        .description-box
        {
            width: 450px;
            float: left;
            font-family: 'Quicksand', sans-serif;
            font-weight: bold;
            text-align: left;
            font-size: 1rem;
            line-height: 1.1rem;
            color: rgba($color: #000000, $alpha: .8);
            padding: 11px 0;
            &::after
            {
                content: '';
                width: 42px;
                height: 42px;
                position: absolute;
                right: -8px;
                bottom: -8px;
                border-radius: 50%;
                background-image: url("assets/images/songs/music-icon.png");
                background-repeat: no-repeat;
                background-position: bottom;
                z-index: 5;
            }
        }
        .letter-box
        {
            width: 106px;
            height: 77px;
            float: right;
            background-image: url("assets/images/songs/songs-letters.png");
            position: absolute;
            z-index: 2;
            top: 0;
            right: 0;
            &.n1-1
            {
                background-position: 0 0;
            }   
            &.n1-2
            {
                background-position: -106px 0;
            }
            &.n1-3
            {
                background-position: -212px 0;
            }
            &.n1-4
            {
                background-position: -318px 0;
            }
            &.n1-5
            {
                background-position: 0 -77px;
            }
            &.n1-6
            {
                background-position: -106px -77px;
            }
            &.n1-7
            {
                background-position: -212px -77px;
            }
            &.n1-8
            {
                background-position: -318px -77px;
            }
            &.n1-9
            {
                background-position: 0 -154px;
            }
            &.n1-10
            {
                background-position: -106px -154px;
            }
            &.n1-11
            {
                background-position: -212px -154px;
            }
            &.n1-12
            {
                background-position: -318px -154px;
            }
            &.n1-13
            {
                background-position: 0 -231px;
            }
            &.n1-14
            {
                background-position: -106px -231px;
            }
            &.n1-15
            {
                background-position: -212px -231px;
            }
            &.n1-16
            {
                background-position: -318px -231px;
            }
            
        }
        &.lock
        {
            cursor: default;
            .description-box{
                &::after
                {
                    background-position: top;
                }
            }
            &::after
            {
                content: '';
                width: 100%;
                height: 100%;
                position: absolute;
                top: 0;
                left: 0;
                z-index: 4;
                border-radius: 16px;
                background-color: rgba($color: #000000, $alpha: .2);
            }
            &::before
            {
                content: '';
                width: 28px;
                height: 33px;
                position: absolute;
                top: 20px;
                right: 33px;
                z-index: 6;
                background-image: url("assets/images/songs/songs-lock-icon.png");
                background-repeat: no-repeat;
                background-position: center center;
            }
        }
    }

}
