@font-face {
    font-family: 'Beanstalker DEMO';
    src: url('assets/fonts/beanstalker/BeanstalkerDEMO-Regular.eot');
    src: url('assets/fonts/beanstalker/BeanstalkerDEMO-Regular.eot?#iefix') format('embedded-opentype'), url('assets/fonts/beanstalker/BeanstalkerDEMO-Regular.woff2') format('woff2'), url('assets/fonts/beanstalker/BeanstalkerDEMO-Regular.woff') format('woff'), url('assets/fonts/beanstalker/BeanstalkerDEMO-Regular.ttf') format('truetype'), url('assets/fonts/beanstalker/BeanstalkerDEMO-Regular.svg#BeanstalkerDEMO-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
}

.ulitka {
    font-family: 'Kids Rock DEMO' !important;
}

.font-loader {
    position: absolute;
    left: -1000px;
    visibility: hidden;
}