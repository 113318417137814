.letter-song {
    position: relative;

    .letter-box {
        position: absolute;
        margin: 95px auto 60px;
        left: 306px;

        img {
            width: 416px;
            height: 256px;
        }

        &.video {
            width: 980px;
            height: 600px;
            margin: 0;
            left: 0;
            top: 0;
        }

        video {
            width: 980px;
            height: 600px;
        }
    }

    .audio-box {
        position: absolute;
        width: 560px;
        left: 238px;
        top: 438px;
        display: flex;
        justify-content: space-between;

        .actions-box {
            button {
                width: 60px;
                height: 62px;
                border: none;
                background: url("assets/images/activity/letter-song/play-button.png") no-repeat;
                outline: none;
                background-position: bottom left;

                &.pause {
                    background-position: bottom right;
                }

                &:hover {
                    background-position: top left;

                    &.pause {
                        background-position: top right;
                    }
                }
            }
        }

        .progress-box {
            width: 480px;
            padding-top: 20px;
            position: relative;

            .progress-bar {
                height: 12px;
                border-radius: 10px;
                background: linear-gradient(to top, #53a3c9, #74b7d7, #53a3c9);
                border: solid 1px #41ffde;

                .mat-progress-bar-background {
                    display: none;
                }

                .mat-progress-bar-fill {
                    border-radius: 10px !important;
                    background: linear-gradient(to top, #a30d42, #f66f6c);

                    &::after {
                        display: none;
                        border-radius: 10px;
                        background: linear-gradient(to top, #a30d42, #f66f6c);
                    }
                }

                .mat-progress-bar-buffer {
                    background: none;
                }
            }

            .red-dot {
                content: "";
                width: 27px;
                height: 29px;
                display: block;
                background-image: url("assets/images/activity/letter-song/red-dot.png");
                background-repeat: no-repeat;
                background-position: center right;
                position: absolute;
                left: -12px;
                top: 11px;
                z-index: 4;
                cursor: pointer !important;
            }
        }

        .time {
            text-align: center;
            margin-top: 10px;

            .timer {
                color: #ffffff;
                font-family: "Kids Rock DEMO";
                font-size: 1.2rem;
                font-weight: bold;
            }
        }
    }

    &.video-box {
        .audio-box {
            width: 629px;
            height: 121px;
            padding: 25px;
            left: 176px;
            background-image: url("assets/images/activity/video/player-bg.png");
            transition: all linear 200ms;
            animation-name: hide;
            animation-duration: 2000ms;
            opacity: 0;

            &.active, &:hover {
                opacity: 1;
            }
        }

        @keyframes hide {
            from {
                opacity: 1;
            }

            to {
                opacity: 0;
            }
        }
    }
}
