$game-bg: #00afde;
$font-color: #010101;
$primary_color: #2bb24c;
$danger-color: #ff0000;

$cabinet-font-color: #495057;

$grey:  #777;
$grey2: #dfe3e4;
$blue:  #2183dd;
$green: #009900;
$white: #fff;