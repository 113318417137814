.loginBox{
    font-family: 'Nunito', sans-serif;
    .loginBox-text
    {
        text-align: center;
        
        font-size: 1.2rem;
        color: rgba($color: #000000, $alpha: .8);
    }
    .loginBox-error-text
    {
        color: #ff0000;
    }
    .loginBox-form
    {
        margin: 20px auto 0;
    }
    .form-group
    {
        min-height: 60px;
        margin-bottom: 10px;
        input
        {
            background: transparent;
            color: rgba($color: #000000, $alpha: .8);
            display: block;
            width: 100%;
            padding: 2px 20px;
            outline: none;
            border: solid 2px #bebebe;
        }
        
    }

    .loginBox-button {
        width: 218px;
        height: 84px;
        background: url("assets/images/inner-log-in/log-in-button.png");
        background-position: bottom;
        border: none;
        outline: none;

        &:hover {
            background-position: top;
        }
    }
}