.cabinet-main {
    font-family: "Nunito", sans-serif;
    background: radial-gradient(circle, #3db8ff, #ffffff);
    min-height: 100vh;

    @media (max-width: 600px) {
        .container {
            width: 100vw !important;
        }

        .tab-header {
            flex-wrap: wrap;

            li {
                margin-bottom: 20px !important;
            }
        }

        .tab-body {
            padding: 80px 20px !important;

            .form-box {
                width: 100% !important;
            }

            .childs {
                justify-content: center;
            }
        }
    }

    .cabinet-header {
        justify-content: space-between;
        align-items: flex-end;
        margin: 30px 0 0;

        .logout-button {
            margin-bottom: 0 !important;
        }
    }

    .back-button {
        float: left;
        position: absolute;
        left: 30px;
        top: 90px;
        min-width: 150px;
    }
}

.mat-radio-button {
    margin: 0 20px 0 0;
}

.cabinet-box {
    margin: 20px 0;

    .profile-tab-box {
        .tab-header {
            margin: 0;
            padding: 0;
            list-style: none;
            display: flex;
            justify-content: center;

            li {
                text-align: center;
                margin: 0 5px;

                a {
                    display: block;
                    min-width: 256px;
                    background-color: #e5e6e9;
                    border-radius: 5px 5px 0 0;
                    color: $cabinet-font-color;
                    padding: 15px 30px;

                    &.active {
                        background-color: $primary-color;
                        color: #ffffff;
                        box-shadow: 0 0 1px rgba($color: #000000, $alpha: 0.2);
                    }
                }
            }
        }

        .tab-body {
            background-color: #ffffff;
            border-radius: 5px;
            box-shadow: 0 6px 6px rgba($color: #000000, $alpha: 0.5);
            font-size: 1rem;
            padding: 100px 40px;
            position: relative;

            &::before,
            &::after {
                content: "";
                background-color: $primary-color;
                width: 100%;
                height: 60px;
                display: block;
                position: absolute;
                top: 0;
                left: 0;
                border-radius: 5px 5px 0 0;
            }

            &::after {
                bottom: 0;
                top: auto;
                left: 0;
                border-radius: 0 0 5px 5px;
            }

            &.billing-box {
                padding: 54px 0;
            }
        }
    }

    .new-child {
        display: flex;
        min-height: 60vh;
        justify-content: center;
        align-items: center;
    }

    .childs {
        display: flex;
        flex-wrap: wrap;
        align-items: flex-start;
    }

    .avatar-box {
        text-align: center;
        margin: 20px;

        .avatar {
            width: 160px;
            height: 160px;
            padding: 2px;
            border-radius: 50%;
            background-color: #f5f7f9;
            color: $cabinet-font-color;

            .mat-icon {
                width: 160px;
                height: 160px;
                font-size: 100px;
                line-height: 160px;
            }
        }

        .title {
            color: $primary-color;
            padding: 15px 0;
            font-weight: bold;
        }

        &.new {
            .title {
                color: $cabinet-font-color;
            }
        }
    }

    .form-box {
        width: 70%;
        margin: 0 auto;
        color: $cabinet-font-color;

        .title {
            text-align: center;
            margin-bottom: 50px;
        }

        .sub-title {
            font-size: 1rem;
        }
    }

    .create-button {
        width: 100%;
    }

    .personal-info {
        .avatar {
            display: table;
            margin: 20px auto 0 auto;
            position: relative;
        }

        .user {
            width: 160px;
            height: 160px;
            border-radius: 50%;
            color: #d5d5d5;
            line-height: 160px;
            font-size: 160px;
        }

        .edit {
            width: 30px;
            height: 30px;
            line-height: 30px;
            font-size: 24px;
            border-radius: 50%;
            background-color: $primary-color;
            color: #ffffff;
            text-align: center;
            position: absolute;
            top: 30px;
            right: 10px;
        }

        .title,
        .info-value {
            margin-top: 20px;
            line-height: 1.1rem;
        }

        .info-value {
            color: #b3b3b3;
        }
    }

    .billing {
        .billing-header {
            min-height: 125px;
            background-color: #495057;
        }

        .billing-body {
            padding: 20px;
            display: grid;
            overflow-x: auto;
        }

        .plan-buttons {
            border: solid 1px $primary_color;
            border-radius: 4px;
            display: inline-block;

            button {
                background: none;
                border: none;
                box-shadow: none;
                outline: none;
                padding: 4px 10px;
                color: #a0a2a7;

                &:first-child {
                    border-radius: 4px 0 0 4px;
                }

                &:last-child {
                    border-radius: 0 4px 4px 0;
                }

                &.active {
                    background-color: $primary_color;
                    color: #ffffff;
                }
            }
        }

        .status {
            &::before {
                content: "";
                margin-right: 15px;
                background-color: #ff005a;
                width: 20px;
                height: 20px;
                display: inline-block;
                border-radius: 50%;
                position: relative;
                top: 3px;
            }

            &.active {
                &::before {
                    background-color: $primary_color;
                }
            }
        }

        .billing-button {
            background: none;
            border: none;
            box-shadow: none;
            outline: none;
            padding: 5px 10px;
            background-color: #dddddd;
            color: #ffffff;
            border-radius: 4px;
            min-width: 120px;

            &.primary {
                background-color: $primary_color;
            }
        }
    }

    .billing-table {
        min-width: 100%;

        th {
            padding: 10px;
        }

        tbody {
            tr {
                &:nth-child(odd) {
                    background-color: #f7f9fc;
                }
            }
        }

        td {
            padding: 10px;
            color: #a0a2a7;

            &:first-child {
                text-align: center;
            }
        }
    }
}

.stripe-pop-up {
    width: 100vw;
    height: 100vh;
    position: fixed;
    background-color: rgba($color: #ffffff, $alpha: 0.95);
    display: flex;
    align-items: center;
    justify-content: center;
    top: 0;
    left: 0;
    font-family: "Nunito", sans-serif;
    z-index: 999;

    .stripe-pop-up-in {
        width: 37vw;
        padding: 20px 20px 0 20px;
        border-radius: 10px;
        box-shadow: 0 3px 6px rgba($color: #000000, $alpha: 0.5);
        background-color: #ffffff;

        h4 {
            font-size: 1.4rem;
            text-align: center;
            margin-bottom: 30px;
        }

        &.success-pup-up {
            .mat-icon {
                width: 75px;
                height: 75px;
                line-height: 75px;
                text-align: center;
                border-radius: 50%;
                font-size: 72px;
                background-color: $primary_color;
                color: #ffffff;
                margin: 15px auto;
            }

            .row {
                flex-direction: column;
                justify-content: space-around;
                align-items: center;
            }

            .done-button {
                background-color: $primary-color;
                color: #ffffff;
                min-width: 120px;
            }

            p {
                text-align: center;
            }
        }
    }

    .stripe-pop-up-inMobi {
        width: 78vw;
        padding: 20px 20px 0 20px;
        border-radius: 10px;
        box-shadow: 0 3px 6px rgba($color: #000000, $alpha: 0.5);
        background-color: #ffffff;

        h4 {
            font-size: 1.4rem;
            text-align: center;
            margin-bottom: 30px;
        }

        &.success-pup-up {
            .mat-icon {
                width: 75px;
                height: 75px;
                line-height: 75px;
                text-align: center;
                border-radius: 50%;
                font-size: 72px;
                background-color: $primary_color;
                color: #ffffff;
                margin: 15px auto;
            }

            .row {
                flex-direction: column;
                justify-content: space-around;
                align-items: center;
            }

            .done-button {
                background-color: $primary-color;
                color: #ffffff;
                min-width: 120px;
            }

            p {
                text-align: center;
            }
        }
    }

    .stripe-pop-up-inMobi2 {

        padding: 20px 20px 0 20px;
        border-radius: 10px;
        box-shadow: 0 3px 6px rgba($color: #000000, $alpha: 0.5);
        background-color: #ffffff;

        h4 {
            font-size: 1.4rem;
            text-align: center;
            margin-bottom: 30px;
        }

        &.success-pup-up {
            .mat-icon {
                width: 75px;
                height: 75px;
                line-height: 75px;
                text-align: center;
                border-radius: 50%;
                font-size: 72px;
                background-color: $primary_color;
                color: #ffffff;
                margin: 15px auto;
            }

            .row {
                flex-direction: column;
                justify-content: space-around;
                align-items: center;
            }

            .done-button {
                background-color: $primary-color;
                color: #ffffff;
                min-width: 120px;
            }

            p {
                text-align: center;
            }
        }
    }

    @media (max-width: 600px) {
        .stripe-pop-up-in {
            width: 90vw;
        }
    }
}

.done-box {
    .mat-icon {
        width: 100px;
        height: 100px;
        line-height: 100px;
        text-align: center;
        border-radius: 50%;
        font-size: 80px;
        background-color: $primary_color;
        color: #ffffff;
        margin: 15px auto;
    }

    .row {
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
    }

    .done-button {
        background-color: $primary-color;
        color: #ffffff;
        min-width: 120px;
    }

    p {
        text-align: center;
    }
}

.myCardElement {
    height: 50px;
    padding: 15px 12px;
    width: 100%;
    color: #32325d;
    background-color: white;
    border: 1px solid rgba(0, 0, 0, 0.12);
    border-radius: 4px;
    // box-shadow: 0 1px 3px 0 #e6ebf1;
    -webkit-transition: box-shadow 150ms ease;
    transition: box-shadow 150ms ease;
    margin-bottom: 15px;
}

.myCardElement--focus {
    box-shadow: 0 1px 3px 0 #cfd7df;
}

.myCardElement--invalid {
    border-color: #fa755a;
}

.myCardElement--webkit-autofill {
    background-color: #fefde5 !important;
}

.loader-box {
    width: 100vw;
    height: 100vh;
    position: fixed;
    background-color: rgba($color: #ffffff, $alpha: 0.4);
    display: flex;
    align-items: center;
    justify-content: center;
    top: 0;
    left: 0;
    z-index: 9999;

    img {
        max-width: 100%;
        max-height: 100%;
    }
    .circle {
        $circle-count: 60;
        $circle-diameter: 0.5em;
        $ring-radius: 4em;
        $duration: 3s;
        position: absolute;
        width: $circle-diameter;
        height: $circle-diameter;
        border-radius: 50%;
        background: black;
        opacity: 0;
        animation: move-to-center $duration ease-in-out infinite;
      
        @for $i from 1 through $circle-count {
          &:nth-child(#{$i}) {
            $ratio: $i / $circle-count;
            // Make a ring of circles, and move them to center;
            transform: rotate($ratio * 720deg) translateX($ring-radius);
            // Then stagger the animation, and it becomes a spiral.
            animation-delay: $ratio * $duration;
          }
        }
      }
      @keyframes move-to-center {
        to {
          opacity: 1;
          transform: translate(0, 0);
        }
      }
}