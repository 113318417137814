a {
    text-decoration: none;
    color: $primary_color;

    &:hover {
        color: darken($color: $primary_color, $amount: 10);
    }
}

.m-0 {
    margin: 0 !important;
}

.page-main {
    padding-top: 60px;
    background-color: #3db8ff;
    background-image: url("assets/images/page_bg.jpg");
    background-size: cover;
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: flex-start;
}

.mat-button,
.mat-flat-button {
    line-height: 50px !important;
    margin-bottom: 20px !important;
}

.login-box {
    font-family: 'Nunito', sans-serif;
    margin: 20px 0;
    background-color: #ffffff;
    border-radius: 10px;
    box-shadow: 0 8px 10px rgba($color: #000000, $alpha: 0.5);
    font-size: 1rem;

    .logo-box {
        padding: 15px;
        //background-color: #fbfbfb;
        text-align: center;
        border-radius: 10px 10px 0 0;

        img {
            width: 201px;
        }
    }

    .main-box {
        padding: 30px;
        padding-top: 0px !important;
        color: #929292;
        text-align: center;

        &.with-row {
            padding: 30px 60px;
        }

        .title {
            color: $primary_color;
            text-align: center;
        }

        .title-balck {
            color: #000000;
        }

        .sub-title {
            font-size: 1.1em;
            font-weight: normal;
            color: rgba($color: #000000, $alpha: .9);
        }

        .text-left {
            text-align: left;
        }

        .text-center {
            text-align: center;
        }

        a {
            text-decoration: none;
            color: $primary_color;

            &:hover {
                color: darken($color: $primary_color, $amount: 10);
            }
        }
    }

    .logo-footer {
        padding: 15px;
        background-color: $primary_color;
        color: #ffffff;
        text-align: center;
        border-radius: 0 0 10px 10px;

        a {
            color: #ffffff;
            text-decoration: underline;
            transition: all linear 200ms;

            &:hover {
                text-decoration: none;
            }
        }

        p {
            margin: 0;
        }
    }

    .sign-up-button {
        width: 100%;
        max-width: 300px;
        font-size: 1.2rem;
        font-weight: bold;
    }

    .myCardElement {
        margin-bottom: 40px;
    }
}

.m-b {
    margin-bottom: 20px;
}

.amount {
    font-family: "Montserrat", sans-serif;
    font-size: 1.1rem;
}
