body:before {
    display: none;
    content: url("assets/images/zones/south-america-3/ecuador-hover.png") url("/assets/images/zones/south-america-3/colombia-hover.png") url("/assets/images/zones/south-america-3/guyana-hover.png") url("/assets/images/zones/south-america-3/venezuela-hover.png");
}

.frame {
    .items.south-america-3-frame {
        .road {
            height: 600px !important;
        }

        .road.south-america-3 {
            z-index: 10;
            top: 86px;
            right: 228px;
        }

        .country {
            &.hover {
                z-index: 1;

                &.active {
                    z-index: 6;
                }
            }

            &.south-america-3 {
                background-image: url("/assets/images/zones/south-america-3/south-america-3.png"), url("/assets/images/zones/south-america-3/bg.jpg") !important;
                width: 101%;
                height: 101%;
                background-position: bottom right !important;
            }

            &.guyana {
                background-image: url("/assets/images/zones/south-america-3/guyana.png");
                width: 168px;
                height: 186px;
                top: 119px;
                right: 299px;
                z-index: 8;

                &.hover {
                    background-image: url("/assets/images/zones/south-america-3/guyana-hover.png");
                    width: 180px;
                    height: 203px;
                    top: 115px;
                    right: 295px;
                    z-index: 8;
                }
            }

            &.venezuela {
                background-image: url("/assets/images/zones/south-america-3/venezuela.png");
                width: 333px;
                height: 310px;
                left: 244px;
                top: 14px;
                z-index: 6;

                &.hover {
                    background-image: url("/assets/images/zones/south-america-3/venezuela-hover.png");
                    width: 343px;
                    height: 327px;
                    left: 234.5px;
                    top: 17px;
                }
            }

            &.colombia {
                background-image: url("/assets/images/zones/south-america-3/colombia.png");
                width: 319px;
                height: 452px;
                left: 78px;
                top: -13px;
                z-index: 7;

                &.hover {
                    background-image: url("/assets/images/zones/south-america-3/colombia-hover.png");
                    width: 336px;
                    height: 465px;
                    left: 69.5px;
                    top: -17px;
                }
            }

            &.ecuador {
                background-image: url("assets/images/zones/south-america-3/ecuador.png");
                width: 196px;
                height: 197px;
                bottom: 142px;
                left: 12px;
                z-index: 9;

                &.hover {
                    background-image: url("assets/images/zones/south-america-3/ecuador-hover.png");
                    width: 200px;
                    height: 223px;
                    bottom: 127px;
                    left: 12px;
                }
            }
        }

        .poi {
            &.guyana {
                top: 233px;
                right: 360px;
                bottom: unset;
            }

            &.venezuela {
                top: 77px;
                right: 495px;
                bottom: unset;
            }

            &.colombia {
                top: 144px;
                left: 193px;
                bottom: unset;
            }

            &.ecuador {
                left: 48px;
                bottom: 200px;
            }

            &.quiz-1 {
                top: 196px;
                width: 64px;
                height: 64px;
                background-size: cover;
                left: 11px;
                background-image: url("/assets/images/zones/poi-quiz.png");
            }

            &.lock {
                background-image: url("/assets/images/zones/zone-poi-lock.png");
            }
        }
    }
}
