.frame {
    .items.zone-7-frame {
        .road {
            width: 400px;
            height: 489px;
            z-index: 8;
            bottom: 65px;
            right: 23px;
        }
        .country {
            &.hover {
                z-index: 1;
                &.active {
                    z-index: 6;
                }
            }
            &.zone-7 {
                background-image: url("assets/images/zones/7/zone7.png"),
                url("assets/images/zones/7/frame-bg.png");
                width: 100%;
                height: 100%;
                background-position: bottom right!important;
            }

            &.germany {
                background-image: url("assets/images/zones/7/germany.png");
                background-position: 0 0;
                width: 343px;
                height: 253px;
                right: -51px;
                top: 69px;
                z-index: 7;
                &.hover {
                    background-position: 1px -284px;
                    right: -50px;
                }
            }

            &.uk {
                background-image: url("assets/images/zones/7/uk.png");
                background-position: 0 -51px;
                width: 343px;
                height: 228px;
                left: 363px;
                top: 32px;
                z-index: 6;
                &.hover {
                    background-position: 0px -350px;
                    height: 231px;
                }
            }

            &.france {
                background-image: url("assets/images/zones/7/france.png");
                width: 343px;
                height: 284px;
                right: 103px;
                top: 130px;
                background-position: 0 -14px;
                z-index: 6;
                &.hover {
                    background-position: 0 -324px;
                    height: 314px;
                    right: 104px;
                }
            }

            &.spain {
                background-image: url("assets/images/zones/7/spain.png");
                width: 343px;
                height: 233px;
                bottom: 82px;
                right: 211px;
                z-index: 6;
                background-position: 0 -35px;
                &.hover {
                    background-position: -1px -305px;
                    height: 270px;
                    bottom: 75px;
                }
            }
        }
        .poi{
            &.germany {
                top: 157px;
                right: 27px;
                bottom: unset;
            }

            &.uk {
                top: 174px;
                right: 393px;
                bottom: unset;
            }
            &.france {
                bottom: 255px;
                right: 250px;
                top: unset;
            }
            &.spain {
                right: 347px;
                bottom: 80px;
            }

            &.quiz-1 {
                top: 81px;
                width: 50px;
                height: 50px;
                background-size: cover;
                right: 4px;
                background: url("assets/images/zones/quiz-activity-new.png") bottom;
            }
            &.quiz-2 {
                width: 50px;
                height: 50px;
                background-size: cover;
                right: 192px;
                bottom: 44px;
            }
        }
    }
}
