body:before {
    display: none;
    content: url("assets/images/zones/north-america-3/cuba-hover.png") url("/assets/images/zones/north-america-3/mexico-hover.png") url("/assets/images/zones/north-america-3/usa-hover-original.png") url("/assets/images/zones/north-america-3/canada-hover.png");
}

.frame {
    .items.north-america-3-frame {
        .road {
            height: 600px !important;
        }

        .road.north-america-3 {
            z-index: 12;
            top: 14px;
            right: 98px;
        }

        .country {
            &.hover {
                z-index: 1;

                &.active {
                    z-index: 6;
                }
            }

            &.north-america-3 {
                background-image: url("/assets/images/zones/north-america-3/north-america-3.png"), url("/assets/images/zones/north-america-3/bg.jpg") !important;
                width: 100%;
                height: 100%;
                background-position: bottom right !important;
            }

            &.cuba {
                background-image: url("/assets/images/zones/north-america-3/cuba.png");
                width: 324px;
                height: 200px;
                bottom: 75px;
                right: -72px;
                top: 298px;
                z-index: 7;

                &.hover {
                    background-image: url("/assets/images/zones/north-america-3/cuba-hover.png");
                    width: 324px;
                    height: 200px;
                    bottom: 75px;
                    right: -72px;
                    top: 374.5px;
                    z-index: 7;
                }
            }

            &.mexico {
                background-image: url("/assets/images/zones/north-america-3/mexico.png");
                width: 329px;
                height: 692px;
                left: 368px;
                top: -131px;
                z-index: 8;

                &.hover {
                    background-image: url("/assets/images/zones/north-america-3/mexico-hover.png");
                    width: 329px;
                    height: 692px;
                    left: 368px;
                    top: 326px;
                }
            }

            &.usa-original {
                background-image: url("/assets/images/zones/north-america-3/usa-original.png");
                width: 510px;
                height: 723px;
                right: 173px;
                top: -310px;
                z-index: 10;

                &.hover {
                    background-image: url("/assets/images/zones/north-america-3/usa-hover-original.png");
                    width: 510px;
                    height: 723px;
                    right: 173px;
                    top: 127px;
                    z-index: 10;
                }
            }

            &.canada {
                background-image: url("assets/images/zones/north-america-3/canada.png");
                width: 649px;
                height: 263px;
                top: -32px;
                right: 77px;
                z-index: 9;

                &.hover {
                    background-image: url("assets/images/zones/north-america-3/canada-hover.png");
                    width: 649px;
                    height: 263px;
                    top: -35px;
                    right: 77px;
                }
            }

        }

        .poi {
            &.cuba {
                bottom: 127px;
                right: 146px;
                top: unset;
            }

            &.mexico {
                top: 443px;
                right: 422px;
                bottom: unset;
            }

            &.usa-original {
                top: 271px;
                right: 315px;
                bottom: unset;
            }

            &.canada {
                right: 428px;
                top: 70px;
            }

            &.quiz-1 {
                top: 106px;
                width: 64px;
                height: 64px;
                background-size: cover;
                right: 805px;
                background-image: url("/assets/images/zones/poi-quiz.png");
            }

            &.lock {
                background-image: url("/assets/images/zones/zone-poi-lock.png");
            }
        }
    }
}
