// @import url("https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200;0,300;0,400;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,600;1,700;1,800;1,900&display=swap");
@import "zone6";
@import "zone7";
@import "africa-1";
@import "africa-2";
@import "africa-3";
@import "south-america-1";
@import "south-america-2";
@import "south-america-3";
@import "north-america-1";
@import "north-america-2";
@import "north-america-3";

body {
    margin: 0;
    padding: 0;
    color: $font-color;
    font-size: 14px;
    font-family: "Beanstalker DEMO";
}

img {
    -webkit-user-select: none;
    -webkit-touch-callout: inherit;
    touch-action: none;
    -ms-touch-action: none;
    pointer-events: none;
}

a,
a:hover,
a:active {
    text-decoration: none;
    outline: none;
    color: $font-color;
}

.s-b {
    justify-content: space-between;
}

.a-c-c {
    align-content: center;
}

.clearfix {
    clear: both;
}

.row {
    margin-right: -5px;
    margin-left: -5px;
}

.col-xl,
.col-xl-auto,
.col-xl-12,
.col-xl-11,
.col-xl-10,
.col-xl-9,
.col-xl-8,
.col-xl-7,
.col-xl-6,
.col-xl-5,
.col-xl-4,
.col-xl-3,
.col-xl-2,
.col-xl-1,
.col-lg,
.col-lg-auto,
.col-lg-12,
.col-lg-11,
.col-lg-10,
.col-lg-9,
.col-lg-8,
.col-lg-7,
.col-lg-6,
.col-lg-5,
.col-lg-4,
.col-lg-3,
.col-lg-2,
.col-lg-1,
.col-md,
.col-md-auto,
.col-md-12,
.col-md-11,
.col-md-10,
.col-md-9,
.col-md-8,
.col-md-7,
.col-md-6,
.col-md-5,
.col-md-4,
.col-md-3,
.col-md-2,
.col-md-1,
.col-sm,
.col-sm-auto,
.col-sm-12,
.col-sm-11,
.col-sm-10,
.col-sm-9,
.col-sm-8,
.col-sm-7,
.col-sm-6,
.col-sm-5,
.col-sm-4,
.col-sm-3,
.col-sm-2,
.col-sm-1,
.col,
.col-auto,
.col-12,
.col-11,
.col-10,
.col-9,
.col-8,
.col-7,
.col-6,
.col-5,
.col-4,
.col-3,
.col-2,
.col-1 {
    padding-right: 5px;
    padding-left: 5px;
}

.hidden {
    display: none !important;
}

.full-width {
    width: 100%;
}

.text-danger {
    color: $danger-color;
}

.mat-radio-outer-circle {
    border-color: $primary_color !important;
}

.mat-radio-inner-circle {
    background-color: $primary_color !important;
}

.mat-form-field-appearance-outline .mat-form-field-outline-thick {
    color: $primary_color !important;
}

.mat-flat-button,
.mat-raised-button,
.mat-fab,
.mat-mini-fab {
    color: #ffffff;
    background-color: #dddddd;
}

.game-box {
    background-color: $game-bg;
    background-image: url("assets/images/bg/bg-1-1-2.jpg");
    background-size: cover;
    min-height: 100vh;
    overflow: auto;
}

.container {
    // width: 1184px;
    // max-width: 1184px !important;
    display: table;
    margin: 0 auto;
}

.justyfy-content-center {
    justify-content: center;
}

.frame {
    width: 980px;
    height: 600px;
    display: block;
    margin: 45px 110px 100px;
    position: relative;
    background-image: url("assets/images/loader.gif");
    background-repeat: no-repeat;
    background-position: center;
    background-color: #3db8ff;
    background-size: contain;

    .zone-5-frame {
        .poi.quiz {
            top: 18px;
            left: 49%;
        }
    }

    &::after {
        width: 1174px;
        height: 684px;
        content: "";
        background-image: url("assets/images/new_frame.png");
        background-repeat: no-repeat;
        position: absolute;
        z-index: 3;
        top: -46px;
        left: -103px;
    }

    .frame-title {
        position: absolute;
        top: -88px;
        left: 243px;
        z-index: 5;
    }

    .frame-bg {
        position: absolute;
        top: 0;
        left: 0;
        z-index: -1;

        .top {
            z-index: 0;
        }
    }

    .frame-bg-school {
        background: url(assets/images/profile/bg-big.jpg);
        width: 300px;
        height: 66px;
        background-position: -420px 340px;
        position: absolute;
        top: 219px;
        left: 422px;
    }

    .frame-in {
        width: 980px;
        height: 600px;
        display: block;
        overflow: hidden;
        position: relative;
        top: 0;
        left: 0;
        z-index: 4;

        &.flex {
            display: flex;
            justify-content: center;
        }
    }

    .continents {
        position: relative;
        height: 600px;

        svg {
            position: absolute;
            background-repeat: no-repeat;
            background-image: url("assets/images/continents/all-continents.png");
            z-index: 5;

            g {
                fill: transparent;
            }

            &.hover g {
                fill: rgba(#000000, 0.2) !important;
                cursor: pointer;
            }

            &.asia {
                width: 455px;
                height: 405px;
                top: 98px;
                right: 15px;
                background-position: -209px 0;
            }

            &.europe {
                width: 178px;
                height: 139px;
                top: 101px;
                right: 387px;
                background-position: -664px 0;
            }

            &.africa {
                width: 209px;
                height: 240px;
                top: 226px;
                right: 387px;
                background-position: 0 0;
            }

            &.southamerica {
                width: 143px;
                height: 227px;
                top: 307px;
                left: 192px;
                background-position: -1235px 0;
            }

            &.northamerica {
                width: 392px;
                height: 241px;
                top: 93px;
                left: 30px;
                background-position: -842px 0;
            }
        }

        .label-img {
            width: 90px;
            height: 90px;
            background-image: url("/assets/images/continents/labels/labels.png");
            background-position-x: 0;
            cursor: pointer;
            position: absolute;
            top: 0;
            left: 0;
            z-index: 8;

            &.active {
                @keyframes jump {
                    from {
                        bottom: 71px;
                    }

                    50% {
                        bottom: 91px;
                    }

                    to {
                        bottom: 71px;
                    }
                }

                &:after {
                    content: '';
                    background-image: url("assets/images/zones/arrow.png");
                    position: absolute;
                    width: 41px;
                    height: 61px;
                    left: 23px;
                    animation: jump 1.4s linear infinite;
                }
            }

            &.label-img1 {
                background-position-y: 0;
            }

            &.label-img2 {
                background-position-y: -90px;
            }

            &.label-img3 {
                background-position-y: -180px;
            }

            &.label-img4 {
                background-position-y: -270px;
            }

            &.label-img5 {
                background-position-y: -360px;
            }

            &.lock {
                background-position-x: 90px;
            }
        }

        .label {
            width: 90px;
            height: 90px;
            cursor: pointer;
            position: absolute;
            top: 0;
            left: 0;
            z-index: 9;

            &.active {
                @keyframes jump {
                    from {
                        bottom: 71px;
                    }

                    50% {
                        bottom: 91px;
                    }

                    to {
                        bottom: 71px;
                    }
                }

                &:after {
                    content: '';
                    background-image: url("assets/images/zones/arrow.png");
                    position: absolute;
                    width: 41px;
                    height: 61px;
                    left: 23px;
                    animation: jump 1.4s linear infinite;
                }
            }
        }
    }

    .activity-list {
        display: flex;
        margin-top: 155px;
        flex-wrap: wrap;
        text-align: center;
        align-content: center;
        width: 920px;

        .activity {
            margin-bottom: 5px;
            width: 131px;
            height: 180px;
            background-image: url("assets/images/activity-list/activity-bg.png");
            background-position: 0px -175px;
            position: relative;
            display: flex;
            flex-direction: column;
            align-items: center;

            .number {
                font-family: "Kids Rock DEMO";
                color: #fdf0c9;
                font-size: 1.1rem;
                text-shadow: 2px 0px 1px #5a2a0a;
                padding-top: 12px;
            }

            .title {
                font-family: "Kids Rock DEMO";
                color: #a15221;
                font-size: 1rem;
                text-shadow: 2px 0px 1px #fff1c5;
                padding: 12px 15px 0 15px;
                height: 50px;
                line-height: 1.1em;
                vertical-align: middle;
            }

            .type-image {
                width: 53px;
                height: 53px;
                background-image: url("assets/images/activity-list/types/defoult.png");
                background-size: cover;
            }

            &.video {
                .type-image {
                    background-image: url("assets/images/activity-list/types/video.png");
                }
            }

            &.welcome {
                .type-image {
                    background-image: url("assets/images/activity-list/types/welcome.png");
                }
            }

            &.questions {
                .type-image {
                    background-image: url("assets/images/activity-list/types/questions.png");
                }
            }

            &.dot-to-dot {
                .type-image {
                    background-image: url("assets/images/activity-list/types/dot-to-dot.png");
                }
            }

            &.match-the-codes {
                .type-image {
                    background-image: url("assets/images/activity-list/types/match-the-codes.png");
                }
            }

            &.letter-song {
                .type-image {
                    background-image: url("assets/images/activity-list/types/letter-songs.png");
                }
            }

            &.reading {
                .type-image {
                    background-image: url("assets/images/activity-list/types/reading.png");
                }
            }

            &.sound-search {
                .type-image {
                    background-image: url("assets/images/activity-list/types/sound-search.png");
                }
            }

            &.find-the-letter {
                .type-image {
                    background-image: url("assets/images/activity-list/types/find-the-letter.png");
                }
            }

            &.drag-drop,
            &.drag-n-drop {
                .type-image {
                    background-image: url("assets/images/activity-list/types/dragDrop.png");
                }
            }

            &.sound-hive {
                .type-image {
                    background-image: url("assets/images/activity-list/types/sound-hive.png");
                }
            }

            &.letter-grid {
                .type-image {
                    background-image: url("assets/images/activity-list/types/letter-grid.png");
                }
            }

            &.stack-wood {
                .type-image {
                    background-image: url("assets/images/activity-list/types/Stack the Logs.png");
                }
            }

            &.jumping-kangaroo {
                .type-image {
                    background-image: url("assets/images/activity-list/types/Jumping Kangaroo.png");
                }
            }

            &.submarine-game {
                .type-image {
                    background-image: url("assets/images/activity-list/types/Submarine.png");
                }
            }

            &.to-the-air {
                .type-image {
                    background-image: url("assets/images/activity-list/types/Up to the Sky.png");
                }
            }

            &.truck-game {
                .type-image {
                    background-image: url("assets/images/activity-list/types/Truck Checkpoint.png");
                }
            }

            &.letter-cloud {
                .type-image {
                    background-image: url("assets/images/activity-list/types/letter-cloud.png");
                }
            }

            &.catch-the-crab {
                .type-image {
                    background-image: url("assets/images/activity-list/types/catch-the-crab.png");
                }
            }

            &.cross-the-bridge {
                .type-image {
                    background-image: url("assets/images/activity-list/types/cross-the-bridge.png");
                }
            }

            &.letter-basket {
                .type-image {
                    background-image: url("assets/images/activity-list/types/letter-basket.png");
                }
            }

            &.ice-blocks {
                .type-image {
                    background-image: url("assets/images/activity-list/types/ice-blocks.png");
                }
            }

            &.car-repair {
                .type-image {
                    background-image: url("assets/images/activity-list/types/car-repairs.png");
                }
            }

            &.pop-the-bubbles {
                .type-image {
                    background-image: url("assets/images/activity-list/types/pop-the-bubbles.png");
                }
            }

            &.shooting-hoops {
                .type-image {
                    background-image: url("assets/images/activity-list/types/shooting-hoops.png");
                }
            }

            &.sound-pads {
                .type-image {
                    background-image: url("assets/images/activity-list/types/Sound pad icon.png");
                }
            }

            &.sound-balls {
                .type-image {
                    background-image: url("assets/images/activity-list/types/Snowball icon.png");
                }
            }

            &.climb-wall {
                .type-image {
                    background-image: url("assets/images/activity-list/types/climb-wall.png");
                }
            }

            &.dough-press {
                .type-image {
                    background-image: url("assets/images/activity-list/types/dough-press.png");
                }
            }

            &.demolish-building {
                .type-image {
                    background-image: url("assets/images/activity-list/types/demolish-building.png");
                }
            }

            &.dough-press {
                .type-image {
                    background-image: url("assets/images/activity-list/types/dough-press.png");
                }
            }

            &.letter-bubble {
                .type-image {
                    background-image: url("assets/images/activity-list/types/letter-bubble.png");
                }
            }

            &.load-train {
                .type-image {
                    background-image: url("assets/images/activity-list/types/load-train.png");
                }
            }

            &.rocket-launch {
                .type-image {
                    background-image: url("assets/images/activity-list/types/rocket-launch.png");
                }
            }

            &.truck-race {
                .type-image {
                    background-image: url("assets/images/activity-list/types/truck-race.png");
                }
            }

            &.iqra-time {
                .type-image {
                    background-image: url("assets/images/activity-list/types/iqra-time.png");
                }
            }

            &.recite-verse {
                .type-image {
                    background-image: url("assets/images/activity-list/types/iqra-time.png");
                }
            }

            &.mosque-complete-verse {
                .type-image {
                    background-image: url("assets/images/activity-list/types/At the Mosque.png");
                }
            }

            &.aeroplane {
                .type-image {
                    background-image: url("assets/images/activity-list/types/Flying Verse.png");
                }
            }

            &.meteors-game {
                .type-image {
                    background-image: url("assets/images/activity-list/types/Save the Planet.png");
                }
            }

            &.farm-game {
                .type-image {
                    background-image: url("assets/images/activity-list/types/Melon Farm.png");
                }
            }

            &.castle-game {
                .type-image {
                    background-image: url("assets/images/activity-list/types/Treasure Hunt.png");
                }
            }

            &.find-the-way {
                .type-image {
                    background-image: url("assets/images/activity-list/types/Find the way.png");
                }
            }

            &.slime-splat {
                .type-image {
                    background-image: url("assets/images/activity-list/types/Slime Splat.png");
                }
            }

            &.bird-game {
                .type-image {
                    background-image: url("assets/images/activity-list/types/Lost Bird.png");
                }
            }

            &.factory-game {
                .type-image {
                    background-image: url("assets/images/activity-list/types/Sort the Boxes.png");
                }
            }

            &.ice-board {
                .type-image {
                    background-image: url("assets/images/activity-list/types/Ice Board.png");
                }
            }

            .star-box {
                font-family: "Kids Rock DEMO";
                color: #a15221;
                font-size: 1rem;
                text-shadow: 4px 0px 2px #fff1c5;
                padding: 0 0 0 32px;
                position: relative;

                &::before {
                    content: "";
                    width: 24px;
                    height: 23px;
                    background-image: url("assets/images/activity-list/star.png");
                    position: absolute;
                    left: 0;
                    display: block;
                }
            }

            svg {
                width: 126px;
                height: 187px;
                position: absolute;
                z-index: 10;
                top: 1px;
                left: 5px;

                g {
                    fill: none;
                }
            }

            &.lock {
                pointer-events: none;
                cursor: default;

                svg {
                    g {
                        fill: rgba(#000000, 0.5);
                    }
                }

                &::after {
                    content: "";
                    width: 29px;
                    height: 34px;
                    //background-image: url("assets/images/activity-list/lock.png");
                    position: absolute;
                    z-index: 11;
                    right: 0;
                    bottom: 0;
                }

                &:hover {
                    cursor: unset;
                    background-position: bottom;

                    svg {
                        g {
                            fill: rgba(#000000, 0.5);
                        }
                    }
                }
            }

            &:hover {
                cursor: pointer;
                background-position: -3px 5px;

                svg {
                    g {
                        fill: rgba(#ffffff, 0.2);
                    }
                }
            }
        }
    }

    .continet-title,
    .zone-title {
        width: 197px;
        height: 54px;
        position: absolute;
        top: -44px;
        text-align: center;
        left: 20px;
        background-image: url("assets/images/activity-list/title-bg.png");
        font-family: "Kids Rock DEMO";
        z-index: 9;
        color: #c7670a;
        font-size: 1.2rem;
        text-shadow: 1px 0px 0px #eef58a;
        padding: 10px 0;
    }

    .zone-title {
        left: auto;
        right: 20px;
    }

    .back-to-map {
        width: 124px;
        height: 66px;
        position: absolute;
        cursor: pointer;
        border: none;
        outline: none;
        top: 40px;
        left: 40px;
        background: url("assets/images/activity-list/back-to-map.png");
        background-position: bottom;
        z-index: 9;

        &:hover {
            background-position: top;
        }
    }

    .items {
        position: relative;
        height: 600px;
        transition: all 500ms;

        .poi {
            width: 66px;
            height: 68px;
            display: block;
            background-image: url("assets/images/zones/zone-poi.png");
            position: absolute;
            bottom: 0;
            right: 0;
            z-index: 17;
            opacity: 0;
            text-align: center;
            color: #ffffff;
            font-weight: normal;
            cursor: pointer;
            padding-top: 12px;
            animation: fade 1s linear forwards;

            &.quiz {
                background-image: url("assets/images/zones/poi-quiz.png");
            }

            &.turkey {
                bottom: 262px;
                right: 176px;
            }

            &.greece {
                left: 37%;
                bottom: 136px;
            }

            &.italy {
                left: 151px;
                bottom: 199px;
            }

            &.bosnia {
                left: 37%;
                top: 115px;
            }

            span {
                font-family: "Kids Rock DEMO";
                font-size: 1.8rem;
                background-color: #ffffff;
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
                -webkit-text-stroke: 1px #000000;
                filter: drop-shadow(2px 2px #000000);
            }

            &.dark {
                background-image: url("assets/images/zones/zone-poi-dark.png");
                cursor: default;

                span {
                    background-color: rgba($color: #ffffff, $alpha: 0.5);
                }
            }

            @keyframes fade {
                to {
                    opacity: 1;
                }
            }

            &.active {
                &::after {
                    bottom: 71px;
                    background-image: url("assets/images/zones/arrow.png");
                    opacity: 1;
                    animation: jump 1.4s linear infinite;
                }
            }

            &::after {
                content: "";
                width: 41px;
                height: 61px;
                display: block;
                bottom: 5px;
                position: absolute;
                opacity: 0;
                left: 13px;
                transition: opacity 400ms linear;
                z-index: 10;

                @keyframes jump {
                    from {
                        bottom: 71px;
                    }

                    50% {
                        bottom: 91px;
                    }

                    to {
                        bottom: 71px;
                    }
                }
            }

            &.lock {
                background-image: url("assets/images/zones/zone-poi-lock.png");
            }
        }

        .quiz-activity {
            width: 53px;
            height: 53px;
            background: url("assets/images/zones/quiz-activity-new.png");
            background-position: top;
            border: none;
            outline: none;
            position: absolute;
            z-index: 7;
            left: 86px;
            top: 26px;

            &.zone-2-1 {
                left: 88px;
                top: 496px;
            }

            &.zone-2-2 {
                left: 198px;
                top: 158px;
            }

            &.zone-3-1 {
                left: 668px;
                top: 170px;
            }

            &.zone-3-2 {
                left: 15px;
                top: 170px;
            }

            &.zone-4-1 {
                left: 493px;
                top: 170px;
            }

            &.zone-4-2 {
                left: 203px;
                top: 218px;
            }

            &.active {
                background-position: center;
                cursor: pointer;

                &.current {
                    &:after {
                        content: '';
                        bottom: 71px;
                        left: 5px;
                        background-image: url("assets/images/zones/arrow.png");
                        opacity: 1;
                        animation: jump 1.4s linear infinite;
                        width: 41px;
                        height: 61px;
                        position: absolute;
                    }
                }
            }

            &.block {
                background-position: bottom;
                cursor: default;
            }
        }

        .plane {
            width: 85px;
            height: 53px;
            display: block;
            position: absolute;
            z-index: 8;
            background-image: url("assets/images/zones/plane.png");
            bottom: 168px;
            right: 16px;
            animation: start 3s linear;
            transition: all 1.5s linear;

            @keyframes start {
                from {
                    bottom: 260px;
                    right: -160px;
                    transform: rotate(-40deg);
                }

                30% {
                    bottom: 260px;
                    right: -160px;
                }

                to {
                    bottom: 168px;
                    right: 16px;
                    transform: rotate(0);
                }
            }

            &.to-1 {
                bottom: 168px;
                right: 16px;
            }

            &.to-2 {
                bottom: 180px;
                right: 378px;
                transform: rotate(-20deg);
            }

            &.to-3 {
                bottom: 386px;
                right: 736px;
                transform: rotate(10deg);
            }

            &.to-4 {
                bottom: 480px;
                right: 603px;
            }
        }

        .country {
            background-repeat: no-repeat;
            background-position: bottom left;
            transition: all 200ms fade;
            position: absolute;
            z-index: 6;

            &.hover {
                background-position: top left;
                cursor: pointer;
            }

            &.australia {
                width: 400px;
                height: 377px;
                bottom: 55px;
                right: 186px;
                background-image: url("assets/images/zones/1/australia.png");
            }

            &.new-zealand {
                width: 183px;
                height: 250px;
                bottom: 22px;
                right: 55px;
                background-image: url("assets/images/zones/1/new-zealand.png");
            }

            &.indonesia {
                width: 562px;
                height: 249px;
                bottom: 316px;
                left: 127px;
                background-image: url("assets/images/zones/1/indonesia.png");

                &.dark {
                    bottom: -109px;
                    left: 117px;
                    background-image: url("assets/images/zones/2/2-indonesia.png");
                }
            }

            &.malaysia {
                width: 260px;
                height: 137px;
                bottom: 453px;
                left: 183px;
                background-image: url("assets/images/zones/1/malaysia.png");

                &.dark {
                    bottom: 20px;
                    left: 170px;
                    background-image: url("assets/images/zones/2/2-malaysia.png");
                }
            }

            &.thailand {
                width: 148px;
                height: 212px;
                bottom: 532px;
                left: 148px;
                background-image: url("assets/images/zones/2/thailand.png");

                &.zone2 {
                    bottom: 90px;
                    left: 88px;
                }
            }

            &.philippines {
                width: 158px;
                height: 206px;
                bottom: 513px;
                left: 417px;
                background-image: url("assets/images/zones/1/philippines.png");

                &.zone2 {
                    bottom: 70px;
                    left: 390px;
                }
            }

            &.japan {
                width: 185px;
                height: 288px;
                bottom: 300px;
                left: 387px;
                background-image: url("assets/images/zones/2/japan.png");
            }

            &.south-korea {
                width: 170px;
                height: 126px;
                bottom: 349px;
                left: 275px;
                background-image: url("assets/images/zones/2/south-korea.png");
            }

            &.china {
                width: 550px;
                height: 384px;
                bottom: 240px;
                left: -153px;
                background-image: url("assets/images/zones/2/china-mini.png");

                &.zone3 {
                    width: 679px;
                    height: 466px;
                    bottom: 161px;
                    left: 221px;
                    background-image: url("assets/images/zones/3/china.png");
                }
            }

            &.india {
                width: 299px;
                height: 317px;
                bottom: 22px;
                left: 240px;
                background-image: url("assets/images/zones/3/india.png");
            }

            &.pakistan {
                width: 144px;
                height: 181px;
                bottom: 211px;
                left: 142px;
                background-image: url("assets/images/zones/3/pakistan.png");
            }

            &.uzbekistan {
                width: 148px;
                height: 126px;
                bottom: 409px;
                left: 68px;
                background-image: url("assets/images/zones/3/uzbekistan.png");
            }

            &.iran {
                width: 172px;
                height: 201px;
                bottom: 230px;
                left: 12px;
                background-image: url("assets/images/zones/4/iran.png");

                &.zone4 {
                    bottom: 234px;
                    left: 475px;
                }
            }

            &.uae {
                width: 84px;
                height: 144px;
                bottom: 178px;
                left: 102px;
                background-image: url("assets/images/zones/4/uae.png");

                &.zone4 {
                    bottom: 181px;
                    left: 563px;
                }
            }

            &.saudi {
                width: 303px;
                height: 232px;
                bottom: 92px;
                left: -138px;
                background-image: url("assets/images/zones/4/saudi.png");

                &.zone4 {
                    bottom: 94px;
                    left: 323px;
                }
            }

            &.palestine {
                width: 162px;
                height: 107px;
                bottom: 264px;
                left: 276px;
                background-image: url("assets/images/zones/4/palestine.png");
            }

            &.zone-5 {
                background-image: url("assets/images/zones/5/zone5.png"), url("assets/images/zones/5/frame-bg-2.png");
                width: 100%;
                height: 100%;
            }

            &.turkey {
                width: 534px;
                height: 282px;
                right: -24px;
                bottom: 89px;
                z-index: 8;
                background-size: cover;
                background-image: url("assets/images/zones/5/turkey.png");
                background-position: 0 0;

                &.hover {
                    background-position: 0 -305px;
                    right: -21px;
                    bottom: 86px;
                }
            }

            &.greece {
                background-image: url("assets/images/zones/5/greece.png");
                background-position: 0 0;
                width: 276px;
                height: 353px;
                bottom: 93px;
                right: 430px;
                z-index: 6;

                &.hover {
                    background-position: 0 -360px;
                    bottom: 99px;
                }
            }

            &.italy {
                background-image: url("assets/images/zones/5/italy.png");
                width: 437px;
                height: 470px;
                left: -51px;
                bottom: 54px;
                background-position: 0 -57px;

                &.hover {
                    background-position: 1px -551px;
                    bottom: 80px;
                    left: -51px;
                }
            }

            &.bosnia {
                background-image: url("assets/images/zones/5/bosnia.png");
                background-position: 0 0;
                width: 206px;
                height: 195px;
                bottom: 329px;
                left: 230px;

                &.hover {
                    background-position: 0 -200px;
                    bottom: 333px;
                }
            }
        }
    }

    .road {
        width: 740px;
        height: 493px;
        bottom: 110px;
        right: 132px;
        background: none;
        position: absolute;
        z-index: 6;

        path {
            stroke: #ffffff;
            stroke-width: 4px;
            stroke-dasharray: 20;
            stroke-dashoffset: 200;
            animation: dash 4s linear forwards;
            fill: none !important;
        }

        &.zone2 {
            width: 576px;
            height: 455px;
            bottom: -25px;
            right: 412px;
        }

        &.zone3 {
            width: 900px;
            height: 290px;
            bottom: 155px;
            right: 78px;
        }

        &.zone4 {
            width: 400px;
            height: 316px;
            bottom: 150px;
            right: 353px;
        }

        &.zone-5 {
            z-index: 10;
            width: 810px;
            height: 500px;
        }

        @keyframes dash {
            from {
                stroke: transparent;
            }

            30% {
                stroke: transparent;
                stroke-dashoffset: 200;
            }

            to {
                stroke-dashoffset: 0;
                stroke: #ffffff;
            }
        }
    }
}

.footer {
    width: 370px;
    height: 134px;
    position: absolute;
    z-index: 5;
    bottom: -70px;
    right: 276px;

    .footer-bg {
        width: 370px;
        height: 134px;
        position: relative;
    }

    .zone-menu {
        width: 330px;
        position: absolute;
        z-index: 2;
        top: 68px;
        left: 20px;
        list-style: none;
        display: flex;
        justify-content: space-around;
        padding: 0;
        margin: 0;

        li {
            width: 60px;
        }

        .menu-item {
            height: 32px;
            display: inline-block;
            background-repeat: no-repeat;
            background-position: right;
            padding-right: 36px;
            font-size: 24px;
            font-family: "Kids Rock DEMO";
            line-height: 34px;
            background: #ffffff;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            -webkit-text-stroke: 1px #91301a;
            position: relative;

            &::after {
                content: "";
                width: 31px;
                height: 30px;
                position: absolute;
                right: 0;
                top: 0;
                background-image: url("assets/images/zones/menu-objects.png");
                background-position: 0 -70px;
            }

            &.active {
                &::before {
                    content: "";
                    width: 32px;
                    height: 41px;
                    position: absolute;
                    right: 14px;
                    top: 32px;
                    background-image: url("assets/images/zones/menu-objects.png");
                    background-position: -68px -59px;
                }
            }
        }

        .lock {
            width: 24px;
            height: 32px;
            display: inline-block;
            background-image: url("assets/images/zones/menu-objects.png");
            background-position: 0 0;
        }

        li:nth-child(3) {
            .lock {
                background-position: -24px 0;
            }
        }

        li:nth-child(4) {
            .lock {
                background-position: -48px 0;
            }
        }
    }
}

.activity-title {
    width: 384px;
    height: 149px;
    position: absolute;
    z-index: 2;
    top: -13px;
    text-align: center;
    left: 305px;
    background-image: url("assets/images/activity-list/main-title-bg.png");
    font-family: "Kids Rock DEMO";
    color: #0e551b;
    font-size: 2.4rem;
    text-shadow: 2px 0px 1px #c1ea75;
    padding: 54px 0 0;

    &.red {
        background-image: url("assets/images/activity-list/main-title-bg-red.png");
        color: #810224;
        text-shadow: 2px 0px 1px #fb8396;
    }

    &.blue {
        background-image: url("assets/images/activity-list/main-title-bg-blue.png");
        color: #0e4f54;
        text-shadow: 2px 0px 1px #d1f1ef;
    }

    &.brown {
        background-image: url("assets/images/activity-list/main-title-bg-brown.png");
        color: #65271e;
        text-shadow: 2px 0px 1px #f7c194;
    }

    &.yellow {
        background-image: url("assets/images/activity-list/main-title-bg-yellow.png");
        color: #803e03;
        text-shadow: 2px 0px 1px #ffb943;
    }

    &.violet {
        background-image: url("assets/images/activity-list/main-title-bg-violet.png");
        color: #472050;
        text-shadow: 2px 0px 1px #e4a5cc;
    }
}

.introduction {
    text-align: center;

    .title {
        position: relative !important;
        display: inline-block;
        font-family: "Kids Rock DEMO";
        margin: 70px 0;
        font-size: 2.9rem;

        .main-text {
            background: linear-gradient(to bottom, #edee62, #e6bd4e, #dd7c33);
            background-clip: text;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            filter: drop-shadow(3px 3px 0 #43425d);
            position: relative;
            z-index: 2;
        }

        .bg-text {
            position: absolute;
            left: 0;
            z-index: 0;
            -webkit-text-stroke: 16px #ffffff;
            filter: none;
        }
    }

    .img {
        width: 100%;
        max-width: 100%;
        display: block;
        margin: 0 auto;
    }

    .text {
        width: 600px;
        display: table;
        margin: 30px auto;
        font-family: "Kids Rock DEMO";
        text-shadow: 2px 0px #fff9f1;
        font-size: 30px;
        color: #43425d;
        min-height: 150px;
        line-height: 50px;
    }

    .introduction-audio-button {
        width: 58px;
        height: 59px;
        background: url("assets/images/activity/introduction/introduction-sound.png");
        background-position: bottom;
        border: none;
        outline: none;
        position: absolute;
        z-index: 2;
        right: 226px;
        top: 77px;
        overflow: visible;

        &:hover {
            background-position: top;
        }

        &.ios::after {
            content: "";
            width: 41px;
            height: 61px;
            display: block;
            background-image: url("assets/images/activity/introduction/red-arrow.png");
            animation: jump 1.4s linear infinite;
            position: absolute;
            opacity: 1;
            bottom: 50px;
            left: 13px;
            transition: opacity 400ms linear;
            z-index: 10;

            @keyframes jump {
                from {
                    bottom: 50px;
                }

                50% {
                    bottom: 70px;
                }

                to {
                    bottom: 50px;
                }
            }
        }
    }

    .introduction-button {
        width: 218px;
        height: 84px;
        background: url("assets/images/activity/introduction/play.png");
        background-position: bottom;
        border: none;
        outline: none;

        &:hover {
            background-position: top;
        }
    }

    &.quiz {
        .title {
            margin: 70px 0;
            font-size: 2rem;
            letter-spacing: 4px;

            .main-text {
                background: linear-gradient(to bottom, #4aeaff, #00aff1);
                background-clip: text;
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
                filter: drop-shadow(3px 3px 0 #404f6e);
            }

            .bg-text {
                -webkit-text-stroke: 6px #404f6e;
            }
        }

        .text {
            width: 690px;
            display: table;
            margin: 10px auto;
            padding-left: 288px;
            font-family: "Kids Rock DEMO";
            text-shadow: 2px 0px #fff9f1;
            font-size: 1.6rem;
            color: #332e28;
            min-height: 150px;
            text-align: left;
            line-height: 3.2rem;
        }

        .introduction-button {
            width: 168px;
            height: 67px;
            background: url("assets/images/activity/quiz/quiz-play-button.png");
            background-position: bottom;
            border: none;
            outline: none;
            margin-top: 15px;

            &:hover {
                background-position: top;
            }
        }

        .introduction-audio-button {
            right: 136px;
            top: 64px;
        }
    }
}

.complete {
    position: relative;
    text-align: center;

    .trophy {
        width: 239px;
        height: 233px;
        background-image: url("assets/images/activity/complete/trophy.png");
        position: absolute;
        top: 240px;
        left: 164px;
        animation: zoomIn 500ms linear;
    }

    &.welcome {
        .trophy {
            left: 374px;
        }
    }

    .total-score {
        font-family: "Kids Rock DEMO";
        font-size: 2.8rem;
        color: #ffe058;
        position: absolute;
        top: 270px;
        left: 686px;
        background: linear-gradient(to bottom, #f4fa38, #fdba22, #bd6c2f);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        -webkit-text-stroke: 2px #91301a;
        filter: drop-shadow(2px 2px #5a2a0a);
        animation: zoomIn 100ms linear;
    }

    .stars {
        font-family: "Kids Rock DEMO";
        font-size: 2rem;
        color: #ffffff;
        position: absolute;
        top: 359px;
        left: 497px;
        -webkit-text-stroke: 1px #91301a;
        animation: zoomIn 100ms linear;
    }

    .back-to-menu,
    .again,
    .next-activity {
        width: 135px;
        height: 76px;
        background: url("assets/images/activity/complete/complete-bg.png");
        position: absolute;
        top: 490px;
        left: 405px;
        background-position: 0 -676px;
        border: none;
        outline: none;

        &:hover {
            background-position: 0 -600px;
        }
    }

    .again {
        width: 136px;
        height: 75px;
        background-position: -135px -675px;
        left: 556px;

        &:hover {
            background-position: -135px -600px;
        }
    }

    .next-activity {
        width: 136px;
        height: 75px;
        background-position: -271px -675px;
        left: 710px;

        &:hover {
            background-position: -271px -600px;
        }
    }

    @keyframes zoomIn {
        from {
            opacity: 0;
            -webkit-transform: scale3d(0.3, 0.3, 0.3);
            transform: scale3d(0.3, 0.3, 0.3);
        }

        50% {
            opacity: 1;
        }
    }

    &.quiz {
        .title {
            margin: 70px 0;
            font-size: 2rem;
            letter-spacing: 4px;
            position: relative !important;
            display: inline-block;
            font-family: "Kids Rock DEMO";

            .main-text {
                background: linear-gradient(to bottom, #4aeaff, #00aff1);
                background-clip: text;
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
                filter: drop-shadow(3px 3px 0 #404f6e);
                position: relative;
                z-index: 2;
            }

            .bg-text {
                position: absolute;
                left: 0;
                z-index: 0;
                -webkit-text-stroke: 6px #404f6e;
                filter: none;
            }
        }

        .center-box {
            width: 660px;
            margin: 0 auto;
            display: flex;
        }

        .cert-box {
            flex: auto;
            width: 590px;
            display: flex;
            align-items: center;

            .sert {
                width: 155px;
                padding: 2px;
                background-color: rgba($color: #000000, $alpha: 0.8);
                text-align: center;
                position: relative;
                transition: all linear 200ms;
                cursor: pointer;
                margin: 27px;
                margin-left: 100px;
                margin-top: 12px;

                img {
                    width: 100%;
                    max-width: 100%;
                }

                .cert-text {
                    top: 88px;
                    position: absolute;
                    z-index: 4;
                }

                .name {
                    font-size: 0.7rem;
                }

                .description {
                    font-size: 0.5rem;
                    color: #e51b00;
                    padding: 4px 10px;
                }

                .date {
                    font-size: 0.3rem;
                    padding: 4px 0 0 60px;
                    color: #0090d9;
                }
            }
        }

        .box-style {
            font-family: "Kids Rock DEMO";
            padding-top: 70px;
            color: #000000;
            font-size: 1.6rem;
            opacity: 0.7;
            text-align: left;
            margin-left: 10px;
        }

        .text-box {
            font-family: "Kids Rock DEMO";
            flex: auto;
            padding-top: 70px;
            color: #000000;
            font-size: 1.6rem;
            text-shadow: 2px 0 #fff9f1;
            opacity: 0.7;
            text-align: left;
        }

        .again {
            left: 460px;
        }

        .next-activity {
            left: 610px;
        }
    }

    &.continent {
        position: absolute;
        z-index: 1000000;
        top: 0;
        left: 120px;

        &.quiz {
            .title {
                margin: 70px 73px;
                width: 100%;
                text-align: center;

                .bg-text {
                    width: 100%;
                }
            }

            .cert-box {
                width: 663px;

                .cert-text {
                    width: 100%;
                    color: #a72441;
                }

                .date {
                    bottom: -39px;
                    left: -38px;
                }
            }

            .next-activity {
                left: 524px;
            }
        }
    }
}

.try-again {
    position: relative;

    .fail-star {
        width: 239px;
        height: 233px;
        background-image: url("assets/images/activity/try-again/fail-star.png");
        position: absolute;
        top: 240px;
        left: 164px;
        animation: zoomIn 500ms linear;
    }

    .total-score {
        font-family: "Kids Rock DEMO";
        font-size: 2.8rem;
        color: #ffe058;
        position: absolute;
        top: 270px;
        left: 686px;
        background: linear-gradient(to bottom, #f4fa38, #fdba22, #bd6c2f);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        -webkit-text-stroke: 2px #91301a;
        filter: drop-shadow(2px 2px #5a2a0a);
        animation: zoomIn 100ms linear;
    }

    .stars {
        font-family: "Kids Rock DEMO";
        font-size: 2rem;
        color: #ffffff;
        position: absolute;
        top: 359px;
        left: 497px;
        -webkit-text-stroke: 1px #91301a;
        animation: zoomIn 100ms linear;
    }

    .back-to-menu,
    .again {
        width: 136px;
        height: 76px;
        background: url("assets/images/activity/complete/back-to-menu.png");
        position: absolute;
        top: 490px;
        left: 425px;
        background-position: bottom;
        border: none;
        outline: none;

        &:hover {
            background-position: top;
        }
    }

    .again {
        width: 136px;
        height: 75px;
        background: url("assets/images/activity/complete/again.png");
        background-position: bottom;
        left: 576px;
    }

    @keyframes zoomIn {
        from {
            opacity: 0;
            -webkit-transform: scale3d(0.3, 0.3, 0.3);
            transform: scale3d(0.3, 0.3, 0.3);
        }

        50% {
            opacity: 1;
        }
    }

    &.quiz {
        .title {
            margin: 70px 0;
            font-size: 2rem;
            letter-spacing: 4px;
            position: relative !important;
            display: inline-block;
            font-family: "Kids Rock DEMO";

            .main-text {
                background: linear-gradient(to bottom, #4aeaff, #00aff1);
                background-clip: text;
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
                filter: drop-shadow(3px 3px 0 #404f6e);
                position: relative;
                z-index: 2;
            }

            .bg-text {
                position: absolute;
                left: 0;
                z-index: 0;
                -webkit-text-stroke: 6px #404f6e;
                filter: none;
            }
        }

        .center-box {
            width: 660px;
            margin: 0 auto;
            display: flex;
        }

        .cert-box {
            flex: auto;
            width: 590px;
        }

        .text-box {
            font-family: "Kids Rock DEMO";
            flex: auto;
            padding-top: 70px;
            color: #000000;
            font-size: 1.6rem;
            text-shadow: 2px 0 #fff9f1;
            opacity: 0.7;
            text-align: left;

            p {
                margin-bottom: 25px;
            }
        }

        .again {
            left: 610px;
        }

        .back-to-menu {
            left: 460px;
        }
    }
}

.leave {
    .text2 {
        width: 660px;
        display: table;
        margin: 288px auto 39px;
        font-family: "Kids Rock DEMO";
        text-shadow: 2px 0px #fff9f1;
        font-size: 26px;
        color: #43425d;
        text-align: center;
    }

    .text {
        width: 660px;
        display: table;
        margin: 260px auto 60px;
        font-family: "Kids Rock DEMO";
        text-shadow: 2px 0px #fff9f1;
        font-size: 26px;
        color: #43425d;
        text-align: center;
    }





    .save-ok {
        width: 218px;
        height: 84px;
        background: url("assets/images/activity/dot-to-dot/ok-button.png");
        background-position: bottom;
        border: none;
        outline: none;

        &:hover {
            background-position: top;
        }
    }

    .leave-yes,
    .leave-no {
        width: 172px;
        height: 79px;
        background: url("assets/images/activity/leave/leave-bg.png");
        background-position: -172px -679px;
        border: none;
        outline: none;

        &:hover {
            background-position: -172px -600px;
        }
    }

    .leave-no {
        background-position: 0 -679px;

        &:hover {
            background-position: 0 -600px;
        }
    }
}

.leave-button {
    width: 63px;
    height: 65px;
    background: url("assets/images/activity/leave/leave.png");
    background-position: bottom;
    border: none;
    outline: none;
    position: absolute;
    z-index: 2;
    right: 10px;
    top: 10px;

    &:hover {
        background-position: top;
    }
}

.video-box {
    position: relative;
}

.quiz-box {
    .leave-button {
        width: 95px;
        height: 98px;
        background: url("assets/images/activity/quiz/quiz-close-new.png");
        background-position: bottom;
        border: none;
        outline: none;
        position: absolute;
        z-index: 2;
        right: 14px;
        top: -15px;

        &:hover {
            background-position: top;
        }
    }

    .time-box {
        font-family: "Kids Rock DEMO";
        color: #543018;
        font-size: 1.4rem;
        line-height: 84px;
        position: absolute;
        top: 8px;
        right: 400px;
    }

    .title-box {
        position: relative;
        top: 0;
        left: 375px;
        width: 240px;

        .title {
            font-family: "Kids Rock DEMO";
            color: #ffffff;
            font-size: 2.2rem;
            line-height: 84px;
            display: inline;
            position: relative;

            .main-text {
                background: linear-gradient(to bottom, #ffffff, #ffffff);
                background-clip: text;
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
                filter: drop-shadow(3px 3px 0 #43425d);
                position: relative;
                z-index: 2;
            }

            .bg-text {
                width: 300px;
                position: absolute;
                left: 0;
                z-index: 0;
                -webkit-text-stroke: 6px #4a1d00;
                filter: none;
                text-align: left;
            }
        }
    }

    .sound-button {
        width: 95px;
        height: 97px;
        background: url("assets/images/activity/quiz/quiz-audio-new.png");
        background-position: bottom;
        border: none;
        outline: none;
        position: absolute;
        z-index: 2;
        right: 76px;
        top: 126px;

        &:hover {
            background-position: top;
        }
    }

    .question-box {
        width: 646px;
        position: absolute;
        font-family: "Kids Rock DEMO";
        top: 143px;
        left: 140px;
        text-align: left;
        font-size: 1.4rem;
        line-height: 1.8rem;

        .question-number {
            color: #000000;
            display: block;
            float: left;
        }

        .question-text {
            color: #000000;
            display: block;
            float: left;
            padding-left: 20px;
        }
    }

    .answers-box {
        position: absolute;
        width: 688px;
        top: 216px;
        left: 140px;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        font-family: "QuranErafont";

        .answer-button {
            width: 340px;
            height: 150px;
            background: url("assets/images/activity/quiz/quiz-buttons-new.png");
            background-position: 0 -450px;
            background-repeat: no-repeat;
            border: none;
            outline: none;
            text-align: left;
            font-size: 4rem;
            margin-bottom: 0;
            color: #000000;
            display: flex;
            justify-content: center;
            align-items: center;

            img {
                height: 110px;
            }

            &:hover {
                background-position: 0 -300px;
            }

            &.correnct {
                background-position: 0 0;
            }

            &.incorrenct {
                background-position: 0 -150px;
            }
        }
    }

    .progress-bar {
        width: 735px;
        position: absolute;
        top: 535px;
        left: 120px;

        .bar {
            height: 28px;
            border-radius: 4px;

            .mat-progress-bar-background {
                display: none;
            }

            .mat-progress-bar-fill {
                &::before {
                    content: "";
                    width: 98%;
                    height: 14px;
                    margin: 3px 1%;
                    background-color: rgba($color: #40bcd5, $alpha: 0.2);
                    position: absolute;
                    z-index: 5;
                    border-radius: 6px 6px 0 0;
                    left: 0;
                }

                &::after {
                    border-radius: 6px;
                    // background-color: #0065C3;
                    background: linear-gradient(to top, #40bcd5, #1c97be 20%, #44c1e1);
                }
            }

            .mat-progress-bar-buffer {
                background: none;
            }
        }
    }
}

.dot-to-dot-box {

    // background: linear-gradient(to right, #7163f5, #aca4f5, #7163f5);
    .title-box {
        text-align: center;
        width: 685px;
        height: 90px;
        background-image: url("assets/images/activity/dot-to-dot/top-bar.png");
        margin: 10px 126px;

        span {
            font-family: "Kids Rock DEMO";
            color: #8e3f04;
            font-size: 2rem;
            line-height: 84px;
            text-transform: uppercase;

            small {
                padding-left: 25px;
                font-family: "QuranErafont";
                font-size: 3rem;
                color: #343333;
                text-shadow: -1px -1px 0 #fff, 1px -1px 0 #fff, -1px 1px 0 #fff, 1px 1px 0 #fff;
                -webkit-text-shadow: -1px -1px 0 #fff, 1px -1px 0 #fff, -1px 1px 0 #fff, 1px 1px 0 #fff;
            }
        }
    }

    .leave-button {
        width: 63px;
        height: 65px;
        background: url("assets/images/activity/leave/leave.png");
        background-position: bottom;
        border: none;
        outline: none;
        position: absolute;
        z-index: 2;
        right: 45px;
        top: 28px;

        &:hover {
            background-position: top;
        }
    }

    .sound-button {
        width: 63px;
        height: 65px;
        background: url("assets/images/activity/dot-to-dot/sound.png");
        background-position: bottom;
        border: none;
        outline: none;
        position: absolute;
        z-index: 2;
        left: 30px;
        top: 28px;

        &.pause,
        &:hover {
            background-position: top;
        }
    }

    .main-box {
        display: flex;

        .brush-size {
            width: 115px;
            height: 250px;
            position: absolute;
            top: 170px;
            left: 0;

            ul {
                padding: 0;
                list-style: none;
            }

            li {
                width: 14px;
                height: 14px;
                border-radius: 50%;
                background-color: #000000;
                border: solid 3px transparent;
                position: absolute;
                cursor: pointer;
                top: 206px;
                left: 50px;

                &.m {
                    width: 22px;
                    height: 22px;
                    top: 164px;
                    left: 46px;
                }

                &.l {
                    width: 30px;
                    height: 30px;
                    top: 114px;
                    left: 42px;
                }

                &.active {
                    border-color: #eda742;
                }

                &.s.active {
                    width: 20px;
                    height: 20px;
                    top: 203px;
                    left: 47px;
                }

                &.m.active {
                    width: 28px;
                    height: 28px;
                    top: 161px;
                    left: 43px;
                }

                &.l.active {
                    width: 36px;
                    height: 36px;
                    top: 111px;
                    left: 39px;
                }
            }

            .brush {
                width: 115px;
                height: 104px;
                cursor: pointer;
                position: absolute;
                left: 0;
                top: 0;
                background: url("assets/images/activity/dot-to-dot/brush-active.png");
                opacity: 0;
            }

            &.active,
            &:hover {
                .brush {
                    opacity: 1;
                }
            }
        }

        .eraser {
            width: 114px;
            height: 103px;
            position: absolute;
            left: 0;
            bottom: 60px;
            cursor: pointer;

            &:hover,
            &.active {
                background: url("assets/images/activity/dot-to-dot/eraser.png");
            }
        }

        .draw-box {
            width: 500px;
            height: 400px;
            position: absolute;
            top: 160px;
            left: 210px;
            text-align: left;

            .canvas-bg {
                width: 500px;
                height: 400px;
                position: absolute;
                top: 0;
                left: 0;
                user-drag: none;
                user-select: none;
                -moz-user-select: none;
                -webkit-user-drag: none;
                -webkit-user-select: none;
                -ms-user-select: none;
            }

            .current-letter {
                position: absolute;
                z-index: 5;
                font-family: "Kids Rock DEMO";
                color: #8e3f04;
                font-size: 3.2rem;
                line-height: 3.2rem;
                border: solid 4px #8e3f04;
                border-radius: 50%;
                padding: 15px 30px;
                text-transform: uppercase;
            }
        }

        .paint-example {
            width: 120px;
            height: 120px;
            position: absolute;
            top: 124px;
            right: 24px;
            border-radius: 16px;
        }

        .color-box {
            width: 132px;
            padding: 0;
            list-style: none;
            position: absolute;
            top: 258px;
            right: 18px;

            li {
                width: 64px;
                height: 66px;
                cursor: pointer;
                background-repeat: no-repeat;
                float: left;
                position: absolute;
                top: 0;
                left: 0;

                &:nth-child(2n) {
                    right: 0;
                    left: auto;
                }

                &:nth-child(3) {
                    top: 52px;
                }

                &:nth-child(4) {
                    top: 52px;
                }

                &:nth-child(5) {
                    top: 104px;
                }

                &:nth-child(6) {
                    top: 104px;
                }

                &:nth-child(7) {
                    top: 156px;
                }

                &:nth-child(8) {
                    top: 156px;
                }

                &:nth-child(9) {
                    top: 208px;
                }

                &:nth-child(10) {
                    top: 208px;
                }

                &:nth-child(11) {
                    top: 260px;
                }

                &:nth-child(12) {
                    top: 260px;
                }

                &.active,
                &:hover {
                    background-image: url("assets/images/activity/dot-to-dot/colors_hover.png");
                    background-position: 0 0;
                }

                &.active:nth-child(2),
                &:nth-child(2):hover {
                    background-position: -64px 0;
                }

                &.active:nth-child(3),
                &:nth-child(3):hover {
                    background-position: 0 -66px;
                }

                &.active:nth-child(4),
                &:nth-child(4):hover {
                    background-position: -64px -66px;
                }

                &.active:nth-child(5),
                &:nth-child(5):hover {
                    background-position: 0 -132px;
                }

                &.active:nth-child(6),
                &:nth-child(6):hover {
                    background-position: -64px -132px;
                }

                &.active:nth-child(7),
                &:nth-child(7):hover {
                    background-position: 0 -198px;
                }

                &.active:nth-child(8),
                &:nth-child(8):hover {
                    background-position: -64px -198px;
                }

                &.active:nth-child(9),
                &:nth-child(9):hover {
                    background-position: 0 -264px;
                }

                &.active:nth-child(10),
                &:nth-child(10):hover {
                    background-position: -64px -264px;
                }

                &.active:nth-child(11),
                &:nth-child(11):hover {
                    background-position: 0 -328px;
                }

                &.active:nth-child(12),
                &:nth-child(12):hover {
                    background-position: -64px -328px;
                }
            }
        }
    }
}

.done-button2 {
    width: 92px;
    height: 64px;
    background: none;
    background-image: url("assets/images/activity/dot-to-dot/next-button.png");
    background-position: bottom;
    border: none;
    outline: none;
    position: absolute;
    z-index: 2;
    left: 610px;
    top: 489px;

    &:hover {
        background-position: top;
    }

    &.last {
        background-image: url("assets/images/activity/dot-to-dot/done-button.png");
    }
}

.profile {
    .leave-button {
        width: 63px;
        height: 65px;
        background: url("assets/images/activity/leave/leave.png");
        background-position: bottom;
        border: none;
        outline: none;
        position: absolute;
        z-index: 2;
        right: 24px;
        top: 10px;

        &:hover {
            background-position: top;
        }
    }

    .parents-button {
        width: 162px;
        height: 56px;
        background: url("assets/images/profile/parents.png");
        background-position: bottom;
        border: none;
        outline: none;
        position: absolute;
        z-index: 2;
        left: 24px;
        top: 15px;

        &:hover {
            background-position: top;
        }
    }

    .profile-data {
        width: 800px;
        min-height: 360px;
        position: absolute;
        z-index: 2;
        left: 60px;
        top: 100px;
        display: flex;

        .profile-school-name {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }

        .avatar {
            width: 360px;
            height: 360px;
            display: flex;
            align-items: center;
            justify-content: center;
            position: relative;

            img {
                width: 325px;
                height: 325px;
            }

            .edit {
                width: 75px;
                height: 64px;
                background: url("assets/images/profile/pen.png");
                background-position: bottom;
                border: none;
                outline: none;
                position: absolute;
                z-index: 2;
                right: 48px;
                bottom: 48px;

                &:hover {
                    background-position: top;
                }
            }
        }

        ul {
            list-style: none;
            padding: 50px 0 0 150px;

            li {
                width: 280px;
                font-size: 1.5rem;
                line-height: 70px;
                min-height: 70px;
                font-family: "Kids Rock DEMO";
                text-align: left;
            }
        }

        .user-data5 {
            ul {
                padding-top: 88px;
            }
        }
    }

    .action-buttons {
        width: 880px;
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        z-index: 2;
        left: 40px;
        bottom: 16px;

        .awards,
        .assignment,
        .progress,
        .gallery {
            width: 246px;
            height: 83px;
            background: url("assets/images/profile/my-awards.png");
            background-position: bottom;
            border: none;
            outline: none;
            margin: 0 5px 0 15px;

            &:hover {
                background-position: top;
            }

            &.progress {
                background-image: url("assets/images/profile/my-progress.png");
            }

            &.gallery {
                background-image: url("assets/images/profile/my-gallery.png");
            }

            &.assignment {
                background-image: url("assets/images/profile/my-assignments.png");
            }
        }
    }
}

.set-avatar {
    .back-button {
        width: 133px;
        height: 48px;
        background: url("assets/images/avatar/back-1.png");
        background-position: bottom;
        border: none;
        outline: none;
        position: absolute;
        z-index: 2;
        left: 16px;
        top: 25px;

        &:hover {
            background-position: top;
        }
    }

    .in-box {
        width: 850px;
        height: 570px;
        display: flex;
        align-items: center;
        margin-left: 124px;
        margin-top: 12px;
    }

    .big-avatar {
        width: 540px;
        height: 496px;
        margin-top: 94px;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;

        .done {
            width: 75px;
            height: 48px;
            background: url("assets/images/avatar/done.png");
            background-position: center;
            border: none;
            outline: none;
            position: absolute;
            z-index: 2;
            right: 34px;
            top: 28px;

            &:hover {
                background-position: top;
            }

            &.set {
                background-position: bottom;
            }
        }
    }

    .avatar-list {
        width: 124px;
        margin-left: 34px;

        ul {
            height: 550px;
            display: flex;
            flex-direction: column;
            align-items: center;
            list-style: none;
            padding: 0;
            margin-top: 30px;

            li {
                height: 92px;
            }

            button {
                width: 98px;
                height: 95px;
                background: url("assets/images/avatar/avatars/avatars.png");
                background-position: bottom;
                border: none;
                outline: none;

                &:hover {
                    background-position: top;
                }

                &.b1 {
                    background-position: 1176px bottom;

                    &:hover {
                        background-position: 1176px center;
                    }

                    &.active {
                        background-position: 1176px top;
                    }
                }

                &.b6 {
                    background-position: 686px bottom;

                    &:hover {
                        background-position: 686px center;
                    }

                    &.active {
                        background-position: 686px top;
                    }
                }

                &.b5 {
                    background-position: 784px bottom;

                    &:hover {
                        background-position: 784px center;
                    }

                    &.active {
                        background-position: 784px top;
                    }
                }

                &.b4 {
                    background-position: 882px bottom;

                    &:hover {
                        background-position: 882px center;
                    }

                    &.active {
                        background-position: 882px top;
                    }
                }

                &.b3 {
                    background-position: 980px bottom;

                    &:hover {
                        background-position: 980px center;
                    }

                    &.active {
                        background-position: 980px top;
                    }
                }

                &.b2 {
                    background-position: 1078px bottom;

                    &:hover {
                        background-position: 1078px center;
                    }

                    &.active {
                        background-position: 1078px top;
                    }
                }

                &.g1 {
                    background-position: 588px bottom;

                    &:hover {
                        background-position: 588px center;
                    }

                    &.active {
                        background-position: 588px top;
                    }
                }

                &.g2 {
                    background-position: 490px bottom;

                    &:hover {
                        background-position: 490px center;
                    }

                    &.active {
                        background-position: 490px top;
                    }
                }

                &.g3 {
                    background-position: 392px bottom;

                    &:hover {
                        background-position: 392px center;
                    }

                    &.active {
                        background-position: 392px top;
                    }
                }

                &.g4 {
                    background-position: 294px bottom;

                    &:hover {
                        background-position: 294px center;
                    }

                    &.active {
                        background-position: 294px top;
                    }
                }

                &.g5 {
                    background-position: 196px bottom;

                    &:hover {
                        background-position: 196px center;
                    }

                    &.active {
                        background-position: 196px top;
                    }
                }

                &.g6 {
                    background-position: 98px bottom;

                    &:hover {
                        background-position: 98px center;
                    }

                    &.active {
                        background-position: 98px top;
                    }
                }
            }
        }
    }

    .sex-box {
        width: 150px;
        height: 258px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-end;

        button {
            width: 99px;
            height: 101px;
            background: url("assets/images/avatar/boy.png");
            background-position: bottom;
            border: none;
            outline: none;
            position: relative;

            &.female {
                background-image: url("assets/images/avatar/girl.png");
            }

            &:hover {
                background-position: top;
            }

            &.active {
                &::after {
                    content: "";
                    width: 37px;
                    height: 54px;
                    background: url("assets/images/avatar/arrow-1.png");
                    position: absolute;
                    left: -44px;
                    top: 24px;
                }
            }
        }
    }
}

.alert-pop-up {
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999;
    transition: all linear 200ms;
    font-family: 'Nunito', sans-serif;
    font-size: 1.1rem;
    background-color: rgba($color: #ffffff, $alpha: 0.4);

    .alert-pop-up-in {
        width: 500px;
        padding: 20px;
        background-color: #ffffff;
        box-shadow: 0 0 20px rgba($color: #000000, $alpha: 0.8);
        color: rgba($color: #000000, $alpha: 0.8);
        position: relative;

        &-content {
            max-height: 350px;
            overflow: auto;
        }

        button {
            min-width: 150px;
            margin: 15px auto;
            display: table;
        }

        h4 {
            text-align: center;
            font-size: 1.3rem;
            margin-bottom: 30px;
        }

        p {
            margin-bottom: 30px;
        }
    }
}

.progress {
    position: relative;
    display: flex;
    margin-bottom: 20px;
}

.progress-track {
    position: absolute;
    top: 5px;
    width: 100%;
    height: 5px;
    background-color: $grey2;
    z-index: 1;
}

.progress-step {
    position: relative;
    width: 100%;
    font-size: 12px;
    text-align: center;
    z-index: 2;

    // Hide the final step's progress bar
    &:last-child:after {
        display: none;
    }

    // Step's circle in default state
    &:before {
        content: "";
        display: flex;
        margin: 0 auto;
        margin-bottom: 10px;
        width: 16px;
        height: 16px;
        background: $white;
        border: 4px solid $grey2;
        border-radius: 100%;
        color: $white;
        position: relative;
        z-index: 4;
    }

    // Step's progress bar in default state
    &:after {
        content: "";
        position: absolute;
        top: 6px;
        left: 50%;
        width: 0%;
        transition: width 1s ease-in;
        height: 5px;
        background: $grey2;
        z-index: 3;
    }

    &.is-active {
        color: $blue;

        &:before {
            border: 4px solid $grey;
            animation: pulse 2s infinite;
        }
    }

    &.is-complete {
        color: $green;

        // Step's circle in complete state
        &:before {
            font-size: 10px;
            background: $white;
            border: 4px solid $green;
        }

        // Step's progress bar in complete state
        &:after {
            background: $blue;
            animation: nextStep 1s;
            animation-fill-mode: forwards;
        }
    }
}

@keyframes pulse {
    0% {
        box-shadow: 0 0 0 0 rgba(33, 131, 221, 0.4);
    }

    70% {
        box-shadow: 0 0 0 10px rgba(33, 131, 221, 0);
    }

    100% {
        box-shadow: 0 0 0 0 rgba(33, 131, 221, 0);
    }
}

@keyframes nextStep {
    0% {
        width: 0%;
    }

    100% {
        width: 100%;
    }
}

.text-denger {
    color: $danger-color;
}

.m-b-0 {
    margin-bottom: 0;
}

.mat-focused .mat-form-field-label {
    /*change color of label*/
    color: rgba($color: #000000, $alpha: .9) !important;
}

.mat-form-field-empty.mat-form-field-label {
    color: #000000 !important;
}

.phaser-3 {
    canvas {
        margin-top: 0px !important;
    }
}

#game-holder {
    canvas {
        margin-top: 0px !important;
    }

    &.quiz {
        canvas {
            margin-top: 0px !important;
            margin-left: -16px !important;
        }
    }
}

.is-mobile-app {
    .zone-title {
        display: none;
    }

    .continet-title {
        display: none;
    }

    .footer {
        bottom: 6px;
    }

    &>.game-box>main>.container {
        max-width: 960px;
        padding: 0;
    }

    .frame {
        margin: 0;

        &:after {
            display: none;
        }
    }

    .frame-title {
        display: none;
    }
}

.is-mobile-app {
    .zone-title {
        display: none;
    }

    .continet-title {
        display: none;
    }

    .footer {
        bottom: 6px;
    }

    &>.game-box>main>.container {
        max-width: 960px;
        padding: 0;
    }

    .frame {
        margin: 0;

        &:after {
            display: none;
        }
    }

    .frame-title {
        display: none;
    }
}

.pr-5 {
    padding-right: 5px !important
}

.ml-auto {
    margin-left: auto !important;
}

.font-QuranEra {
    font-family: "QuranErafont";
}

.font-HafsQuranEra {
    font-family: "HafsQuranEra";
}

.font-QuraneraV3 {
    font-family: "QuranEraV3";
}

.mat-select-panel {
    max-height: 356px !important;
}

.simple-snack-bar .mat-simple-snackbar {
    justify-content: center;
    font-family: "Nunito", sans-serif;
    font-size: 16px !important;
}

.mat-snack-bar-container {
    min-width: unset !important;
}