@font-face {
    font-family: "PointSoft";
    src: url("assets/fonts/PointSoft/PointSoftDEMO-Light.eot");
    src: url("assets/fonts/PointSoft/PointSoftDEMO-Light.eot?#iefix") format("embedded-opentype"), url("assets/fonts/PointSoft/PointSoftDEMO-Light.woff2") format("woff2"), url("assets/fonts/PointSoft/PointSoftDEMO-Light.woff") format("woff"), url("assets/fonts/PointSoft/Ndiscover - Point Soft DEMO Light.otf") format("truetype"), url("assets/fonts/PointSoft/PointSoftDEMO-SemiBold.svg#PointSoft") format("svg");
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: "QuranErafont";
    src: url("assets/fonts/quranera-font/QuranErafont.eot");
    src: url("assets/fonts/quranera-font/QuranErafont.eot?#iefix") format("embedded-opentype"), url("assets/fonts/quranera-font/QuranErafont.woff") format("woff"), url("assets/fonts/quranera-font/QuranErafont.ttf") format("truetype"), url("assets/fonts/quranera-font/QuranErafont.svg#QuranErafont") format("svg");
    font-weight: 500;
    font-style: normal;
}

body,
.mat-card {
    font-family: PointSoft;
}

.field-wrap {
    margin-bottom: 10px;

    &.field-wrap-form {
        padding: 3px 20px;

        button {
            width: 100%;
        }

        .mat-form-field {
            width: 100%;
        }

        .custom-label {
            font-weight: bold;
            font-size: 16px;
        }
    }

    &.field-wrap-inline {
        margin-right: 30px;
        display: inline-block;
    }

    .mat-form-field {
        .mat-form-field-wrapper {
            .mat-form-field-flex {
                border-radius: 10px;
                padding-bottom: 0.3em;
                padding-top: 0.3em;

                .mat-form-field-infix {
                    width: 300px;
                }
            }

            .mat-form-field-underline {
                display: none;
            }
        }
    }

    .custom-label {
        display: block;
        margin-bottom: 5px;
        color: #425c5a;
        font-size: 19px;
    }
}

.mat-flat-button {
    margin-right: 15px !important;
    border-radius: 10px !important;
    font-size: 18px;

    &.mat-primary {
        background-color: rgba(1, 191, 165, 1);

        &[disabled] {
            background-color: rgba(1, 191, 165, 1);
        }
    }

    &.mat-warn {
        background-color: rgba(239, 97, 72, 1);

        &[disabled] {
            background-color: rgba(239, 97, 72, 1);
        }
    }
}

$color-red: #ef6148;
$color-orange: #fea53b;
$color-green: #01bfa5;
$color-blue: #61a5de;

@media (max-width: 700px) {
    .dashboard-grid {
        .main-pic-ulitka {
            display: none;
        }
    }
}

@media (max-width: 550px) {
    .dashboard-grid {
        .person-game-avatar {
            display: none !important;
        }
    }
}

.dashboard-grid {
    .mat-card {
        margin: 10px;
        border-radius: 10px;
        height: 95%;
    }

    .mat-grid-tile {
        .mat-figure {
            align-items: flex-start;
        }
    }

    .colored-card {
        cursor: default;
        min-height: 200px;
        overflow: hidden;

        p {
            margin: 12px 0 30px 0;
        }

        a {
            font-weight: bold;
            position: absolute;
            bottom: 15px;
            left: 17px;
            right: 17px;

            &:after {
                content: "›";
                font-weight: bold;
                font-size: 30px;
                position: absolute;
                top: 50%;
                line-height: 10px;
                margin-top: -7px;
                margin-left: 10px;
            }

            .mat-icon {
                width: 10px;
                height: 10px;
                font-size: 20px;
                vertical-align: text-top;
            }
        }

        .mat-icon {
            width: 60px;
            height: 60px;
        }

        .icon-wrap {
            text-align: center;
        }

        &.red {
            .mat-icon {
                fill: $color-red;
            }

            a {
                color: $color-red;
            }
        }

        &.orange {
            .mat-icon {
                fill: $color-orange;
            }

            a {
                color: $color-orange;
            }
        }

        &.green {
            .mat-icon {
                fill: $color-green;
            }

            a {
                color: $color-green;
            }
        }

        &.blue {
            .mat-icon {
                fill: $color-blue;
            }

            a {
                color: $color-blue;
            }
        }
    }
}

.mat-card-content {
    font-size: 17px;
}

.tab-buttons {
    .mat-card-content {
        text-align: center;
        font-size: 21px;
    }

    .colored-card {
        cursor: pointer;

        .mat-card-content {
            font-weight: normal;
        }

        &.green {
            background-color: #a0e3dc;

            .mat-card-content {
                font-weight: bold;
            }
        }
    }
}

.custom-table-tile {
    height: 550px;
}

.custom-table-wrap {
    width: 100%;
    padding: 10px;

    .custom-table {
        width: 100%;
    }
}

.card-table {
    width: 100%;
    box-shadow: none;
}

.page-title {
    color: #425c5a;
    //cursor: pointer;
    margin-bottom: 20px;
    font-size: 22px;

    .mat-icon {
        font-size: 32px;
        vertical-align: text-top;
        margin-right: 10px;
        cursor: pointer;
    }
}

.blue-button {
    background-color: #61a5de;

    &:focus {
        box-shadow: none !important;
    }
}

.click-row {
    cursor: pointer;

    &:hover {
        background-color: rgba(0, 0, 0, 0.04);
    }
}

.mat-flat-button {
    min-width: 120px !important;
    margin-right: 10px !important;
    font-size: 21px;
    margin-left: 0px !important;

    &.mat-error {
        display: inline-block;
    }

    &:focus {
        outline: none;
        box-shadow: 0 0 0 2px #101010;
    }

    &[disabled] {
        color: #fff !important;
    }

    &.orange {
        background-color: rgba(254, 165, 59, 1);

        &[disabled] {
            background-color: rgba(254, 165, 59, 1);
        }
    }

    &.red {
        background-color: rgba(239, 97, 72, 1);

        &[disabled] {
            background-color: rgba(239, 97, 72, 1);
        }
    }

    &.grey {
        background-color: rgba(66, 92, 90, 1);

        &[disabled] {
            background-color: rgba(66, 92, 90, 1);
        }
    }

    &.simple {
        min-width: auto !important;
    }
}

.sidenav {
    padding-top: 11px;
}

.lesson-wrap-reso {
    border-radius: 10px;
    box-shadow: 0 0 6px rgba($color: #000000, $alpha: 0.5);
    text-align: center;
    padding-bottom: 10px;
    margin: 5px;
    height: 98%;

    .lesson-image {
        img {
            border-radius: 10px;
            max-width: 100%;

            &.quiz {
                max-height: 303px;
            }
        }
    }

    .lesson-title {
        color: #425c5a;
        margin-top: 15px;
        margin-bottom: 15px;
        font-size: 16px;
    }

    .lesson-button {
        margin: 18px 0 15px 0 !important;
        line-height: 40px !important;
    }

    .lesson-description {
        color: #425c5a;
        font-size: 14px;
    }

    .lesson-letters {
        color: #425c5a;
        font-size: 35px;
        font-family: QuranErafont;

        .fCalibri {
            font-size: 14px !important;
            font-family: Calibri !important;
        }

        span {
            //font-size: 18px;
        }
    }

}

.lesson-wrap {
    border-radius: 10px;
    box-shadow: 0 0 6px rgba($color: #000000, $alpha: 0.5);
    text-align: center;
    padding-bottom: 100px;
    margin: 5px;
    height: 107%;

    .lesson-image {
        img {
            border-radius: 10px;
            max-width: 100%;

            &.quiz {
                max-height: 303px;
            }
        }
    }

    .lesson-title {
        color: #425c5a;
        margin-top: 15px;
        margin-bottom: 15px;
        font-size: 16px;
    }

    .lesson-button {
        margin: 18px 0 15px 0 !important;
        line-height: 40px !important;

        &.card-btn {
            margin: 0px 0 25px 0 !important;
            line-height: 40px !important;
        }
    }

    .lesson-description {
        color: #425c5a;
        font-size: 14px;
    }

    .lesson-letters {
        color: #425c5a;
        font-size: 35px;
        font-family: QuranErafont;

        .fCalibri {
            font-size: 14px !important;
            font-family: Calibri !important;
        }

        span {
            //font-size: 18px;
        }
    }
}

.mat-paginator-container {
    justify-content: flex-start !important;
}

.pull-right {
    float: right;
    white-space: nowrap;
}

.custom-select {
    &.white-background {
        .mat-select {
            background-color: #fff;
        }
    }

    .mat-select {
        display: inline-block;
        padding: 10px 20px;
        background-color: #efefef;
        border-radius: 10px;
        font-size: 18px;
        width: auto;
        margin-left: 10px;

        .mat-select-value {
            max-width: none;
            text-align: center !important;
        }
    }
}

.colored-tab-item {
    padding-left: 13px;
    padding-right: 13px;

    .green {
        background-color: #a0e3dc;

        .mat-card-content {
            color: #425c5a;
            font-weight: bold;
        }
    }

    .mat-card-content {
        font-size: 20px;
        text-align: center;
        color: #718583;
    }

    &:hover {
        .top-line {
            background-color: #01bfa5;
        }
    }
}

.mat-drawer-container {
    background-color: #f6f7f9;
}

.lesson-vowels {
    color: #425c5a;
    height: 80px;
    position: relative;

    .lesson-sncd-line {
        span {
            color: #425c5a;
            height: 80px;
            position: relative;
            font-size: 17px !important;
            font-family: PointSoft !important;
        }

        .kasra01 {
            font-family: Arabic Typesetting !important;
            font-size: 65px !important;
            position: relative !important;
        }
    }

    .kasra {
        position: relative;
        font-size: 40px !important;

        .vowel-line {
            font-size: 57px;
            position: absolute;
            top: -4px;
            left: 50%;
            margin-left: -7px;
        }

        .fCalibri {
            font-size: 14px !important;
            font-family: Calibri !important;
        }

        .tanween {
            top: 63px !important;
        }

        .vowel-line-Waw {
            font-size: 57px;
            position: absolute;
            top: -4px;
            left: 53%;
            margin-left: -7px;
        }

        .vowel-line-start {
            font-size: 36px;
            position: absolute;
            top: 25px;
            left: 46%;
            margin-left: -7px;
        }

        .vowel-line-end {
            font-size: 57px;
            position: absolute;
            top: -5px;
            left: 50%;
            margin-left: -7px;
        }

        .vowel-line-endA {
            font-size: 57px;
            position: absolute;
            top: 29px;
            left: 54.2%;
            margin-left: -7px;
        }

        .vowel-line-endAK {
            font-size: 57px;
            position: absolute;
            top: 29px;
            left: 50%;
            margin-left: -7px;
        }

        .vowel-line-endS {
            font-size: 57px;
            position: absolute;
            top: -7px;
            left: 78.2%;
            margin-left: -7px;
        }

        .vowel-line-endSS {
            font-size: 57px;
            position: absolute;
            top: 22px;
            left: 81.2%;
            margin-left: -7px;
        }
    }

    .tanween {
        top: 40px !important;
    }

    .fCalibri {
        font-size: 14px !important;
        font-family: Calibri !important;
    }

    .fArabic {
        font-family: 'Arabic Typesetting' !important;
    }

    .fatan {
        top: 29px !important;
    }

    .dhamma-top {
        margin-top: 10px !important;
    }

    .dhamma {
        font-size: 24px !important;
    }

    span {
        font-size: 30px;
    }

    .vowel-circle {
        font-family: QuranErafont;
        font-size: 30px;
    }

    .vowel-line {
        font-size: 57px;
        position: absolute;
        top: 7px;
        left: 50%;
        margin-left: -7px;
    }

    .vowel-line2 {
        font-size: 57px;
        position: absolute;
        top: 33px;
        left: 50%;
        margin-left: -7px;
    }
}

.disabledRow {
    opacity: 0.5;
}

.email-field {
    .mat-form-field-flex {
        background: none !important;
        padding-left: 0 !important;
        padding-right: 1.5em !important;

        input {
            color: inherit;
        }
    }
}

.navbar-sidenav-container {
    .mat-nav-list {
        .mat-list-item {
            height: 80px !important;
        }
    }

    .mat-list-text {
        padding-top: 10px !important;
        font-size: 22px;
    }
}

.mat-header-cell {
    font-size: 17px;
    font-weight: bold;
}

.mat-cell,
.mat-footer-cell {
    font-size: 16px;
}

#studentsMainContent {
    .mat-paginator-page-size {
        display: flex !important;
    }
}

.mat-paginator-page-size {
    display: none !important;
}

.mat-paginator {
    font-size: 14px;
}

.student-disabled {
    color: #f00;
    font-weight: bold;
    float: right;
}

.pagination-wrap {
    margin-top: 50px;
    text-align: center;

    .pagination {
        display: inline-block;
        padding-left: 0;

        .page-item {
            display: inline-block;
            list-style: none;
            margin: 0 10px;

            span {
                color: rgba(0, 0, 0, 0.54);
                cursor: pointer;

                svg {
                    width: 20px;
                    margin-top: -6px;
                }

                &.active {
                    font-weight: bold;
                }
            }
        }
    }
}

.resource-wrap {
    border-radius: 10px;
    box-shadow: 0 0 6px rgba($color: #000000, $alpha: 0.5);
    text-align: center;
    padding-bottom: 5px;
    margin: 5px;
    height: 97%;
    cursor: pointer;

    .resource-image {
        img {
            border-radius: 10px;
            max-width: 100%;

            &.quiz {
                max-height: 303px;
            }
        }
    }

    .resource-title {
        color: #425c5a;
        margin-top: 5px;
        font-size: 17px;
    }
}

.sub-title {
    padding: 17px;
    border-radius: 10px;
    background-color: #fedfba;
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 10px;
}

.custom-full-width {
    width: 100%;

    .mat-form-field-flex {
        background-color: whitesmoke;
        padding-bottom: 10px;
        border-radius: 10px;
        padding-left: 10px;
        padding-right: 10px;
    }
}

.custom-field-wrap {
    margin-bottom: 10px;
    margin-top: 15px;
    vertical-align: top;

    &.field-wrap-select {
        .mat-form-field {
            width: 100%;
        }
    }

    &.field-wrap-inline {
        display: inline-block;

        &.field-wrap-select {
            width: 100%;

            .mat-form-field {
                width: 100%;
            }

            .mat-form-field-flex {
                padding-bottom: 3px !important;
            }
        }
    }

    .mat-form-field {
        .mat-form-field-wrapper {
            .mat-form-field-flex {
                border-radius: 10px;
                padding-bottom: 0.3em;
                padding-top: 0.3em;

                .mat-form-field-infix {
                    width: 300px;
                }
            }

            .mat-form-field-underline {
                display: none;
            }
        }
    }

    .custom-label {
        display: block;
        margin-bottom: 5px;
        color: #425c5a;
        font-size: 19px;
    }
}

.inner-tabs {
    .blue-button {
        min-width: 255px !important;
        border-radius: 25px !important;
        background: none;
        color: #61a5de;
        border: #61a5de 1px solid;

        &-active {
            background: #61a5de;
            color: #fff;
        }
    }
}

.custom-modal-wrap {
    .mat-dialog-container {
        border-radius: 15px;
        padding: 0;
    }
}

.custom-modal {
    width: 450px;

    &-header {
        background: #a6e8df;
        padding: 18px;
        border-radius: 15px;
        font-weight: bold;
        font-size: 20px;

        img {
            width: 93px;
            position: absolute;
            margin-top: -40px;
            margin-left: 310px;
        }
    }

    &-content {
        padding: 18px;
        color: #425c5a;
        font-size: 16px;

        strong {
            color: #01bfa5;
        }
    }

    &-btn {
        text-align: center;

        button {
            width: 100px;
        }
    }

    &-close {
        width: 29px;
        position: absolute;
        z-index: 18;
        margin-top: -15px;
        background: #fff;
        margin-left: 435px;
        border-radius: 50%;
    }
}

.cutsom-table-action {
    border-radius: 10px;
    background-color: #eee;
    display: inline-block;
    padding: 3px;

    &-btn {
        display: inline-block;
        padding: 4px 7px;
        cursor: pointer;

        svg {
            color: #566d6b;
        }

        &-red svg {
            color: #ef7864;
        }

        &:first-child {
            border-right: #000 1px solid;
        }
    }
}

.assignment-status {
    color: #ef7864;

    &.assignment-active {
        color: #01bfa5;
    }
}

.create-an-activity {
    .date-picker-field {
        .mat-form-field-infix {
            border-top: 0;
            line-height: 6px;
        }
    }
}

.manage-assignment-form {
    border-radius: 10px;
    background-color: #f6f7f9;
    padding: 15px 15px 0 15px;

    .mat-form-field-infix {
        border-top: 0;
        line-height: 6px;
    }

    &-element {
        display: inline-block;
    }

    &-action {
        display: inline-block;
    }

    &-title {
        font-weight: bold;
        margin-bottom: 10px;
    }
}

.white-background .search {
    background-color: #fff !important;
}

.report-header {
    margin-bottom: 20px;
}

.custom-widget {
    border-radius: 10px;
    box-shadow: 1px 1px 6px #c3c1c1;
    margin-bottom: 10px;

    &.red-lessons {
        box-shadow: none;

        .custom-widget-item {
            border-right: 0;
            background-color: #fde7e4;
            border-radius: 0px 0px 10px 10px;
            margin: 0px 5px 0 5px;

            div {
                color: #ef6148;
                margin: 10px 0 10px 0;
            }
        }
    }

    &.gold-trophy {
        .custom-widget-header-trophy {
            background-color: #fea53b;
        }
    }

    &.green-quiz {
        .quiz-description {
            background-color: #fff2e2;
            margin: 10px;
            padding: 15px;

            h1 {
                color: #536966;
                font-size: 15px;
            }

            span {
                color: #61a5de;
                display: block;
                font-size: 22px;
                font-weight: bold;
                margin-top: 10px;
            }

            div {
                color: #5a6f6b;
                font-size: 12px;
            }
        }
    }

    &-title {
        color: #01bfa5;
        text-align: center;
        font-weight: bold;
    }

    &-sub-item {
        &-line {
            text-align: center;

            div {
                font-size: 25px;
                font-weight: bold;
                color: #fea53b;
            }

            span {
                font-size: 13px;
                color: #425c5a;
            }
        }
    }

    &-item {
        text-align: center;
        min-height: 110px;
        margin: 10px;
        border-right: 1px solid #cdcdcd;
        padding-left: 0;
        padding-right: 13px;

        &:last-child {
            border-right: 0;
        }

        div {
            font-size: 35px;
            font-weight: bold;
            color: #fea53b;
        }

        span {
            font-size: 13px;
            color: #425c5a;
        }
    }

    &-header {
        padding: 15px 20px;
        border-radius: 10px 10px 0 0;

        &-red-lesson-icon {
            background-image: url("assets/images/admin/red-lesson-icon.png");
            width: 25px;
            height: 25px;
            display: inline-block;
            background-size: 100%;
            background-repeat: no-repeat;
            margin-bottom: -7px;
            margin-right: 10px;
        }

        &-green-quiz-icon {
            background-image: url("assets/images/admin/green-quiz-icon.png");
            width: 25px;
            height: 25px;
            display: inline-block;
            background-size: 100%;
            background-repeat: no-repeat;
            margin-bottom: -7px;
            margin-right: 10px;
        }

        &-blue {
            color: #fff;
            background-color: #61a5de;
        }

        &-red {
            color: #fff;
            background-color: #ef6148;
        }

        &-green {
            color: #fff;
            background-color: #01bfa5;
        }
    }
}

.mat-datepicker-content {
    .mat-calendar {
        height: auto !important;
    }
}

.filter-form .custom-field-wrap {
    .mat-input-element {
        margin-bottom: 8px;
    }

    .mat-select {
        margin-top: 8px;
    }

    .mat-form-field-label-wrapper {
        .mat-form-field-label {
            margin-top: 0;
        }
    }
}

.custom-select {
    .mat-select {
        .mat-select-trigger {
            padding: 6px 55px;
        }
    }
}