.frame {
    .items.zone-6-frame {

        .country {
            &.zone-6 {
                background-image: url("assets/images/zones/6/zone6.png"), url("assets/images/zones/5/frame-bg-2.png");
                width: 100%;
                height: 100%;
            }

            &.zone-6-germany {
                background-image: url("assets/images/zones/6/germany-zone6.png");
                width: 179px;
                height: 192px;
                bottom: 23px;
            }

            &.zone-6-bosnia {
                background-image: url("/assets/images/zones/6/bosnia.png");
                width: 28px;
                height: 50px;
                bottom: 0;
                left: 252px;
            }

            &.poland {
                background-image: url("assets/images/zones/6/poland.png");
                background-position: 1px -24px;
                width: 264px;
                height: 275px;
                bottom: 21px;
                left: 87px;
                &.hover {
                    background-position: 0px -393px;
                }
            }
            &.russia {
                background-image: url("assets/images/zones/6/russia.png");
                width: 824px;
                height: 716px;
                right: -91px;
                bottom: -84px;
                background-position: 0 -148px;
                &.hover {
                    background-position: -1px -931px;
                }
            }
            &.finland {
                background-image: url("assets/images/zones/6/finland.png");
                width: 276px;
                height: 247px;
                left: 203px;
                top: -17px;
                background-position: 0 -60px;
                &.hover {

                    background-position: 1px -405px;
                }
            }
            &.sweden {
                background-image: url("assets/images/zones/6/sweden.png");
                width: 235px;
                height: 343px;
                left: 57px;
                background-position: 0 -55px;
                top: 0;
                &.hover {
                    background-position: 0px -459px;
                    height: 354px;
                    top: 2px;
                }
            }
        }


        .poi {
            &.poland {
                left: 269px;
                bottom: 132px;
            }
            &.russia {
                right: 235px;
                top: 50%;
            }
            &.finland {
                left: 267px;
                top: 161px;
            }
            &.sweden {
                left: 88px;
                top: 237px;
            }
            &.quiz-1 {
                left: 360px;
                bottom: 61px;
                width: 50px;
                height: 53px;
                background-size: cover;
                top: auto;
                z-index: 10;
                background: url("assets/images/zones/quiz-activity-new.png") bottom;
            }
            &.quiz-2 {
                left: 112px;
                bottom: 200px;
                width: 50px;
                height: 53px;
                background-size: cover;
            }
        }
    }
    .road.zone-6-1 {
        z-index: 10;
        bottom: 133px;
        left: 0;
        width: 144px;
        height: 80px;
        right: auto;
    }

    .road.zone-6 {
        bottom: -62px;
        left: 113px;
        z-index: 10;
    }
}
