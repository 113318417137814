.games{
    .leave-button {
        width: 121px;
        height: 134px;
        background: url("assets/images/awards/awards-back.png");
        background-position: bottom;
        border: none;
        outline: none;
        position: absolute;
        z-index: 2;
        left: 0;
        top: 0;
        &:hover {
            background-position: top;
        }
    }
    .slider-back, .slider-next
    {
        width: 100px;
        height: 106px;
        background: url("assets/images/games/games-arrows.png");
        background-position: bottom left;
        border: none;
        outline: none;
        position: absolute;
        z-index: 2;
        left: 10px;
        top: 280px;
        &:hover {
            background-position: top left;
        }
    }
    .slider-next
    {
        background-position: bottom right;
        left: auto;
        right: 10px;
        &:hover {
            background-position: top right;
        }
    }
    .blocks-list
    {
        width: 736px;
        position: relative;
        top: 150px;
        left: 122px;
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        align-items: stretch;
    }
    .game-item
    {
        width: 160px;
        height: 128px;
        border: solid 4px transparent;
        position: relative;
        overflow: hidden;
        box-shadow: 0 0 10px rgba($color: #000000, $alpha: .6);
        border-radius: 16px;
        margin: 0 16px 16px 0;
        cursor: pointer;
        transition: all linear 300ms;
        &:hover
        {
            border: solid 4px #9458db;
            
        }
        
        .thumbnail
        {
            width: 158px;
            height: 94px;
            margin: 0 auto;
            display: table;
            width: 100%;
            max-width: 100%;
            
            background-position: 0 0;
            &.match-the-codes
            {
                background: url("assets/images/games/dolphines.jpg");
            }
            &.drag-drop
            {
                background: url("assets/images/games/drag-and-drop-game.jpg");
            }
        }
        &:nth-child(5n-5)
        {
            .match-the-codes
            {
                background-position: 0 -94px;
            }
            
        }
        &:nth-child(5n-4)
        {
            .match-the-codes
            {
                background-position: 0 -188px;
            }
            
        }
        &:nth-child(5n-3)
        {
            .match-the-codes
            {
                background-position: 0 -282px;
            }
            
        }
        &:nth-child(5n-2)
        {
            .match-the-codes
            {
                background-position: 0 -376px;
            }
            
        }
        &:nth-child(5n-1)
        {
            .match-the-codes
            {
                background-position: 0 -470px;
            }
            
        }
        &:nth-child(4n+3)
        {
            .drag-drop
            {
                background-position: 0 -94px;
            }            
        }
        &:nth-child(4n+4)
        {
            .drag-drop
            {
                background-position: 0 -188px;
            }            
        }
        &:nth-child(4n+5)
        {
            .drag-drop
            {
                background-position: 0 -282px;
            }            
        }
        &:nth-child(4n+2)
        {
            .drag-drop
            {
                background-position: 0 -376px;
            }            
        }

        .number
        {
            width: 40px;
            height: 44px;
            background: url("assets/images/games/number-bg.png");
            position: absolute;
            top: 0;
            left: 0;
            line-height: 44px;
            text-align: center;
            color: rgba($color: #000000, $alpha: .8);
            font-family: "Kids Rock DEMO";
            font-size: 1.2rem;
            z-index: 6;
        }
        .description-box
        {
            width: 100%;
            padding: 7px 30px 7px 10px;
            position: absolute;
            bottom: 0;
            left: 0;
            font-family: 'Quicksand', sans-serif;
            background-color: #ffffff;
            font-weight: bold;
            text-align: left;
            font-size: 0.8rem;
            line-height: 0.8rem;
            color: rgba($color: #000000, $alpha: .8);
            &::after
            {
                content: '';
                width: 36px;
                height: 36px;
                position: absolute;
                right: 0;
                top: -19px;
                background-color: #ffffff;
                border-radius: 50%;
                background-image: url("assets/images/games/game-icon.png");
                background-repeat: no-repeat;
                background-position: center center;
            }
        }
        &.lock
        {
            cursor: default;
            &:hover
            {
                border-color: transparent;
            }
            &::after
            {
                content: '';
                width: 168px;
                height: 136px;
                position: absolute;
                top: -4px;
                left: -4px;
                z-index: 4;
                border-radius: 10px;
                background-color: rgba($color: #000000, $alpha: .6);
            }
            &::before
            {
                content: '';
                width: 36px;
                height: 36px;
                position: absolute;
                top: 0;
                right: 0;
                z-index: 6;
                border-radius: 0 10px 0 10px;
                background-color: #ffffff;
                background-image: url("assets/images/games/games-lock.png");
                background-repeat: no-repeat;
                background-position: center center;
            }
        }
    }
}