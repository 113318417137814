.frame {
    .items.africa-3-frame {
        .road.africa-3 {
            z-index: 10;
            bottom: 76px;
            right: 124px;
            height: 527px !important;
        }

        .country {
            &.hover {
                z-index: 1;

                &.active {
                    z-index: 6;
                }
            }

            &.africa-3 {
                background-image: url("assets/images/zones/africa-3/africa-3.png"), url("assets/images/zones/africa-3/bg.jpg");
                width: 100%;
                height: 100%;
                background-position: bottom right !important;
            }

            &.madagascar {
                background-image: url("assets/images/zones/africa-3/madagascar.png");
                background-position: 0 0;
                width: 343px;
                height: 320px;
                right: -17px;
                top: 4px;
                z-index: 7;

                &.hover {
                    background-position: 0px -340px;
                }
            }

            &.southAfrica {
                background-image: url("assets/images/zones/africa-3/southAfrica.png");
                background-position: 0 0;
                width: 380px;
                height: 350px;
                left: 196px;
                top: 199px;
                z-index: 8;

                &.hover {
                    background-position: -1px -353.5px;
                }
            }

            &.zimbabwe {
                background-image: url("assets/images/zones/africa-3/zimbabwe.png");
                width: 343px;
                height: 284px;
                right: 311px;
                top: 20px;
                background-position: 0 0;
                z-index: 7;

                &.hover {
                    background-position: -1px -343px;
                }
            }

            &.namibia {
                background-image: url("assets/images/zones/africa-3/namibia.png");
                width: 343px;
                height: 325px;
                bottom: 189px;
                right: 530px;
                z-index: 6;
                background-position: 0px 0px;

                &.hover {
                    background-position: 1.4px -298px;
                }
            }
        }

        .poi {
            &.madagascar {
                top: 88px;
                right: 103px;
                bottom: unset;
            }

            &.southAfrica {
                top: 416px;
                right: 583px;
                bottom: unset;
            }

            &.zimbabwe {
                bottom: 356px;
                right: 439px;
                top: unset;
            }

            &.namibia {
                right: 691px;
                top: 293px;
            }

            &.quiz-1 {
                top: 487px;
                width: 64px;
                height: 64px;
                background-size: cover;
                right: 857px;
                background-image: url("assets/images/zones/poi-quiz.png");
            }

            &.lock {
                background-image: url("assets/images/zones/zone-poi-lock.png");
            }
        }
    }
}
