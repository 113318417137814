.letters{
    .leave-button {
        width: 121px;
        height: 134px;
        background: url("assets/images/awards/awards-back.png");
        background-position: bottom;
        border: none;
        outline: none;
        position: absolute;
        z-index: 2;
        left: 0;
        top: 0;
        &:hover {
            background-position: top;
        }
    }
    .slider-back, .slider-next
    {
        width: 100px;
        height: 106px;
        background: url("assets/images/games/games-arrows.png");
        background-position: bottom left;
        border: none;
        outline: none;
        position: absolute;
        z-index: 2;
        left: 10px;
        top: 280px;
        &:hover {
            background-position: top left;
        }
    }
    .slider-next
    {
        background-position: bottom right;
        left: auto;
        right: 10px;
        &:hover {
            background-position: top right;
        }
    }
    .blocks-list
    {
        width: 700px;
        position: relative;
        top: 180px;
        left: 140px;
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        align-items: stretch;
    }
    .item
    {
        width: 205px;
        height: 158px;
        border: solid 4px transparent;
        position: relative;
        box-shadow: 0 0 10px rgba($color: #000000, $alpha: .6);
        border-radius: 8px;
        margin: 0 14px 46px;
        cursor: pointer;
        transition: all linear 300ms;

        img
        {
            max-width: 100%;
            border-radius: 4px 4px 0 0;
        }

        &:hover
        {
            border: solid 4px #5ed933;
            .number
            {
                background-position: top;
            }
            
        }
        
        .number
        {
            width: 48px;
            height: 48px;
            background: url("assets/images/new-letters/letters-number-bg.png");
            background-position: bottom;
            position: absolute;
            top: 0;
            left: -24px;
            line-height: 44px;
            text-align: center;
            color: rgba($color: #000000, $alpha: .8);
            font-family: "Kids Rock DEMO";
            font-size: 1.2rem;
            z-index: 6;
        }
        .description-box-menu
        {
            width: 100%;
            min-height: 40px;
            padding: 8px 30px 8px 5px;
            position: absolute;
            bottom: 0;
            left: 0;
            font-family: sans-serif;
            background-color: #ffffff;
            font-weight: bold;
            text-align: left;
            font-size: 0.8rem;
            line-height: 0.8rem;
            color: rgba($color: #000000, $alpha: .8);
            &::after
            {
                content: '';
                width: 30px;
                height: 30px;
                position: absolute;
                right: 0;
                top: -19px;
                background-color: #ffffff;
                border-radius: 50%;
                background-image: url("assets/images/new-letters/play-icon.png");
                background-repeat: no-repeat;
                background-position: center center;
            }
        }
        .description-box
        {
            width: 100%;
            min-height: 40px;
            padding: 8px 30px 8px 5px;
            position: absolute;
            bottom: 0;
            left: 0;
            font-family: 'Quicksand', sans-serif;
            background-color: #ffffff;
            font-weight: bold;
            text-align: left;
            font-size: 0.8rem;
            line-height: 0.8rem;
            color: rgba($color: #000000, $alpha: .8);
            &::after
            {
                content: '';
                width: 30px;
                height: 30px;
                position: absolute;
                right: 0;
                top: -19px;
                background-color: #ffffff;
                border-radius: 50%;
                background-image: url("assets/images/new-letters/play-icon.png");
                background-repeat: no-repeat;
                background-position: center center;
            }
        }
        &.lock
        {
            cursor: default;
            &:hover
            {
                border-color: transparent;
            }
            &::after
            {
                content: '';
                width: 205px;
                height: 158px;
                position: absolute;
                top: -4px;
                left: -4px;
                z-index: 4;
                border-radius: 8px;
                background-color: rgba($color: #000000, $alpha: .4);
            }
            &::before
            {
                content: '';
                width: 36px;
                height: 36px;
                position: absolute;
                top: 0;
                right: 0;
                z-index: 6;
                border-radius: 0 10px 0 10px;
                background-color: #ffffff;
                background-image: url("assets/images/games/games-lock.png");
                background-repeat: no-repeat;
                background-position: center center;
            }
        }
    }
    &.stories
    {
        .blocks-list
        {
            top: 150px;
        }
    }

}