.progress {
    .leave-button {
        width: 121px;
        height: 134px;
        background: url("assets/images/awards/awards-back.png");
        background-position: bottom;
        border: none;
        outline: none;
        position: absolute;
        z-index: 2;
        left: 0;
        top: 0;

        &:hover {
            background-position: top;
        }
    }

    .top-bar {
        position: relative;
        top: 0!important;
        margin-top:120px!important;

        .select-box {
            width: 190px;
            margin-right: 100px;
            position: absolute;
            left: 194px;
            top: -5px;

            .main-select {
                width: 185px;
                height: 47px;
                position: absolute;
                z-index: 56;
                background-image: url("assets/images/progress/progress-slect.png");
                background-position: bottom;
                padding-right: 30px;
                margin: 4px 2px;
                cursor: pointer;

                span {
                    font-family: 'Quicksand', sans-serif;
                    color: #DBECFB;
                    font-size: 1.1rem;
                    line-height: 46px;
                    background-color: #DBECFB;
                    -webkit-background-clip: text;
                    font-weight: bolder;
                    -webkit-text-fill-color: transparent;
                    // -webkit-text-stroke: 1px #03176E;
                    filter: drop-shadow(2px 2px rgba($color: #03176E, $alpha: .2) );
                }

                &.active {
                    background-position: top;
                }
            }


            .select-items {
                width: 184px;
                max-height: 400px;
                font-family: 'Quicksand', sans-serif;
                position: absolute;
                background-color: #186994;
                top: 4px;
                left: 4px;
                z-index: 50;
                padding: 5px 8px 4px 8px;
                border-radius: 0 0 15px 15px;
                box-shadow: 0 0 10px #186994;
                overflow-y: auto;
                margin-top: 46px;

                div {
                    color: #DBECFB;
                    padding: 4px 0;
                    border-bottom: 1px solid rgba(#89B6D9, .5);
                    cursor: pointer;
                }

                &::-webkit-scrollbar {
                    width: 6px;
                    background-color: transparent;
                }

                &::-webkit-scrollbar-thumb {
                    background-color: #186994;
                }

                &::-webkit-scrollbar-track {
                    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
                    background-color: transparent;
                }
            }
            /* Hide the items when the select box is closed: */
            .select-hide {
                display: none;
            }

            .select-items div:hover {
                background-image: url("assets/images/progress/progress-slected.png");
                background-repeat: no-repeat;
                border: none;
                margin-top: 1px;
            }
        }

        .points {
            width: 100px;
            position: absolute;
            left: 700px;
            top: 88px;

            ul {
                list-style: none;
                margin: 0;
                padding: 0;

                li {
                    font-family: 'Kids Rock DEMO Regular', sans-serif;
                    font-size: 1.5rem;
                    width: auto !important;
                    display: inline-flex;
                    color: #EF6100;
                    text-shadow: 0 0 1px rgba($color: #000000, $alpha: .5);
                    margin-top: 5px;

                    img {
                        padding-bottom: 2px !important;
                        margin-left: 30px !important;
                    }

                    span:nth-child(2) {
                        margin-left: 12px !important;
                        cursor: default !important;
                    }
                }
            }
            // &::after
            // {
            //     width: 14px;
            //     height: 17px;
            //     content: "";
            //     background-image: url("assets/images/progress/progress-arrows.png");
            //     position: absolute;
            //     right: 10px;
            //     top: 8px;
            // }
            // &.down::after
            // {
            //     background-position: bottom;
            //     top: 40px;
            // }
        }
    }

    .bottom-bar {
        position: relative;
        top: 0!important;
        margin-top: 248px!important;

        .progress-box {
            width: 55px;
            position: absolute;
            top: -21px;
            left: 141px;

            ul {
                list-style: none;
                margin: 0;
                padding: 0;
                display: flex;
                flex-direction: column;
            }

            ul > :last-child {
                margin-top: auto;
            }
        }

        .progress {
            width: 198px;
            margin: 10px 0 6px 12px;
            float: left;

            .bar {
                height: 17px;
                border-radius: 10px;

                .mat-progress-bar-background {
                    display: none;
                }

                .mat-progress-bar-fill {
                    border-radius: 20px;

                    &::after {
                        border-radius: 30px;
                        // background-color: #0065C3;
                        background: linear-gradient(to top, #F08236, #DD5112 20%, #F08236 70%, #FFCA61);
                    }
                }

                .mat-progress-bar-buffer {
                    background: none;
                }
            }
        }

        .minuts {
            font-family: 'Quicksand', sans-serif;
            margin: 6.5px 2px 0 0;
            float: right;
            color: #161D35;
            font-size: 0.8rem;
            font-weight: bold;
        }

        .chart-box {
            width: 262px;
            height: 241px;
            position: absolute;
            top: -3px;
            left: 200px;
            display: inline-flex;
        }

        .chart-box > .chart-column {
            height: 100% !important;
            width: 50% !important;
            vertical-align: bottom;
            position: relative;
        }

        .chart-box > .chart-column > .chart-percentage-tw {
            width: 25% !important;
            position: absolute;
            background: rgb(2,0,36);
            background: linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(255,119,215,1) 0%, rgba(196,0,138,1) 100%);
            bottom: 0 !important;
            left: 32.5% !important;
            border-top-left-radius: 25px !important;
            border-top-right-radius: 25px !important;
        }

        .chart-box > .chart-column > .chart-percentage-lw {
            width: 25% !important;
            position: absolute;
            background: rgb(2,0,36);
            background: linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(252,255,136,1) 0%, rgba(201,198,0,1) 100%);
            bottom: 0 !important;
            left: 32.5% !important;
            border-top-left-radius: 25px !important;
            border-top-right-radius: 25px !important;
        }

        .time-box {
            position: absolute;
            left: 718px;
            top: 168px;
            color: #ffffff;
            font-family: 'Quicksand', sans-serif;
            font-size: 1.5rem;
            font-weight: 800;
        }
    }
}
